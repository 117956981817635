import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function clearLocations() {
    return (dispatch) => {
        dispatch({type: actionTypes.LOCATION_LIST_CLEAR});
    };
}

export function clearLocation() {
    return (dispatch) => {
        dispatch({type: actionTypes.LOCATION_GET_CLEAR});
    };
}

export function listLocations(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/location/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.LOCATION_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function save(obj, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/location/save", obj)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function listCustomers(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/customer/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.LOCATION_LIST_CUSTOMER, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function getLocation(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/location/get/?id=" + id)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.LOCATION_GET, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function removeLocation(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/location/delete", {id: id})
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}