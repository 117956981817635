import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/shipment/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.SHIPMENT_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function get(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/shipment/get/?id=" + id)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.SHIPMENT_GET_DETAILS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function getVisualTrackingItems(shipmentId) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/shipment/getVisualTrackingItems?shipmentId="+shipmentId)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.SHIPMENT_GET_VISUAL_TRACKING, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearVisualTrackingItems() {
    return (dispatch) => {
        dispatch({type: actionTypes.SHIPMENT_CLEAR_VISUAL_TRACKING});
    };
}

export function clearList() {
    return (dispatch) => {
        dispatch({type: actionTypes.SHIPMENT_LIST_CLEAR});
    };
}

export function clear() {
    return (dispatch) => {
        dispatch({type: actionTypes.SHIPMENT_CLEAR_DETAILS});
    };
}

export function remove(obj, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/shipment/delete", obj)
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}