import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import * as notificationActions from "../../../notifications/actions/notificationActions";
import * as orderActions from "../../actions/orderActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import FormLoader from "../../../../components/common/FormLoader";
import FileImportLine from "./visualTracking/FileImportLine";

class OrderVisualTrackingTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            orderId: this.props.orderId,
            isLoading: false,
            isWorking: false
        };
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.props.actions.clearVisualTrackingItems();
        this
            .props
            .actions
            .getVisualTrackingItems(this.state.orderId)
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            })
    }

    render() {
        return (
            <div className="relative">
                {this.state.isLoading
                    ? <FormLoader/>
                    : null}
                {this.props.orders && this.props.orders.order && this.props.orders.order.exception?<div class="alert alert-danger margin-top-10">
                    <strong>Unhandled error!</strong> {this.props.orders.order.exception}
                </div>:null}
                {(this.props.orders && this.props.orders.visualTrackingItems)?
                    this.props.orders.visualTrackingItems.map(x=> {
                        return (<FileImportLine
                            history={this.props.history}
                            settings={this.props.settings}
                            key={x.fileImport.id}
                            item={x}
                            reprocess={this.props.reprocess}
                            saveData={this.props.saveData}
                        />);
                    }):null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        orders: state.order,
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(orderActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderVisualTrackingTab)