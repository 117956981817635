import React, {Component} from 'react'
import * as orderActions from "../actions/orderActions";
import * as notificationActions from "../../notifications/actions/notificationActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {DropdownButton, MenuItem, Tab, Tabs} from "react-bootstrap";
import VisualTrackingTab from "./tabs/OrderVisualTrackingTab";
import FormLoader from "../../../components/common/FormLoader";
import TriggerExecutionsTab from "./tabs/TriggerExecutionsTab";
import FlagsTab from "./tabs/FlagsTab";
import ChangeHistoryTab from './tabs/ChangeHistoryTab';
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import OrderSettingsTab from "./tabs/OrderSettingsTab";
import OrderActionsManager from "./OrderActionsManager";
import * as OrderManagerActions from '../constants/orderActionsManagerTypes';
import * as orderStates from '../constants/orderStates';
import getLabelByValue from "../../../utils/enumHelper";
import {Helmet} from "react-helmet";
import SyncTab from "./tabs/SyncTab";

class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.reloadOrder();
    }

    reloadOrder = () =>{
        this.props.actions.clear();
        if (this.state.orderId) {
            this.setState({isLoading: true});
            this
                .props
                .actions
                .get(this.state.orderId)
                .then(() => {
                    this.setState({isLoading: false})
                })
                .catch(() => {
                    this.setState({isLoading: false})
                })
        }else {
            this.setState({order: {}});
        }
    }

    getInitialState() {
        return {
            orderId: this.props.match.params.orderId,
            isLoading: false,
            isWorking: false,
            orders: {},
            order: {},
            selectedTab: this.props.match.params.tab,
            showReprocessOrderConfirm: false,
            showActionsManager: false,
            activeOrderAction: ''
        };
    }

    reprocessOrder = () => {
        this.setState({isLoading: true});
        this.props.actions
          .reprocessOrder({id: this.state.orderId})
          .then(() => {})
          .catch((error) => { console.log(error); }).then(()=>{
            Promise.all([
                this.props.actions.get(this.state.orderId),
                this.props.actions.getVisualTrackingItems(this.state.orderId)
            ]).then(()=>{}).catch(()=>{}).then(()=>{
                this.setState({
                    isLoading: false,
                    showReprocessOrderConfirm: false,
                });
            });
        });
    };

    editStageData = (data) => {
        this.setState({isLoading: true});
        this.props.actions
            .editStageData({...data, orderId: this.state.orderId})
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            });
    };

    reprocessFromStep = (data) => {
        this.setState({isLoading: true});
        this.props.actions
            .reprocessFromStep({...data, orderId: this.state.orderId})
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            });
    };

    render() {
        let { orders } = this.props;

        return (
            <div>
                <Helmet>
                    {orders.order && orders.order.items && orders.order.items.length > 0 ? <title>{'SL3 - '+ orders.order.items[0].identityNumber}</title>:  <title>SL3 - Order</title>}
                </Helmet>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Order details{(orders && orders.order) ? (": "+orders.order.id+" "+getLabelByValue(orderStates, orders.order.state)  ):null}</span>
                            </h4>
                        </div>

                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <DropdownButton
                                    className="bg-slate"
                                    bsStyle="Success"
                                    title={'Actions'}>
                                        <MenuItem eventKey="1" onClick={(e)=>{
                                            this.setState({
                                                showActionsManager: true,
                                                activeOrderAction: OrderManagerActions.LOCATION_OVERWRITE
                                            });
                                        }}>Location overwrite</MenuItem>
                                    <MenuItem eventKey="2" onClick={(e)=>{
                                        this.setState({
                                            showActionsManager: true,
                                            activeOrderAction: OrderManagerActions.CHANGE_STATUS
                                        });
                                    }}>Change status</MenuItem>
                                </DropdownButton>
                                <a onClick={() => this.setState({ showReprocessOrderConfirm: true })}
                                   className="btn bg-slate has-text">
                                    <i className="fa fa-refresh" />
                                    <span>Reprocess</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat relative">
                    {this.state.isLoading
                        ? <FormLoader/>
                        : null}
                    <div className="panel-body">
                        <Tabs
                            id="OrdersTabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(key) => this.setState({selectedTab: key},() => {
                                this.props.history.push('/order/details/'+this.state.orderId+'/'+key);
                            })}>
                            <Tab eventKey={"visualTracking"} title="Visual tracking">
                                <VisualTrackingTab
                                    settings={this.props.settings}
                                    orderId={this.state.orderId}
                                    history={this.props.history}
                                    reprocess={(step) => this.setState({ showReprocessOrderConfirm: true, reprocessStage: step })}
                                    saveData={(data) => this.editStageData(data)}/>
                            </Tab>
                            <Tab eventKey={"triggers"} title="Triggers">
                                <TriggerExecutionsTab
                                    history={this.props.history}
                                    turvoShipmentId={this.props.orders && this.props.orders.order? this.props.orders.order.turvoShipmentId: null}
                                    orderId={this.state.orderId}/>
                            </Tab>
                            <Tab eventKey={"flags"} title="Flags">
                                <FlagsTab history={this.props.history}
                                          orderId={this.state.orderId}
                                          turvoShipmentId={this.props.orders && this.props.orders.order? this.props.orders.order.turvoShipmentId: null}/>
                            </Tab>
                            <Tab eventKey={"history"} title="History">
                                <ChangeHistoryTab orderId={this.state.orderId}/>
                            </Tab>
                            <Tab eventKey={"sync"} title="Sync">
                                <SyncTab orderId={this.state.orderId}/>
                            </Tab>
                            <Tab eventKey={"settings"} title="Settings">
                                <OrderSettingsTab
                                    settings={this.props.orders && this.props.orders.order? this.props.orders.order.settings: {}}
                                    onChange={(set)=>{
                                        console.log(set);
                                        this.props.actions.updateOrderSettings(set);
                                    }}
                                    onSave={()=> {
                                        this.props.actions.saveOrderSettings(this.props.orders.order).then(()=>{
                                            this
                                                .props
                                                .notification
                                                .add("Order settings saved", "", "success");
                                        }).catch(()=>{
                                            this
                                                .props
                                                .notification
                                                .add("Cannot save order settings", "", "fail");
                                        });
                                    }}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <ConfirmationModal
                    title={"Reprocess order confirmation"}
                    description={`Are you sure you want to reprocess order${this.state.reprocessStage ? ` from stage: ${this.state.reprocessStage}`: ''}?`}
                    onClose={() => this.setState({ showReprocessOrderConfirm: false })}
                    onConfirm={this.reprocessOrder}
                    show={this.state.showReprocessOrderConfirm}/>
                <OrderActionsManager
                    orderId={this.state.orderId}
                    onSave={()=>{
                        this.reloadOrder();
                    }}
                    order={(orders.order? orders.order: null)}
                    show={this.state.showActionsManager}
                    action={this.state.activeOrderAction}
                    onClose={()=>{
                        this.setState({
                            showActionsManager: false
                        });
                }}/>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        orders: state.order,
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        actions: bindActionCreators(orderActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)