import React from "react";
import TagsInput from "react-tagsinput";

type ListVariableDefinitionProps = {
  name: string;
  value: string[];
  onChange: (item: string[]) => void;
};

function ListVariableDefinition(props: ListVariableDefinitionProps) {
  const [tags, setTags] = React.useState(props.value || []);

  return (
    <div className="form-group">
      <label className="col-sm-2 control-label">{props.name}</label>
      <div className="col-sm-8">
        <TagsInput
          tagProps={{
            "aria-label": "Remove",
            className: "react-tagsinput-tag",
            classNameRemove: "react-tagsinput-remove",
          }}
          inputProps={{
            placeholder: "Add value",
          }}
          value={tags}
          onChange={(items) => {
            setTags(items);
            props.onChange(items);
          }}
        />
      </div>
    </div>
  );
}

export default ListVariableDefinition;
