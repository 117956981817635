import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import MegaTable from "../../../components/common/MegaTable";
import * as apiCallActions from "../actions/apiCallActions";
import * as apiTypes from '../constants/apiTypes';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import getLabelByValue from "../../../utils/enumHelper";
import displayDateTime from "../../../utils/dateTimeHelper";
import * as importStates from "../../fileImport/constants/importStates";

class ApiCallsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 10,
            selectedItem: {},
            isWorking: false,
            search: ''
        };
    }

    componentDidMount() {
        this.reloadData();
    }


    reloadData = (noLoader) => {
        if (!noLoader) {
            this.setState({ isWorking: true });
        }
        this
            .props
            .actions
            .list({ page: this.state.page, pageSize: this.state.pageSize, search: this.state.search })
            .then(() => this.setState({ isWorking: false }))
            .catch(() => this.setState({ isWorking: false }));
    }

    getData = () => {
        if (this.props.apiCalls && this.props.apiCalls.list) {
            return this.props.apiCalls.list.data;
        }
        return [];
    }

    render() {
        let columns = [
            {
                name: 'Date',
                id: 'dateTime',
                type: 'custom',
                width: '190',
                renderDisplay: d => <span>
                    {displayDateTime(d.createDate)}
                </span>
            },
            {
                name: 'User',
                id: 'userName',
                type: 'text'
            },
            {
                name: 'Api Type',
                id: 'apiType',
                type: 'custom',
                width: '100',
                renderDisplay: d => <span>
                    {getLabelByValue(apiTypes, d.apiType)}
                </span>
            },
            {
                name: 'Elapsed',
                id: 'elapsedTime',
                type: 'custom',
                renderDisplay: d => <span>
                    {d.elapsedTime}
                </span>
            },
            {
                name: 'Request',
                id: 'request',
                type: 'text'
            },
            {
                name: 'Response',
                id: 'response',
                type: 'text'
            },
            {
                name: 'Error',
                id: 'error',
                type: 'text'
            },
            {
                name: 'Status',
                id: 'status',
                width: '150',
                type: 'custom',
                renderDisplay: d => <span>
                    {d.apiCallResult == 0 ? <span><i className="fa fa-check color-green" />&nbsp;Success</span> : null}
                    {d.apiCallResult == 1 ? <span><i className="fa fa-remove color-red" />&nbsp;Failed</span> : null}
                </span>
            },
            {
                name: 'Actions',
                id: 'Actions',
                type: 'custom',

                renderDisplay: d => <div>
                    <a className="btn btn-xxs bg-slate" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push('/historyApiCalls/logs/' + d.id);
                    }}>Logs</a>
                </div>
            }
        ];

        let apiCalls = this.props.apiCalls || {};

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4><span className="text-semibold">Api calls</span></h4>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat">
                    <MegaTable
                        editMode={false}
                        columns={columns}
                        onRefresh={() => {
                            this.reloadData();
                        }}
                        isWorking={this.state.isWorking}
                        summary={apiCalls.list
                            ? {
                                skip: apiCalls.list.skip,
                                take: apiCalls.list.take,
                                total: apiCalls.list.total,
                                totalPages: apiCalls.list.totalPages
                            }
                            : null}
                        page={this.state.page}
                        pageChanged={(page) => {
                            if (page < 1)
                                page = 1;
                            this.setState({
                                page: page
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        pageSizeChanged={(size) => {
                            this.setState({
                                pageSize: size
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        searchChanged={(search) => {
                            this.setState({
                                search: search
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        data={this.getData()} />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { apiCalls: state.apiCall }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(apiCallActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiCallsTable)