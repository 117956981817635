type TriggerTestsTabProps = {
  testResults: string;
};

const TriggerTestsTab = (props: TriggerTestsTabProps) => {
  return (
    <div>
      <pre
        className="bg-black text-detroitblue-300 font-mono"
        style={{ fontSize: "16px" }}
      >
        {props.testResults}
      </pre>
    </div>
  );
};

export default TriggerTestsTab;
