import React from 'react';

const CustomerLabel = ({ text, onDelete }) => {
    return (
    <div className="customer_label">
        <span>{text}</span>
        <button onClick={onDelete}>×</button>
    </div>);
};

export { CustomerLabel as default };
