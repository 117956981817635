import React, {Component} from 'react';
import {Modal, Button} from "react-bootstrap";
import UploadButton from "../../../components/common/UploadButton";
import * as notificationActions from "../../notifications/actions/notificationActions";
import {connect} from "react-redux";
import * as triggerActions from "../actions/triggerActions";
import {bindActionCreators} from "redux";

class TestRunModal extends Component {
    handleRun = (e) => {
        this.setState({isWorking: true});

        this.props.triggerActions.testRun({
            turvoShipmentId: this.state.turvoShipmentId,
            triggerId: this.props.triggerId
        }).then((response) => {
            this
                .props
                .notification
                .add("Test run executed", "", "success")
            this
                .props
                .triggerActions
                .getTrigger(this.props.triggerId).then(() => {
                this.setState({isWorking: false});
                this
                    .props
                    .onClose()
            });
        }).catch((error) => {
            this
                .props
                .notification
                .add("Test run failed: " + error.response.data.message, "", "fail")
            this.setState({isWorking: false});
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            isWorking: false,
            isLoading: false,
            turvoShipmentId: ''
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Test run</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Turvo shipment Id</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({turvoShipmentId: e.target.value})}
                                    value={this.state.turvoShipmentId}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={this.handleRun}>
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : <i className="fa fa-play"></i>}
                        Run
                    </Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {importers: state.importer}
}

function mapDispatchToProps(dispatch) {
    return {
        triggerActions: bindActionCreators(triggerActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestRunModal)
