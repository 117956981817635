import * as actionTypes from "../constants/actionTypes";

export default (state = {}, action) => {
  if (action.type == actionTypes.RECEIVE_LOGS) {
    return {
      ...state,
      list: action.payload,
    };
  } else {
    return state;
  }
};
