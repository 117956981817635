import React, {Component} from 'react';

export default class OrderSettingsTab extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        let settings = this.props.settings || {};

        return (
            <form className="form-horizontal">
                <div className="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.disableDuplicateValidation || false}
                            onChange={(e) => {
                                this.props.onChange({
                                    disableDuplicateValidation: e.target.checked
                                });
                            }}
                        />
                        <p>Disable duplicate validation</p>
                    </label>
                </div>
                <button onClick={(e)=> {
                    e.preventDefault();
                    this.props.onSave();
                }} className="btn bg-slate">Save</button>
            </form>
        )
    }
}

