import * as actionTypes from "../constants/actionTypes";

export default (
  state = {
    isSaving: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.DICTIONARIES_LIST_FOLDER:
      return {
        ...state,
        foldersList: action.payload,
      };
    case actionTypes.DICTIONARIES_LIST_DICTIONARY:
      return {
        ...state,
        dictionariesList: action.payload,
      };
    case actionTypes.DICTIONARIES_GET_DICTIONARY:
      return {
        ...state,
        dictionary: action.payload,
      };
    case actionTypes.DICTIONARIES_CLEAR_DICTIONARY:
      return {
        ...state,
        dictionary: null,
      };
    case actionTypes.DICTIONARIES_CLEAR_ITEMS:
      return {
        ...state,
        itemsList: null,
      };
    case actionTypes.DICTIONARIES_LIST_ITEMS:
      return {
        ...state,
        itemsList: action.payload,
      };
    case actionTypes.START_DICTIONARY_SAVE:
      return {
        ...state,
        isSaving: true,
      };
    case actionTypes.SUCCESS_DICTIONARY_SAVE:
      return {
        ...state,
        isSaving: false,
        dictionary: action.newDictionary ? action.newDictionary : state.dictionary
      };
    case actionTypes.ERROR_DICTIONARY_SAVE:
      return {
        ...state,
        isSaving: false,
      };
    default:
      return state;
  }
};
