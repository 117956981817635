import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/snippets/javascript';
import 'brace/ext/language_tools';

export default class ScriptMappingStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            editing: false,
            model: this.props.model
        };
    }

    renderDetails = (model) =>{
        if(model.Error){
            return (<div>
                <b>Error: </b>{model.Error.Message}
            </div>);
        }
        return (<div>
            <b>Order: </b>
            <br/>
            <AceEditor
                mode="javascript"
                theme="monokai"
                readOnly
                width="100%"
                value={model.Json}
                setOptions={{
                    showLineNumbers: false,
                    showGutter: false,
                    tabSize: 2,
                    showPrintMargin: false
                }}
            />
        </div>);
    }

    render() {
        let { model } = this.props;

        return (
            <div>
                <b>Elapsed: </b> {model.ElapsedTime}<br/>
                {this.state.expanded && this.renderDetails(model)}
                <a
                    onClick={(e)=>{ e.preventDefault(); this.setState({expanded: !this.state.expanded}); }}>
                    Details
                    &nbsp;
                    {this.state.expanded?<i className="fa fa-arrow-up"/>:<i className="fa fa-arrow-down"/>}
                </a>
                <Modal show={this.state.editing} onHide={() => this.setState({ editing: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit stage data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AceEditor
                            mode="javascript"
                            theme="monokai"
                            width="100%"
                            value={this.state.model.Json}
                            setOptions={{
                                showLineNumbers: false,
                                showGutter: false,
                                tabSize: 2,
                                showPrintMargin: false
                            }}
                            onChange={(e) => {
                                this.setState({ model: {...this.state.model, Json: e}})
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={() => {
                                this
                                .props
                                .saveData(this.state.model);
                                this.setState({ editing: false });
                            }}>Save</Button>
                        <Button
                            onClick={() => this.setState({ editing: false })}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}