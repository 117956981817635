import * as actionTypes from '../constants/actionTypes';

const initialState = {
    list: { data: [] },
    transfer: {}
};

export default(state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TRANSFER_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.TRANSFER_LIST_CLEAR:
            return {
                ...state,
                list: []
            };
        case actionTypes.TRANSFER_GET:
            return {
                ...state,
                transfer: action.payload
            };
        case actionTypes.TRANSFER_CLEAR:
            return {
                ...state,
                transfer: null
            };
        default:
            return state;
    }
};