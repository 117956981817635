import React, { Component } from 'react';
import FormLoader from '../../../components/common/FormLoader';
import * as notificationActions from '../../notifications/actions/notificationActions';
import * as commonItemsActions from '../actions/commonItemsActions';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import CustomerImportTab from "../../customer/components/tabs/CustomerImportTab";
import TriggersTab from "../../customer/components/tabs/TriggersTab";
import CustomerTransfersTab from '../../customer/components/tabs/CustomerTransfersTab';
import SharedScriptsTab from './tabs/SharedScriptsTab';
import { Helmet } from "react-helmet";

class CommonItems extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.props.commonItemsActions.clear();
        this.setState({ isLoading: true });
        this
            .props
            .commonItemsActions
            .commonImportersList({})
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(() => {
                this.setState({ isLoading: false })
            })
    }

    componentWillReceiveProps(nextProps) {

    }

    getInitialState() {
        return {
            selectedTab: this.props.match.params.tab,
        };
    }

    changeTab = () => {
        const { selectedTab } = this.state;
        this.props.history.push(`/common-items/${selectedTab}`);
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>SL3 - common items</title>
                </Helmet>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Common items</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat relative">
                    {this.state.isLoading
                        ? <FormLoader />
                        : null}
                    <div className="panel-body">
                        <Tabs
                            id="CustomerTabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(key) => this.setState({ selectedTab: key }, this.changeTab)}>
                            <Tab eventKey={"import"} title="Import">
                                <CustomerImportTab
                                    customerId={this.state.customerId}
                                    importers={this.props.commonItems.commonImporters ? this.props.commonItems.commonImporters.data : null}
                                    reloadImporters={() => {
                                        this.props.commonItemsActions.commonImportersList({});
                                    }}
                                    history={this.props.history}
                                    onChange={(set) => this.setState({
                                        customer: {
                                            ...this.state.customer,
                                            ...set
                                        }
                                    })} />
                            </Tab>
                            <Tab eventKey={"triggers"} title="Triggers">
                                <TriggersTab
                                    history={this.props.history}
                                    customer={this.state.customer} common />
                            </Tab>
                            <Tab eventKey={"transfers"} title="Transfers">
                                <CustomerTransfersTab
                                    history={this.props.history}
                                    customer={this.state.customer} />
                            </Tab>
                            <Tab eventKey={"scripts"} title="Scripts">
                                <SharedScriptsTab                                     
                                    history={this.props.history} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { commonItems: state.commonItems }
}

function mapDispatchToProps(dispatch) {
    return {
        commonItemsActions: bindActionCreators(commonItemsActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonItems)
