import moment from "moment";

export default function displayDateTime(value, format, utc) {
  let formatTemplate = format || "YYYY-MM-DD hh:mm A";
  let dateTime = moment.utc(value);
  if (dateTime.isValid()) {
    if (!utc) return dateTime.local().format(formatTemplate);
    else return dateTime.utc().format(formatTemplate);
  } else {
    return "Invalid date time";
  }
}
