import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as notificationActions from '../../../notifications/actions/notificationActions';
import * as orderActions from '../../actions/orderActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormLoader from '../../../../components/common/FormLoader';
import getLabelByValue from '../../../../utils/enumHelper';
import MicroTable from '../../../../components/common/MicroTable';
import displayDateTime from '../../../../utils/dateTimeHelper';
import * as stageTypes from '../../constants/stageTypes';

class ChangeHistoryTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderId: this.props.orderId,
            isLoading: false,
            isWorking: false,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.actions.clearOrderChanges();
        this
            .props
            .actions
            .listOrderChanges({ orderId: this.state.orderId })
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(() => {
                this.setState({ isLoading: false })
            })
    }

    render() {
        return (
            <div className="relative">
                {this.state.isLoading && <FormLoader />}
                <b>Change history list: </b>
                <MicroTable
                    data={this.props.orderChangeHistoryList}
                    noEdit
                    itemSelected={(item) => {

                    }}
                    columns={[
                        {
                            name: 'Id',
                            id: 'id',
                            type: 'custom',
                            renderDisplay: d =>
                                <Link to={`/order/history/${d.id}`}>{d.id}</Link>
                        },
                        {
                            name: 'Stage type',
                            id: 'triggerType',
                            type: 'custom',
                            renderDisplay: d => getLabelByValue(stageTypes, d.importStageType)
                        },
                        {
                            name: 'Change date',
                            id: 'createDate',
                            type: 'custom',
                            renderDisplay: d => displayDateTime(d.createDate)
                        },
                        {
                            name: 'User',
                            id: 'userName',
                            type: 'string',
                        },
                    ]}
                    onChange={(data) => {

                    }} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        orderChangeHistoryList: state.order.orderChangeHistoryList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(orderActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeHistoryTab)