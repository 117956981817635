import * as actionTypes from '../constants/actionTypes';

export default(state = {}, action) => {
    switch (action.type) {
        case actionTypes.SHOW_IMPORT_MODAL:
            return {
                ...state,
                showImportModal: true
            };
        case actionTypes.HIDE_IMPORT_MODAL:
            return {
                ...state,
                showImportModal: false
            };
        case actionTypes.IMPORT_LIST_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            };
        case actionTypes.IMPORT_LIST_IMPORTERS:
            return {
                ...state,
                importers: action.payload
            };
        default:
            return state;
    }
};