export const SHIPMENT_LIST = 'SHIPMENT_LIST';
export const SHIPMENT_LIST_CLEAR = 'SHIPMENT_LIST_CLEAR';

export const SHIPMENT_GET_DETAILS = 'SHIPMENT_GET_DETAILS';
export const SHIPMENT_CLEAR_DETAILS = 'SHIPMENT_CLEAR_DETAILS';

export const SHIPMENT_GET_VISUAL_TRACKING = 'SHIPMENT_GET_VISUAL_TRACKING';
export const SHIPMENT_CLEAR_VISUAL_TRACKING = 'SHIPMENT_CLEAR_VISUAL_TRACKING';

export const SHIPMENT_LIST_CHANGES = 'SHIPMENT_LIST_CHANGES';
export const SHIPMENT_CLEAR_CHANGES = 'SHIPMENT_CLEAR_CHANGES';