import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DraggableTable from "../../../../components/common/DraggableTable";
import FormLoader from '../../../../components/common/FormLoader';
import getLabelByValue from "../../../../utils/enumHelper";
import DeleteConfirmationModal from "../../../../components/common/DeleteConfirmationModal";
import * as notificationActions from "../../../notifications/actions/notificationActions";
import * as customerActions from "../../actions/customerActions";
import * as triggerActions from '../../../trigger/actions/triggerActions';
import * as triggerTypes from '../../../trigger/constants/triggerTypes';

class TriggersTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            showImporterDeleteConfirm: false,
            selectedItem: {},
            isLoading: false,
        };
    }

    componentDidMount() {
        if(this.props.common){
            this.reloadData();
        }
    }

    componentWillReceiveProps(nextProps) {
        let prevCustomerId = (this.state.customer ? this.state.customer.id : null);

        this.setState({
            customer: nextProps.customer
        }, () => {
            if(nextProps.customer && nextProps.customer.id !== prevCustomerId)
                this.reloadData();
        });
    }

    getData = () => {
        if (this.props.triggers && this.props.triggers.list && this.props.triggers.list.data) {
            const triggers = this.props.triggers.list.data;
            const data = triggers.map(el => {
               if (el.runAfterTriggerId) {
                   const runAfterTrigger = triggers.find(tr => tr.id === el.runAfterTriggerId);
                   return {
                       ...el,
                       runAfterName: runAfterTrigger.name
                   }
               }
               return el;
            });
            return data;
        }

        return [];
    };

    checkSequences = () => {
      const data = this.props.triggers.list.data;
        if (data.some(el => !el.sequenceNumber)) {
          this.reorderTriggers(data);
      }
    };

    reorderTriggers = (data) => {
      const wrongOrder = data.some((el, index) => {
          if (el.runAfterTriggerId) {
            const runAfterIndex = data.findIndex(tr => tr.id === el.runAfterTriggerId);
            return runAfterIndex > index;
          }
          return false;
      });
      console.log('wrong order', wrongOrder);
      if (wrongOrder) {
          this.props.notification.add('', 'Cannot put trigger before the trigger it should run after', 'fail');
          this.reloadData();
          return;
      }
      const updatedData = data.map((el, index) => ({ ...el, sequenceNumber: index + 1 }));
      this.setState({ isLoading: true });
      this
      .props
      .triggerActions
      .reorderTriggers(updatedData)
      .then(() => {
          this.setState({ isLoading: false });
      })
      .catch(() => {
          this.setState({ isLoading: false });
      });
    };

    reloadData = () =>{
        this.props.triggerActions.clearTriggerList();
        if (this.state.customer && this.state.customer.id && this.state.customer.id !== 'undefined') {
            this.loadCustomerTriggers();
        }
        if (this.props.common) {
            this.loadCommonData();
        }
    };

    loadCustomerTriggers = () => {
        this.setState({isLoading: true});
        this
            .props
            .triggerActions
            .listTriggers({customerId: this.state.customer.id, pageSize: null, page: null})
            .then(() => {
                this.setState({isLoading: false});
                //this.checkSequences();
            })
            .catch(() => {
                this.setState({isLoading: false});
            })
    }

    loadCommonData = () => {
        this.setState({isLoading: true});
        this
            .props
            .triggerActions
            .listTriggers({common: true})
            .then(() => {
                this.setState({isLoading: false});
            })
            .catch(() => {
                this.setState({isLoading: false});
            })
    }

    onReorder = (data) => {
        this.reorderTriggers(data);
    };

    render() {
        let addButtonStyle = {
            marginTop:"-10px",
            marginRight: "10px"
        };

        return (
            <div>
                <div className="form-group row">
                    <b className="table-top-heading">Triggers</b>
                    <a className="btn btn-xs bg-blue has-text pull-right" style={addButtonStyle}
                       onClick={(e)=> {
                           e.preventDefault();
                           this.props.history.push(`/trigger/edit${this.props.customer ? `?customerId=${this.props.customer.id}`: ''}`);
                       }}>
                        <i className="fa fa-plus" />
                        <span>Add</span>
                    </a>
                    <div className="col-sm-12">
                        {this.state.isLoading && <FormLoader/>}
                        <DraggableTable
                            data={this.getData()}
                            noEdit
                            columns={[
                                { name: 'Name', id:'name', type:'text' },
                                {
                                    name: 'Trigger type',
                                    id: 'triggerType',
                                    type: 'custom',
                                    renderDisplay: d => <span>{getLabelByValue(triggerTypes, d.triggerType)}</span>
                                },
                                { name: 'Condition', id:'condition', type:'text' },
                                { name: 'Sequence', id: 'sequenceNumber', type: 'number'},
                                { name: 'Run after', id: 'runAfterName', type: 'text'},
                                { name: 'Send delay(minutes)', id: 'sendDelay', type: 'number'},
                                {
                                    name: 'Enabled',
                                    id: 'enabled',
                                    type: 'custom',
                                    renderDisplay: d=>{ return (d.enabled ? <i className="fa fa-check color-green"></i>: <i className="fa fa-close color-red"></i>) }},
                                {
                                    name: 'Actions',
                                    id: 'actions',
                                    type: 'custom',
                                    width: '100',
                                    renderDisplay: d => <div>
                                        <a className="btn btn-xxs btn-primary" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push('/trigger/edit/'+d.id);
                                        }}>Edit</a>
                                        &nbsp;
                                        <a className="btn btn-xxs btn-danger"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               this.setState({showImporterDeleteConfirm: true, selectedItem: d});
                                           }}>Delete</a>
                                    </div>
                                }
                            ]}
                            onChange={(data) => {}}
                            onDragEnd={this.onReorder}
                        />
                    </div>
                </div>
                <DeleteConfirmationModal
                    title={"Delete trigger confirmation"}
                    description={"Are you sure you want to delete " + this.state.selectedItem.name + "?"}
                    onClose={() => this.setState({showDeleteConfirm: false})}
                    onConfirm={() => {
                        this
                            .props
                            .triggerActions
                            .removeTrigger(this.state.selectedItem.id, () => {
                                this.reloadData();
                                this.setState({showImporterDeleteConfirm: false});
                            })
                    }}
                    show={this.state.showImporterDeleteConfirm}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        triggers: state.trigger
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
        triggerActions: bindActionCreators(triggerActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggersTab)