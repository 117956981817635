import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import UserTable from './components/UserTable';

export default class Users extends Component {
    render() {
        return (
            <Switch>
                <Route path="/users" component={UserTable}/>
=            </Switch>
        )
    }
}