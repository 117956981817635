import useSWR from "swr";
import fetcherPost from "../fetchers/fetcherPost";
import { ResponseFetch } from "../models/ResponseFetch";
import { TableRequestObject } from "../models/Requests/TableRequestObject";
import { DictionaryFolderObject } from "../models/DictionaryFolderObject";

function useGetDictionaryFolders(args: TableRequestObject) {
  const { data, error, isLoading, mutate } = useSWR<
    ResponseFetch<DictionaryFolderObject[]>
  >(
    ["/api/dictionaryfolder/list", args],
    ([url, args]) => fetcherPost(url, args),
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    folders: data,
    error,
    isLoading,
    mutate,
  };
}

export default useGetDictionaryFolders;
