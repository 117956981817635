import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {debounce} from 'throttle-debounce';

import MegaTable from '../../../components/common/MegaTable';
import DeleteConfirmationModal from '../../../components/common/DeleteConfirmationModal';
import * as locationActions from '../actions/locationActions';
import { shallowEqual } from '../../../utils/comparator';
import getLabelByValue from "../../../utils/enumHelper";
import * as importerTypes from "../../customer/constants/importerTypes";

class LocationsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                page: 1,
                pageSize: 10,
                customerId: ''
            },
            isWorking: false,
            selectedItem: {}
        };
        this.refreshDebounce = debounce(300, false, () => {this.reloadData();});
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!shallowEqual(prevState.filters, this.state.filters))
            this.reloadData();
    }

    reloadData = (noLoader) => {
        if(!noLoader){
            this.setState({isWorking: true});
        }
        const { filters } = this.state;
        this
            .props
            .locationActions
            .listLocations({page: filters.page, pageSize: filters.pageSize, search: this.state.search, ...filters})
            .then(() => this.setState({isWorking: false}))
            .catch(() => this.setState({isWorking: false}));
    };

    getData = () => {
        if (this.props.location && this.props.location.list) {
            return this.props.location.list.data;
        }
        return [];
    };

    changeFilterState = (obj) => {
        this.setState({ filters: {...this.state.filters, ...obj } });
    };

    render() {
        let columns = [
            {
                name: 'Name',
                id: 'name',
                type: 'text'
            },
            {
                name: 'Customer',
                id: 'customer',
                type: 'custom',
                renderDisplay: d => <a target="_blank" href={`/customer/edit/${d.customerId}`}>{d.customerName}</a>
            },
            {
                name: 'Line1',
                id: 'line1',
                type: 'text'
            },
            {
                name: 'City',
                id: 'city',
                type: 'text'
            },
            {
                name: 'State',
                id: 'state',
                type: 'text'
            },
            {
                name: 'Zip',
                id: 'zip',
                type: 'text'
            },
            {
                name: 'Country',
                id: 'country',
                type: 'text'
            },
            {
                name: 'Turvo location id',
                id: 'turvoLocationId',
                type: 'text'
            },
            {
                name: 'Actions',
                id: 'Actions',
                type: 'custom',
                width: '160',
                renderDisplay: d => <div>
                    <a className="btn btn-xxs btn-primary" onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/location/edit/${d.id}`);
                    }}>Edit</a>
                    &nbsp;
                    <a className="btn btn-xxs btn-danger"
                       onClick={(e) => {
                           e.preventDefault();
                           this.setState({showDeleteConfirm: true, selectedItem: d});
                       }}>Delete</a>
                </div>
            }
        ];

        const locations = this.props.location.list || {};
        const { filters } = this.state;

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4><span className="text-semibold">Locations</span></h4>
                        </div>

                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <a onClick={(e)=>{
                                    e.preventDefault();
                                    this.props.history.push('/location/new');
                                }} className="btn bg-blue has-text">
                                    <i className="fa fa-plus" />
                                    <span>Add</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat">
                    <div>
                        <MegaTable
                            editMode={false}
                            columns={columns}
                            onRefresh={()=> {
                                this.reloadData();
                            }}
                            isWorking={this.state.isWorking}
                            summary={{
                                skip: locations.skip,
                                take: locations.take,
                                total: locations.total,
                                totalPages: locations.totalPages
                            }}
                            page={filters.page}
                            pageChanged={(page) => {
                                if (page < 1)
                                    page = 1;
                                this.changeFilterState({page});
                            }}
                            pageSizeChanged={(pageSize) => this.changeFilterState({pageSize})}
                            data={this.getData()}
                            searchChanged={(search) => {
                            this.setState({
                                search: search
                            }, () => {
                                this.refreshDebounce();
                            });
                        }}/>
                    </div>
                </div>
                <DeleteConfirmationModal
                    title={"Delete location confirmation"}
                    description={`Are you sure you want to delete ${this.state.selectedItem.name}?`}
                    onClose={() => this.setState({showDeleteConfirm: false})}
                    onConfirm={() => {
                        this
                            .props
                            .locationActions
                            .removeLocation(this.state.selectedItem.id, () => {
                                this.reloadData();
                                this.setState({showDeleteConfirm: false});
                            })
                    }}
                    show={this.state.showDeleteConfirm}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        location: state.location,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        locationActions: bindActionCreators(locationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsTable);
