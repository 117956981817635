import CounterWidget from "../components/widgets/CounterWidget";
import React from "react";
import BarChartWidget from "../components/widgets/BarChartWidget";
import PieChartWidget from "../components/widgets/PieChartWidget";

const metricsTypes = [
    {value: 0, text: 'Counter', component: <CounterWidget/>, defaultSize: { x: 0, y: 0, w: 2, h: 4, static: false}},
    {value: 1, text: 'Bar', component: <BarChartWidget/>, defaultSize: { x: 0, y: 0, w: 2, h: 4, static: false}},
    {value: 2, text: 'Pie', component: <PieChartWidget/>, defaultSize: { x: 0, y: 0, w: 2, h: 4, static: false}}
]

export { metricsTypes as default }