export const TRIGGER_GET_ADDITIONAL_DATA = 'TRIGGER_GET_ADDITIONAL_DATA';

export const TRIGGER_GET = 'TRIGGER_GET';
export const TRIGGER_CLEAR = 'TRIGGER_CLEAR';
export const TRIGGER_LIST = 'TRIGGER_LIST';
export const TRIGGER_LIST_CLEAR = 'TRIGGER_LIST_CLEAR';

export const TRIGGERS_SCRIPT_CLEAR = 'TRIGGERS_SCRIPT_CLEAR';
export const TRIGGERS_SCRIPT_LIST = 'TRIGGERS_SCRIPT_LIST';

export const TRIGGERS_EXECUTION_LOGS_CLEAR = 'TRIGGERS_EXECUTION_LOGS_CLEAR';
export const TRIGGERS_EXECUTION_LOGS_LIST = ' TRIGGERS_EXECUTION_LOGS_LIST';

export const TRIGGERS_EXECUTION_LIST = 'TRIGGERS_EXECUTION_LIST';
export const TRIGGERS_EXECUTION_LIST_CLEAR = 'TRIGGERS_EXECUTION_LIST_CLEAR';