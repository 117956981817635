import React, {Component} from 'react'
import {Button, Modal} from "react-bootstrap";
import TagsInput from 'react-tagsinput'
import {bindActionCreators} from "redux";
import * as actions from "../actions/dashboardActions";
import * as notification from "../../notifications/actions/notificationActions";
import connect from "react-redux/es/connect/connect";
import FormLoader from "../../../components/common/FormLoader";

class DashboardNotificationsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isWorking: false,
            isLoading: false,
            notificationEmails: []
        }
    }

    changeEmails = (emails) => {
        this.setState({notificationEmails: emails});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show != this.props.show)
            this.reloadSettings();

        if (nextProps.settings && nextProps.settings.notificationEmails)
            this.setState({notificationEmails: nextProps.settings.notificationEmails});
    }

    reloadSettings = () =>{
        this.setState({isLoading: true});
        this.props.actions.getMetricsSettings().then().catch().then(()=>{
            this.setState({isLoading: false});
        });
    }

    saveSettings = () => {
        this.setState({isWorking: true});
        this.props.actions.saveMetricsSettings({
            notificationEmails: this.state.notificationEmails
        }).then().catch().then(()=>{
            this.setState({isWorking: false});
            this.props.onClose();
        });
    }

    render() {
        const {show, onClose, onSave} = this.props;

        return <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Notifications</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.isLoading && <FormLoader/>}
                <div>
                    <label>Emails</label>
                    <TagsInput
                        inputProps={{
                            placeholder: 'Add email'
                        }}
                        value={this.state.notificationEmails || []} onChange={this.changeEmails} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={this.state.isWorking} bsStyle="success" onClick={this.saveSettings}>
                    {this.state.isWorking
                        ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                        : null}
                    <span>Save</span>
                </Button>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}

function mapStateToProps(state) {
    return {
        settings: state.dashboard.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        notification: bindActionCreators(notification, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNotificationsModal)