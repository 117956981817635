import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import ApiCallsTable from "./components/ApiCallsTable";
import ApiCallLogsTable from "./components/ApiCallLogsTable";

export default class ApiCalls extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/historyApiCalls" component={ApiCallsTable}/>
                <Route exact path="/historyApiCalls/logs/:apiCallId" component={ApiCallLogsTable}/>
            </Switch>
        )
    }
}