import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import CommonItems from './components/CommonItems';
import SharedScriptEdit from './components/SharedScriptEdit';

export default class CommonItemsPage extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/common-items/:tab?" component={CommonItems}/>
                <Route exact path="/common-items/script/edit/:id?" component={SharedScriptEdit}/>
            </Switch>
        )
    }
}