import React, { Component } from 'react'
import Diff from 'react-diff-component';
import * as shipmentActions from '../actions/orderActions';
import * as notificationActions from '../../notifications/actions/notificationActions';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import FormLoader from '../../../components/common/FormLoader';
import * as stageTypes from '../constants/stageTypes';
import getLabelByValue from "../../../utils/enumHelper";

class OrderChangeHistoryDetails extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.props.actions.clear();
        if (this.state.changeHistoryId) {
            this.setState({isLoading: true});
            this
                .props
                .actions
                .getOrderChangeHistory(this.state.changeHistoryId)
                .then(() => {
                    this.setState({isLoading: false})
                })
                .catch(() => {
                    this.setState({isLoading: false})
                })
        }
    }

    getInitialState() {
        return {
            changeHistoryId: this.props.match.params.changeHistoryId,
            isLoading: false,
            isWorking: false,
        };
    }


    render() {
        let { orderChangeHistoryDetails } = this.props;

        console.log('orderChangeHistoryDetails', JSON.stringify(orderChangeHistoryDetails));

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Change history details for shipment: {orderChangeHistoryDetails.shipmentId}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat relative">
                    {this.state.isLoading && <FormLoader />}
                    <div className="panel-body">
                        <div className="panel panel-flat relative">
                            <div className="row">
                                <label className="col-sm-2 control-label">Changed by: {orderChangeHistoryDetails.userName}</label>
                                <label className="col-sm-2 control-label">Changed at: {orderChangeHistoryDetails.createDate}</label>
                                <label className="col-sm-2 control-label">Stage: {getLabelByValue(stageTypes, orderChangeHistoryDetails.importStageType)}</label>
                            </div>
                        </div>
                        <div className="panel panel-flat relative">
                            <Diff
                                inputA={orderChangeHistoryDetails.beforeModel}
                                inputB={orderChangeHistoryDetails.afterModel}
                                type="lines"
                                options={{newLineIsToken: true}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        orderChangeHistoryDetails: state.order.orderChangeHistoryDetails,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        actions: bindActionCreators(shipmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderChangeHistoryDetails)