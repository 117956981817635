import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/inboundProcessing/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.INBOUNDPROCESSING_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function listCustomers(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/customer/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.INBOUNDPROCESSING_LIST_CUSTOMERS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function getVisualTrackingItems(inboundProcessingId) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/inboundProcessing/getVisualTrackingItems?inboundProcessingId="+inboundProcessingId)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.INBOUNDPROCESSING_GET_VISUAL_TRACKING, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearVisualTrackingItems() {
    return (dispatch) => {
        dispatch({type: actionTypes.INBOUNDPROCESSING_CLEAR_VISUAL_TRACKING});
    };
}

export function get(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/inboundProcessing/get/?id=" + id)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.INBOUNDPROCESSING_GET_DETAILS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearList() {
    return (dispatch) => {
        dispatch({type: actionTypes.INBOUNDPROCESSING_LIST_CLEAR});
    };
}

export function clear() {
    return (dispatch) => {
        dispatch({type: actionTypes.INBOUNDPROCESSING_CLEAR_DETAILS});
    };
}

export function listFlags(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/flag/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.INBOUNDPROCESSING_GET_FLAG_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearFlags() {
    return (dispatch) => {
        dispatch({type: actionTypes.INBOUNDPROCESSING_CLEAR_FLAG_LIST});
    };
}


export function removeFlag(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/flag/delete", {id: id})
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}

export function listResourceLogs(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/log/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.RESOURCE_LIST_LOGS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function clearResourceLogs() {
    return (dispatch) => {
        dispatch({type: actionTypes.RESOURCE_CLEAR_LOGS});
    };
}