import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import AuthPlaceholder from './AuthPlaceholder';
import Customer from '../pages/customer';
import Importer from '../pages/importer';
import Trigger from '../pages/trigger';
import Transfer from '../pages/transfer';
import FileImport from '../pages/fileImport';
import Order from '../pages/orders';
import InboundProcessing from '../pages/InboundProcessing';
import Shipment from '../pages/shipments';
import Dictionaries from '../pages/dictionaries';
import Dashboard from '../pages/dashboard';
import ApiCalls from '../pages/apiCall';
import ApiAccessKey from '../pages/apiAccessKey';
import ExportList from '../pages/exportList';
import Service from '../pages/service';
import Flags from '../pages/flags';
import Location from '../pages/location';
import Users from '../pages/users';
import RoleGuard from './RoleGuard';
import CommonItemsPage from '../pages/common-items';
import ActionsLogTable from '../pages/action-log/components/ActionsLogTable';
import SentryInit from '../pages/sentry';

class MainRoute extends Component {
    render() {
        return this.props.oidc && this.props.oidc.user && !this.props.oidc.user.expired ?
            <RoleGuard userRoles={this.props.oidc.user.profile.roles}>
                <Route path="/" component={Dashboard} />
                <Route path="/customer" component={Customer} />
                <Route path="/importer" component={Importer} />
                <Route path="/trigger" component={Trigger} />
                <Route path="/transfer" component={Transfer} />
                <Route path="/fileImport" component={FileImport} />
                <Route path="/order" component={Order} />
                <Route path="/updates" component={InboundProcessing} />
                <Route path="/shipment" component={Shipment} />
                <Route path="/dictionaries" component={Dictionaries} />
                <Route path="/historyApiCalls" component={ApiCalls} />
                <Route path="/accessApiKey" component={ApiAccessKey} />
                <Route path="/exportList" component={ExportList} />
                <Route path="/service" component={Service} />
                <Route path="/flags" component={Flags} />
                <Route path="/location" component={Location} />
                <Route path="/users" component={Users} />
                <Route path="/common-items" component={CommonItemsPage} />
                <Route path="/sentry" component={SentryInit} />
                <Route path="/dictionary-actions-log" component={ActionsLogTable} />
            </RoleGuard>
            : <Route component={AuthPlaceholder} />;
    };
}

function mapStateToProps(state) {
    return { oidc: state.oidc }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRoute)