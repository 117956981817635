import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function clearMetrics() {
    return {type: actionTypes.CLEAR_METRICS};
}

export function getMetrics(key, name, data) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post(`/api/metrics/generate/${name}`, data)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.GET_METRICS, payload: response.data, key: key});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function saveDashboardView(data) {
    return () => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/settings/saveDashboardSettings", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function saveMetrics(data) {
    return () => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/metrics/save", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function updateLayout(data) {
    return () => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/metrics/updateLayout", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function listMetrics(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/metrics/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.LIST_WIDGET, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function refreshAllMetrics(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/metrics/refreshAll", args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function getDashboardSettings() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/settings/getDashboardSettings")
                .then((response) => {
                    resolve(response);
                    let payload = response.data ? JSON.parse(response.data.settingsJson) : [];
                    dispatch({type: actionTypes.GET_DASHBOARD_SETTINGS, payload});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function getMetricsSettings(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/metrics/getSettings")
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.GET_METRICS_SETTINGS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function saveMetricsSettings(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/metrics/saveSettings", args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function deleteMetrics(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/metrics/delete", {id: id})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}