import React, { Component } from 'react'
import FormLoader from '../../../components/common/FormLoader';
import * as customerActions from '../actions/customerActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as notificationActions from '../../notifications/actions/notificationActions';

import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/snippets/javascript';
import 'brace/ext/language_tools';
import TestRunModal from '../../importer/components/TestRunModal';
import RowSnapshotModal from '../../importer/components/RowSnapshotModal';

class ImporterEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isWorking: false,
            importer: this.getDefaultImporter(),
            showTestRunModal: false,
            snapshotOpened: false
        };
    }

    getDefaultImporter = () => {
        return {
            id: this.props.match.params.importerId,
            name: '',
            workbook: '',
            mapInputToShipmentCode: '',
            customerId: this.props.match.params.customerId,
        };
    }

    reloadImporter = (noClean) => {
        if (!noClean)
            this.props.actions.clearImporter();
        if (this.props.match.params.importerId) {
            this.setState({ isLoading: true });
            this
                .props
                .actions
                .getImporter(this.props.match.params.importerId)
                .then(() => {
                    this.setState({ isLoading: false })
                })
                .catch(() => {
                    this.setState({ isLoading: false })
                })
        } else {
            this.setState({ importer: this.getDefaultImporter() });
        }
        this.getAdditionalData();
    };

    componentDidMount() {
        this.reloadImporter();
    }

    getAdditionalData = () => {
        this.props.actions.getAdditionalData();
    };

    handleSave = (e, noClean) => {
        return new Promise((resolve, reject) => {
            this.setState({ isWorking: true });
            e.preventDefault();
            this
                .props
                .actions
                .saveImporter(this.state.importer)
                .then((response) => {
                    this
                        .props
                        .notification
                        .add("Importer saved", "", "success");
                    this.setState({ isWorking: false });
                    this.props.history.push('/customer/edit/' + this.props.match.params.customerId + '/import/editImporter/' + response.data);
                    this.reloadImporter(noClean);
                    resolve();
                })
                .catch(() => {
                    this
                        .props
                        .notification
                        .add("Save failed", "", "fail")
                    this.setState({ isWorking: false });
                    reject();
                })
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.customers && nextProps.customers.additionalData) {
            this.setState({
                importerTypes: nextProps.customers.additionalData.importerTypes || []
            }, () => {
                this.initImporterType();
            });
        }

        this.setState({
            importer: nextProps.customers.importer || this.getDefaultImporter()
        });
    }

    initImporterType = () => {
        if (!this.state.importer.importerType && this.state.importerTypes.length > 0) {
            this.setState({
                importer: {
                    ...this.state.importer,
                    importerType: this.state.importerTypes[0].value
                }
            });
        }
    };

    render() {
        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Importer edit</span>
                            </h4>
                        </div>

                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <button
                                    onClick={() => {
                                        this.setState({ showTestRunModal: true });
                                    }}
                                    className="btn bg-slate has-text">
                                    <i className="fa fa-play" />
                                    <span>Test run</span>
                                </button>
                                <button
                                    disabled={this.state.isWorking || this.state.isLoading}
                                    onClick={this.handleSave}
                                    className="btn bg-blue has-text">
                                    {this.state.isWorking
                                        ? <i className="fa fa-spinner fa-spin fa-fw" />
                                        : <i className="fa fa-check" />}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat relative">
                    {this.state.isLoading
                        ? <FormLoader />
                        : null}
                    <div className="panel-body">
                        <div className="row">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-1 control-label">Name</label>
                                    <div className="col-sm-11">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => this.setState({
                                                importer: {
                                                    ...this.state.importer,
                                                    name: e.target.value
                                                }
                                            })}
                                            value={this.state.importer.name} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-1 control-label">Type</label>
                                    <div className="col-sm-5">
                                        <select
                                            value={this.state.importer.importerType}
                                            onChange={(e) => {
                                                this.setState({
                                                    importer: {
                                                        ...this.state.importer,
                                                        importerType: e.target.value
                                                    }
                                                });
                                            }} className="form-control">
                                            {this.state.importerTypes ? this.state.importerTypes.map(x => {
                                                return <option key={x.value} value={x.value}>{x.text}</option>
                                            }) : null}
                                        </select>
                                    </div>
                                    <label className="col-sm-1 control-label">Workbook</label>
                                    <div className="col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => this.setState({
                                                importer: {
                                                    ...this.state.importer,
                                                    workbook: e.target.value
                                                }
                                            })}
                                            value={this.state.importer.workbook} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div>
                                <label className="control-label"><b>Map input data to shipment</b></label>
                                &nbsp;
                                <span className="margin-left-10">{this.state.importer.elapsedMappingTime}</span>
                                &nbsp;
                                <a className="margin-left-10"
                                    onClick={(e) => { e.preventDefault(); this.setState({ snapshotOpened: !this.state.snapshotOpened }); }}>
                                    First row snapshot
                                </a>
                            </div>
                            <div className="margin-top-15">
                                <div className="row">
                                    <div className="col-md-5" style={{ width: '49%' }}>
                                        <AceEditor
                                            mode="javascript"
                                            theme="monokai"
                                            width="100%"
                                            value={this.state.importer.mapInputToShipmentCode || ''}
                                            setOptions={{
                                                enableBasicAutocompletion: true,
                                                enableLiveAutocompletion: true,
                                                enableSnippets: true,
                                                showLineNumbers: true,
                                                tabSize: 2,
                                                showPrintMargin: false
                                            }}

                                            onChange={(e) => {
                                                this.setState({
                                                    importer: {
                                                        ...this.state.importer,
                                                        mapInputToShipmentCode: e
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2" style={{ width: '2%' }}>
                                        <i style={{ marginLeft: '-8px' }} className="fa fa-arrow-circle-right fa-2x" />
                                    </div>
                                    <div className="col-md-5" style={{ width: '49%' }}>
                                        <AceEditor
                                            mode="javascript"
                                            theme="monokai"
                                            readOnly
                                            width="100%"
                                            value={this.state.importer.mappingLastTestResult || ''}
                                            setOptions={{
                                                showLineNumbers: true,
                                                tabSize: 2,
                                                showPrintMargin: false
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TestRunModal
                    onClose={() => this.setState({ showTestRunModal: false })}
                    saveImporter={(e) => { return this.handleSave(e, true); }}
                    show={this.state.showTestRunModal}
                />
                <RowSnapshotModal
                    onClose={() => this.setState({ snapshotOpened: false })}
                    show={this.state.snapshotOpened}
                    data={this.state.importer.documentColumns}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { customers: state.customer }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImporterEdit)