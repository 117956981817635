import React, { Component } from "react";
import * as dictionariesActions from "../actions/dictionariesActions";
import * as notificationActions from "../../notifications/actions/notificationActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormLoader from "../../../components/common/FormLoader";
import MegaTable from "../../../components/common/MegaTable";
import DictionaryFileImportModal from "./DictionaryFileImportModal";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import debounce from "debounce";
import DictionaryNewItemModal from "./DictionaryNewItemModal";
import GuardedComponent from "../../../components/GuardedComponent";
import DictionaryEditModal from "./DictionaryEditModal";
import * as userRoles from "../../../constants/userRoles";

class DictionaryEdit extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  componentDidMount() {
    this.props.actions.clearDictionary();
    this.props.actions.clearItems();

    this.setState({ isLoading: true, columns: [] });
    this.props.actions
      .getDictionary(this.state.dictionaryId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
    this.reloadItems();
  }

  reloadItems = (noLoader, callback) => {
    if (!noLoader) {
      this.setState({ isWorking: true });
    }
    this.props.actions
      .listItems({
        page: this.state.page,
        pageSize: this.state.pageSize,
        dictionaryId: this.state.dictionaryId,
        columnFilters: this.state.columnFilters,
      })
      .then(() => {
        this.setState({ isWorking: false });
        if (callback) callback();
      })
      .catch(() => this.setState({ isWorking: false }));
  };

  handleSave = (e) => {};

  getInitialState() {
    return {
      dictionaryId: this.props.match.params.dictionaryId,
      isLoading: false,
      isWorking: false,
      showImportModal: false,
      page: 1,
      pageSize: 10,
      showDeleteConfirm: false,
      columnFilters: [],
      columns: [],
      showNewItemRow: false,
      showNewItemModal: false,
      showClearConfirmation: false,
      showDeleteDictionaryConfirmation: false,
      onEditOpen: false,
    };
  }

  getEditColumn = () => {
    const actionLinkStyle = {
      marginLeft: "10px",
    };

    return {
      name: "Actions",
      id: "Actions",
      type: "custom",
      //width: 160,
      renderDisplay: (d) => (
        <div>
          <a
            onClick={(e) => {
              d.isEditMode = true;
              this.forceUpdate();
            }}
          >
            <i className="fa fa-pencil-square-o"></i>
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              this.setState({ showDeleteConfirm: true, selectedItem: d });
            }}
            style={actionLinkStyle}
          >
            <i className="fa fa-times"></i>
          </a>
        </div>
      ),
    };
  };

  getColumns = () => {
    let data = this.getData()[0];

    let columns = [];
    if (data)
      columns = Object.keys(data)
        .filter((x) => x != "id")
        .map((x) => {
          return {
            name: x,
            id: x,
            type: "text",
          };
        });

    if (columns.length > 0) {
      //In case if empty set filtered
      if (!this.state.columns || this.state.columns.length == 0)
        this.setState({ columns: columns });
    }
    {
      return this.state.columns;
    }
    return columns;
  };

  onFilterChanged = (columnId, value) => {
    let columnFilters = this.state.columnFilters.slice(0);
    let columnFilter = columnFilters.find((x) => x.id == columnId);

    if (columnFilter) {
      columnFilter.value = value;
    } else {
      columnFilters.push({ id: columnId, value: value });
    }

    this.setState({ columnFilters: columnFilters }, () => {
      this.reloadItems();
    });
  };

  getData = () => {
    if (this.props.dictionaries && this.props.dictionaries.itemsList) {
      return this.props.dictionaries.itemsList.data.map((x) => {
        return { ...x.cells, ["id"]: x.id };
      });
    }
    return [];
  };

  handleEditModalOpen = (e) => {
    e.preventDefault();
    this.setState({ onEditOpen: true });
  };

  handleEditModalCancel = (e) => {
    this.setState({ onEditOpen: false });
  };

  handleEditModalSave = (data) => {
    let { dictionaries } = this.props;
    let objToSave = {
      ...dictionaries.dictionary,
      name: data.name,
      dictionarySettings: {
        effectiveDate: data.effectiveDate,
        expirationDate: data.expirationDate,
        turvoAccountId: data.turvoAccountId,
        turvoAccountName: data.turvoAccountName,
        type: data.type,
      },
    };

    this.props.actions
      .saveDictionary(objToSave)
      .then(() => {
        this.setState({ onEditOpen: false });
        this.props.notification.add(
          "Dictionary updated successfully",
          "",
          "success"
        );
      })
      .catch((err) => {
        this.setState({ onEditOpen: false });
        this.props.notification.add(err.message, "", "fail");
        console.error(err.message);
      });
  };

  render() {
    let { dictionaries } = this.props;
    let { dictionary, isSaving } = dictionaries;
    let { items } = dictionaries;

    let columns = this.getColumns();

    return (
      <div>
        <DictionaryEditModal
          open={this.state.onEditOpen}
          onCancel={this.handleEditModalCancel}
          onSave={this.handleEditModalSave}
          dictionaryName={dictionary ? dictionary.name : null}
          data={dictionary ? dictionary.dictionarySettings : {}}
          isSaving={isSaving}
          fetchCustomers={this.props.actions.fetchTurvoCustomerList}
        />
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">
                  Edit dictionary: {dictionary ? dictionary.name : null}
                </span>
              </h4>
              <div className="table-refresh-button">
                <a onClick={this.handleEditModalOpen}>
                  <i className="fa fa-edit" />
                </a>
              </div>
            </div>

            <div className="heading-elements">
              <div className="heading-btn-group">
                <button
                  disabled={this.state.isWorking}
                  onClick={(e) => {
                    this.setState({ isWorking: true });
                    this.props.actions
                      .dictionaryExport(this.state.dictionaryId)
                      .then((response) => {
                        let fileName = response.headers[
                          "content-disposition"
                        ].split("''")[1];
                        //console.log(response);

                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                      .then(() => {
                        this.setState({ isWorking: false });
                      });
                  }}
                  className="btn bg-grey has-text"
                >
                  {this.state.isWorking ? (
                    <i className="fa fa-spin fa-refresh"></i>
                  ) : (
                    <i className="fa fa-download"></i>
                  )}

                  <span>Export</span>
                </button>

                {this.state.showNewItemRow ? (
                  <button
                    onClick={(e) => {
                      this.setState({ showNewItemRow: false });
                    }}
                    className="btn bg-danger has-text"
                  >
                    <i className="fa fa-times"></i>
                    <span>Cancel</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => {
                      let data = this.getData();
                      if (data.length > 0) {
                        this.setState({ showNewItemRow: true });
                      } else {
                        this.setState({ showNewItemModal: true });
                      }
                    }}
                    className="btn bg-green has-text"
                  >
                    <i className="fa fa-plus"></i>
                    <span>Add</span>
                  </button>
                )}

                <button
                  onClick={(e) => {
                    this.setState({ showImportModal: true });
                  }}
                  className="btn bg-blue has-text"
                >
                  <i className="fa fa-plus"></i>
                  <span>Upload</span>
                </button>
                <GuardedComponent allowedRoles={[userRoles.Admin]}>
                  <button
                    onClick={(e) => {
                      this.setState({ showDeleteDictionaryConfirmation: true });
                    }}
                    className="btn bg-danger has-text"
                  >
                    <i className="fa fa-times"></i>
                    <span>Delete</span>
                  </button>
                </GuardedComponent>
                <button
                  onClick={(e) => {
                    this.setState({ showClearConfirmation: true });
                  }}
                  className="btn bg-danger-400 has-text"
                >
                  <i className="fa fa-times"></i>
                  <span>Clear</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat relative">
          <div className="panel-body">
            <MegaTable
              showNewItemRow={this.state.showNewItemRow}
              showFilter
              hideHeader
              editMode={true}
              columns={columns}
              onRefresh={() => {
                this.reloadItems();
              }}
              isWorking={this.state.isWorking}
              summary={
                dictionaries.itemsList
                  ? {
                      skip: dictionaries.itemsList.skip,
                      take: dictionaries.itemsList.take,
                      total: dictionaries.itemsList.total,
                      totalPages: dictionaries.itemsList.totalPages,
                    }
                  : null
              }
              page={this.state.page}
              pageChanged={(page) => {
                if (page < 1) page = 1;
                this.setState(
                  {
                    page: page,
                  },
                  () => {
                    this.reloadItems();
                  }
                );
              }}
              pageSizeChanged={(size) => {
                this.setState(
                  {
                    pageSize: size,
                  },
                  () => {
                    this.reloadItems();
                  }
                );
              }}
              searchChanged={(search) => {
                this.setState(
                  {
                    search: search,
                  },
                  () => {
                    this.reloadItems();
                  }
                );
              }}
              handleRemove={(item) => {
                this.setState({ showDeleteConfirm: true, selectedItem: item });
              }}
              onChange={(item) => {
                let { id, ...cellsModel } = item;
                let dictionaryItem = {
                  id: item.id,
                  cells: cellsModel,
                };
                this.props.actions
                  .saveItem(dictionaryItem)
                  .then(() => {
                    this.props.notification.add("Item saved", "", "success");
                    this.reloadItems();
                  })
                  .catch(() => {
                    this.props.notification.add("Item save failed", "", "fail");
                  });
              }}
              onFilterChange={(col, val) => {
                debounce(this.onFilterChanged(col, val), 300);
              }}
              onNewItem={(item) => {
                let dictionaryItem = {
                  cells: item,
                  dictionaryId: this.state.dictionaryId,
                };
                this.props.actions
                  .saveItem(dictionaryItem)
                  .then(() => {
                    this.props.notification.add("Item saved", "", "success");
                    this.reloadItems();
                    this.setState({ showNewItemRow: false });
                  })
                  .catch(() => {
                    this.props.notification.add("Item save failed", "", "fail");
                  });
              }}
              data={this.getData()}
            />
          </div>
        </div>
        <DictionaryFileImportModal
          showModal={this.state.showImportModal}
          onClose={() => this.setState({ showImportModal: false })}
        />
        <DeleteConfirmationModal
          title={"Delete item confirmation"}
          description={"Are you sure you want to delete item?"}
          onClose={() => this.setState({ showDeleteConfirm: false })}
          onConfirm={() => {
            this.props.actions
              .removeItem(this.state.selectedItem.id)
              .then(() => {
                this.reloadItems();
                this.setState({ showDeleteConfirm: false });
              });
          }}
          show={this.state.showDeleteConfirm}
        />
        <DictionaryNewItemModal
          dictionaryId={this.state.dictionaryId}
          onClose={() => {
            this.setState({ showNewItemModal: false });
            this.reloadItems();
          }}
          show={this.state.showNewItemModal}
        />
        <DeleteConfirmationModal
          title={"Clear dictionary confirmation"}
          description={"Are you sure you want to delete all items?"}
          onClose={() => this.setState({ showClearConfirmation: false })}
          onConfirm={() => {
            this.props.actions
              .clearDictionaryItems(this.state.dictionaryId)
              .then(() => {
                this.props.notification.add("All items deleted", "", "success");
                this.reloadItems(false, () => {
                  this.setState(this.getInitialState());
                });
              });
          }}
          show={this.state.showClearConfirmation}
        />
        <DeleteConfirmationModal
          title={"Delete dictionary confirmation"}
          description={"Are you sure you want to delete dictionary?"}
          onClose={() =>
            this.setState({ showDeleteDictionaryConfirmation: false })
          }
          onConfirm={() => {
            this.props.actions
              .deleteDictionary(this.state.dictionaryId)
              .then(() => {
                this.props.notification.add(
                  "Dictionary deleted",
                  "",
                  "success"
                );
                this.props.history.push("/dictionaries");
              })
              .catch(() => {
                this.props.notification.add(
                  "Dictionary delete failed",
                  "",
                  "fail"
                );
              });
          }}
          show={this.state.showDeleteDictionaryConfirmation}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { dictionaries: state.dictionaries };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dictionariesActions, dispatch),
    notification: bindActionCreators(notificationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryEdit);
