import * as actionTypes from '../constants/actionTypes';

const initialState = {
    list: {
        data: [],
    },
    executionList: {
        data: []
    }
};

export default(state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TRIGGER_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.TRIGGER_LIST_CLEAR:
            return {
                ...state,
                list: []
            };
        case actionTypes.TRIGGER_GET:
            return {
                ...state,
                trigger: action.payload
            };
        case actionTypes.TRIGGER_CLEAR:
            return {
                ...state,
                trigger: null
            };
        case actionTypes.TRIGGER_GET_ADDITIONAL_DATA:
            return {
                ...state,
                additionalData: action.payload
            };
        case actionTypes.TRIGGERS_SCRIPT_CLEAR:
            return {
                ...state,
                scripts: []
            };
        case actionTypes.TRIGGERS_SCRIPT_LIST:
            return {
                ...state,
                scripts: action.payload
            };
        case actionTypes.TRIGGERS_EXECUTION_LOGS_CLEAR:
            return {
                ...state,
                executionLogs: null
            };
        case actionTypes.TRIGGERS_EXECUTION_LOGS_LIST:
            return {
                ...state,
                executionLogs: action.payload
            };
        case actionTypes.TRIGGERS_EXECUTION_LIST:
            return {
                ...state,
                executionList: action.payload
            };
        case actionTypes.TRIGGERS_EXECUTION_LIST_CLEAR:
            return {
                ...state,
                executionList: null
            };
        default:
            return state;
    }
};