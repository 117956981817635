import * as notificationActions from "../../pages/notifications/actions/notificationActions";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import "react-toastify/dist/ReactToastify.css";

const Notification = () => {
  const notifications = useAppSelector((state) => state.notification);

  const dispatch = useAppDispatch();

  notifications.forEach((notification) => {
    toast(notification.title, {
      type: notification.type === "success" ? "success" : "error",
    });
    dispatch(notificationActions.remove(notification.id));
  });

  return (
    <ToastContainer position="top-right" autoClose={5000} theme="colored" />
  );
};

export default Notification;
