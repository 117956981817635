import React, {Component} from 'react'
import {Modal, Button} from "react-bootstrap";
import * as notificationActions from "../../notifications/actions/notificationActions";
import {connect} from "react-redux";
import * as dictionariesActions from "../actions/dictionariesActions";
import {bindActionCreators} from "redux";
import generate from "../../../utils/guid";

class DictionaryNewItemModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [
                this.getNewItem()
            ]
        };
    }

    getNewItem = () =>{
        return {
          id: generate(),
          name: '',
          value: ''
        };
    }

    componentDidMount() {
        this.setState({isWorking:false});
    }

    handleAddField = (e) => {
        e.preventDefault();

        let items = this.state.items.slice(0);
        items.push(this.getNewItem());

        this.setState({items: items});
    }

    handleItemRemove = (item) => {
        let items = this.state.items.slice(0).filter(x=> x.id != item.id);
        this.setState({items: items});
    }

    handleSave = () =>{
        this.setState({isWorking: true});

        let item = {};
        this.state.items.forEach(x => {
            item[x.name] = x.value;
        });

        let dictionaryItem = {
            cells: item,
            dictionaryId: this.props.dictionaryId
        }
        this.props.actions.saveItem(dictionaryItem).then(()=>{
            this
                .props
                .notification
                .add("Item saved", "", "success")
            this.setState({isWorking: true});
            this.props.onClose();
        }).catch(()=>{
            this
                .props
                .notification
                .add("Item save failed", "", "fail");
            this.setState({isWorking: true});
        });
    }

    renderItem = (item) =>{
        return (
            <div key={item.id} className="form-group">
                <div className="col-md-3">
                    <input type="text"
                           onChange={(e)=>{
                               let items = this.state.items.slice(0);
                               let targetItem = items.find(x=>x.id == item.id);
                               targetItem.name = e.target.value;
                               this.setState({items: items});
                           }}
                           className="form-control" placeholder="Field name"/>
                </div>
                <div className="col-md-8">
                    <input
                        onChange={(e)=>{
                            let items = this.state.items.slice(0);
                            let targetItem = items.find(x=>x.id == item.id);
                            targetItem.value = e.target.value;
                            this.setState({items: items});
                        }}
                        type="text" className="form-control" placeholder="Value"/>
                </div>
                <div className="col-md-1">
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleItemRemove(item);
                        }}><i className="fa fa-times color-red margin-top-10"></i></a>
                </div>
            </div>
        );
    }



    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>New dictionary item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal">
                        {this.state.items.map((i)=>{
                            return this.renderItem(i);
                        })}
                    </form>
                    <button
                        onClick={this.handleAddField}
                        className="btn bg-blue has-text">
                        <i className="fa fa-plus"></i>
                        <span>Add</span>
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={(e)=>{
                            this.handleSave();
                        }}>
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : <i className="fa fa-check"></i>}
                        Save
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(dictionariesActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryNewItemModal)
