import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import TriggerEdit from "./components/TriggerEdit";
import TriggerExecutionLogsTable from "./components/TriggerExecutionLogsTable";
import TriggerExecutionsTable from './components/TriggerExecutionsTable';

export default class Trigger extends Component {
    render() {
        return (
            <Switch>
                <Route path="/trigger/edit/:triggerId?" component={TriggerEdit}/>
                <Route path="/trigger/execution/:triggerExecutionId?/logs" component={TriggerExecutionLogsTable}/>
                <Route path="/trigger/executions" component={TriggerExecutionsTable} />
            </Switch>
        )
    }
}