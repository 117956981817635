import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import ExportListTable from "./components/ExportListTable";

export default class ExportList extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/exportList" component={ExportListTable}/>
            </Switch>
        )
    }
}