import { combineReducers } from "redux";
import auth from "../pages/login/reducers/auth";
import customer from "../pages/customer/reducers/customer";
import trigger from "../pages/trigger/reducers/trigger";
import flags from "../pages/flags/reducers/flags";
import transfer from "../pages/transfer/reducers/transfer";
import fileImport from "../pages/fileImport/reducers/fileImport";
import importReducer from "../pages/import/reducers/import";
import order from "../pages/orders/reducers/order";
import inboundProcessing from "../pages/InboundProcessing/reducers/inboundProcessing";
import shipment from "../pages/shipments/reducers/shipment";
import settings from "../pages/settings/reducers/settings";
import dashboard from "../pages/dashboard/reducers/dashboard";
import dictionaries from "../pages/dictionaries/reducers/dictionaries";
import notification from "../pages/notifications/reducers/notification";
import importer from "../pages/importer/reducers/importer";
import service from "../pages/service/reducers/service";
import logReducer from "../components/common/LogHistory/reducer";
import exportList from "../pages/exportList/reducers/exportList";
import apiCall from "../pages/apiCall/reducers/apiCall";
import location from "../pages/location/reducers/location";
import apiAccessKey from "../pages/apiAccessKey/reducers/apiAccessKey";
import users from "../pages/users/reducers/users";
import commonItems from "../pages/common-items/reducers/commonItems";
import actionLog from "../pages/action-log/reducers/actionLog";
import { connectRouter } from "connected-react-router";
import { reducer as oidc } from "redux-oidc";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    notification,
    customer,
    trigger,
    flags,
    transfer,
    fileImport,
    import: importReducer,
    order,
    inboundProcessing,
    shipment,
    settings,
    dashboard,
    dictionaries,
    importer,
    exportList,
    log: logReducer,
    apiCall,
    apiAccessKey,
    service,
    location,
    users,
    oidc,
    commonItems,
    actionLog,
  });

export default rootReducer;
