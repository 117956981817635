import React, { Component } from "react";
import TagsInput from "react-tagsinput";
import InputVariableDefinition from "../variables/InputVariableDefinition";
import CheckboxVariableDefinition from "../variables/CheckboxVariableDefinition";
import ListVariableDefinition from "../variables/ListVariableDefinition";
import * as orderTypes from "../../constants/orderTypes";
import DropdownSelector from "../../../../components/common/DropdownSelector";
import PropTypes from 'prop-types';

const validationStratsDesc = {
  0: "Validates: shipmentId, customer, status",
  1: "Validates: location.id, appointment timezone, time range",
  2: "Validates: ",
  3: "Validates: vin",
};

class CustomerSettingsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strategies: [],
      duplicateActions: [],
      exportActions: [],
      orderTypes: [],
      selectedExportAction: "",
      selectedOrderGroupStrategy: "",
      selectedDuplicateAction: "",
      selectedTurvoCustomerIds: [],
      exportCostsStrategies: [],
      orderGroupStrategies: [],
      variableDefinitions: [],
      defaultOrderType: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    const { additionalData } = nextProps;
    const { customerSettings } = nextProps.customer;

    if (additionalData && customerSettings) {
      const { validationStrategies } = nextProps.customer.customerSettings;
      this.setState({
        strategies: nextProps.additionalData.validationStrategies.map((el) => ({
          ...el,
          checked: validationStrategies
            ? validationStrategies[el.value]
            : false,
        })),
        exportActions: nextProps.additionalData.exportActions.slice(0),
        orderTypes: nextProps.additionalData.orderTypes.slice(0),
        selectedExportAction: nextProps.customer.customerSettings.exportAction,
        duplicateActions: nextProps.additionalData.duplicateActions.slice(0),
        exportCostsStrategies:
          nextProps.additionalData.exportCostsStrategies.slice(0),
        orderGroupStrategies:
          nextProps.additionalData.orderGroupStrategy.slice(0),

        selectedDuplicateAction:
          nextProps.customer.customerSettings.duplicateAction,
        selectedTurvoCustomerIds:
          nextProps.customer.customerSettings.turvoCustomerIds,
        selectedExportCostsStrategy:
          nextProps.customer.customerSettings.exportCostStrategy,
        selectedOrderGroupStrategy:
          nextProps.customer.customerSettings.orderGroupStrategy,

        variableDefinitions:
          nextProps.customer.customerSettings.variableDefinitions,
        defaultOrderType: nextProps.customer.customerSettings.defaultOrderType,
        monitorAppointments:
          nextProps.customer.customerSettings.monitorAppointments,
        createGenericLocations:
          nextProps.customer.customerSettings.createGenericLocations,
        salesforceAccountId:
          nextProps.customer.customerSettings.salesforceAccountId,
        syncProviders: nextProps.customer.customerSettings.syncProviders,

        autoBuildAll: nextProps.customer.customerSettings.autoBuildAll,
        autoSendToTmsFlag:
          nextProps.customer.customerSettings.autoSendToTmsFlag,
        autoSendAsASingleToTms:
          nextProps.customer.customerSettings.autoSendAsASingleToTms,
        consolidateShipments:
          nextProps.customer.customerSettings.consolidateShipments,
        skipOMSDateCorrection:
          nextProps.customer.customerSettings.skipOMSDateCorrection,

        selectedNotificationEmails:
          nextProps.customer.customerSettings.notificationEmails,

        disableLocationNormalization:
          nextProps.customer.customerSettings.disableLocationNormalization,

        disableShipmentRouteOptimization:
          nextProps.customer.customerSettings.disableShipmentRouteOptimization,
      });
    }
  }
  changeStrategyStatus = (strategy) => {
    const strategies = [
      ...this.state.strategies.map((el) => {
        if (el.value === strategy.value) {
          return {
            ...el,
            checked: !el.checked,
          };
        }
        return el;
      }),
    ];
    this.setState({ strategies }, () => {
      this.props.onChange({
        validationStrategies: this.state.strategies.reduce((obj, item) => {
          obj[item.value] = item.checked;
          return obj;
        }, {}),
      });
    });
  };

  changeSelectedDuplicateAction = (e) => {
    this.setState({ selectedDuplicateAction: e }, () => {
      this.props.onChange({
        duplicateAction: this.state.selectedDuplicateAction,
      });
    });
  };

  changeSelectedExportAction = (e) => {
    this.setState({ selectedExportAction: e }, () => {
      this.props.onChange({
        exportAction: this.state.selectedExportAction,
      });
    });
  };

  changeSelectedOrderGroupStrategy = (e) => {
    this.setState({ selectedOrderGroupStrategy: e }, () => {
      this.props.onChange({
        orderGroupStrategy: this.state.selectedOrderGroupStrategy,
      });
    });
  };

  changeOrderType = (e) => {
    this.setState({ defaultOrderType: e }, () => {
      this.props.onChange({
        defaultOrderType: this.state.defaultOrderType,
      });
    });
  };

  changeMonitorAppointments = (e) => {
    this.setState({ monitorAppointments: e.target.checked }, () => {
      this.props.onChange({
        monitorAppointments: this.state.monitorAppointments,
      });
    });
  };

  changeCreateGenericLocations = (e) => {
    this.setState({ createGenericLocations: e.target.checked }, () => {
      this.props.onChange({
        createGenericLocations: this.state.createGenericLocations,
      });
    });
  };

  changeSalesforceAccountId = (e) => {
    this.setState({ salesforceAccountId: e.target.value }, () => {
      this.props.onChange({
        salesforceAccountId: this.state.salesforceAccountId,
      });
    });
  };

  changeSelectedExportCostStrategy = (e) => {
    this.setState({ selectedExportCostsStrategy: e }, () => {
      this.props.onChange({
        exportCostStrategy: this.state.selectedExportCostsStrategy,
      });
    });
  };

  changeTurvoCustomerIds = (ids) => {
    this.setState({ selectedTurvoCustomerIds: ids }, () => {
      this.props.onChange({
        turvoCustomerIds: this.state.selectedTurvoCustomerIds,
      });
    });
  };

  changeSyncProviders = (e) => {
    this.setState({ syncProviders: e }, () => {
      this.props.onChange({
        syncProviders: e,
      });
    });
  };

  changeAutoBuildAll = (e) => {
    this.setState({ autoBuildAll: e.target.checked }, () => {
      this.props.onChange({
        autoBuildAll: this.state.autoBuildAll,
      });
    });
  };

  changeAutoSendToTmsFlag = (e) => {
    this.setState({ autoSendToTmsFlag: e.target.checked }, () => {
      this.props.onChange({
        autoSendToTmsFlag: this.state.autoSendToTmsFlag,
      });
    });
  };
  changeAutoSendAsASingleToTms = (e) => {
    this.setState({ autoSendAsASingleToTms: e.target.checked }, () => {
      this.props.onChange({
        autoSendAsASingleToTms: this.state.autoSendAsASingleToTms,
      });
    });
  };
  changeConsolidateShipments = (e) => {
    this.setState({ consolidateShipments: e.target.checked }, () => {
      this.props.onChange({
        consolidateShipments: this.state.consolidateShipments,
      });
    });
  };
  changeSkipOMSDateCorrection = (e) => {
    this.setState({ skipOMSDateCorrection: e.target.checked }, () => {
      this.props.onChange({
        skipOMSDateCorrection: this.state.skipOMSDateCorrection,
      });
    });
  };
  changeLocationNormalization = (e) => {
    this.setState({ disableLocationNormalization: e.target.checked }, () => {
      this.props.onChange({
        disableLocationNormalization: this.state.disableLocationNormalization,
      });
    });
  };

  changeShipmentRouteOptimization = (e) => {
    this.setState({ disableShipmentRouteOptimization: e.target.checked }, () => {
      this.props.onChange({
        disableShipmentRouteOptimization: this.state.disableShipmentRouteOptimization,
      });
    });
  };

  changeRenderVariableDefinition = (value, variableDefinitionId) => {
    let variableDefinitions = [...this.state.variableDefinitions];
    let targetVariableDefinition = variableDefinitions.find((vd) => {
      return vd.id === variableDefinitionId;
    });
    targetVariableDefinition.value = value;

    this.setState({
      variableDefinitions: variableDefinitions,
    });
  };

  changeNotificationEmails = (values) => {
    this.setState({ selectedNotificationEmails: values }, () => {
      this.props.onChange({
        notificationEmails: this.state.selectedNotificationEmails,
      });
    });
  };

  render() {
    const { additionalData } = this.props;

    return (
      <div>
        <h5 className="text-semibold">Validation settings</h5>
        {this.state.strategies.map((el) => (
          <div className="checkbox" key={el.value}>
            <label>
              <input
                type="checkbox"
                checked={el.checked || false}
                onChange={() => this.changeStrategyStatus(el)}
              />
              <p>{el.text}</p>
              <p className="small-text">{validationStratsDesc[el.value]}</p>
            </label>
          </div>
        ))}
        <form className="form-horizontal">
          <h5 className="text-semibold">Export settings</h5>
          <div className="form-group">
            <label className="col-sm-2 control-label">Export action</label>
            <div className="col-sm-8">
              <select
                value={this.state.selectedExportAction}
                onChange={(e) => {
                  this.changeSelectedExportAction(e.target.value);
                }}
                className="form-control"
              >
                {this.state.exportActions.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Export cost strategy
            </label>
            <div className="col-sm-8">
              <select
                value={this.state.selectedExportCostsStrategy}
                onChange={(e) => {
                  this.changeSelectedExportCostStrategy(e.target.value);
                }}
                className="form-control"
              >
                {this.state.exportCostsStrategies.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Order type</label>
            <div className="col-sm-8">
              <select
                value={this.state.defaultOrderType || ""}
                onChange={(e) => {
                  this.changeOrderType(e.target.value);
                }}
                className="form-control"
              >
                <option value={""}>Not selected</option>
                {this.state.orderTypes.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Export providers</label>
            <div className="col-sm-8">
              <DropdownSelector
                title="Providers"
                items={additionalData ? additionalData.syncProviders : []}
                itemId={(el) => el.value}
                itemRender={(el) => el.text}
                onChange={this.changeSyncProviders}
                selectedOptions={this.state.syncProviders}
              />
            </div>
          </div>
          <h5 className="text-semibold">Turvo settings</h5>
          <div className="form-group">
            <label className="col-sm-2 control-label">Duplicate action</label>
            <div className="col-sm-8">
              <select
                value={this.state.selectedDuplicateAction}
                onChange={(e) => {
                  this.changeSelectedDuplicateAction(e.target.value);
                }}
                className="form-control"
              >
                {this.state.duplicateActions.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Turvo customers</label>
            <div className="col-sm-8">
              <TagsInput
                inputProps={{
                  placeholder: "Add Id",
                }}
                value={this.state.selectedTurvoCustomerIds || []}
                onChange={this.changeTurvoCustomerIds}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Appointment monitoring
            </label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={!!this.state.monitorAppointments}
                onChange={this.changeMonitorAppointments}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Create generic locations
            </label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={!!this.state.createGenericLocations}
                onChange={this.changeCreateGenericLocations}
              />
            </div>
          </div>
          <h5 className="text-semibold">Salesforce settings</h5>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Salesforce account id
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                value={this.state.salesforceAccountId || ""}
                onChange={this.changeSalesforceAccountId}
              />
            </div>
          </div>
          <h5 className="text-semibold">OMS Settings</h5>
          <div className="form-group">
            <label className="col-sm-2 control-label">AutoBuild All</label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={!!this.state.autoBuildAll}
                onChange={this.changeAutoBuildAll}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Auto send to tms</label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={!!this.state.autoSendToTmsFlag}
                onChange={this.changeAutoSendToTmsFlag}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Send as single to TMS
            </label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={!!this.state.autoSendAsASingleToTms}
                onChange={this.changeAutoSendAsASingleToTms}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Consolidate shipments
            </label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={!!this.state.consolidateShipments}
                onChange={this.changeConsolidateShipments}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Skip OMS Date Correction
            </label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={
                  this.state.skipOMSDateCorrection == null
                    ? true
                    : this.state.skipOMSDateCorrection
                }
                onChange={this.changeSkipOMSDateCorrection}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Order group strategy
            </label>
            <div className="col-sm-8">
              <select
                value={this.state.selectedOrderGroupStrategy}
                onChange={(e) => {
                  this.changeSelectedOrderGroupStrategy(e.target.value);
                }}
                className="form-control"
              >
                {this.state.orderGroupStrategies.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <h5 className="text-semibold">Variables</h5>
          {this.state.variableDefinitions
            ? this.state.variableDefinitions.map((variableDefinition) => {
                switch (variableDefinition.type) {
                  case 0: {
                    return (
                      <InputVariableDefinition
                        name={variableDefinition.name}
                        value={variableDefinition.value}
                        key={variableDefinition.name}
                        onChange={(value) =>
                          this.changeRenderVariableDefinition(
                            value,
                            variableDefinition.id
                          )
                        }
                      />
                    );
                  }
                  case 1: {
                    return (
                      <CheckboxVariableDefinition
                        name={variableDefinition.name}
                        value={variableDefinition.value}
                        key={variableDefinition.name}
                        onChange={(value) =>
                          this.changeRenderVariableDefinition(
                            value,
                            variableDefinition.id
                          )
                        }
                      />
                    );
                  }
                  case 2:
                    return (
                      <ListVariableDefinition
                        name={variableDefinition.name}
                        value={variableDefinition.value}
                        key={variableDefinition.name}
                        onChange={(value) =>
                          this.changeRenderVariableDefinition(
                            value,
                            variableDefinition.id
                          )
                        }
                      />
                    );
                }
              })
            : null}
          <h5 className="text-semibold">Notifications</h5>
          <div className="form-group">
            <label className="col-sm-2 control-label">Emails</label>
            <div className="col-sm-8">
              <TagsInput
                inputProps={{
                  placeholder: "Add email",
                }}
                value={this.state.selectedNotificationEmails || []}
                onChange={this.changeNotificationEmails}
              />
            </div>
          </div>
          <h5 className="text-semibold">Provider Settings</h5>
          <div className="form-group">
            <label className="col-sm-2 control-label">
              Disable Location Normalization
            </label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={
                  this.state.disableLocationNormalization == null
                    ? false
                    : this.state.disableLocationNormalization
                }
                onChange={this.changeLocationNormalization}
              />
            </div>
          </div>
          <h5 className="text-semibold">SL3 Settings</h5>
          <div className="form-group">
            <label className="col-sm-2 control-label">
                Disable Shipment Route Optimization
            </label>
            <div className="col-sm-8">
              <input
                type="checkbox"
                checked={
                  this.state.disableShipmentRouteOptimization == null
                    ? false
                    : this.state.disableShipmentRouteOptimization
                }
                onChange={this.changeShipmentRouteOptimization}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CustomerSettingsTab.propTypes = {
  additionalData: PropTypes.object,
  customerSettings: PropTypes.object,
  validationStrategies: PropTypes.object,
  customer: PropTypes.any,
  onChange: PropTypes.func,
  monitorAppointments: PropTypes.bool,
  createGenericLocations: PropTypes.bool,
};

export { CustomerSettingsTab as default };
