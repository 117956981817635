import React from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Datetime from "react-datetime";
import { debounce } from "throttle-debounce";

import FormLoader from "../../../components/common/FormLoader";
import { dictionaryType } from "../constants/enums";

class DictionaryEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      effectiveDate: null,
      expirationDate: null,
      turvoAccountId: null,
      turvoAccountName: null,
      type: null,
      isAccountsLoading: false,
      accountsOptions: null,
    };

    this.handleAccountNameSearch = debounce(500, this.handleAccountNameSearch);
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps, this.props)
    if (this.props.dictionaryName && this.props.data) {
      this.setState({
        name: this.props.dictionaryName,
        effectiveDate: this.props.data.effectiveDate,
        expirationDate: this.props.data.expirationDate,
        turvoAccountId: this.props.data.turvoAccountId,
        turvoAccountName: this.props.data.turvoAccountName,
        type: this.props.data.type,
        accountsOptions: [
          {
            value: this.props.data.turvoAccountId,
            label: this.props.data.turvoAccountName,
          },
        ],
      });
    }
  }

  handleAccountNameSearch = (searchQuery) => {
    if (!searchQuery) return;

    this.setState({ isAccountsLoading: true });
    this.props
      .fetchCustomers(searchQuery)
      .then((res) => {
        const options = res.data.map((x) => ({ value: x.id, label: x.name }));
        this.setState({ isAccountsLoading: false, accountsOptions: options });
      })
      .catch((err) => {
        console.error(err.message);
        this.setState({ isAccountsLoading: false });
      });
  };

  handleFieldChange = (value, fieldName) => {
    this.setState({ [fieldName]: value });
  };

  handleAccountSelect = (item) => {
    this.setState({
      turvoAccountId: item ? item.value : null,
      turvoAccountName: item ? item.label : null,
    });
  };

  handleSave = () => {
    this.props.onSave(this.state);
  };

  getTypes = () => {
    return dictionaryType.map((x) => ({ value: x.id, label: x.text }));
  };

  render() {
    return (
      <Modal show={this.props.open} onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Dictionary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.isSaving && <FormLoader inline />}
          {!this.props.isSaving && (
            <React.Fragment>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="dict-edit-name">
                    <b>Name</b>
                  </label>
                  <input
                    id="dict-edit-name"
                    className="form-control"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={(e) =>
                      this.handleFieldChange(e.target.value, "name")
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="dict-edit-account-name">
                    <b>Account Name</b>
                  </label>
                  <Select
                    id="dict-name-edit"
                    placeholder="Account Name"
                    isLoading={this.state.isAccountsLoading}
                    onInputChange={(value) => {
                      this.handleAccountNameSearch(value);
                    }}
                    options={this.state.accountsOptions}
                    onChange={(value) => {
                      this.handleAccountSelect(value);
                    }}
                    value={this.state.turvoAccountId}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="dict-edit-type">
                    <b>Type</b>
                  </label>
                  <Select
                    id="dict-edit-type"
                    placeholder="Type"
                    options={this.getTypes()}
                    onChange={(item) =>
                      this.handleFieldChange(item ? item.value : null, "type")
                    }
                    value={this.state.type}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="dict-edit-eff-date">
                    <b>Effective Date</b>
                  </label>
                  <Datetime
                    id="edit-eff-date"
                    inputProps={{
                      placeholder: "Effective Date",
                    }}
                    value={this.state.effectiveDate}
                    onChange={(e) => this.handleFieldChange(e, "effectiveDate")}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="dict-edit-exp-date">
                    <b>Expiration Date</b>
                  </label>
                  <Datetime
                    id="dict-edit-exp-date"
                    inputProps={{
                      placeholder: "Expiration Date",
                    }}
                    value={this.state.expirationDate}
                    onChange={(e) =>
                      this.handleFieldChange(e, "expirationDate")
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </Modal.Body>
        {!this.props.isSaving && (
          <Modal.Footer>
            <Button onClick={this.props.onCancel}>Close</Button>
            <button
              onClick={() => this.handleSave()}
              className="btn bg-success has-text"
            >
              <span>Save</span>
            </button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

export default DictionaryEditModal;
