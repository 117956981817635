import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function commonImportersList(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/importer/list", {...args, common: true})
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.COMMON_IMPORTERS_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function commonScriptsList(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/script/list", {...args, isShared: true})
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.COMMON_SCRIPTS_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function saveCommonScript(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/script/save", args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function getCommonScript(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/script/list", { id: id })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function deleteCommonScript(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
                httpHandler
                .post("/api/script/delete", {id: id})
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function commonScriptsClear() {
    return (dispatch) => {
        dispatch({ type: actionTypes.COMMON_SCRIPTS_CLEAR });
    };
}

export function clear() {
    return (dispatch) => {
        dispatch({type: actionTypes.COMMON_ITEMS_CLEAR});
    };
}