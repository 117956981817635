import useSWR from "swr";
import { CustomerObject } from "../models/CustomerObject";
import fetcherPost from "../fetchers/fetcherPost";
import { ResponseFetch } from "../models/ResponseFetch";
import { TableRequestObject } from "../models/Requests/TableRequestObject";

function useGetCustomers(args: TableRequestObject) {
  const { data, error, isLoading, mutate } = useSWR<
    ResponseFetch<CustomerObject[]>
  >(["/api/customer/list", args], ([url, args]) => fetcherPost(url, args), {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });

  return {
    customers: data,
    error,
    isLoading,
    mutate,
  };
}

export default useGetCustomers;
