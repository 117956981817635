import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'throttle-debounce';

import MegaTable from '../../../components/common/MegaTable';
import DropdownSelector from '../../../components/common/DropdownSelector';
import DeleteConfirmationModal from '../../../components/common/DeleteConfirmationModal';

import * as triggerActions from '../../trigger/actions/triggerActions';
import * as flagsActions from '../actions/flagsActions'
import displayDateTime from '../../../utils/dateTimeHelper';
import { shallowEqual } from '../../../utils/comparator';

class FlagsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {
                page: 1,
                pageSize: 10,
                customerId: '',
                turvoShipmentId: '',
                triggers: []
            },
            isWorking: false,
            flagToDelete: { id: null, key: null }
        };
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filters.customerId !== this.state.filters.customerId)
            this.getTriggers();
        if (!shallowEqual(prevState.filters, this.state.filters))
            this.reloadData();
    }

    reloadData = (noLoader) => {
        if (!noLoader) {
            this.setState({ isWorking: true });
        }
        const { filters } = this.state;
        this
            .props
            .flagsActions
            .listFlags({ page: filters.page, pageSize: filters.pageSize, ...filters })
            .then(() => this.setState({ isWorking: false }))
            .catch(() => this.setState({ isWorking: false }));
    };

    getTriggers = () => {
        this.props.triggerActions.listTriggers({ customerId: this.state.filters.customer });
    };

    getData = () => {
        if (this.props.flags && this.props.flags.list) {
            return this.props.flags.list.data;
        }
        return [];
    };

    changeFilterState = (obj) => {
        this.setState({ filters: { ...this.state.filters, ...obj } });
    };

    render() {
        let columns = [
            {
                name: 'Key',
                id: 'key',
                type: 'text'
            },
            {
                name: 'Turvo resource id',
                id: 'resourceId',
                type: 'text'
            },
            {
                name: 'Date',
                id: 'dateTime',
                type: 'custom',
                renderDisplay: d => <span>
                    {displayDateTime(d.createDate)}
                </span>
            },
            {
                name: 'Trigger',
                id: 'triggerName',
                type: 'text',
            },
            {
                name: 'Actions',
                id: 'Actions',
                type: 'custom',
                width: '100',
                renderDisplay: d => <div>
                    <button className="btn btn-xxs bg-danger"
                        onClick={() => this.setState({ flagToDelete: { key: d.key, id: d.id } })}
                    >
                        Delete
                    </button>
                </div>
            }
        ];

        const flags = this.props.flags.list || {};
        const customers = this.props.customers ? this.props.customers.data : [];
        const { filters } = this.state;

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4><span className="text-semibold">Flags</span></h4>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-2">
                                <select value={filters.customerId}
                                    onChange={e => this.changeFilterState({ customerId: e.target.value })}
                                    className="form-control">
                                    <option value={''}>None</option>
                                    {customers.map(item => {
                                        return (<option key={item.id} value={item.id}>{item.name}</option>);
                                    })}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <DropdownSelector
                                    title="Triggers"
                                    items={this.props.trigger.list ? this.props.trigger.list.data : []}
                                    itemId={el => el.id}
                                    itemRender={el => el.name}
                                    onChange={triggers => this.changeFilterState({ triggers })}
                                    selectedOptions={filters.triggers}
                                />
                            </div>
                            <div className="col-md-2">
                                <input
                                    value={filters.resourceId}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        debounce(500, false, () => {

                                            this.changeFilterState({ resourceId: value })
                                        })()
                                    }
                                    }
                                    className="form-control"
                                    placeholder="Turvo resource id"
                                />
                            </div>
                            <div className="col-md-1 table-refresh-button">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.reloadData();
                                }}>
                                    <i className="fa fa-refresh" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <MegaTable
                            hideHeader
                            editMode={false}
                            columns={columns}
                            onRefresh={() => {
                                this.reloadData();
                            }}
                            isWorking={this.state.isWorking}
                            summary={{
                                skip: flags.skip,
                                take: flags.take,
                                total: flags.total,
                                totalPages: flags.totalPages
                            }}
                            page={filters.page}
                            pageChanged={(page) => {
                                if (page < 1)
                                    page = 1;
                                this.changeFilterState({ page });
                            }}
                            pageSizeChanged={(pageSize) => this.changeFilterState({ pageSize })}
                            data={this.getData()} />
                    </div>
                </div>
                <DeleteConfirmationModal
                    title={"Delete flag confirmation"}
                    description={`Are you sure you want to delete flag ${this.state.flagToDelete.key}?`}
                    onClose={() => this.setState({ flagToDelete: { id: null, key: null } })}
                    onConfirm={() => {
                        this
                            .props
                            .flagsActions
                            .remove(this.state.flagToDelete.id, () => {
                                this.setState({ flagToDelete: { id: null, key: null } });
                            })
                    }}
                    show={!!this.state.flagToDelete.id}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        flags: state.flags,
        trigger: state.trigger,
        customers: state.import.customers
    }
}

function mapDispatchToProps(dispatch) {
    return {
        triggerActions: bindActionCreators(triggerActions, dispatch),
        flagsActions: bindActionCreators(flagsActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlagsTable);
