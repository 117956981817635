import React, {Component} from 'react'
import RowSnapshotModal from '../../importer/components/RowSnapshotModal';

export default class TriggerStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            snapshotOpened: false
        };
    }

    render() {
        const trigger = this.props.trigger || {};

        return (
            <div className={this.props.className}>
                <div>
                    <label className="control-label"><b>Trigger execution</b></label>
                    &nbsp;
                    <span className="margin-left-10">{trigger.elapsedExecutionTime}</span>
                    &nbsp;
                    <a className="margin-left-10"
                       onClick={(e) => {
                           e.preventDefault();
                           this.setState({ snapshotOpened: !this.state.snapshotOpened });
                       }}>
                        Input data snapshot
                    </a>
                </div>
                <RowSnapshotModal
                    onClose={() => this.setState({ snapshotOpened: false })}
                    show={this.state.snapshotOpened}
                    data={trigger.inputDataSnapshot}
                />
            </div>
        )
    }
}