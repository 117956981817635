import React, { Component } from "react";
import displayDateTime from "../../../../../utils/dateTimeHelper";
import VisualStageItem from "./VisualStageItem";
import * as stageTypes from "../../../constants/stageTypes";
import MicroTable from "../../../../../components/common/MicroTable";
import getLabelByValue from "../../../../../utils/enumHelper";
import LogMessage from "../../../../../components/common/LogHistory/LogMessage";
import { Panel, PanelGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export default class FileImportLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logLevelIcons: {
        0: () => <i className="fa fa-wrench" />,
        1: () => <i className="fa fa-info-circle color-blue" />,
        2: () => <i className="fa fa-warning" />,
        3: () => <i className="fa fa-times-circle-o color-red" />,
      },
    };
  }

  renderLogGroupHeader = (logGroup) => {
    return (
      <Panel.Title toggle>
        {logGroup.reprocessing
          ? "Reprocessing " +
          displayDateTime(
            logGroup.reprocessing.createDate
          ) +
          " by " +
          logGroup.reprocessing.userName
          : "Initial import"}
      </Panel.Title>
    );
  };

  renderLogGroup = (logGroup) => {
    let logItems = logGroup.logs;
    let key = logGroup.reprocessing ? logGroup.reprocessing.id : "default";

    return (
      <Panel key={key} eventKey={key}>
        <Panel.Heading>{this.renderLogGroupHeader(logGroup)}</Panel.Heading>
        <Panel.Body collapsible>
          <MicroTable
            data={logItems || []}
            noEdit
            itemSelected={(item) => { }}
            columns={[
              {
                name: "Stage",
                id: "stage",
                type: "custom",
                renderDisplay: (d) => (
                  <span>{getLabelByValue(stageTypes, d.stageType)}</span>
                ),
              },
              {
                name: "Message",
                id: "message",
                type: "custom",
                renderDisplay: (d) => (
                  <LogMessage settings={this.props.settings} log={d} />
                ),
              },
            ]}
            onChange={(data) => { }}
          />
        </Panel.Body>
      </Panel>
    );
  };

  render() {
    let { item } = this.props;
    let { fileImport, logGroups } = item;

    let lastGroup = logGroups.slice(-1)[0];

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <b>File import: </b>
            <a
              href={`/api/fileImport/downloadArchiveFile?fileImportId=${fileImport.id}`}
            >
              {fileImport.originalFileName}{" "}
            </a>
            <FontAwesomeIcon icon={icon({ name: "arrow-right" })} />
            <a
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push(
                  `/customer/edit/${fileImport.customerId}/import/editImporter/${fileImport.importerId}`
                );
              }}
            >
              {" "}
              {fileImport.importerName}
            </a>
            &nbsp;
            {displayDateTime(fileImport.startedDate)}
            &nbsp;by&nbsp;{fileImport.userName}
          </div>
        </div>
        <div className="row margin-top-10">
          <div className="col-md-12">
            <div className="visual-tracker-flex-container">
              {item.stages.map((stage, i) => {
                return (
                  <VisualStageItem
                    settings={this.props.settings}
                    saveData={this.props.saveData}
                    reprocess={this.props.reprocess}
                    isLast={i === item.stages.length - 1}
                    key={stage.id}
                    stage={stage}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="row margin-top-10">
          <div className="col-md-12">
            <b>Log messages: </b>
            <div className="margin-top-5">
              <PanelGroup
                accordion
                defaultActiveKey={
                  lastGroup.reprocessing ? lastGroup.reprocessing.id : "default"
                }
                id="logGroups-panels"
              >
                {logGroups.map((g) => this.renderLogGroup(g))}
              </PanelGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
