import { Component } from "react";
import PropTypes from "prop-types";

class PhotoUploader extends Component {
  state = {
    image: null,
  };

  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    if (this.validateFiles) {
      let formData = new FormData();
      formData.append("File", file);
      this.props.uploadFile(formData);
    } else if (this.props.notify)
      this.props.notify("Not valid size or file type");
  };

  validateFiles = ({ size, type }) => {
    const { fileSize, fileTypes } = this.props;
    return size > fileSize && fileTypes.some((el) => el === type);
  };

  render() {
    return (
      <div>
        <div>
          {this.props.imageUrl ? (
            <img alt="image" className="logoImage" src={this.props.imageUrl} />
          ) : (
            <span>No image</span>
          )}
        </div>
        <div className="input-group">
          <div className="custom-file">
            <input
              type="file"
              onChange={this.fileChangedHandler}
              className="inputfile"
              id="logoinput"
            />
            <label
              htmlFor="logoinput"
              className="btn-sm bg-blue has-text clickable"
            >
              Choose image
            </label>
          </div>
        </div>
      </div>
    );
  }
}

PhotoUploader.proptypes = {
  uploadFile: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  notify: PropTypes.func,
  fileSize: PropTypes.number,
  fileTypes: PropTypes.array,
};

export { PhotoUploader as default };
