import React, { Component, Fragment } from "react";
import MegaTable from "../../../components/common/MegaTable";
import * as inboundProcessingActions from "../actions/inboundProcessingActions";
import * as fileImportActions from "../../fileImport/actions/fileImportActions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import { debounce } from "throttle-debounce";

import getLabelByValue from "../../../utils/enumHelper";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as inboundProcessingStates from "../constants/inboundProcessingStates";
import parse from "../../../utils/querystring";

import DropdownSelector from "../../../components/common/DropdownSelector";
import DateRangeSelector from "../../../components/common/DateRangeSelector";
import * as inboundProcessingHelper from "../helpers/inboundProcessingHelper";
import Toggle from "react-bootstrap-toggle";
import { Helmet } from "react-helmet";

const stateTypes = [
  { id: 0, value: "Processing" },
  { id: 1, value: "Error" },
  { id: 2, value: "Duplicated" },
  { id: 3, value: "Processed" },
];

class InboundProcessingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 10,
      selectedItem: {},
      isWorking: false,
      search: "",
      fileImportIds: null,
      from: moment.utc("0001-01-01"),
      to: moment.utc("0001-01-01"),
      states: [0, 1, 2, 3],
      selectionMode: false,
      selectedInboundProcessing: [],
      data: [],
      advancedSearch: false,
    };

    this.refreshDebounce = debounce(300, false, () => {
      this.reloadData();
    });
  }

  initSearchData = (callback) => {
    let searchParams = parse(this.props.location.search);
    if (searchParams["fileImportId"]) {
      this.props.fileImportActions
        .getById(searchParams["fileImportId"])
        .then((response) => {
          this.setState(
            {
              fileImportIds: [searchParams["fileImportId"]],
              fileImport: response.data,
              states: [],
            },
            () => {
              callback();
            }
          );
        });
    } else {
      callback();
    }
  };

  componentDidMount() {
    this.props.actions.clearList();
    this.props.actions.listCustomers({ pageSize: null, page: null });

    this.initSearchData(() => {
      this.reloadData();
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: this.getData(nextProps),
    });
  }

  changeDatesValues = (from, to) => {
    if (from <= to) {
      this.setState(
        {
          to,
          from,
        },
        () => this.reloadData()
      );
    }
  };

  checkOrUncheckItem = (item) => {
    const foundItem = this.state.selectedInboundProcessing.find(
      (el) => el.id === item.id
    );
    if (foundItem)
      this.setState({
        selectedInboundProcessing: this.state.selectedInboundProcessing.filter(
          (el) => el.id !== item.id
        ),
      });
    else
      this.setState({
        selectedInboundProcessing: [
          ...this.state.selectedInboundProcessing,
          item,
        ],
      });
  };

  checkAll = () => {
    const checkedData = this.props.inboundProcessing.list.data.map((el) => ({
      ...el,
      checked: !el.checked,
    }));
    this.setState({
      data: checkedData,
      selectedInboundProcessing: checkedData,
    });
  };

  reloadData = (noLoader) => {
    console.log("reload");
    if (!noLoader) {
      this.setState({ isWorking: true });
    }
    this.props.actions
      .list({
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
        customerId: this.state.customerIds,
        fileImportId: this.state.fileImportIds,
        from: this.state.from,
        to: this.state.to,
        states: this.state.states,
        advancedSearch: this.state.advancedSearch,
      })
      .then(() => this.setState({ isWorking: false }))
      .catch(() => this.setState({ isWorking: false }));
  };

  getData = (props) => {
    if (props.inboundProcessing && props.inboundProcessing.list) {
      return props.inboundProcessing.list.data;
    }
    return [];
  };

  getSystemSettings = () => {
    if (this.props.settings) return this.props.settings.system_settings || {};
    return {};
  };

  getCustomers = () => {
    if (
      this.props.inboundProcessing &&
      this.props.inboundProcessing.customers
    ) {
      return this.props.inboundProcessing.customers.data || [];
    }
    return [];
  };

  getColumns = () => {
    let systemSettings = this.getSystemSettings();
    let columns = [
      ...inboundProcessingHelper.getDefaultInboundProcessingColumns(
        this.props.history,
        systemSettings.turvoBaseUrl
      ),
    ];

    if (this.state.states.some((el) => el === 2))
      columns.push({
        name: "Error",
        id: "exception",
        type: "text",
      });
    columns.push({
      name: "Actions",
      id: "Actions",
      type: "custom",
      width: 130,
      renderDisplay: (d) => (
        <span>
          {d.state === 3 ? <i className="fa fa-check color-green" /> : null}
          {d.state === 2 || d.state === 1 ? (
            <i className="fa fa-times color-red" />
          ) : null}
          &nbsp;
          {getLabelByValue(inboundProcessingStates, d.state)}
          <br />
          <a
            className="btn btn-xxs bg-slate"
            href={"/updates/details/" + d.id}
            target="_blank"
          >
            Details
          </a>
        </span>
      ),
    });

    return columns;
  };

  render() {
    let columns = this.getColumns();

    let inboundProcessing = this.props.inboundProcessing || {};

    return (
      <div>
        <Helmet>
          <title>SL3 - Updates</title>
        </Helmet>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Processing</span>
              </h4>
            </div>
            <div className="heading-elements">
              <div className="heading-btn-group">
                {this.state.selectionMode ? (
                  <Fragment>
                    <button
                      onClick={() =>
                        this.setState({
                          selectionMode: false,
                          selectedInboundProcessing: [],
                        })
                      }
                      className="btn bg-orange btn-sm"
                    >
                      Cancel
                    </button>
                  </Fragment>
                ) : (
                  <button
                    onClick={() => this.setState({ selectionMode: true })}
                    className="btn bg-blue btn-sm"
                  >
                    Select processing:
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat">
          <div className="panel-body">
            <div className="row">
              <div className="col-md-2">
                <input
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState(
                      {
                        search: e.target.value,
                      },
                      this.refreshDebounce()
                    );
                  }}
                  className="form-control"
                  placeholder="OrderId, VIN"
                />
              </div>
              <div className="col-md-1">
                <Toggle
                  style={{ height: "35px" }}
                  onClick={() =>
                    this.setState(
                      { advancedSearch: !this.state.advancedSearch },
                      this.reloadData
                    )
                  }
                  on={<span>Quick</span>}
                  off={<span>Advanced</span>}
                  size="xs"
                  offstyle="success"
                  active={!this.state.advancedSearch}
                />
              </div>
              <div className="col-md-2">
                <DateRangeSelector
                  title=""
                  selectedFrom={this.state.from}
                  selectedTo={this.state.to}
                  onDatesChanged={(from, to) =>
                    this.changeDatesValues(from, to)
                  }
                />
              </div>
              <div className="col-md-2">
                <DropdownSelector
                  title="Action type"
                  items={stateTypes}
                  itemId={(el) => el.id}
                  itemRender={(el) => el.value}
                  onChange={(states) =>
                    this.setState({ states }, this.reloadData)
                  }
                  selectedOptions={this.state.states}
                />
              </div>
              <div className="col-md-2">
                <DropdownSelector
                  title="Customer"
                  selectedOptions={this.state.customerIds}
                  items={this.getCustomers()}
                  itemRender={(item) => {
                    return item.name;
                  }}
                  itemId={(item) => {
                    return item.id;
                  }}
                  onChange={(val) =>
                    this.setState({ customerIds: val }, this.reloadData)
                  }
                />
              </div>
              {this.state.fileImport ? (
                <div className="col-md-2">
                  <DropdownSelector
                    title="File import"
                    selectedOptions={this.state.fileImportIds}
                    items={[this.state.fileImport]}
                    itemRender={(item) => {
                      return item.originalFileName;
                    }}
                    itemId={(item) => {
                      return item.id;
                    }}
                    onChange={(val) => {
                      if (val.length === 0) {
                        this.setState(
                          {
                            fileImportIds: null,
                            fileImport: null,
                          },
                          () => this.reloadData()
                        );
                      }
                    }}
                  />
                </div>
              ) : null}
              <div className="col-md-1">
                <a
                  className="refresh-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    this.reloadData();
                  }}
                >
                  <i className="fa fa-refresh" />
                </a>
              </div>
            </div>
          </div>
          <div className="">
            <MegaTable
              hideHeader
              editMode={false}
              selectionMode={this.state.selectionMode}
              onItemCheck={this.checkOrUncheckItem}
              checkAll={this.checkAll}
              columns={columns}
              onRefresh={() => {
                this.reloadData();
              }}
              className={"table-align-top"}
              isWorking={this.state.isWorking}
              summary={
                inboundProcessing.list
                  ? {
                      skip: inboundProcessing.list.skip,
                      take: inboundProcessing.list.take,
                      total: inboundProcessing.list.total,
                      totalPages: inboundProcessing.list.totalPages,
                    }
                  : null
              }
              page={this.state.page}
              pageChanged={(page) => {
                if (page < 1) page = 1;
                this.setState(
                  {
                    page: page,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              pageSizeChanged={(size) => {
                this.setState(
                  {
                    pageSize: size,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              searchChanged={(search) => {
                this.setState(
                  {
                    search: search,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              data={this.state.data}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    inboundProcessing: state.inboundProcessing,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notification: bindActionCreators(notificationActions, dispatch),
    actions: bindActionCreators(inboundProcessingActions, dispatch),
    fileImportActions: bindActionCreators(fileImportActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InboundProcessingTable);
