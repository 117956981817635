import React, { Component } from 'react'
import * as notificationActions from '../../../notifications/actions/notificationActions';
import * as shipmentActions from '../../actions/orderActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormLoader from '../../../../components/common/FormLoader';
import getLabelByValue from '../../../../utils/enumHelper';
import * as triggerExecutionResult from '../../../trigger/constants/triggerExecutionResult';
import * as triggerTypes from '../../../trigger/constants/triggerTypes';
import MicroTable from '../../../../components/common/MicroTable';
import displayDateTime from '../../../../utils/dateTimeHelper';
import TriggerFilePreviewModal from '../../../trigger/components/TriggerFilePreviewModal';
import LogHistory from '../../../../components/common/LogHistory/LogHistory';
import ConfirmationModal from "../../../../components/common/ConfirmationModal";

class TriggerExecutionsTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            orderId: this.props.orderId,
            isLoading: false,
            isWorking: false,
            isFilePreviewModalOpen: false,
            isRepeatPromtOpen: false,
            selectedTriggerExecution: null
        };
    }

    getItems = () => {
        if (this.props.orders && this.props.orders.triggerExecutions && this.props.orders.triggerExecutions.data)
            return this.props.orders.triggerExecutions.data;
        return [];
    };

    componentDidMount() {
        this.reloadData();
    }

    reloadData = () => {
        this.setState({ isLoading: true });

        if (this.state.orderId) {
            this.props.actions.clearTriggerExecutions();
            this
                .props
                .actions
                .listTriggerExecutions({ orderId: this.state.orderId, pageSize: null, page: null })
                .then(() => {
                    this.setState({ isLoading: false })
                })
                .catch(() => {
                    this.setState({ isLoading: false })
                })
        }
    }

    repeatTriggerExecution = (e) => {
        this.setState({ isWorking: true });
        this.props.actions.repeatTriggerExecution(this.state.selectedTriggerExecution.id).then(() => {
            this
                .props
                .notification
                .add("Trigger reprocessed", "", "success")
        }).catch((error) => {
            this
                .props
                .notification
                .add("Trigger reprocess failed", "", "fail")
        }).then(() => {
            this.reloadData();
            this.setState({ isWorking: false, isRepeatPromtOpen: false });
        })
    }

    render() {
        return (
            <div className="relative">
                {this.state.isLoading
                    ? <FormLoader />
                    : null}
                <b>Trigger executions: </b>
                <MicroTable
                    data={this.getItems()}
                    noEdit
                    itemSelected={(item) => {

                    }}
                    columns={[
                        {
                            name: 'Trigger',
                            id: 'triggerName',
                            type: 'custom',
                            renderDisplay: d => <a onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(`/trigger/edit/${d.triggerId}`);
                            }}>{d.triggerName}</a>
                        },
                        {
                            name: 'Trigger type',
                            id: 'triggerType',
                            type: 'custom',
                            renderDisplay: d => getLabelByValue(triggerTypes, d.triggerType)
                        },
                        {
                            name: 'Date Time',
                            id: 'dateTime',
                            type: 'custom',
                            renderDisplay: d => displayDateTime(d.createDate)
                        },
                        {
                            name: 'Status',
                            id: 'status',
                            type: 'custom',
                            renderDisplay: d => <span>
                                {getLabelByValue(triggerExecutionResult, d.executionResult)}&nbsp;
                                {d.executionResult === 0 && <i className="fa fa-check color-green" />}
                                {d.executionResult === 1 && <i className="fa fa-remove color-red" />}
                                {d.isTestRun && <span className="color-red">&nbsp;<b>Test run</b></span>}
                            </span>
                        },
                        {
                            name: 'Ack',
                            id: 'acknowledgementMarker',
                            type: 'text'
                        },
                        {
                            name: 'Error',
                            id: 'error',
                            type: 'text'
                        },
                        {
                            name: 'File',
                            id: 'custom',
                            type: 'custom',
                            renderDisplay: d => <span>
                                {d.fileExportState === 0 && <span><i className="fa fa-clock-o" /> Pending{d.fileExportDelayedInMinutes && `, delayed: ${d.fileExportDelayedInMinutes} mins `}</span>}&nbsp;
                                {d.fileExportState === 1 && <span><i className="fa fa-check color-green" /> Uploaded</span>}&nbsp;
                                {d.fileExportState === 2 && <span><i className="fa fa-remove color-red" /> Upload error</span>}&nbsp;
                                {d.fileExportState === 3 && <span><i className="fa fa-exclamation-circle" /> No transfer</span>}&nbsp;
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ isFilePreviewModalOpen: true, selectedFileExportId: d.fileExportId });
                                }
                                }><b>{d.fileExportName}</b></a>
                                {`${d.resultType === 'Empty' ? 'Empty content' : ''}`}
                            </span>
                        },
                        {
                            name: 'Actions',
                            id: 'Actions',
                            type: 'custom',
                            width: 130,
                            renderDisplay: d => <div>
                                <a className="btn btn-xxs bg-slate" onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push('/trigger/execution/' + d.id + '/logs');
                                }}>Logs</a>
                                &nbsp;
                                <a className="btn btn-xxs bg-slate" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ isRepeatPromtOpen: true, selectedTriggerExecution: d });
                                }}><i className="fa fa-refresh"></i> Repeat</a>
                            </div>
                        }
                    ]}
                    onChange={(data) => {

                    }} />
                <div className="margin-top-15" />
                {this.props.turvoShipmentId && <LogHistory
                    logName="Turvo shipment updates log:"
                    args={{ turvoResourceId: this.props.turvoShipmentId }}
                />}
                <TriggerFilePreviewModal fileExportId={this.state.selectedFileExportId} show={this.state.isFilePreviewModalOpen} onClose={() => {
                    this.setState({ isFilePreviewModalOpen: false });
                }} />
                <ConfirmationModal
                    title={"Reprocess trigger confirmation"}
                    isWorking={this.state.isWorking}
                    description={`Are you sure you want to reprocess ${this.state.selectedTriggerExecution ? this.state.selectedTriggerExecution.triggerName : null} trigger?`}
                    onClose={() => this.setState({ isRepeatPromtOpen: false })}
                    onConfirm={this.repeatTriggerExecution}
                    show={this.state.isRepeatPromtOpen} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { orders: state.order }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(shipmentActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerExecutionsTab);