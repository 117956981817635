import React, {Component} from 'react'

export default class LocationOverwrite extends Component {
    constructor(props) {
        super(props);

        this.state = {
            locationType: 'pickup',
            turvoLocationId: ''
        }
        this.props.onChange(this.state);
    }

    handleChange = (set) => {
        this.setState(set, ()=>{
            this.props.onChange(this.state);
        })
    }

    render() {
        return <div>
            <b>Location type</b>
            <select className='form-control' value={this.state.locationType} onChange={(e) => {
                this.handleChange({
                    locationType: e.target.value
                });
            }}>
                <option value={'pickup'}>Pickup</option>
                <option value={'delivery'}>Delivery</option>
            </select>
            <div className="margin-top-15">
                <b>Turvo location id</b>
                <input value={this.state.turvoLocationId} onChange={(e)=>{
                    this.handleChange({turvoLocationId: e.target.value});
                }} type='text' className='form-control'/>
            </div>
        </div>
    }
}