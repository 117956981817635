import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MegaTable from "../../../components/common/MegaTable";
import * as exportListActions from "../actions/exportListActions";
import statuses from "../constants/fileStatus";
import displayDateTime from "../../../utils/dateTimeHelper";
import TriggerFilePreviewModal from "../../trigger/components/TriggerFilePreviewModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class ExportListTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 10,
      showDeleteConfirm: false,
      selectedItem: {},
      isWorking: false,
      search: "",
      isFilePreviewModalOpen: false,
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  reloadData = (noLoader) => {
    if (!noLoader) {
      this.setState({ isWorking: true });
    }
    this.props.actions
      .list({
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
      })
      .then(() => this.setState({ isWorking: false }))
      .catch(() => this.setState({ isWorking: false }));
  };

  getData = () => {
    if (this.props.exportList && this.props.exportList.list) {
      return this.props.exportList.list.data;
    }
    return [];
  };

  render() {
    let columns = [
      {
        name: "File name",
        id: "fileName",
        type: "custom",
        renderDisplay: (d) => (
          <div>
            <a
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  isFilePreviewModalOpen: true,
                  selectedFileExportId: d.id,
                });
              }}
            >
              {d.fileName}
            </a>
            {d.resultType === "Empty" ? "Empty content" : null}
          </div>
        ),
      },
      {
        name: "Trigger name",
        id: "triggerName",
        type: "custom",
        renderDisplay: (d) =>
          d.triggerObject.id ? (
            <div>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    `/customer/edit/${d.triggerObject.customerId}`
                  );
                }}
              >
                {d.triggerObject.customerName}
              </a>
              <span>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    `/trigger/edit/${d.triggerObject.id}`
                  );
                }}
              >
                {d.triggerObject.name}
              </a>
            </div>
          ) : (
            <span>Processing Trigger</span>
          ),
      },
      {
        name: "Status",
        id: "status",
        type: "custom",
        renderDisplay: (d) => (
          <div>
            {d.exportState === 0 && (
              <span>
                <i className="fa fa-clock-o" />{" "}
              </span>
            )}
            &nbsp;
            {d.exportState === 1 && (
              <span>
                <i className="fa fa-check color-green" />{" "}
              </span>
            )}
            &nbsp;
            {d.exportState === 2 && (
              <span>
                <i className="fa fa-remove color-red" />{" "}
              </span>
            )}
            &nbsp;
            {d.exportState === 3 && (
              <span>
                <i className="fa fa-exclamation-circle" />{" "}
              </span>
            )}
            &nbsp;
            {statuses[d.exportState]}
            {d.exportState === 0 &&
              d.delayedInMinutes &&
              `, delayed:${d.delayedInMinutes} mins`}
          </div>
        ),
      },
      {
        name: "Date created",
        id: "createDate",
        type: "custom",
        renderDisplay: (d) => (
          <div>{displayDateTime(d.createDate)}</div>
        ),
      },
    ];

    let exportList = this.props.exportList || {};

    return (
      <div>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Export List</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="panel panel-flat">
          <MegaTable
            editMode={false}
            columns={columns}
            onRefresh={() => {
              this.reloadData();
            }}
            isWorking={this.state.isWorking}
            summary={
              exportList.list
                ? {
                  skip: exportList.list.skip,
                  take: exportList.list.take,
                  total: exportList.list.total,
                  totalPages: exportList.list.totalPages,
                }
                : null
            }
            page={this.state.page}
            pageChanged={(page) => {
              if (page < 1) page = 1;
              this.setState(
                {
                  page: page,
                },
                () => {
                  this.reloadData();
                }
              );
            }}
            pageSizeChanged={(size) => {
              this.setState(
                {
                  pageSize: size,
                },
                () => {
                  this.reloadData();
                }
              );
            }}
            searchChanged={(search) => {
              this.setState(
                {
                  search: search,
                },
                () => {
                  this.reloadData();
                }
              );
            }}
            data={this.getData()}
          />
        </div>
        <TriggerFilePreviewModal
          fileExportId={this.state.selectedFileExportId}
          show={this.state.isFilePreviewModalOpen}
          onClose={() => {
            this.setState({ isFilePreviewModalOpen: false });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { exportList: state.exportList };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(exportListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportListTable);
