import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import JobsTable from "./components/JobsTable";

export default class Service extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/service" component={JobsTable}/>
            </Switch>
        )
    }
}