import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { MetricsObject } from "../../../../models/Metrics/MetricsObject";

type PieChartWidgetProps = {
  editMode: boolean;
  widget: MetricsObject;
};

type Data = {
  name: string;
  value: unknown;
};

function PieChartWidget(props: PieChartWidgetProps) {
  const generateData = () => {
    const { series } = props.widget.result;
    let data: Data[] = [];

    series.forEach((series) => {
      data.push({
        name: series.key,
        value: series.data,
      });
    });

    return data;
  };

  const legendFormat = (value: string, entry: any) => {
    return (
      <span>
        {value}: {entry.payload.value}
      </span>
    );
  };

  const { series } = props.widget.dataSource;
  let data = generateData();

  return (
    <ResponsiveContainer debounce={300}>
      <PieChart>
        <Pie data={data} dataKey="value" cx="50%" cy="50%">
          {series.map((s) => (
            <Cell key={s.id} fill={s.color} />
          ))}
        </Pie>
        <Tooltip />
        <Legend formatter={legendFormat} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default PieChartWidget;
