import displayDateTime from "../../../utils/dateTimeHelper";
import React from "react";


const getDefaultInboundProcessingColumns = (history, turvoBaseUrl) => {
    return [
        {
            name: 'Created',
            id: 'created',
            type: 'custom',
            renderDisplay: d => <span>
                {displayDateTime(d.createDate)}
                <br />
                {d.status ? d.status.value : null}
            </span>
        },
        {
            name: 'Customer',
            id: 'customer',
            type: 'custom',
            renderDisplay: d => <span>
                <a onClick={(e) => {
                    e.preventDefault();
                    history.push('/customer/edit/' + d.customerId);
                }}>{d.customerImg && <img className="customer-img-small" src={d.customerImg} />} {d.customerName} </a>
            </span>
        },
        {
            name: 'File name',
            id: 'originalFileName',
            type: 'custom',
            renderDisplay: d => <span>
                <a href={"/api/fileImport/downloadArchiveFile?fileImportId=" + d.fileImportId}>{d.originalFileName}</a>
            </span>
        },
    ];
};

export { getDefaultInboundProcessingColumns };