import React, {Component} from 'react'
import {connect} from "react-redux";
import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/snippets/javascript';
import 'brace/ext/language_tools';
import ApiResponseItem from './ApiResponseItem';

class ApiOperationsStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    renderApiResponse = (model) => {
        return (
            <ApiResponseItem settings={this.props.settings} model={model}/>
        );
    };

    renderDetails = (model) =>{
        return (<div>
            {model.ApiResponses ? model.ApiResponses.map(x=>{
                return this.renderApiResponse(x);
            }):null}
        </div>);
    };

    getSystemSettings = () =>{
        if(this.props.settings)
            return this.props.settings.system_settings || {};
        return {};
    };

    render() {
        let { model } = this.props;

        return (
            <div>
                <b>Shipment id: </b> {model.Shipment && <a href={this.getSystemSettings().turvoBaseUrl+'/#/shipments/'+model.Shipment.id}
                                                        target="_blank">{model.Shipment.id}</a> }
                {model.ShipmentState === 7 && <span>Ignored</span>}
                <br/>
                <b>Api requests:</b> <span>{model.ApiResponses ? model.ApiResponses.length : 0}<br/></span>
                {this.state.expanded?this.renderDetails(model):null}
                <a
                    onClick={(e)=>{ e.preventDefault(); this.setState({expanded: !this.state.expanded}); }}>
                    Details
                    &nbsp;
                    {this.state.expanded?<i className="fa fa-arrow-up"/>:<i className="fa fa-arrow-down"/>}
                </a>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiOperationsStage)