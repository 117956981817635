import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from 'connected-react-router'

class CallbackPage extends React.Component {
    render() {
        // just redirect to '/' in both cases
        return (
            <CallbackComponent
                userManager={this.props.userManager}
                successCallback={() => {
                    let url =  '/';
                    //let url = localStorage.getItem('last_initial_url') || '/';

                    this.props.dispatch(push(url));
                }}
                errorCallback={error => {
                    console.error(error);
                }}
            >
                <div>
                    <div className="panel panel-body login-form">
                        <div className="text-center">
                            <h5 className="content-group">Redirecting...
                            </h5>
                            <i className="fa fa-refresh fa-spin fa-2x"></i>
                        </div>
                    </div>
                </div>
            </CallbackComponent>
        );
    }
}

export default connect()(CallbackPage);