import React, {Component} from 'react'
import * as orderStates from '../../constants/orderStates';

export default class StatusChange extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
        this.props.onChange(this.state);
    }

    handleChange = (set) => {
        this.setState(set, ()=>{
            this.props.onChange(this.state);
        })
    }

    render() {
        return <div>
            <b>Status</b>
            <select className='form-control' value={this.state.status || this.props.order.state} onChange={(e) => {
                this.handleChange({
                    status: e.target.value
                });
            }}>
            {Object.entries(orderStates).map((o)=>{
                return <option key={o[1]} value={o[1]}>{o[0]}</option>
            })}
            </select>
        </div>
    }
}