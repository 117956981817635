import * as actionTypes from '../constants/actionTypes';

const initialState = {
    list: {
        data: [],
    },
};

export default(state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RECEIVE_USERS:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.CLEAR_LIST:
            return {
                ...state,
                list: []
            };
        default:
            return state;
    }
};