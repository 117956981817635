import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import AceEditor from 'react-ace';
import ReactJson from 'react-json-view'


const RowSnapshotModal = ({ show, onClose, data }) => {

    let jsonObj = null;

    try {
        jsonObj = JSON.parse(data);
        if(typeof jsonObj !== "object")
            throw '';
    } catch (e) {
        jsonObj = null;
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Row snapshot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {jsonObj ? <ReactJson theme="monokai" src={jsonObj} />: <textarea className="form-control" rows={10}>{data}</textarea>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
)};

export { RowSnapshotModal as default};
