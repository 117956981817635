import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function getAdditionalData() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/trigger/getAdditionalData")
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.TRIGGER_GET_ADDITIONAL_DATA, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function saveTrigger(obj, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/trigger/save", obj)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearTrigger() {
    return (dispatch) => {
        dispatch({type: actionTypes.TRIGGER_CLEAR});
    };
}


export function getTrigger(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/trigger/get/?id=" + id)
                .then((response) => {
                    dispatch({type: actionTypes.TRIGGER_GET, payload: response.data});
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function removeTrigger(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/trigger/delete", {id: id})
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}

export function listTriggers(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/trigger/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.TRIGGER_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearTriggerList() {
    return (dispatch) => {
        dispatch({type: actionTypes.TRIGGER_LIST_CLEAR});
    };
}


export function clearTriggersScripts() {
    return (dispatch) => {
        dispatch({type: actionTypes.TRIGGERS_SCRIPT_CLEAR});
    };
}

export function listTriggersScripts(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/script/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.TRIGGERS_SCRIPT_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function reorderTriggers(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/trigger/reorder", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.TRIGGER_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function testRun(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/trigger/testRun", args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function listLogs(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/log/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.TRIGGERS_EXECUTION_LOGS_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function clearLogs() {
    return (dispatch) => {
        dispatch({type: actionTypes.TRIGGERS_EXECUTION_LOGS_CLEAR});
    };
}

export function listTriggerExecutions(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/triggerExecution/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.TRIGGERS_EXECUTION_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearTriggerExections() {
    return (dispatch) => {
        dispatch({type: actionTypes.TRIGGERS_EXECUTION_LIST_CLEAR});
    };
}


export function enableTrigger(id, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/trigger/enable", {id: id})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function disableTrigger(id, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/trigger/disable", {id: id})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function repeatTriggerExecution(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/triggerExecution/repeat', {id: id})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function batchRepeatTriggerExecution(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/triggerExecution/batchRepeat', args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function runTrigger(obj, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/trigger/run", obj)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}