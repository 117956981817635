import React, {Component} from 'react'
import RowSnapshotModal from './RowSnapshotModal';

export default class ImporterStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            snapshotOpened: false
        };
    }

    render() {
        const importer = this.props.importer || {};

        return (
            <div className={this.props.className}>
                <div>
                    <label className="control-label"><b>Map input data to shipment</b></label>
                    &nbsp;
                    <span className="margin-left-10">{importer.elapsedMappingTime}</span>
                    &nbsp;
                    <a className="margin-left-10"
                       onClick={(e) => {
                           e.preventDefault();
                           this.setState({ snapshotOpened: !this.state.snapshotOpened });
                       }}>
                        First row snapshot
                    </a>
                </div>
                    <RowSnapshotModal
                        onClose={() => this.setState({ snapshotOpened: false })}
                        show={this.state.snapshotOpened}
                        data={importer.documentColumns}
                    />
            </div>
        )
    }
}