import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import useGetCustomers from "../../../hooks/useGetCustomers";

type AppendCustomerModalProps = {
  show: boolean;
  onClose: Function;
  onConfirm: Function;
};

const AppendCustomerModal = (props: AppendCustomerModalProps) => {
  const [selectedCustomer, setSelectedCustomer] = useState(0);

  const { customers } = useGetCustomers({
    page: null,
    pageSize: null,
    sorting: [
      {
        id: "name",
        desc: false,
      },
    ],
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCustomer(parseInt(e.target.value));
  };

  return (
    <Modal show={props.show} onHide={() => props.onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Append customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <select
          value={selectedCustomer}
          onChange={handleChange}
          className="form-control"
        >
          {customers?.data?.map((el) => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="success"
          onClick={() => {
            props.onConfirm(selectedCustomer);
          }}
        >
          <i className="fa fa-check" /> Append
        </Button>
        <Button
          onClick={() => {
            props.onClose();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppendCustomerModal;
