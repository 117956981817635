import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import OrderTable from "./components/OrderTable";
import OrderDetails from "./components/OrderDetails";
import OrderChangeHistoryDetails from './components/OrderChangeHistoryDetails';

export default class Order extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/order" component={OrderTable} />
                <Route exact path="/order/details/:orderId?/:tab?" component={OrderDetails} />
                <Route exact path="/order/history/:changeHistoryId" component={OrderChangeHistoryDetails} />
            </Switch>
        )
    }
}