import {Component} from "react";
import {Route, Switch} from "react-router-dom";
import React from "react";
import ApiAccessKeyTable from "./components/ApiAccessKeyTable";

export default class ApiAccessKey extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/accessApiKey" component={ApiAccessKeyTable}/>
            </Switch>
        )
    }
}