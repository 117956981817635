import React, {Component} from 'react'
import * as shipmentActions from "../actions/shipmentActions";
import * as notificationActions from "../../notifications/actions/notificationActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Tab, Tabs} from "react-bootstrap";
import FormLoader from "../../../components/common/FormLoader";
import VisualTrackingTab from "../../orders/components/tabs/OrderVisualTrackingTab";
import ShipmentVisualTrackingTab from "./tabs/ShipmentVisualTrackingTab";

class ShipmentDetails extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.props.actions.clear();
        if (this.state.shipmentId) {
            this.setState({isLoading: true});
            this
                .props
                .actions
                .get(this.state.shipmentId)
                .then(() => {
                    this.setState({isLoading: false})
                })
                .catch(() => {
                    this.setState({isLoading: false})
                })
        }else {
            this.setState({shipment: {}});
        }
    }

    getInitialState() {
        return {
            shipmentId: this.props.match.params.shipmentId,
            isLoading: false,
            isWorking: false,
            shipments: {},
            shipment: {},
            selectedTab: this.props.match.params.tab
        };
    }

    render() {
        let { shipment } = this.props;

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Shipment details{(shipment && shipment.shipment)?(": "+shipment.shipment.id):null}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat relative">
                    {this.state.isLoading
                        ? <FormLoader/>
                        : null}
                    <div className="panel-body">
                        <Tabs
                            id="OrdersTabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(key) => this.setState({selectedTab: key},() => {
                                this.props.history.push('/shipment/details/'+this.state.shipmentId+'/'+key);
                            })}>
                            <Tab eventKey={"visualTracking"} title="Visual tracking">
                                <ShipmentVisualTrackingTab
                                    shipmentId={this.state.shipmentId}/>
                            </Tab>
                            <Tab eventKey={"history"} title="History">

                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        shipment: state.shipment,
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        actions: bindActionCreators(shipmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetails)