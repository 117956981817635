import * as actionTypes from '../constants/actionTypes';

const initialState = {
  orderChangeHistoryList: [],
  orderChangeHistoryDetails: {}
};

export default(state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ORDER_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.ORDER_LIST_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            };
        case actionTypes.ORDER_LIST_CLEAR:
            return {
                ...state,
                list: null
            };
        case actionTypes.ORDER_GET_VISUAL_TRACKING:
            return {
                ...state,
                visualTrackingItems: action.payload
            };
        case actionTypes.ORDER_CLEAR_VISUAL_TRACKING:
            return {
                ...state,
                visualTrackingItems: null
            };
        case actionTypes.ORDER_GET_DETAILS:
            return {
                ...state,
                order: action.payload
            };
        case actionTypes.ORDER_CLEAR_DETAILS:
            return {
                ...state,
                order: null
            };
        case actionTypes.ORDER_UPDATE_SETTING:
            return {
                ...state,
                order: {
                    ...state.order,
                    settings: {
                        ...state.order.settings,
                        ...action.payload
                    }
                }
            };
        case actionTypes.ORDER_LIST_TRIGGER_EXECUTIONS:
            return {
                ...state,
                triggerExecutions: action.payload
            };
        case actionTypes.ORDER_CLEAR_TRIGGER_EXECUTIONS:
            return {
                ...state,
                triggerExecutions: null
            };
        case actionTypes.ORDER_GET_FLAG_LIST:
            return {
                ...state,
                flags: action.payload
            };
        case actionTypes.ORDER_CLEAR_FLAG_LIST:
            return {
                ...state,
                flags: null
            };
        case actionTypes.RESOURCE_LIST_LOGS:
            return {
                ...state,
                resourceLogs: action.payload
            };
        case actionTypes.RESOURCE_CLEAR_LOGS:
            return {
                ...state,
                resourceLogs: null
            };
        case actionTypes.ORDER_CHANGE_HISTORY_LIST:
            return {
                ...state,
                orderChangeHistoryList: action.payload
            };
        case actionTypes.ORDER_CHANGE_HISTORY_LIST_CLEAR:
            return {
                ...state,
                orderChangeHistoryList: []
            };
        case actionTypes.ORDER_CHANGE_HISTORY_DETAILS:
            return {
                ...state,
                orderChangeHistoryDetails: action.payload
            };
        case actionTypes.ORDER_CHANGE_HISTORY_CLEAR_DETAILS:
            return {
                ...state,
                orderChangeHistoryDetails: {}
            };
        case actionTypes.ORDER_GET_SYNC_LIST:
            return {
                ...state,
                sync: action.payload
            };
        case actionTypes.ORDER_CLEAR_SYNC_LIST:
            return {
                ...state,
                sync: null
            };
        default:
            return state;
    }
};