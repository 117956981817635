import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import * as serviceActions from "../actions/serviceActions";
import { connect } from "react-redux";
import getLabelByValue from "../../../utils/enumHelper";
import * as importerTypes from "../../customer/constants/importerTypes";
import MicroTable from "../../../components/common/MicroTable";
import FormLoader from "../../../components/common/FormLoader";
import displayDateTime from "../../../utils/dateTimeHelper";
import { debounce } from "throttle-debounce";

const logLevelIcons = {
    0: (<i className="fa fa-wrench" />),
    1: (<i className="fa fa-info-circle color-blue" />),
    2: (<i className="fa fa-warning" />),
    3: (<i className="fa fa-times-circle-o color-red" />)
};

class JobLogsModal extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        this.refreshDebounce = debounce(300, false, () => { this.reloadData(); });
    }



    componentWillReceiveProps(nextProps) {
        if (nextProps.show && !this.props.show) {
            this.setState(this.getInitialState(), () => {
                this.props.serviceActions.clearLogs();
                this.reloadData();
            });
        }
    }

    getInitialState = () => {
        return {
            isWorking: false,
            search: ''
        };
    }

    componentDidMount() {
        this.reloadData();
    }


    reloadData = (noLoader) => {
        if (!this.props.jobName)
            return;

        if (!noLoader) {
            this.setState({ isWorking: true });
        }
        this
            .props
            .serviceActions
            .listLogs({ jobName: this.props.jobName, search: this.state.search })
            .then()
            .catch((err) => { console.log(err); }).then(() => {
                this.setState({ isWorking: false }, () => {
                    this.scrollToBottom();
                });
            });
    };

    getData = () => {
        if (this.props.service && this.props.service.logs) {
            return this.props.service.logs;
        }
        return [];
    };

    scrollToBottom = () => {
        this.container.scrollIntoView({ behavior: "smooth" });
    }

    render() {
        return (
            <Modal bsSize="lg" show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.jobName} logs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isWorking && <FormLoader />}
                    <form ref={(el) => { this.container = el; }} className="form-horizontal" style={{ 'maxHeight': '800px', 'overflowY': 'scroll', 'overflowX': 'hidden' }}>
                        <div className="form-group">
                            <div className="col-sm-6">
                                <input
                                    placeholder='Search'
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({ search: e.target.value }, () => {
                                        this.refreshDebounce();
                                    })}
                                    value={this.state.search} />
                            </div>
                        </div>
                        <MicroTable
                            data={this.getData() || []}
                            noEdit
                            columns={[
                                {
                                    name: 'Date',
                                    id: 'dateTime',
                                    type: 'custom',
                                    width: '190',
                                    renderDisplay: d => <span>
                                        {displayDateTime(d.createDate)}
                                    </span>
                                },
                                {
                                    name: 'Message',
                                    id: 'message',
                                    type: 'custom',
                                    renderDisplay: d => <span>
                                        {logLevelIcons[d.logLevel]}
                                        &nbsp;
                                        {d.data}
                                    </span>
                                }
                            ]}
                            onChange={(data) => { }} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="primary"
                        onClick={() => {
                            this.reloadData();
                        }}><i className={"fa fa-refresh"} /> Refresh</Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


function mapStateToProps(state) {
    return { service: state.service }
}

function mapDispatchToProps(dispatch) {
    return {
        serviceActions: bindActionCreators(serviceActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobLogsModal)