import React from "react";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as triggerContextTypes from "../constants/triggerContextTypes";
import * as triggerExecutionResults from "../constants/triggerExecutionResult";

import displayDateTime from "../../../utils/dateTimeHelper";
import getLabelByValue from "../../../utils/enumHelper";
import MegaTable from "../../../components/common/MegaTable";
import { debounce } from "throttle-debounce";
import DropdownSelector from "../../../components/common/DropdownSelector";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import { Helmet } from "react-helmet";
import useGetCustomers from "../../../hooks/useGetCustomers";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import useGetTriggerExecution from "../../../hooks/useGetTriggerExecution";
import useGetCustomerTriggers from "../../../hooks/useGetCustomerTriggers";
import { TriggerObject } from "../../../models/TriggerObject";
import { Button, Checkbox, IconButton, Text } from "@radix-ui/themes";
import { ActivityLogIcon, UpdateIcon } from "@radix-ui/react-icons";
import { TriggerExecutionResultType } from "../../../models/TriggerExecutionResultType";
import { TriggerExecutionRequestObject } from "../../../models/Requests/TriggerExecutionRequestObject";
import { Link } from "react-router-dom";
import { TriggerExecutionObject } from "../../../models/TriggerExecutionObject";
import {
  batchRepeatTriggerExecution,
  repeatTriggerExecution,
} from "../actions/triggerActions";
import TriggerFilePreviewModal from "./TriggerFilePreviewModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const TriggerExecutionsTable = () => {
  const [triggerExecutionResult, setTriggerExecutionResult] =
    React.useState<TriggerExecutionRequestObject>({
      page: 1,
      pageSize: 20,
      search: "",
      customer: null,
      turvoResourceId: "",
      turvoShipmentId: "",
      triggers: [],
      isEmptyFileContent: false,
      isNotEmptyFileContent: false,
      identities: "",
      triggerExecutionResultTypes: [],
    });
  const [isWorking, setIsWorking] = React.useState(false);
  const [isRepeatPromtOpen, setIsRepeatPromtOpen] = React.useState(false);
  const [isBatchRepeatPromtOpen, setIsBatchRepeatPromtOpen] =
    React.useState(false);
  const [selectedTriggerExecution, setSelectedTriggerExecution] =
    React.useState<TriggerExecutionObject | null>(null);
  const dispatch = useAppDispatch();
  const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] =
    React.useState(false);

  const [selectedFileExportId, setSelectedFileExportId] = React.useState<
    string | null
  >(null);

  const { executionList, mutate, isLoading, isValidating } =
    useGetTriggerExecution(triggerExecutionResult);

  const { customers } = useGetCustomers({
    page: null,
    pageSize: null,
    sorting: [
      {
        id: "name",
        desc: false,
      },
    ],
  });

  const { triggers: customerTriggers } = useGetCustomerTriggers({
    page: null,
    pageSize: null,
    customerId: triggerExecutionResult.customer,
    common: false,
  });

  const repeatTriggerExecutionClick = () => {
    setIsWorking(true);
    dispatch(repeatTriggerExecution(selectedTriggerExecution?.id ?? null))
      .then(() => {
        dispatch(notificationActions.add("Trigger reprocessed", "", "success"));
      })
      .catch(() => {
        dispatch(
          notificationActions.add("Trigger reprocess failed", "", "fail"),
        );
      })
      .finally(() => {
        mutate();
        setIsWorking(false);
        setIsRepeatPromtOpen(false);
      });
  };

  const batchRepeatTriggerExecutionClick = () => {
    setIsWorking(true);
    dispatch(
      batchRepeatTriggerExecution({
        page: triggerExecutionResult.page,
        pageSize: triggerExecutionResult.pageSize,
        isWorking: isWorking,
        search: triggerExecutionResult.search,
        customer: triggerExecutionResult.customer,
        turvoResourceId: triggerExecutionResult.turvoResourceId,
        turvoShipmentId: triggerExecutionResult.turvoShipmentId,
        Identities: triggerExecutionResult.identities,
        triggers: triggerExecutionResult.triggers,
        isRepeatPromtOpen: isRepeatPromtOpen,
        isBatchRepeatPromtOpen: isBatchRepeatPromtOpen,
        selectedTriggerExecution: selectedTriggerExecution,
        IsEmptyFileContent: triggerExecutionResult.isEmptyFileContent,
        isNotEmptyFileContent: triggerExecutionResult.isNotEmptyFileContent,
      }),
    )
      .then((response: any) => {
        dispatch(
          notificationActions.add(
            `${response.data} triggers sent to reprocessing`,
            "",
            "success",
          ),
        );
      })
      .catch(() => {
        dispatch(
          notificationActions.add("Triggers reprocessing failed", "", "fail"),
        );
      })
      .finally(() => {
        setIsWorking(false);
        setIsBatchRepeatPromtOpen(false);
      });
  };

  const getSystemSettings = useAppSelector(
    // @ts-ignore
    (state) => state.settings.system_settings,
  );

  let columns = [
    {
      name: "Customer",
      id: "customerName",
      type: "custom",
      renderDisplay: (d: TriggerExecutionObject) => (
        <span>
          <Link to={"/customer/edit/" + d.customerId} target="_blank">
            {d.customerName}
          </Link>
        </span>
      ),
    },
    {
      name: "Trigger",
      id: "triggerName",
      type: "custom",
      renderDisplay: (d: TriggerExecutionObject) => (
        <span>
          <Link to={"/trigger/edit/" + d.triggerId} target="_blank">
            {d.triggerName}
          </Link>
        </span>
      ),
    },
    {
      name: "Turvo shipment",
      id: "turvoShipment",
      type: "custom",
      renderDisplay: (d: TriggerExecutionObject) => (
        <span>
          {getSystemSettings.turvoBaseUrl ? (
            <a
              href={
                getSystemSettings.turvoBaseUrl +
                "/#/shipments/" +
                d.turvoShipmentId
              }
              target="_blank"
            >
              {d.turvoShipmentId}
            </a>
          ) : (
            <span>{d.turvoShipmentId}</span>
          )}
        </span>
      ),
    },
    {
      name: "Turvo context",
      id: "turvoResourceId",
      type: "custom",
      renderDisplay: (d: TriggerExecutionObject) => (
        <span>
          {getLabelByValue(triggerContextTypes, d.contextType)}:{" "}
          {d.turvoResourceId}
        </span>
      ),
    },
    {
      name: "Date",
      id: "dateTime",
      type: "custom",
      renderDisplay: (d: TriggerExecutionObject) => (
        <span>{displayDateTime(d.createDate)}</span>
      ),
    },
    {
      name: "File",
      id: "custom",
      type: "custom",
      renderDisplay: (d: TriggerExecutionObject) => (
        <div className="flex flex-col">
          <div className="flex items-center">
            {d.fileExportState === 0 && (
              <>
                <FontAwesomeIcon
                  icon={icon({ name: "clock" })}
                  className="mr-2"
                />
                <Text>
                  Pending
                  {d.fileExportDelayedInMinutes &&
                    `, delayed: ${d.fileExportDelayedInMinutes} mins `}
                </Text>
              </>
            )}
            {d.fileExportState === 1 && (
              <>
                <FontAwesomeIcon
                  icon={icon({ name: "check" })}
                  className="mr-2 text-green-500"
                />
                <Text>Uploaded</Text>
              </>
            )}
            {d.fileExportState === 2 && (
              <>
                <FontAwesomeIcon
                  icon={icon({ name: "remove" })}
                  className="mr-2 text-red-500"
                />
                <Text>Upload Error</Text>
              </>
            )}
            {d.fileExportState === 3 && (
              <>
                <FontAwesomeIcon
                  icon={icon({ name: "exclamation-circle" })}
                  className="mr-2 text-amber-500"
                />
                <Text>No Transfer</Text>
              </>
            )}
          </div>
          <div>
            {d.fileExportName ? (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setIsFilePreviewModalOpen(true);
                  setSelectedFileExportId(d.fileExportId);
                }}
                variant="ghost"
              >
                <b>{d.fileExportName ?? "Empty File Name"}</b>
              </Button>
            ) : null}
            {`${d.resultType === "Empty" ? "Empty content" : ""}`}
          </div>
        </div>
      ),
    },
    {
      name: "Error",
      id: "error",
      type: "text",
    },
    {
      name: "Ack",
      id: "acknowledgementMarker",
      type: "text",
    },
    {
      name: "Status",
      id: "status",
      width: "200",
      type: "custom",
      renderDisplay: (d: TriggerExecutionObject) => (
        <span>
          <span>
            {d.executionResult === 0 && (
              <>
                <FontAwesomeIcon
                  icon={icon({ name: "check" })}
                  className="text-green-500 mr-2"
                />
                Success
              </>
            )}
            {d.executionResult === 1 && (
              <>
                <FontAwesomeIcon
                  icon={icon({ name: "remove" })}
                  className="text-red-500 mr-2"
                />
                Failed
              </>
            )}
          </span>
          {(d.isTestRun || d.isReprocessing || d.reprocessingRequested) && (
            <br />
          )}
          {d.isTestRun && (
            <span>
              {" "}
              Is test run:&nbsp; <i className="fa fa-check color-green" />
              &nbsp;True <br />{" "}
            </span>
          )}
          {d.isReprocessing && (
            <span>
              {" "}
              Is reprocessing:&nbsp; <i className="fa fa-check color-green" />
              &nbsp;True
            </span>
          )}
          {d.reprocessingRequested && (
            <span>
              {" "}
              <i className="fa fa-refresh" />
              &nbsp; Reprocess requested{" "}
            </span>
          )}
          {d.elapsedTime && <span>&nbsp;{d.elapsedTime}ms</span>}
          {d.attempt && d.attempt > 0 && (
            <span>
              <br />
              &nbsp;Attempt: {d.attempt}
            </span>
          )}
        </span>
      ),
    },
    {
      name: "Actions",
      id: "Actions",
      type: "custom",
      width: "160",
      renderDisplay: (d: TriggerExecutionObject) => (
        <div className="flex gap-2">
          <Link to={"/trigger/execution/" + d.id + "/logs"} target="_blank">
            <Button variant="soft" color="gray">
              <ActivityLogIcon width={16} height={16} />
              Logs
            </Button>
          </Link>
          <Button
            onClick={() => {
              setIsRepeatPromtOpen(true);
              setSelectedTriggerExecution(d);
            }}
            variant="outline"
            color="indigo"
          >
            <UpdateIcon width={16} height={16} />
            Retrigger
          </Button>
        </div>
      ),
    },
  ];

  const turvoResourceIdDebounce = debounce(500, false, (search: string) => {
    setTriggerExecutionResult({
      ...triggerExecutionResult,
      turvoResourceId: search,
      page: 1,
    });
  });

  const turvoShipmentIdDebounce = debounce(500, false, (search: string) => {
    setTriggerExecutionResult({
      ...triggerExecutionResult,
      turvoShipmentId: search,
      page: 1,
    });
  });

  return (
    <div>
      <Helmet>
        <title>SL3 - Trigger executions</title>
      </Helmet>
      <div className="page-header page-header-default">
        <div className="page-header-content">
          <div className="page-title">
            <h4>
              <span className="text-semibold">Trigger Executions</span>
            </h4>
          </div>

          <div className="heading-elements">
            <div className="heading-btn-group">
              <Button
                onClick={() => {
                  setIsBatchRepeatPromtOpen(true);
                }}
                className="btn bg-slate has-text"
              >
                <FontAwesomeIcon
                  icon={icon({ name: "refresh" })}
                  className="mr-2"
                />
                <span>Batch repeat</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-flat">
        <div className="panel-body">
          <div className="flex gap-8 items-center mb-4">
            <div className="basis-1/4">
              <select
                value={triggerExecutionResult.customer ?? ""}
                onChange={(e) =>
                  setTriggerExecutionResult({
                    ...triggerExecutionResult,
                    customer: e.target.value ? parseInt(e.target.value) : null,
                    page: 1,
                  })
                }
                className="form-control"
              >
                <option value={""}>None</option>
                {customers?.data?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="basis-1/4">
              <DropdownSelector
                title="Triggers"
                items={customerTriggers?.data ?? []}
                itemId={(el: TriggerObject) => el.id}
                itemRender={(el: TriggerObject) => el.name}
                onChange={(triggers: number[]) => {
                  setTriggerExecutionResult({
                    ...triggerExecutionResult,
                    triggers: triggers,
                    page: 1,
                  });
                  mutate();
                }}
                selectedOptions={triggerExecutionResult.triggers}
              />
            </div>{" "}
            <div className="basis-1/4">
              <DropdownSelector
                title="Status"
                items={Object.entries(triggerExecutionResults)}
                itemId={(el: any) => el[1]}
                itemRender={(el: any) => el[0]}
                onChange={(statuses: TriggerExecutionResultType[]) => {
                  setTriggerExecutionResult({
                    ...triggerExecutionResult,
                    triggerExecutionResultTypes: statuses,
                    page: 1,
                  });
                  mutate();
                }}
                selectedOptions={
                  triggerExecutionResult.triggerExecutionResultTypes
                }
              />
            </div>
            <div className="basis-1/4">
              <IconButton
                variant="ghost"
                onClick={() => {
                  mutate();
                }}
              >
                <UpdateIcon />
              </IconButton>
            </div>
          </div>

          <div className="flex gap-8 items-center mb-4">
            <div className="basis-1/4">
              <input
                defaultValue={triggerExecutionResult.turvoResourceId}
                onChange={(e) => {
                  turvoResourceIdDebounce(e.target.value);
                }}
                className="form-control"
                placeholder="Turvo resource id"
              />
            </div>
            <div className="basis-1/4">
              <input
                value={triggerExecutionResult.turvoShipmentId}
                onChange={(e) => {
                  turvoShipmentIdDebounce(e.target.value);
                }}
                className="form-control"
                placeholder="Turvo shipment id"
              />
            </div>
            <div className="basis-1/4">
              <Text as="label" size="3">
                <Checkbox
                  className="mr-2"
                  title="Show empty content"
                  defaultChecked={triggerExecutionResult.isEmptyFileContent}
                  value={1}
                  size="2"
                  onClick={(e) => {
                    setTriggerExecutionResult({
                      ...triggerExecutionResult,
                      isEmptyFileContent:
                        e.currentTarget.dataset.state === "unchecked"
                          ? true
                          : false,
                      page: 1,
                    });
                  }}
                />
                Empty content
              </Text>
            </div>
            <div className="basis-1/4">
              <Text as="label" size="3">
                <Checkbox
                  className="mr-2"
                  defaultChecked={triggerExecutionResult.isNotEmptyFileContent}
                  onClick={(e) => {
                    setTriggerExecutionResult({
                      ...triggerExecutionResult,
                      isNotEmptyFileContent:
                        e.currentTarget.dataset.state === "unchecked"
                          ? true
                          : false,
                      page: 1,
                    });
                  }}
                />
                Not empty content
              </Text>
            </div>
          </div>
        </div>

        <div>
          {executionList ? (
            <MegaTable
              hideHeader
              editMode={false}
              columns={columns}
              onRefresh={() => {
                mutate();
              }}
              isWorking={isLoading || isValidating}
              summary={{
                skip: executionList.skip,
                take: executionList.take,
                total: executionList.total,
                totalPages: executionList.totalPages,
              }}
              page={triggerExecutionResult.page}
              pageChanged={(page: number) => {
                if (page < 1) page = 1;
                setTriggerExecutionResult({
                  ...triggerExecutionResult,
                  page: page,
                });
              }}
              pageSizeChanged={(size: number) => {
                setTriggerExecutionResult({
                  ...triggerExecutionResult,
                  pageSize: size,
                });
              }}
              searchChanged={(search: string) => {
                setTriggerExecutionResult({
                  ...triggerExecutionResult,
                  search: search,
                  page: 1,
                });
              }}
              data={executionList.data}
            />
          ) : null}
        </div>
      </div>

      <TriggerFilePreviewModal
        fileExportId={selectedFileExportId}
        show={isFilePreviewModalOpen}
        onClose={() => {
          setIsFilePreviewModalOpen(false);
        }}
      />

      <ConfirmationModal
        title={"Reprocess trigger confirmation"}
        isWorking={isWorking}
        description={`Are you sure you want to reprocess ${
          selectedTriggerExecution ? selectedTriggerExecution.triggerName : null
        } trigger?`}
        onClose={() => setIsRepeatPromtOpen(false)}
        onConfirm={repeatTriggerExecutionClick}
        show={isRepeatPromtOpen}
      />

      <ConfirmationModal
        title={"Bath trigger reprocessing confirmation"}
        isWorking={isWorking}
        description={`Are you sure you want to reprocess ALL ${
          executionList ? executionList.total : null
        } triggers?`}
        onClose={() => setIsBatchRepeatPromtOpen(false)}
        onConfirm={batchRepeatTriggerExecutionClick}
        show={isBatchRepeatPromtOpen}
      />
    </div>
  );
};
export default TriggerExecutionsTable;
