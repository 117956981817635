import React from "react";
import update from "react-addons-update";
import {SplitButton, MenuItem, Modal, Button} from "react-bootstrap";

export default class MicroTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
        this.buttonStyle = {
            marginRight: '10px'
        }
    }

    getInitialState() {
        return {
            newRow: this.getNewRow(),
            data: this.props.data || [],
            exportFormat: 'CSV',
            showModal: false,
            selectedItem: null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({data: nextProps.data});
    }

    clearNewRow = () => {
        this.setState({
            newRow: this.getNewRow()
        });
    };

    getNewRow = () => {
        var newRow = {};
        this
            .props
            .columns
            .forEach((x) => {
                newRow[x.id] = "";
            });
        return newRow;
    };

    handleRowClick = (e, item) => {
        this.setState({selectedItem: item});
        if(this.props.itemSelected)
            this.props.itemSelected(item);
    }

    renderEditColumn = (col, item) => {
        var colId = col.id;
        const tdStyle = {};
        if (col.width) 
            tdStyle.width = col.width + 'px';
        if (col.type === 'number') {
            return <td style={tdStyle} key={col.id}>
                <input
                    type="number"
                    pattern="[0-9]+"
                    step="1"
                    value={item[colId]}
                    onChange={(e) => {
                    item[colId] = e.target.value;
                    this.forceUpdate();
                }}
                    className="form-control"/>
            </td>;
        } else if (col.type === 'float') {
            return <td style={tdStyle} key={col.id}>
                <input
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    step="0.01"
                    value={item[colId]}
                    onChange={(e) => {
                    item[colId] = e.target.value;
                    this.forceUpdate();
                }}
                    className="form-control"/>
            </td>;
        } else if (col.type === 'custom') {
            return <td style={tdStyle} key={col.id}>
                {col.renderEdit
                    ? col.renderEdit(item)
                    : ""}
            </td>;
        } else {
            return <td style={tdStyle} key={col.id}>
                <input
                    type='text'
                    value={item[colId]}
                    onChange={(e) => {
                    item[colId] = e.target.value;
                    this.forceUpdate();
                }}
                    className="form-control"/>
            </td>;
        }
    };

    renderColumn = (col, item) => {
        if (item.isEditMode) {
            return this.renderEditColumn(col, item);
        } else {
            const tdStyle = {};
            if (col.width) 
                tdStyle.width = col.width + 'px';
            if (col.type === 'custom') {
                return <td style={tdStyle} key={col.id}>{col.renderDisplay
                        ? col.renderDisplay(item)
                        : ""}</td>;
            } else {
                return <td style={tdStyle} key={col.id}>{item[col.id]}</td>;
            }

        }
    };

    renderRow = (item, i) => {
        const actionTdStyle = {
            width: '60px'
        };
        const actionLinkStyle = {
            marginLeft: '10px'
        };

        return <tr
                onClick={(e)=> {this.handleRowClick(e, item)}}
                className={this.props.selectable? (this.state.selectedItem == item?"micro-tr-selectable-selected":"micro-tr-selectable"):""}
                key={item.id || i+"_"}>
            {this
                .props
                .columns
                .map((col) => {
                    return this.renderColumn(col, item);
                })}
            {!this.props.noEdit? item.isEditMode
                ? <td style={actionTdStyle}>
                    <a
                        onClick={(e) => {
                            item.isEditMode = false;
                            this.forceUpdate();
                            this
                                .props
                                .onChange(this.state.data)
                        }}>
                        <i className="fa fa-check"></i>
                    </a>
                </td>
                : <td style={actionTdStyle}>
                    <a
                        onClick={(e) => {
                            item.isEditMode = true;
                            this.forceUpdate();
                        }}>
                        <i className="fa fa-pencil-square-o"></i>
                    </a>
                    <a
                        onClick={(e) => {
                            this.handleRemove(item);
                        }}
                        style={actionLinkStyle}>
                        <i className="fa fa-times"></i>
                    </a>
                </td>:null}

        </tr>;
    };

    handleAdd = (e) => {
        this.setState({
            data: [
                ...this.state.data,
                this.state.newRow
            ]
        }, () => {
            this.clearNewRow();
            this
                .props
                .onChange(this.state.data);
        });
        e.stopPropagation();
        e.preventDefault();
        return false;
    };

    handleRemove = (item) => {
        var index = this
            .state
            .data
            .indexOf(item);
        this.setState({
            data: update(this.state.data, {
                $splice: [
                    [index, 1]
                ]
            })
        }, () => {
            this
                .props
                .onChange(this.state.data)
        });
    };

    handleExport = (format) => {
        this.setState({exportFormat: format})
    };

    close = () => {
        this.setState({showModal: false});
    };

    open = () => {
        this.setState({showModal: true});
    };

    render()
    {
        const {columns} = this.props;

        let lastColumnStyle = {
            width: '60px'
        }

        return (
            <div>
                <table className={"table microtable "+(this.props.className && this.props.className)}>
                    <thead>
                        <tr>
                            {columns.map((col) => {
                                const thStyle = {};
                                if (col.width)
                                    thStyle.width = col.width + 'px';
                                return <th style={thStyle} nowrap="true" key={col.id}>{col.name}</th>;
                            })}
                            {!this.props.noEdit && <th />}
                        </tr>
                    </thead>
                    <tbody>
                        {this
                            .state
                            .data
                            .map((item, i) => {
                                return this.renderRow(item, i)
                            })}

                        {!this.props.noEdit? <tr className="editRow">
                            {columns.map((col) => {
                                return this.renderEditColumn(col, this.state.newRow);
                            })}
                            <td style={lastColumnStyle}>
                                <button
                                    type="submit"
                                    onClick={(e) => this.handleAdd(e)}
                                    className="btn bg-blue btn-xs pull-right">
                                    <i className="fa fa-plus"/>
                                    Add</button>
                            </td>
                        </tr>:null}

                    </tbody>
                </table>

                {this.props.downloadToFile
                    ? <span>
                            <SplitButton
                                id="ExportDropdown"
                                onClick={() => this.props.downloadToFile(this.state.exportFormat)}
                                bsStyle={'success'}
                                title={"Export data " + this.state.exportFormat}>
                                <MenuItem onClick={() => this.handleExport("CSV")} eventKey="1">CSV</MenuItem>
                                <MenuItem onClick={() => this.handleExport("XLSX")} eventKey="2">XLSX</MenuItem>
                                <MenuItem onClick={() => this.handleExport("PDF")} eventKey="3">PDF</MenuItem>
                            </SplitButton>
                        </span>
                    : null
}
                {this.props.uploadFromFile
                    ? <button
                            type="submit"
                            onClick={this.open}
                            className="btn btn-success col-sm-1"
                            style={this.buttonStyle}>Import data</button>
                    : null
}
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload file</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Upload file
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}