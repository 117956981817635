import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import * as notificationActions from "../../../notifications/actions/notificationActions";
import * as orderActions from "../../actions/orderActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormLoader from "../../../../components/common/FormLoader";
import FileImportLine from "./visualTracking/FileImportLine";
import getLabelByValue from "../../../../utils/enumHelper";
import * as triggerExecutionResult from "../../../trigger/constants/triggerExecutionResult";
import * as triggerTypes from "../../../trigger/constants/triggerTypes";
import MicroTable from "../../../../components/common/MicroTable";
import displayDateTime from "../../../../utils/dateTimeHelper";
import TriggerFilePreviewModal from "../../../trigger/components/TriggerFilePreviewModal";
import DeleteConfirmationModal from "../../../../components/common/DeleteConfirmationModal";

class FlagsTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            orderId: this.props.orderId,
            isLoading: false,
            isWorking: false,
            showDeleteConfirm: false,
            selectedItem: {},
        };
    }

    componentDidMount() {
        this.reloadItems();
    }

    reloadItems = () => {
        this.setState({ isLoading: true });
        this.props.actions.clearFlags();
        this
            .props
            .actions
            .listFlags({ orderId: this.state.orderId })
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(() => {
                this.setState({ isLoading: false })
            })
    }

    getItems = () => {
        if (this.props.orders && this.props.orders.flags && this.props.orders.flags.data)
            return this.props.orders.flags.data;
        return [];
    }

    render() {
        return (
            <div className="relative">
                {this.state.isLoading
                    ? <FormLoader />
                    : null}
                <MicroTable
                    data={this.getItems()}
                    noEdit
                    itemSelected={(item) => {

                    }}
                    columns={[
                        {
                            name: 'Key',
                            id: 'key',
                            type: 'text'
                        },
                        {
                            name: 'Metadata',
                            id: 'metadata',
                            type: 'text'
                        },
                        {
                            name: 'Trigger',
                            id: 'triggerName',
                            type: 'custom',
                            renderDisplay: d => <a onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/trigger/edit/' + d.triggerId);
                            }}>{d.triggerName}</a>
                        },
                        {
                            name: 'Date Time',
                            id: 'dateTime',
                            type: 'custom',
                            renderDisplay: d => displayDateTime(d.createDate)
                        },

                        {
                            name: 'Actions',
                            id: 'Actions',
                            type: 'custom',
                            width: 100,
                            renderDisplay: d => <div>
                                <a className="btn btn-xxs bg-danger" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showDeleteConfirm: true, selectedItem: d });
                                }}>Delete</a>
                            </div>
                        }
                    ]}
                    onChange={(data) => {

                    }} />
                <DeleteConfirmationModal
                    title={"Delete flag confirmation"}
                    description={"Are you sure you want to delete " + this.state.selectedItem.key + "?"}
                    onClose={() => this.setState({ showDeleteConfirm: false })}
                    onConfirm={() => {
                        this
                            .props
                            .actions
                            .removeFlag(this.state.selectedItem.id, () => {
                                this.reloadItems(this.props.turvoShipmentId);
                                this.setState({ showDeleteConfirm: false });
                            })
                    }}
                    show={this.state.showDeleteConfirm} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { orders: state.order }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(orderActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlagsTab)