import useSWR from "swr";
import fetcherPost from "../fetchers/fetcherPost";
import { ResponseFetch } from "../models/ResponseFetch";
import { TriggerRequestObject } from "../models/Requests/TriggerRequestObject";
import { TriggerObject } from "../models/TriggerObject";

function useGetCustomerTriggers(args: TriggerRequestObject) {
  const { data, error, isLoading, mutate } = useSWR<
    ResponseFetch<TriggerObject[]>
  >(
    () => (args.customerId ? ["/api/trigger/list", args] : null),
    ([url, args]) => fetcherPost(url, args),
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    triggers: data,
    error,
    isLoading,
    mutate,
  };
}

export default useGetCustomerTriggers;
