import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function saveImporter(obj, callback) {
  const newObject = { ...obj };
  delete newObject.documentColumns;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/importer/save", newObject)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function clearImporter() {
  return (dispatch) => {
    dispatch({ type: actionTypes.IMPORTER_CLEAR });
  };
}

export function getImporter(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .get("/api/importer/get/?id=" + id)
        .then((response) => {
          resolve(response);
          dispatch({ type: actionTypes.IMPORTER_GET, payload: response.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function removeImporter(id, callback) {
  return (dispatch) => {
    httpHandler
      .post("/api/importer/delete", { id: id })
      .then((response) => {
        callback();
      })
      .catch((error) => {
        callback();
      });
  };
}

export function listImporters(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/importer/list", args)
        .then((response) => {
          resolve(response);
          dispatch({ type: actionTypes.IMPORTER_LIST, payload: response.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function clearImporterScripts() {
  return (dispatch) => {
    dispatch({ type: actionTypes.IMPORTER_SCRIPT_CLEAR });
  };
}

export function listImporterScripts(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/script/list", args)
        .then((response) => {
          resolve(response);
          dispatch({
            type: actionTypes.IMPORTER_SCRIPT_LIST,
            payload: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function saveImporterScripts(obj, callback) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/script/save", obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function removeImporterScript(id, callback) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/script/delete", { id: id })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function testRunImporter(args) {
  var formData = new FormData();
  formData.append("importerId", args.importerId);
  formData.append("uploadFile", args.uploadFile);
  formData.append("fileImportId", args.fileImportId);

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/importer/testRun", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function getLastFileImports(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/fileImport/list", args)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
