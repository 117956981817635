import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import ShipmentTable from "./components/ShipmentTable";
import ShipmentDetails from "./components/ShipmentDetails";

export default class Shipment extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/shipment" component={ShipmentTable} />
                <Route exact path="/shipment/details/:shipmentId?/:tab?" component={ShipmentDetails} />
            </Switch>
        )
    }
}