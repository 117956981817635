import React, {Component} from 'react'
import {Button, Modal} from "react-bootstrap";
import LocationOverwrite from "./managerActions/LocationOverwrite";
import {bindActionCreators} from "redux";
import * as orderActions from "../actions/orderActions";
import {connect} from "react-redux";
import FormLoader from "../../../components/common/FormLoader";
import * as notificationActions from "../../notifications/actions/notificationActions";
import StatusChange from "./managerActions/StatusChange";

class OrderActionsManager extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.managerAction = {
            0: {
                title: 'Location overwrite',
                render: (onChange, props) => <LocationOverwrite onChange={onChange} {...props}/>
            },
            1: {
                title: 'Change status',
                render: (onChange, props) => <StatusChange onChange={onChange} {...props}/>
            }
        }
    }

    handleClose = () => {
         this.props.onClose();
    }

    handleSave = (e) =>{
        e.preventDefault();
        let request = {
            actionType: this.props.action,
            orderId: this.props.orderId,
            data: this.state.model
        };
        this.setState({isWorking: true});
        this.props.actions.managementAction(request).then(()=>{
            this
                .props
                .notification
                .add("Saved", "", "success");
            this.handleClose();
            this.props.onSave();
        }).catch((error)=>{
            console.log(error);
            this
                .props
                .notification
                .add("Failed", "", "fail");
        }).then(()=>{
            this.setState({isWorking: false});
        });
    }

    handleActionModelChange = (set) => {
        this.setState({
            model: set
        });
    }

    renderManagerAction = (managerAction) => {
        if(!managerAction)
            return null;

        return (
            <Modal disabled={true} show={this.props.show}  onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{managerAction.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isWorking ? <FormLoader/>: null}
                    {managerAction.render(this.handleActionModelChange, {order: this.props.order})}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleClose}>Close</Button>
                    <button
                        disabled={this.state.isWorking}
                        onClick={this.handleSave}
                        className="btn bg-blue has-text">
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : <i className="fa fa-check"></i>}
                        <span>Save</span>
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        const managerAction = this.managerAction[this.props.action];
        return this.renderManagerAction(managerAction);
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        actions: bindActionCreators(orderActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderActionsManager)