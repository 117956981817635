import "@radix-ui/themes/styles.css";
import "./scss/index.css";
import "./scss/App.scss";
import 'react-datetime/css/react-datetime.css';

import { Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Notification from "./components/common/Notification";
import ImportModal from "./pages/import";
import MainRoute from "./components/MainRoute";
import Callback from "./components/oidc/CallbackPage";
import AuthorizeContainer from "./components/AuthorizeContainer";
import VersionChecker from "./components/VersionChecker";
import { Helmet } from "react-helmet";
import userManager from "./utils/userManager";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://96a4855548fd5b8a13c82e15cf52eaf5@o4506655082020864.ingest.sentry.io/4506671894495232",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  const showImportModel = useSelector((state) => state.import.showImportModal);
  return (
    <>
      <div className="App">
        <Helmet titleTemplate={`${import.meta.env.VITE_APP_ENV ?? ""} %s`}>
          <link
            rel="icon"
            type="image/svg+xml"
            href={
              import.meta.env.VITE_APP_ENV
                ? `/${import.meta.env.VITE_APP_ENV}.svg`
                : "/production.svg"
            }
          />
        </Helmet>
        <Navbar history={history} userManager={userManager} />
        <div className="page-container">
          <div className="page-content">
            <div className="content">
              <Switch>
                <Route
                  path="/oidc-callback"
                  render={() => <Callback userManager={userManager} />}
                />
                <MainRoute />
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
        <Notification />
        <AuthorizeContainer>
          {showImportModel ? <ImportModal /> : null}
        </AuthorizeContainer>
      </div>
      <VersionChecker />
    </>
  );
};

export default App;
