import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import FileImportTable from "./components/FileImportTable";

export default class FileImport extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/fileimport" component={FileImportTable}/>
            </Switch>
        )
    }
}