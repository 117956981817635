import * as actionTypes from '../constants/actionTypes';

export default(state = {
    widgetData: {},
    controls: [],
    ordersStats: {}
}, action) => {
    switch (action.type) {
        case actionTypes.GET_METRICS:
            return {
                ...state,
                widgetData: {
                    ...state.widgetData,
                    [action.key]: action.payload
                }
            };
        case actionTypes.CLEAR_METRICS:
            return {
                ...state,
                widgetData: { }
            };
        case actionTypes.GET_DASHBOARD_SETTINGS:
            return {
                ...state,
                controls: action.payload || []
            };
        case actionTypes.LIST_WIDGET:
            return {
                ...state,
                widgets: action.payload || []
            };
        case actionTypes.GET_METRICS_SETTINGS:
            return {
                ...state,
                settings: action.payload || []
            };
        default:
            return state;
    }
};