import useSWR from "swr";
import fetcherPost from "../fetchers/fetcherPost";
import { ResponseFetch } from "../models/ResponseFetch";
import ImporterRequestObject from "../models/Requests/ImporterRequestObject";
import { ImporterObject } from "../models/ImporterObject";

function useGetCustomerImporters(args: ImporterRequestObject) {
  const { data, error, isLoading, mutate, isValidating } = useSWR<
    ResponseFetch<ImporterObject[]>
  >(
    () => (args.customerId ? ["/api/importer/list", args] : null),
    ([url, args]) => fetcherPost(url, args),
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  );

  return {
    importList: args.customerId ? data : null,
    error,
    customerImportIsLoading: isLoading,
    mutate,
    isValidating,
  };
}

export default useGetCustomerImporters;
