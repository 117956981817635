import React, {Component} from 'react'

export default class ErrorLabel extends Component {
    render() {
        return (
            <div>
                {this.props.error
                    ? <div id="basic-error" className="validation-error-label">{this.props.error}</div>
                    : null}
            </div>
        )
    }
}
