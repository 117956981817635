import React, {Component} from 'react'
import MicroTable from "../../../../components/common/MicroTable";
import getLabelByValue from "../../../../utils/enumHelper";
import DeleteConfirmationModal from "../../../../components/common/DeleteConfirmationModal";
import * as notificationActions from "../../../notifications/actions/notificationActions";
import { bindActionCreators } from "redux";
import * as customerActions from "../../actions/customerActions";
import * as transferActions from '../../../transfer/actions/transferActions';
import * as transferTypes from '../../../transfer/constants/transferTypes';
import {connect} from "react-redux";

class CustomerTransfersTab extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            showImporterDeleteConfirm: false,
            selectedItem: {},
            isLoading: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        let prevCustomerId = (this.state.customer ? this.state.customer.id : null);

        this.setState({
            customer: nextProps.customer
        }, () => {
            if(nextProps.customer && nextProps.customer.id !== prevCustomerId)
                this.reloadData();
        });
    }

    getData = () => {
        if (this.props.transfers && this.props.transfers.list && this.props.transfers.list.data){
            return this.props.transfers.list.data;
        }

        return [];
    };

    reloadData = () => {
        this.props.transferActions.clearTransferList();
        if (this.state.customer && this.state.customer.id) {
            this.setState({isLoading: true});
            this
                .props
                .transferActions
                .listTransfers({ customerId: this.state.customer.id })
                .then(() => {
                    this.setState({isLoading: false})
                })
                .catch(() => {
                    this.setState({isLoading: false})
                })
        }
    };

    render() {
        let addButtonStyle = {
            marginTop:"-10px",
            marginRight: "10px"
        };

        return (
            <div>
                <div className="form-group row">
                    <b className="table-top-heading">Transfers</b>
                    <a className="btn btn-xs bg-blue has-text pull-right" style={addButtonStyle}
                       onClick={(e)=> {
                           e.preventDefault();
                           this.props.history.push(`/transfer/edit/?customerId=${this.props.customer.id}`);
                       }}>
                        <i className="fa fa-plus" />
                        <span>Add</span>
                    </a>
                    <div className="col-sm-12">
                        <MicroTable
                            data={this.getData()}
                            noEdit
                            columns={[
                                { name: 'Name', id:'name', type:'text' },
                                {
                                    name: 'Transfer type',
                                    id: 'transferType',
                                    type: 'custom',
                                    renderDisplay: d => <span>{getLabelByValue(transferTypes, d.transferType)}</span>
                                },
                                {
                                    name: 'Actions',
                                    id: 'actions',
                                    type: 'custom',
                                    width: '100',
                                    renderDisplay: d => <div>
                                        <a className="btn btn-xxs btn-primary" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(`/transfer/edit/${d.id}`);
                                        }}>Edit</a>
                                        &nbsp;
                                        <a className="btn btn-xxs btn-danger"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               this.setState({showImporterDeleteConfirm: true, selectedItem: d});
                                           }}>Delete</a>
                                    </div>
                                }
                            ]}
                            onChange={(data) => {}} />
                    </div>
                </div>
                <DeleteConfirmationModal
                    title={"Delete transfer confirmation"}
                    description={"Are you sure you want to delete " + this.state.selectedItem.name + "?"}
                    onClose={() => this.setState({ showDeleteConfirm: false })}
                    onConfirm={() => {
                        this
                            .props
                            .transferActions
                            .removeTransfer(this.state.selectedItem.id, () => {
                                this.reloadData();
                                this.setState({showImporterDeleteConfirm: false});
                            })
                    }}
                    show={this.state.showImporterDeleteConfirm}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        transfers: state.transfer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
        transferActions: bindActionCreators(transferActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTransfersTab);
