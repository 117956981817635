import useSWR from "swr";
import fetcherPost from "../fetchers/fetcherPost";
import { ResponseFetch } from "../models/ResponseFetch";
import { DictionaryFolderObject } from "../models/DictionaryFolderObject";
import { TriggerExecutionRequestObject } from "../models/Requests/TriggerExecutionRequestObject";

function useGetTriggerExecution(args: TriggerExecutionRequestObject) {
  const { data, error, isLoading, mutate, isValidating } = useSWR<
    ResponseFetch<DictionaryFolderObject[]>
  >(
    ["/api/triggerexecution/list", args],
    ([url, args]) => fetcherPost(url, args),
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    }
  );

  console.log("useGetTriggerExecution", isLoading, isValidating);

  return {
    executionList: data,
    error,
    isLoading,
    mutate,
    isValidating,
  };
}

export default useGetTriggerExecution;
