import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/users/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.RECEIVE_USERS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearList() {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_LIST });
    }
}

export function append(obj, callback) {
    return () => {
        httpHandler
            .post("/api/users/appendcustomer", obj)
            .then(() => {
                callback();
            })
            .catch(() => {
                callback();
            });
    };
}

export function remove(obj, callback) {
    return () => {
        httpHandler
            .post("/api/users/removecustomer", obj)
            .then(() => {
                callback();
            })
            .catch(() => {
                callback();
            });
    };
}
