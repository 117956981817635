import React, { Component } from 'react'
import * as orderActions from "../../actions/orderActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormLoader from "../../../../components/common/FormLoader";
import MicroTable from "../../../../components/common/MicroTable";
import displayDateTime from "../../../../utils/dateTimeHelper";
import syncProviderTypes from "../../constants/syncProviderTypes";
import syncState from "../../constants/syncState";

class SyncTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            orderId: this.props.orderId,
            isLoading: false,
            isWorking: false,
        };
    }

    componentDidMount() {
        this.reloadItems();
    }

    reloadItems = () => {
        this.setState({ isLoading: true });
        this.props.actions.clearSyncPayloads();
        this
            .props
            .actions
            .listSyncPayloads({ orderId: this.state.orderId })
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(() => {
                this.setState({ isLoading: false })
            })
    }

    getItems = () => {
        if (this.props.orders && this.props.orders.sync && this.props.orders.sync.data)
            return this.props.orders.sync.data;
        return [];
    }

    render() {
        return (
            <div className="relative">
                {this.state.isLoading
                    ? <FormLoader />
                    : null}
                <MicroTable
                    data={this.getItems()}
                    noEdit
                    itemSelected={(item) => {

                    }}
                    columns={[
                        {
                            name: 'Date Time',
                            id: 'dateTime',
                            type: 'custom',
                            renderDisplay: d => displayDateTime(d.createDate)
                        },
                        {
                            name: 'Provider',
                            id: 'provider',
                            type: 'custom',
                            renderDisplay: d => syncProviderTypes[d.provider]
                        },
                        {
                            name: 'State',
                            id: 'state',
                            type: 'custom',
                            renderDisplay: d => syncState[d.state]
                        },
                        {
                            name: 'External Id',
                            id: 'externalId',
                            type: 'text'
                        },
                    ]}
                    onChange={(data) => {

                    }} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { orders: state.order }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(orderActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncTab)