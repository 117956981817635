import React, { Component } from "react";
import local_version from "../utils/version.json";
import httpHandler from "../utils/httpHandler";
import useSWR from "swr";


const fetcher = (url) => fetch(url).then((res) => res.json());

const VersionChecker = () => {

  // const { data, error, isLoading } = useSWR("/version.json", fetcher);

  // if (data) {
  //   console.log('data')
  // }

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     showModal: false,
  //   };
  // }

  // componentDidMount() {
  //   this.loadServerVersion();
  // }

  // loadServerVersion = () => {
  //   httpHandler
  //     .get("/version.json")
  //     .then((response) => {
  //       this.setState({ server_version: response.data }, () => {
  //         console.log(
  //           `versions: ${this.state.server_version.build_timestamp} ${local_version.build_timestamp}`
  //         );
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // detectVersionMismatch = () => {
  //   if (
  //     this.state.server_version &&
  //     this.state.server_version.build_timestamp
  //   ) {
  //     if (
  //       this.state.server_version.build_timestamp !=
  //       local_version.build_timestamp
  //     ) {
  //       console.log(
  //         `version mismatch detected: ${this.state.server_version.build_timestamp} ${local_version.build_timestamp}`
  //       );
  //     }
  //   }
  // };

  return (
    <div></div>
  );

};

export default VersionChecker;