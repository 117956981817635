import React from "react";

export default class DropdownSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  getInitialState() {
    return {
      isOpen: false,
      selectedOptions: [],
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleOptionClick(e, item) {
    var selectedOptions = this.props.selectedOptions || [];
    const target = e.target;
    if (target.checked) {
      selectedOptions.push(this.props.itemId(item));
    } else {
      var existingIndex = selectedOptions.indexOf(this.props.itemId(item));
      if (existingIndex !== -1) {
        selectedOptions.splice(existingIndex, 1);
      }
    }
    this.setState({ selectedOptions: selectedOptions }, () => {
      this.props.onChange(selectedOptions);
    });
  }

  handleClickOutside(event) {
    if (this.dropdownOuter && !this.dropdownOuter.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  handleClear(e) {
    this.setState({ selectedOptions: [] }, () => {
      this.props.onChange(this.state.selectedOptions);
    });
    e.stopPropagation();
  }

  renderTitle = () => {
    if (!this.props.selectedOptions) return this.props.title;
    if (this.props.selectedOptions.length === 1) {
      let item = this.props.items.find(
        (x) => this.props.itemId(x) == this.props.selectedOptions[0]
      );
      if (item) return this.props.itemRender(item);
      else return this.props.title;
    } else if (this.props.selectedOptions.length > 1)
      return this.props.selectedOptions.length + " selected";
    else return this.props.title;
  };

  render() {
    return (
      <div className="dropdown">
        <div
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          className={"form-control " + (this.state.isOpen ? "open" : "")}
        >
          <div className="dropdown-row">
            <div className="dropdown-text-col">
              <span className="dropdown-title">{this.renderTitle()}</span>
            </div>
            <div className="dropdown-btn">
              <span className="Select-arrow" />
            </div>
            <div
              className="dropdown-btn"
              title="Clear value"
              aria-label="Clear value"
            >
              <span
                onClick={(e) => this.handleClear(e)}
                className="Select-clear"
              >
                ×
              </span>
            </div>
          </div>
        </div>
        {this.state.isOpen ? (
          <div
            tabIndex="0"
            ref={(instance) => (this.dropdownOuter = instance)}
            className="drowdown-outer"
          >
            {this.props.items.map((item) => {
              return (
                <div className="checkbox" key={this.props.itemId(item)}>
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        this.props.selectedOptions &&
                        this.props.selectedOptions.indexOf(
                          this.props.itemId(item)
                        ) !== -1
                      }
                      onChange={(e) => this.handleOptionClick(e, item)}
                    />
                    {this.props.itemRender(item)}
                  </label>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
