import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import ImporterEdit from "./components/ImporterEdit";

export default class Importer extends Component {
    render() {
        return (
            <Switch>
                <Route path="/importer/edit/:importerId?" component={ImporterEdit}/>
            </Switch>
        )
    }
}