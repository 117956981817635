import { Location } from "history";

export const urlCreator = (url: Location, parameter: string, value: string) => {
  const params = new URLSearchParams(url.search);
  params.set(parameter, value);
  return url.pathname + "?" + params.toString();
};

export const urlDelete = (url: Location, parameter: string[]) => {
  const params = new URLSearchParams(url.search);
  parameter.forEach((param) => {
    params.delete(param);
  });
  return url.pathname + "?" + params.toString();
};

export default urlCreator;
