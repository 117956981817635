import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import CustomerTable from "./components/CustomerTable";
import CustomerEdit from "./components/CustomerEdit";
import ImporterEdit from "./components/ImporterEdit";

export default class Customer extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/customer" component={CustomerTable} />
                <Route path="/customer/edit/:customerId?/import/editImporter/:importerId?" component={ImporterEdit} />
                <Route path="/customer/edit/:customerId?/:tab?" component={CustomerEdit} />
                <Route path="/customer/new/:tab?" component={CustomerEdit} />
            </Switch>
        )
    }
}