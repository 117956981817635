import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import LocationsTable from './components/LocationsTable';
import LocationEdit from "./components/LocationEdit";

const Locations = () => (
    <Switch>
        <Route path="/location/new" component={LocationEdit} />
        <Route path="/location/edit/:locationId?" component={LocationEdit} />
        <Route path="/location" component={LocationsTable} />
    </Switch>
);

export { Locations as default };
