import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import UploadButton from "../../../components/common/UploadButton";
import * as notificationActions from "../../notifications/actions/notificationActions";
import { connect } from "react-redux";
import * as importerActions from "../actions/importerActions";
import { bindActionCreators } from "redux";
import * as importerTypes from "../../customer/constants/importerTypes";
import getLabelByValue from "../../../utils/enumHelper";
import displayDateTime from "../../../utils/dateTimeHelper";
import * as importStates from "../../fileImport/constants/importStates";
import MicroTable from "../../../components/common/MicroTable";
import ErrorsBlock from "../../../components/ErrorsBlock";
import ErrorLabel from "../../../components/ErrorLabel";

class TestRunModal extends Component {
    handleRun = (e) => {
        this.validate((hasErrors) => {
            if (!hasErrors) {
                this.setState({ isWorking: true });
                this.props.saveImporter(e).then(() => {
                    console.log(this.props.customers);
                    this.props.actions.testRunImporter({
                        uploadFile: this.state.uploadFile,
                        importerId: this.props.importers.importer.id,
                        fileImportId: this.state.selectedFileUpload ? this.state.selectedFileUpload.id : null
                    }).then((response) => {
                        this
                            .props
                            .notification
                            .add("Test run executed", "", "success")
                        this
                            .props
                            .actions
                            .getImporter(this.props.importers.importer.id).then(() => {
                                this.setState({ isWorking: false });
                                this
                                    .props
                                    .onClose()
                            });
                    }).catch((error) => {
                        this
                            .props
                            .notification
                            .add("Test run failed: " + error.response.data.message, "", "fail")
                        this.setState({ isWorking: false });
                    });
                });
            }
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            uploadFile: {},
            isWorking: false,
            isLoading: false,
            lastFileUploads: [],
            selectedFileUpload: null,
            errors: {},
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.props.actions.getLastFileImports({ page: 1, pageSize: 10 })
            .then((response) => {
                this.setState({ isLoading: false, lastFileUploads: response.data.data });
            }).catch(() => {
                this.setState({ isLoading: false });
            })
    }

    validate = (callback) => {
        let errors = {};

        if (!this.state.uploadFile.name && !this.state.selectedFileUpload)
            errors["fileUpload"] = "File not selected";
        this.setState({
            errors: errors
        }, () => {
            let hasErrors = !(Object.keys(this.state.errors).length === 0);
            callback(hasErrors);
        });
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Test run</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <b>Upload file</b>
                        <div>
                            <UploadButton onFileChanged={(files) => {
                                this.setState({ uploadFile: files[0] });
                            }} btnClass={"btn btn-xs btn-success"} />
                            {this.state.uploadFile ? <span>{this.state.uploadFile.name}</span> : null}
                        </div>
                        <div className="margin-top-10">
                            <b>Or select from last uploads</b>
                            <MicroTable
                                data={this.state.lastFileUploads || []}
                                noEdit
                                selectable
                                itemSelected={(item) => {
                                    this.setState({ selectedFileUpload: item });
                                }}
                                columns={[
                                    {
                                        name: 'File name',
                                        id: 'originalFileName',
                                        type: 'custom',
                                        renderDisplay: d => <span>
                                            <a href={"/api/fileImport/downloadArchiveFile?fileImportId=" + d.id}>{d.originalFileName}</a>
                                        </span>
                                    },
                                    {
                                        name: 'Status',
                                        id: 'status',
                                        type: 'custom',
                                        renderDisplay: d => <span>
                                            {d.importedRecords}/{d.totalRecords} {getLabelByValue(importStates, d.importState)}
                                            &nbsp;
                                            {d.importState === 2 && <i className="fa fa-check color-green" />}
                                        </span>
                                    },
                                    {
                                        name: 'User',
                                        id: 'userName',
                                        type: 'text'
                                    },
                                    {
                                        name: 'Date time',
                                        id: 'dateTime',
                                        type: 'custom',
                                        renderDisplay: d => <span>
                                            {displayDateTime(d.startedDate)}
                                        </span>
                                    }
                                ]}
                                onChange={(data) => {
                                }} />
                        </div>
                        <ErrorLabel error={this.state.errors["fileUpload"]} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={this.handleRun}>
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw" />
                            : <i className="fa fa-play" />}
                        Run
                    </Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return { importers: state.importer }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(importerActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestRunModal)
