import React, {Component} from 'react';
import {Modal, Button} from "react-bootstrap";
import generate from "../../../../utils/guid";
import * as variableTypes from '../../constants/variableTypes';

export default class CustomerVariablesEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variables: [
                this.getNewItem()
            ]
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.variableDefinitions){
            this.setState({
                variables: nextProps.variableDefinitions
            });
        }
        if(nextProps.show && !this.props.show){
            //reset
        }
    }

    getNewItem = () =>{
        return {
            id: generate(),
            name: '',
            type: variableTypes.Input
        };
    }

    handleItemRemove = (item) => {
        let variables = this.state.variables.slice(0).filter(x=> x.id != item.id);
        this.setState({variables: variables});
    }

    handleAddField = (e) => {
        e.preventDefault();

        let variables = this.state.variables.slice(0);
        variables.push(this.getNewItem());

        this.setState({variables: variables});
    }

    renderItem = (item) =>{
        return (
            <div key={item.id} className="form-group">
                <div className="col-md-6">
                    <input type="text"
                           value={item.name}
                           onChange={(e)=>{
                               let variables = this.state.variables.slice(0);
                               let targetItem = variables.find(x=>x.id == item.id);
                               targetItem.name = e.target.value;
                               this.setState({variables: variables});
                           }}
                           className="form-control" placeholder="Name"/>
                </div>
                <div className="col-md-5">
                    <select className='form-control'
                            value={item.type}
                            onChange={(e)=>{
                                let variables = this.state.variables.slice(0);
                                let targetItem = variables.find(x=>x.id == item.id);
                                targetItem.type = parseInt(e.target.value);
                                this.setState({variables: variables});
                            }}>
                        <option value={variableTypes.Input}>Input</option>
                        <option value={variableTypes.Checkbox}>Checkbox</option>
                        <option value={variableTypes.List}>List</option>
                    </select>
                </div>
                <div className="col-md-1">
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleItemRemove(item);
                        }}><i className="fa fa-times color-red margin-top-10"></i></a>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit customer variables</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal">
                        {this.state.variables.map((i)=>{
                            return this.renderItem(i);
                        })}
                    </form>
                    <button
                        onClick={this.handleAddField}
                        className="btn bg-blue has-text">
                        <i className="fa fa-plus"></i>
                        <span>Add</span>
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={() => {
                            this
                                .props
                                .onConfirm(this.state.variables);
                        }}>
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : <i className="fa fa-check"></i>}
                        Save</Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
