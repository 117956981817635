import { useEffect, useRef, useState } from "react";
import Tree from "rc-tree";
import * as monaco from "monaco-editor";
import "../../utils/userWorker.js";
import { ScriptObjectCamelCase } from "../../models/ScriptObjectCamelCase";
import { Slider, Text } from "@radix-ui/themes";

type ScriptEditorProps = {
  scripts: Array<ScriptObjectCamelCase>;
  onScriptChanged: (scriptId: number, script: string) => void;
  onNodeSelected: (node: any) => void;
};

const ScriptEditor = (props: ScriptEditorProps) => {
  const [selectedScriptId, setSelectedScriptId] = useState<number | null>(null);
  const [selectedScript, setSelectedScript] =
    useState<ScriptObjectCamelCase | null>(null);
  const [prevScripts, setPrevScripts] = useState<any[]>([]);

  const selectedScriptRef = useRef<number | null>(null);

  const [editor, setEditor] =
    useState<monaco.editor.IStandaloneCodeEditor | null>(null);

  if (prevScripts.length === 0 && props.scripts.length > 0) {
    setPrevScripts(props.scripts);
    setSelectedScriptId(props.scripts[0].id);
    setSelectedScript(
      props.scripts.find((x) => x.id === props.scripts[0].id) ?? null,
    );
    editor?.setValue(
      props.scripts.find((x) => x.id === props.scripts[0].id)?.script ?? "",
    );
  }

  useEffect(() => {
    if (selectedScriptId) {
      selectedScriptRef.current = selectedScriptId;
    }
  }, [selectedScriptId]);

  const editorBlur = (code: string) => {
    if (selectedScriptRef.current) {
      props.onScriptChanged(selectedScriptRef.current, code);
    }
  };

  const codeEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (codeEl.current) {
      setEditor((editor) => {
        if (editor) {
          editor.setValue(selectedScript?.script || "");
          return editor;
        }
        const createEditor = monaco.editor.create(codeEl.current!, {
          language: "javascript",
          automaticLayout: true,
          formatOnPaste: true,
          formatOnType: true,
          theme: "vs-dark",
          fontLigatures:
            "'ss01', 'ss02', 'ss03', 'ss04', 'ss05', 'ss06', 'ss07', 'ss08', 'calt', 'dlig', 'zero', 'onum'",
          fontSize: 16,
          fontFamily:
            "'Monaspace Kyrpton','Monaspace Neon', 'Monaspace Argon', 'Monaspace Xenon', 'Cascadia Code', 'Fira Code', Consolas, monospace",
        });
        createEditor.onDidBlurEditorText(() => {
          editorBlur(createEditor?.getValue() ?? "");
        });
        return createEditor;
      });
    }

    return () => {
      editor?.dispose();
    };
  }, []);

  const options =
    monaco.languages.typescript.javascriptDefaults.getCompilerOptions();
  monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
  });
  monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    ...options,
    checkJs: true,
    strictNullChecks: false,
  });

  monaco.languages.typescript.javascriptDefaults.addExtraLib(
    `
  import * as ts from './typescript';
  export = ts;
  export as namespace log
  export as namespace error 
  export as namespace costsProvider 
  export as namespace counterProvider 
  export as namespace databaseProvider 
  export as namespace dictionaryProvider 
  export as namespace distanceProvider 
  export as namespace emailProvider 
  export as namespace fileExportProvider;
  export as namespace fileImportProvider;
  export as namespace flagProvider;
  export as namespace fuelProvider;
  export as namespace geocodingProvider;
  export as namespace helpersProvider;
  export as namespace httpProvider;
  export as namespace importDataProvider;
  export as namespace inboundProvider;
  export as namespace locationResolveProvider;
  export as namespace marketProvider;
  export as namespace orderfulGateway;
  export as namespace orderProvider;
  export as namespace scriptPdfRenderProvider;
  export as namespace profilesProvider;
  export as namespace shipmentProvider;
  export as namespace scriptStorageProvider;
  export as namespace teslaEnergyProvider;
  export as namespace tnxProvider;
  export as namespace triggerProvider;
  export as namespace turvoProvider;
  export as namespace userProvider;
  export as namespace vehicleInfoProvider;
  export as namespace moment;
  export as namespace record;
  export as namespace records;
  export as namespace order;
  export as namespace shipment;
  export as namespace orderGroup;
  export as namespace orders;
  export as namespace inboundProcessingItems;
  export as namespace fileImportUrl;
  export as namespace turvoResource;
  export as namespace turvoResourceId;
  export as namespace turvoShipment;
  export as namespace variables;
  export as namespace interrupt;
  export as namespace input;
  export as namespace root;
  export as namespace fileImport;
  export as namespace args;
  export as namespace temporaryStorage;
  export as namespace overwriteFlow;
  export as namespace overwriteExportAction;
  export as namespace delete;
  export as namespace ignore;
`,
    "global.d.ts",
  );

  const treeData = [
    {
      key: "0-0",
      title: "Default",
      children: props.scripts
        .filter((x) => x.isDefault)
        .map((s) => {
          return { key: s.id, title: s.name, isLeaf: true };
        }),
    },
    {
      key: "0-1",
      title: "Custom",
      children: props.scripts
        .filter((x) => !x.isDefault)
        .map((s) => {
          return { key: s.id, title: s.name, isLeaf: true };
        }),
      isLeaf: false,
    },
  ];

  return (
    <div>
      <div className="flex h-screen gap-8">
        <div className="scripts-editor-browser">
          <div className="mb-4">
            <Tree
              defaultExpandAll
              autoExpandParent
              defaultExpandParent
              showLine
              showIcon
              treeData={treeData}
              selectedKeys={[selectedScriptId ?? ""]}
              onSelect={(_, v) => {
                editor?.setValue(
                  props.scripts.find((x) => x.id === parseInt(v.node.key))
                    ?.script ?? "",
                );
                setSelectedScriptId(parseInt(v.node.key));
                props.onNodeSelected(v.node);
              }}
              className="mb-4"
            />
          </div>
          <Text size="3" className="mb-4">
            Font Size
          </Text>
          <Slider
            min={12}
            max={24}
            step={2}
            defaultValue={[16]}
            size="3"
            color="lime"
            onValueChange={(v) => {
              editor?.updateOptions({ fontSize: v[0] });
            }}
          />
        </div>
        <div className="scripts-editor-content">
          <div ref={codeEl} className="w-full" style={{ height: "100%" }}></div>
        </div>
      </div>
    </div>
  );
};
export default ScriptEditor;
