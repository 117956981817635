import React, { Component } from 'react'

export default class LogMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logLevelIcons:{
                0: ()=><i className="fa fa-wrench" />,
                1: ()=><i className="fa fa-info-circle color-blue" />,
                2: ()=><i className="fa fa-warning color-yellow" />,
                3: ()=><i className="fa fa-times-circle-o color-red" />
            },
            tagPattern: '<(?<name>\\S+)(.*)\\/>',
            attributePattern: "(?<name>\\S+)='(?<value>[^']+)'",
            tagsMap: {
                'request': (attributes) => { return this.renderRequestTag(attributes); }
            },
            requestsMap: {
                'geocoding': (id) => {
                    let geoLink = '';
                    if(this.props.settings && this.props.settings.system_settings){
                        geoLink = this.props.settings.system_settings.geoServiceBaseUrl;
                    }
                    return `<a target="_blank" href=${geoLink}/historyApiCalls?requestId=${id}><i class="fa fa-arrow-right"></i></a>`;
                },
                'turvoGateway': (id) => {
                    let tgLink = '';
                    if(this.props.settings && this.props.settings.system_settings){
                        tgLink = this.props.settings.system_settings.turvoGatewayBaseUrl;
                    }
                    return `<a target="_blank" href=${tgLink}/historyApiCalls?requestId=${id}><i class="fa fa-arrow-right"></i></a>`;
                }
            }
        };
    }

    renderRequestTag = (attributes) => {
        if(this.state.requestsMap[attributes.type]){
            return this.state.requestsMap[attributes.type](attributes.id);
        }
    }

    getAttributes = (tag) => {
        let attributes = {};
        let attributeMatches = tag.match(new RegExp(this.state.attributePattern, 'ig'));
        if(attributeMatches){
            attributeMatches.forEach((a)=>{
                let match = new RegExp(this.state.attributePattern, 'ig').exec(a);
                attributes[match.groups.name] = match.groups.value;
            })
        }
        return attributes;
    }

    replaceTags = (data) => {
        //debugger;
        let tagRegexp = new RegExp(this.state.tagPattern, 'ig');
        let tagMatch = null;
        while ((tagMatch = tagRegexp.exec(data)) != null) {
            let tagName = tagMatch.groups['name'];
            let attributes = this.getAttributes(tagMatch[0]);

            if(this.state.tagsMap[tagName]){
                let component = this.state.tagsMap[tagName](attributes);
                data = data.replace(tagMatch[0], component);
            }
        }
        return <text dangerouslySetInnerHTML={{ __html: data }} ></text>;
    }

    render() {
        return (
            <span>
                {this.state.logLevelIcons[this.props.log.logLevel]()}
                &nbsp;&nbsp;
                {this.replaceTags(this.props.log.data)}
                </span>
        )
    }
}
