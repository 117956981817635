import React, { Component } from 'react'
import AceEditor from 'react-ace';
import JSONDownloader from '../../../../../../components/JSONDowloader';
import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/snippets/javascript';
import 'brace/ext/language_tools';
import moment from "moment";

export default class ApiResponseItem extends Component {
    displayElapsed = (elapsedMs) => {
        let timeSpan = moment.duration(elapsedMs);
        return <span>{timeSpan.seconds()}s {timeSpan.milliseconds()}ms</span>
    }

    getTurvoGatewayLink = () => {
        if (this.props.settings && this.props.settings.system_settings) {
            return this.props.settings.system_settings.turvoGatewayBaseUrl;
        }

        return '';
    }

    render() {
        //debugger;
        let { model } = this.props;
        return (
            <div>
                <b>Request</b>{' '}
                <JSONDownloader name='request' data={model.Request} />{' '}
                {model.ElapsedTime ? this.displayElapsed(model.ElapsedTime) : null}
                {
                    model.RequestId
                        ? <a
                            target="_blank"
                            href={this.getTurvoGatewayLink() + "/historyApiCalls?requestId=" + model.RequestId}
                        >
                            {' '}<i className="fa fa-arrow-right" />
                        </a>
                        : null
                }
                <AceEditor
                    mode="javascript"
                    theme="monokai"
                    readOnly
                    width="100%"
                    value={model.Request || 'empty request'}
                    setOptions={{
                        showLineNumbers: false,
                        showGutter: false,
                        tabSize: 2,
                        showPrintMargin: false
                    }}
                />

                <b>Response</b>{' '}
                <JSONDownloader name='response' data={model.Content} />{' '}
                <AceEditor
                    mode="javascript"
                    theme="monokai"
                    readOnly
                    width="100%"
                    value={model.Content || 'empty response'}
                    setOptions={{
                        showLineNumbers: false,
                        showGutter: false,
                        tabSize: 2,
                        showPrintMargin: false
                    }}
                />

                {model.Error ? <div>
                    <b>Error: </b>{model.Error}
                </div> : null}
            </div>
        )
    }
}