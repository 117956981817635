import React, {Component} from 'react'
import ReactJson from "react-json-view";
import { Modal, Button } from "react-bootstrap";
import AceEditor from 'react-ace';

export default class FileParsingStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            model: {
                ...this.props.model,
                Record: JSON.stringify(this.getData(this.props.model), null, 2)
            }
        };
    }

    renderDetails = (model) => {
        const record = this.getData(model);
        return (<div>
            <b>Record snapshot: </b>
            <ReactJson theme="monokai" src={record} />
        </div>);
    };

    getData = (model) => {
        let record = JSON.parse(JSON.stringify(model.Record));

        if(record.Loops){
            record.Loops = record.Loops.map(l => {return {Id: l.Id}})
        }
        return record;
    };

    render() {
        let {model} = this.props;

        return (
            <div>
                <div>
                    <b>File name: </b>
                    <div style={{wordWrap:'break-word'}}>{model.FileName}</div>
                    <b>Workbook: </b>
                    <div>{model.Workbook}</div>
                </div>
                {this.state.expanded && this.renderDetails(model)}
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({expanded: !this.state.expanded});
                    }}>
                    Details
                    &nbsp;
                    {this.state.expanded ? <i className="fa fa-arrow-up"/> : <i className="fa fa-arrow-down"/>}
                </a>
                <Modal show={this.props.editing} onHide={this.props.closeEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit stage data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AceEditor
                            mode="javascript"
                            theme="monokai"
                            width="100%"
                            value={this.state.model.Record}
                            setOptions={{
                                showLineNumbers: false,
                                showGutter: false,
                                tabSize: 2,
                                showPrintMargin: false
                            }}
                            onChange={(e) => {
                                this.setState({ model: {...this.state.model, Record: e}})
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={() => {
                                let dataObj = { ...this.state.model };
                                delete dataObj.id;
                                this
                                .props
                                .saveData({id: this.state.model.id , data: dataObj});
                                this.props.closeEditing();
                            }}>Save</Button>
                        <Button
                            onClick={this.props.closeEdit}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}