import getUserInformation from "../utils/getUserInformation";

const fetcherPost = (url: string, args: unknown) =>
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUserInformation.id_token}`,
    },
    body: JSON.stringify(args),
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));

const fetcherPostWithNoReturnData = (url: string, args: unknown) =>
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUserInformation.id_token}`,
    },
    body: JSON.stringify(args),
  })
    .then()
    .catch((err) => console.log(err));

export default fetcherPost;
export { fetcherPostWithNoReturnData };
