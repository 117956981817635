import { MetricsObject } from "../../../../models/Metrics/MetricsObject";

type CounterWidgetProps = {
  editMode: boolean;
  widget: MetricsObject;
};

function CounterWidget(props: CounterWidgetProps) {
  let count = props.widget.result.series[0].data as number;
  return <h1 className="counter-value">{count}</h1>;
}

export default CounterWidget;
