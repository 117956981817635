import React, {Component} from 'react';
import { Modal, Button } from "react-bootstrap";

export default class ConfirmationModal extends Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.description}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="primary"
                        disabled={this.props.isWorking}
                        onClick={() => {
                            this
                                .props
                                .onConfirm();
                        }}>{this.props.isWorking && <i className="fa fa-refresh fa-spin" />} Yes</Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
