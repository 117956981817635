import * as actionTypes from '../constants/actionTypes';

export default(state = {}, action) => {
    switch (action.type) {
        case actionTypes.IMPORTER_GET:
            return {
                ...state,
                importer: action.payload
            };
        case actionTypes.IMPORTER_CLEAR:
            return {
                ...state,
                importer: null,
                scripts: null,
            };
        case actionTypes.IMPORTER_SCRIPT_CLEAR:
            return {
                ...state,
                scripts: []
            };
        case actionTypes.IMPORTER_SCRIPT_LIST:
            return {
                ...state,
                scripts: action.payload
            };
        default:
            return state;
    }
};