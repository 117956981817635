import React, {Component} from 'react'
import * as notificationActions from '../../../notifications/actions/notificationActions';
import * as commonItemsActions from '../../actions/commonItemsActions';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MicroTable from "../../../../components/common/MicroTable";
import getLabelByValue from "../../../../utils/enumHelper";
import DeleteConfirmationModal from "../../../../components/common/DeleteConfirmationModal";
import * as scriptTypes from "../../constants/sharedScriptTypes";

class SharedScriptsTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            showScriptDeleteConfirm: false,
            selectedItem: {},
        };
    }

    componentDidMount() {
        this.reloadData();
    }

    reloadData = () => {
        this.props.commonItemsActions.commonScriptsClear();
        this
            .props
            .commonItemsActions
            .commonScriptsList({pageSize: null, page: null})
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(() => {
                this.setState({ isLoading: false })
            })
    }

    componentWillReceiveProps(nextProps) {

    }

    getScripts = () => {
        return this.props.commonItems && this.props.commonItems.commonScripts ? this.props.commonItems.commonScripts.data : [];
    }

    render() {
        let addButtonStyle = {
            marginTop:"-10px",
            marginRight: "10px"
        }

        return (
            <div>
                <div className="form-group row">
                    <b className="table-top-heading">Importers</b>
                    <a className="btn btn-xs bg-blue has-text pull-right" style={addButtonStyle}
                       onClick={(e)=> {
                            e.preventDefault();
                            this.props.history.push(`/common-items/script/edit`);
                        }}>
                        <i className="fa fa-plus"></i>
                        <span>Add</span>
                    </a>
                    <div className="col-sm-12">
                        <MicroTable
                            data={this.getScripts()}
                            noEdit
                            columns={[
                                { name: 'Name', id:'name', type:'text' },
                                {
                                    name: 'Script type',
                                    id: 'scriptType',
                                    type: 'custom',
                                    renderDisplay: d => <span>{getLabelByValue(scriptTypes, d.scriptType)}</span>
                                },
                                {
                                    name: 'Actions',
                                    id: 'actions',
                                    type: 'custom',
                                    width: '100',
                                    renderDisplay: d => <div>
                                        <a className="btn btn-xxs btn-primary" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push('/common-items/script/edit/'+d.id);
                                        }}>Edit</a>
                                        &nbsp;
                                        <a className="btn btn-xxs btn-danger"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               this.setState({showScriptDeleteConfirm: true, selectedItem: d});
                                           }}>Delete</a>
                                    </div>
                                }
                            ]}
                            onChange={(data) => {}} />
                    </div>
                </div>
                <DeleteConfirmationModal
                    title={"Delete importer confirmation"}
                    description={"Are you sure you want to delete " + this.state.selectedItem.name + "?"}
                    onClose={() => this.setState({showScriptDeleteConfirm: false})}
                    onConfirm={() => {
                        this
                        .props
                        .commonItemsActions
                        .deleteCommonScript(this.state.selectedItem.id).then(()=>{
                            this.reloadData();
                            this.setState({showScriptDeleteConfirm: false});
                        });
                    }}
                    show={this.state.showScriptDeleteConfirm}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { commonItems: state.commonItems }
}

function mapDispatchToProps(dispatch) {
    return {
        commonItemsActions: bindActionCreators(commonItemsActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedScriptsTab)