import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import metricsTypes from '../constants/metricsType';
import metricsEntityTypes from '../constants/metricsEntityTypes';
import metricsDateFilterModes from '../constants/metricsDateFilterModes';
import AceEditor from "react-ace";
import 'brace/mode/javascript';
import 'brace/theme/textmate';
import 'brace/snippets/javascript';
import 'brace/ext/language_tools';
import generate from '../../../utils/guid';
import { SketchPicker } from 'react-color';
import ColorPicker from "../../../components/ColorPicker";

const defaultSeriesName = 'default';
const defaultColor = '#0d7cd8';

class AddWidgetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.getInitialState(),
            isWorking: false
        }
    }

    getInitialState = () => {
        return {
            title: '',
            type: metricsTypes[0].value,
            dataSource: {
                entityType: metricsEntityTypes[0].value,
                datesFilter: {
                    mode: metricsDateFilterModes[0].value
                },
                series: [
                    {
                        id: generate(),
                        name: defaultSeriesName,
                        color: defaultColor,
                        filterJson: ''
                    }
                ]
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.widget){
            this.setState({
                ...nextProps.widget
            });
        } else {
            this.setState({
                ...this.getInitialState()
            });
        }
    }

    handleSeriesChanged = (data) => {
        let series = this.state.dataSource.series.slice();
        let currentSeries = series.find((s) => s.id == data.id);
        let index = series.indexOf(currentSeries);
        series[index] = {
            ...currentSeries,
            ...data
        }

        this.setState({
            ...this.state,
            dataSource: {
                ...this.state.dataSource,
                series: [
                    ...series
                ]
            }
        });
    };

    handleSeriesRemove = (data) => {
        let series = this.state.dataSource.series.filter((s) => s.id != data.id).slice();

        this.setState({
            ...this.state,
            dataSource: {
                ...this.state.dataSource,
                series: [
                    ...series
                ]
            }
        });
    };

    handleCreateSeries = () => {
        let series = this.state.dataSource.series.slice();
        this.setState({
            ...this.state,
            dataSource: {
                ...this.state.dataSource,
                series: [
                    ...series,
                    {
                        id: generate(),
                        name: '',
                        filterJson: '',
                        color: defaultColor
                    }
                ]
            }
        });
    };

    render() {
        const {show, onClose, onSave} = this.props;
        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add widget</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>Title</label>
                        <input type="text"
                               name="title"
                               onChange={(e) => {
                                   this.setState({title: e.target.value});
                               }}
                               value={this.state.title}
                               className="form-control"
                        />
                        <label className="margin-top-10">Type</label>
                        <select value={this.state.type}
                                name='type'
                                onChange={(e) => {
                                    this.setState({type: e.target.value});
                                }}
                                className="form-control">
                            {metricsTypes.map(type =>
                                <option key={type.value} value={type.value}>{type.text}</option>
                            )}
                        </select>
                        <label className="margin-top-10">Entity type</label>
                        <select value={this.state.dataSource.entityType}
                                name='type'
                                onChange={(e) => {
                                    this.setState({
                                        ...this.state,
                                        dataSource: {
                                            ...this.state.dataSource,
                                            entityType: e.target.value
                                        }
                                    });
                                }}
                                className="form-control">
                            {metricsEntityTypes.map(type =>
                                <option key={type.value} value={type.value}>{type.text}</option>
                            )}
                        </select>
                        <label className="margin-top-10">Date filter</label>
                        <select value={this.state.dataSource.datesFilter.mode}
                                name='type'
                                onChange={(e) => {
                                    this.setState({
                                        ...this.state,
                                        dataSource: {
                                            ...this.state.dataSource,
                                            datesFilter: {
                                                ...this.state.dataSource.datesFilter,
                                                mode: e.target.value
                                            }
                                        }
                                    });
                                }}
                                className="form-control">
                            {metricsDateFilterModes.map(type =>
                                <option key={type.value} value={type.value}>{type.text}</option>
                            )}
                        </select>
                        <label className="margin-top-10">Series</label>
                        {this.state.dataSource.series.map((dsSeries) => {
                            return <div style={{marginBottom: '5px'}} key={dsSeries.id}>
                                <input style={{marginBottom: '5px'}} type='text' className="form-control"
                                       value={dsSeries.name} onChange={(e) => {
                                    this.handleSeriesChanged({
                                        ...dsSeries,
                                        name: e.target.value
                                    });
                                }}/>
                                <ColorPicker color={dsSeries.color || defaultColor} onChange={(color)=>{
                                    this.handleSeriesChanged({
                                        ...dsSeries,
                                        color: color
                                    });
                                }}/>
                                <AceEditor
                                    mode="javascript"
                                    width="100%"
                                    height="100px"
                                    value={dsSeries.filterJson}
                                    readOnly={false}
                                    theme="textmate"
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: false,
                                        tabSize: 2,
                                        showPrintMargin: false
                                    }}

                                    onChange={(e) => {
                                        this.handleSeriesChanged({
                                            ...dsSeries,
                                            filterJson: e
                                        });
                                    }}
                                />
                                <input style={{marginTop: '5px'}} type='text' className="form-control"
                                       value={dsSeries.warningLevel} placeholder='Warning level' onChange={(e) => {
                                    this.handleSeriesChanged({
                                        ...dsSeries,
                                        warningLevel: e.target.value
                                    });
                                }}/>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.handleSeriesRemove(dsSeries);
                                }} className="btn-xss bg-danger pull-right">
                                    <i className="fa fa-close"/>
                                </a>
                            </div>
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCreateSeries}>Add series</Button>
                    <Button disabled={this.state.isWorking} bsStyle="success" onClick={() => {
                        this.setState({isWorking: true});
                        onSave(this.state, () => {
                            this.setState({isWorking: false});
                        });
                    }}>
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : null}
                        <span>Save</span>
                    </Button>
                    <Button onClick={onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export {AddWidgetModal as default};
