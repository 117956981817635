import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { SlChangeHistory } from "../../models/SlChangeHistory";
import { AfterModel } from "../../models/AfterModel";
import { AUTH_CONFIG } from "../../utils/userManager.js";
import { User } from "oidc-client";
import "../../utils/userWorker.js";
import { Select } from "@radix-ui/themes";

type ScriptDiffCheckerProps = {
  contextId: number;
  currentScript: any;
};

export const ScriptDiffChecker = (props: ScriptDiffCheckerProps) => {
  const [leftScript, setLeftScript] = useState("");
  const [rightScript, setRightScript] = useState("");

  const [editor, setEditor] = useState<monaco.editor.IDiffEditor | null>(null);

  const id_token = JSON.parse(
    localStorage.getItem(
      `oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`,
    ) ?? "{}",
  ) as User;

  const fetcher = (url: string) =>
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${id_token.id_token}`,
      },
    }).then((res) => res.json());

  const { data, error, isLoading } = useSWR<SlChangeHistory[]>(
    `/api/slChangeHistory/list?contextId=${props.contextId}&contextType=3`,
    fetcher,
  );

  const getScript = (id: number, side: string) => {
    if (id === 0) {
      if (side === "left") {
        setLeftScript(props.currentScript.script);
      } else {
        setRightScript(props.currentScript.script);
      }
      return;
    }
    fetch(`/api/slChangeHistory/get?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${id_token.id_token}`,
      },
    })
      .then((res) => {
        res.json().then((data: SlChangeHistory) => {
          const afterModel = JSON.parse(data.afterModel) as AfterModel;
          if (side === "left") {
            setLeftScript(afterModel.Script);
          } else {
            setRightScript(afterModel.Script);
          }
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const diffEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      if (editor) {
        editor.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (diffEl.current) {
      setEditor((editor) => {
        if (editor) {
          editor.setModel({
            original: monaco.editor.createModel(leftScript, "javascript"),
            modified: monaco.editor.createModel(rightScript, "javascript"),
          });
          return editor;
        }
        return monaco.editor.createDiffEditor(diffEl.current!, {
          originalEditable: true,
          automaticLayout: true,
          readOnly: true,
          theme: "vs-dark",
          fontLigatures:
            "'ss01', 'ss02', 'ss03', 'ss04', 'ss05', 'ss06', 'ss07', 'ss08', 'calt', 'dlig', 'zero', 'onum'",
          fontSize: 16,
          fontFamily:
            "'Monaspace Neon', 'Cascadia Code', 'Fira Code', Consolas, monospace",
        });
      });
    }

    return () => {
      editor?.dispose();
    };
  }, [diffEl.current]);

  useEffect(() => {
    if (editor) {
      editor.setModel({
        original: monaco.editor.createModel(leftScript, "javascript"),
        modified: monaco.editor.createModel(rightScript, "javascript"),
      });
    }
  }, [leftScript, rightScript]);

  return (
    <div>
      <div className="h-screen flex flex-wrap">
        <div className="h-full w-full">
          {isLoading && <div>Loading...</div>}
          {error && <div>Error...</div>}
          {data && (
            <div className="flex gap-2">
              <div className="basis-1/2">
                <Select.Root
                  onValueChange={(e) => {
                    getScript(parseInt(e), "left");
                  }}
                >
                  <Select.Trigger className="w-full" />
                  <Select.Content>
                    <Select.Item value="...">...</Select.Item>
                    {data.map((item) => (
                      <Select.Item key={item.id} value={item.id.toString()}>
                        {item.updateDate} - {item.userName}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </div>
              <div className="basis-1/2">
                <Select.Root
                  onValueChange={(e) => {
                    getScript(parseInt(e), "right");
                  }}
                >
                  <Select.Trigger className="w-full" />
                  <Select.Content>
                    <Select.Item value="...">...</Select.Item>
                    <Select.Item value={"0"}>Current</Select.Item>
                    {data.map((item) => (
                      <Select.Item key={item.id} value={item.id.toString()}>
                        {item.updateDate} - {item.userName}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select.Root>
              </div>
            </div>
          )}
          <div
            className="Editor w-full"
            ref={diffEl}
            style={{ height: "calc(100% - 56px)" }}
          />
        </div>
      </div>
    </div>
  );
};
