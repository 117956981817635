import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionaryLog/list", args)
        .then((response) => {
          resolve(response);
          dispatch({ type: actionTypes.RECEIVE_LOGS, payload: response.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
