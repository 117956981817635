import React, {Component} from 'react'
import {SketchPicker} from 'react-color';

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    render() {
        return <div onClick={() => {
            this.setState({open: !this.state.open});
        }}>
            <div className='color-picker' style={{ background: this.props.color }}/>
            {this.state.open && <SketchPicker color={this.props.color} onChangeComplete={(color) => {
                this.props.onChange(color.hex);
            }}/>}</div>
    }
}