import React, {Component} from 'react';
import {Modal, Button} from "react-bootstrap";
import {bindActionCreators} from "redux";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as triggerActions from "../actions/triggerActions";
import connect from "react-redux/es/connect/connect";

class TriggerRunModal extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () =>{
        return {
            isWorking: false,
            ids: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.show && !this.props.show){
            this.setState(this.getInitialState());

        }
    }

    run = () => {
        let ids = this.state.ids.split(',').map((id)=>id.trim());
        this.setState({isWorking: true});
        this.props.actions.runTrigger({
            triggerId: this.props.triggerId,
            ids: ids
        }).then((response)=>{
            this
                .props
                .notification
                .add("Trigger executed", "", "success")
        }).catch((error)=>{
            this
                .props
                .notification
                .add("Run failed: " + error.response.data.message, "", "fail")
        }).then(()=>{
            this.setState({isWorking: false});
        });
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Run trigger</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal">
                        <label>Turvo shipment ids, comma separated</label>
                        <textarea className="form-control" onChange={(e)=>{
                            this.setState({ids: e.target.value});
                        }} rows={15} value={this.state.ids}/>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={this.run}>
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : <i className="fa fa-play"></i>}
                        Run</Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        actions: bindActionCreators(triggerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerRunModal)