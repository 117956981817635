import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as commonItemsActions from "../actions/commonItemsActions";
import { connect } from "react-redux";
import FormLoader from "../../../components/common/FormLoader";
import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/theme/monokai";
import "brace/snippets/javascript";
import "brace/ext/language_tools";

const scriptTypes = [
  { value: 0, text: "Map" },
  { value: 1, text: "Costs" },
  { value: 2, text: "Custom" },
  { value: 3, text: "Trigger" },
  { value: 4, text: "Preprocessing" },
];

class SharedScriptEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isWorking: false,
      name: "",
      script: "",
      scriptType: 1,
    };
  }

  componentDidMount() {
    this.reloadTransfer();
  }

  reloadTransfer = () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      this.props.actions
        .getCommonScript(this.props.match.params.id)
        .then((data) => {
          let item = data.data[0];

          this.setState({
            isLoading: false,
            name: item.name,
            scriptType: item.scriptType,
            script: item.script,
            id: item.id,
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  handleSave = (e, noClean) => {
    const data = {
      id: this.state.id,
      name: this.state.name,
      script: this.state.script,
      scriptType: this.state.scriptType,
      isShared: true,
    };

    e.preventDefault();
    this.props.actions
      .saveCommonScript([data])
      .then((response) => {
        let item = response.data[0];

        this.props.notification.add("Script saved", "", "success");
        this.setState({ isWorking: false, id: item.id });
        this.props.history.push(`/common-items/script/edit/${item.id}`);
      })
      .catch(() => {
        this.props.notification.add("Save failed", "", "fail");
        this.setState({ isWorking: false });
      });
  };

  render() {
    return (
      <div>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Script edit</span>
              </h4>
            </div>
            <div className="heading-elements">
              <div className="heading-btn-group">
                <button
                  disabled={this.state.isWorking || this.state.isLoading}
                  onClick={this.handleSave}
                  className="btn bg-blue has-text"
                >
                  {this.state.isWorking ? (
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  ) : (
                    <i className="fa fa-check" />
                  )}
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat relative">
          {this.state.isLoading ? <FormLoader /> : null}
          <div className="panel-body">
            <form className="form-horizontal">
              <div className="form-group">
                <label className="col-sm-2 control-label">Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                    value={this.state.name}
                    name="scriptName"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Type</label>
                <div className="col-sm-8">
                  <select
                    value={this.state.scriptType}
                    onChange={(e) => {
                      this.setState({ scriptType: e.target.value });
                    }}
                    className="form-control"
                  >
                    {scriptTypes.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group" style={{ height: "1000px" }}>
                <AceEditor
                  mode="javascript"
                  theme="monokai"
                  width="100%"
                  height="1000px"
                  value={this.state.script || ""}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                    showPrintMargin: false,
                  }}
                  onChange={(e) => {
                    this.setState({ script: e });
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transfer: state.transfer.transfer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notification: bindActionCreators(notificationActions, dispatch),
    actions: bindActionCreators(commonItemsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedScriptEdit);
