import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";

export default class DictionaryModal extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () =>{
        return {
            name: ''
        }
    }

    handleClose = () => {
        this.props.onClose();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.showModal && !this.props.showModal){
            this.setState(this.getInitialState());
        }
    }

    componentDidMount() {
    }

    handleSave = (e) =>{
        e.preventDefault();
        this.props.onSave({
            name: this.state.name
        });

    }

    render() {
        return (
            <Modal show={this.props.showModal}  onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Name</b>
                    <input className="form-control"
                           value={this.state.name}
                           onChange={(e)=> this.setState({name: e.target.value})}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleClose}>Close</Button>
                    <button
                        onClick={this.handleSave}
                        className="btn bg-blue has-text">
                        <span>Save</span>
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}
