import React, { Component } from 'react';
import { connect } from "react-redux";
import { AUTH_CONFIG } from "../utils/userManager";

const rolesRoutes = {
    User: [
        '/', '/dashboard', '/fileimport', '/order',
        '/shipment', '/trigger'
    ],
    Superuser: [
        '/', '/dashboard', '/fileimport', '/order',
        '/shipment', '/dictionaries', '/dictionary-actions-log',
        '/dictionaries/import',
    ],
    Administrator: [
        '/', '/customer', '/importer', '/trigger',
        '/transfer', '/fileimport', '/order',
        '/shipment', '/dictionaries', '/dashboard',
        '/historyApiCalls', '/accessApiKey', '/exportList',
        '/service', '/flags', '/updates', '/location', '/users', '/common-items', '/dictionary-actions-log',
        '/dictionaries/import',
        '/sentry'
    ]
};

const appName = AUTH_CONFIG.client_id;

class RoleGuard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedRoutes: []
        }
    }

    setAllowedRoutes = (props) => {
        let allowedRoutes = [];
        const userRoles = props.userRoles.split(',')
            .map(el => {
                const appArray = el.split(':');
                if (appArray[0] === appName)
                    return appArray[1];
            });
        userRoles.forEach(el => {
            if (rolesRoutes[el])
                allowedRoutes = allowedRoutes.concat(rolesRoutes[el])
        });
        allowedRoutes = [...new Set(allowedRoutes)];
        this.setState({ allowedRoutes });
    };

    componentDidMount() {
        this.setAllowedRoutes(this.props);
    };

    componentWillReceiveProps(nextProps) {
        this.setAllowedRoutes(nextProps);
    }

    render() {
        const currentRoute = `/${this.props.currentRoute.split('/')[1]}`;
        const isRouteAllowed = this.state.allowedRoutes.some(el => el.toUpperCase() === currentRoute.toUpperCase());
        return (
            <div>
                {isRouteAllowed ?
                    this.props.children
                    :
                    (<div className="not-allowed-sign">
                        Oops, you don't have access to this page. Please, contact your administrator.
                    </div>)
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { currentRoute: state.router.location.pathname }
}

export default connect(mapStateToProps)(RoleGuard);