import React from "react";

export default class UploadButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            btnClass: this.props.btnClass || "btn btn-success",
            label: this.props.label || "Upload",
        };
    }

    handleClick = (e) => {
        e.preventDefault();
        this.fileInput.click();
    }

    handleFileChaned =(e)=>{
        var fileList = e.target.files;
        this.props.onFileChanged(fileList);
    }

    render() {
        let inputStyle = {
            display: 'none'
        };
        let buttonStyle = {
            marginRight: '10px'
        }
        return (
            <span>
                <button style={buttonStyle} className={this.state.btnClass} onClick={this.handleClick}>{this.state.label}</button>
                <input style={inputStyle} type="file" ref={(input) => { this.fileInput = input; }} onChange={this.handleFileChaned}/>
            </span>
        );
    }
}