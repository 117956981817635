import React, { Component } from "react";
import update from "react-addons-update";
import {SplitButton, MenuItem, Modal, Button} from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


export default class DraggableTable extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
        this.buttonStyle = {
            marginRight: '10px'
        }
    }

    getInitialState() {
        return {
            newRow: this.getNewRow(),
            data: this.props.data || [],
            exportFormat: 'CSV',
            showModal: false,
            selectedItem: null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({data: nextProps.data});
    }

    clearNewRow = () => {
        this.setState({
            newRow: this.getNewRow()
        });
    };

    getNewRow = () => {
        let newRow = {};
        this
            .props
            .columns
            .forEach((x) => {
                newRow[x.id] = "";
            });
        return newRow;
    };

    handleRowClick = (e, item) => {
        this.setState({ selectedItem: item });
        if(this.props.itemSelected)
            this.props.itemSelected(item);
    };

    renderEditColumn = (col, item) => {
        let colId = col.id;
        const tdStyle = {};
        if (col.width)
            tdStyle.width = `${col.width}px`;
        if (col.type === 'number') {
            return <td style={tdStyle} key={col.id}>
                <input
                    type="number"
                    pattern="[0-9]+"
                    step="1"
                    value={item[colId]}
                    onChange={(e) => {
                        item[colId] = e.target.value;
                        this.forceUpdate();
                    }}
                    className="form-control" />
            </td>;
        } else if (col.type === 'float') {
            return <td style={tdStyle} key={col.id}>
                <input
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    step="0.01"
                    value={item[colId]}
                    onChange={(e) => {
                        item[colId] = e.target.value;
                        this.forceUpdate();
                    }}
                    className="form-control" />
            </td>;
        } else if (col.type === 'custom') {
            return <td style={tdStyle} key={col.id}>
                {col.renderEdit
                    ? col.renderEdit(item)
                    : ""}
            </td>;
        } else {
            return <td style={tdStyle} key={col.id}>
                <input
                    type='text'
                    value={item[colId]}
                    onChange={(e) => {
                        item[colId] = e.target.value;
                        this.forceUpdate();
                    }}
                    className="form-control"/>
            </td>;
        }
    };

    renderColumn = (col, item) => {
        if (item.isEditMode) {
            return this.renderEditColumn(col, item);
        } else {
            const tdStyle = {};
            if (col.width)
                tdStyle.width = col.width + 'px';
            if (col.type === 'custom') {
                return <td style={tdStyle} key={col.id}>{col.renderDisplay
                    ? col.renderDisplay(item)
                    : ""}</td>;
            } else {
                return <td style={tdStyle} key={col.id}>{item[col.id]}</td>;
            }

        }
    };

    renderRow = (item, i, provided) => {
        const actionTdStyle = {
            width: '60px'
        };
        const actionLinkStyle = {
            marginLeft: '10px'
        };

        return <tr
            onClick={(e)=> {this.handleRowClick(e, item)}}
            className={this.props.selectable ?
                (this.state.selectedItem === item ? "micro-tr-selectable-selected" : "micro-tr-selectable")
                : ""}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={item.id || i + "_"}>
            {this
                .props
                .columns
                .map((col) => {
                    return this.renderColumn(col, item);
                })}
            {!this.props.noEdit ? item.isEditMode
                ? <td style={actionTdStyle}>
                    <a
                        onClick={() => {
                            item.isEditMode = false;
                            this.forceUpdate();
                            this
                                .props
                                .onChange(this.state.data)
                        }}>
                        <i className="fa fa-check" />
                    </a>
                </td>
                : <td style={actionTdStyle}>
                    <a
                        onClick={() => {
                            item.isEditMode = true;
                            this.forceUpdate();
                        }}>
                        <i className="fa fa-pencil-square-o" />
                    </a>
                    <a
                        onClick={() => {
                            this.handleRemove(item);
                        }}
                        style={actionLinkStyle}>
                        <i className="fa fa-times" />
                    </a>
                </td> : null}
        </tr>;
    };

    handleAdd = (e) => {
        this.setState({
            data: [
                ...this.state.data,
                this.state.newRow
            ]
        }, () => {
            this.clearNewRow();
            this
                .props
                .onChange(this.state.data);
        });
        e.stopPropagation();
        e.preventDefault();
        return false;
    };

    handleRemove = (item) => {
        let index = this
            .state
            .data
            .indexOf(item);
        this.setState({
            data: update(this.state.data, {
                $splice: [
                    [index, 1]
                ]
            })
        }, () => {
            this
                .props
                .onChange(this.state.data)
        });
    };

    handleExport = (format) => {
        this.setState({ exportFormat: format })
    };

    close = () => {
        this.setState({ showModal: false });
    };

    open = () => {
        this.setState({ showModal: true });
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const data = reorder(
            this.state.data,
            result.source.index,
            result.destination.index
        );

        this.setState({
            data,
        }, () => { this.props.onDragEnd && this.props.onDragEnd(this.state.data); });
    };

    render()
    {
        const { columns } = this.props;

        let lastColumnStyle = {
            width: '60px'
        };

        return (
            <div>
                <table className="table microtable">
                    <thead>
                    <tr>
                        {columns.map((col) => {
                            return <th nowrap="true" key={col.id}>{col.name}</th>;
                        })}
                        {!this.props.noEdit && <th />}
                    </tr>
                    </thead>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <tbody
                                    ref={provided.innerRef}
                                >
                                {
                                    this
                                        .state
                                        .data
                                        .map((item, i) =>
                                            (
                                                <Draggable key={item.id} draggableId={item.id} index={i}>
                                                    {(provided) => this.renderRow(item, i, provided)}
                                                </Draggable>
                                            )
                                        )
                                }
                                {provided.placeholder}
                                {!this.props.noEdit && (<tr className="editRow">
                                    {columns.map((col) => {
                                        return this.renderEditColumn(col, this.state.newRow);
                                    })}
                                    <td style={lastColumnStyle}>
                                        <button
                                            type="submit"
                                            onClick={(e) => this.handleAdd(e)}
                                            className="btn bg-blue btn-xs pull-right">
                                            <i className="fa fa-plus"/>
                                            Add
                                        </button>
                                    </td>
                                </tr>)}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </table>

                {this.props.downloadToFile && (<span>
                            <SplitButton
                                id="ExportDropdown"
                                onClick={() => this.props.downloadToFile(this.state.exportFormat)}
                                bsStyle={'success'}
                                title={"Export data " + this.state.exportFormat}>
                                <MenuItem onClick={() => this.handleExport("CSV")} eventKey="1">CSV</MenuItem>
                                <MenuItem onClick={() => this.handleExport("XLSX")} eventKey="2">XLSX</MenuItem>
                                <MenuItem onClick={() => this.handleExport("PDF")} eventKey="3">PDF</MenuItem>
                            </SplitButton>
                        </span>)
                }
                {this.props.uploadFromFile && (
                    <button
                        type="submit"
                        onClick={this.open}
                        className="btn btn-success col-sm-1"
                        style={this.buttonStyle}>
                        Import data
                    </button>
                    )
                }
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload file</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Upload file
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}