import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function getSystemSettings() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/settings/getSystemSettings")
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.GET_SYSTEM_SETTINGS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}