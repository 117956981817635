import * as actionTypes from "../constants/actionTypes";
import httpHandler from "../../../utils/httpHandler";

export function list(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/fileImport/list", args)
        .then((response) => {
          resolve(response);
          dispatch({
            type: actionTypes.FILE_IMPORT_LIST,
            payload: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function downloadFile(id, callback) {
  return (dispatch) => {
    httpHandler
      .post("/api/fileImport/downloadArchiveFile", { id: id })
      .then((response) => {
        callback();
      })
      .catch((error) => {
        callback();
      });
  };
}
export function downloadErrorFile(id) {
  return (dispatch) => {
    httpHandler
      .get("/api/fileImport/downloadErrorsFile?fileImportId=" + id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", new Date() + ".csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };
}
export function getById(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .get("/api/fileImport/get/?id=" + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function listCustomers(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/customer/list", args)
        .then((response) => {
          resolve(response);
          dispatch({
            type: actionTypes.FILE_IMPORT_LIST_CUSTOMERS,
            payload: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function cancel(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/fileImport/cancel", { id: id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function clearFile() {
  return (dispatch) => {
    dispatch({ type: actionTypes.FILE_IMPORT_CLEAR_FILE });
  };
}
