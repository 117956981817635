import { Component } from "react";
import * as userActions from "../actions/usersActions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import CustomerLabel from "./CustomerLabel";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import MegaTable from "../../../components/common/MegaTable"
import AppendCustomerModal from "../modals/AppendCustomerModal";
import { PlusIcon } from "@radix-ui/react-icons";
import { Button } from "@radix-ui/themes";

class UserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 10,
      isWorking: false,
      search: "",
      selectedUser: null,
    };

    this.refreshDebounce = debounce(300, false, () => {
      this.reloadData();
    });
  }

  componentDidMount() {
    this.reloadData();
  }

  reloadData = (noLoader) => {
    if (!noLoader) {
      this.setState({ isWorking: true });
    }
    this.props.actions.clearList();
    this.props.actions
      .list({
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
      })
      .then(() => this.setState({ isWorking: false }))
      .catch(() => this.setState({ isWorking: false }));
  };

  getData = () => this.props?.users?.list?.data || [];

  appendCustomer = (customerId) => {
    this.setState({ isWorking: true });
    this.props.actions.append(
      { customerId, userId: this.state.selectedUser },
      () => {
        this.setState({ isWorking: false, selectedUser: null });
        this.reloadData();
      }
    );
  };

  removeCustomer = (customerId, userId) => {
    this.setState({ isWorking: true });
    this.props.actions.remove({ customerId, userId }, () => {
      this.setState({ isWorking: false });
      this.reloadData();
    });
  };

  render() {
    let columns = [
      {
        name: "Name",
        id: "name",
        type: "custom",
        width: 240,
        renderDisplay: (d) => <span>{d.login}</span>,
      },
      {
        name: "Customers",
        id: "Customers",
        type: "custom",
        renderDisplay: (d) => (
          <div className="appended_customers">
            {d.customers
              .filter((el) => el.name)
              .map((el) => (
                <CustomerLabel
                  key={el.id}
                  onDelete={() => this.removeCustomer(el.id, d.id)}
                  text={el.name}
                />
              ))}
            <Button type="button" onClick={() => this.setState({ selectedUser: d.id })} >
              <PlusIcon /> Add
            </Button>
          </div>
        ),
      },
    ];

    const users = this.props.users || {};

    return (
      <div>
        <Helmet>
          <title>SL3 - Users</title>
        </Helmet>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Users</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="panel panel-flat">
          <MegaTable
            editMode={false}
            columns={columns}
            onRefresh={() => {
              this.reloadData();
            }}
            isWorking={this.state.isWorking}
            summary={
              users.list && {
                skip: users.list.skip,
                take: users.list.take,
                total: users.list.total,
                totalPages: users.list.totalPages,
              }
            }
            page={this.state.page}
            pageChanged={(page) => {
              if (page < 1) page = 1;
              this.setState(
                {
                  page: page,
                },
                () => {
                  this.reloadData();
                }
              );
            }}
            pageSizeChanged={(size) => {
              this.setState(
                {
                  pageSize: size,
                },
                () => {
                  this.reloadData();
                }
              );
            }}
            searchChanged={(search) => {
              this.setState(
                {
                  search: search,
                },
                () => {
                  this.refreshDebounce();
                }
              );
            }}
            data={this.getData()}
          />
        </div>
        <AppendCustomerModal
          onClose={() => this.setState({ selectedUser: null })}
          customers={this.props?.import?.customers?.data || []}
          onConfirm={(customerId) => {
            this.appendCustomer(customerId);
          }}
          show={!!this.state.selectedUser}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { users: state.users, oidc: state.oidc, import: state.import };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

UserTable.propTypes = {
  actions: PropTypes.object.isRequired,
  users: PropTypes.object,
  import: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
