import React, { Component } from "react";
import MegaTable from "../../../components/common/MegaTable";
import * as shipmentActions from "../actions/shipmentActions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { debounce } from "throttle-debounce";
import getLabelByValue from "../../../utils/enumHelper";
import displayDateTime from "../../../utils/dateTimeHelper";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as shipmentStates from "../constants/shipmentStates";

import DropdownSelector from "../../../components/common/DropdownSelector";
import DateRangeSelector from "../../../components/common/DateRangeSelector";
import MicroTable from "../../../components/common/MicroTable";
import * as orderHelper from "../../orders/helpers/orderHelper";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const stateTypes = [
  { id: 0, value: "Draft" },
  { id: 1, value: "Exported" },
];

class ShipmentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 10,
      selectedItem: {},
      isWorking: false,
      search: "",
      from: moment.utc("0001-01-01"),
      to: moment.utc("0001-01-01"),
      states: [],
    };
  }

  componentDidMount() {
    this.props.actions.clearList();
    this.reloadData();
  }

  changeDatesValues = (from, to) => {
    if (from <= to) {
      this.setState(
        {
          to,
          from,
        },
        () => this.reloadData()
      );
    }
  };

  reloadData = (noLoader) => {
    if (!noLoader) {
      this.setState({ isWorking: true });
    }
    this.props.actions
      .list({
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
        from: this.state.from,
        to: this.state.to,
        states: this.state.states,
      })
      .then(() => this.setState({ isWorking: false }))
      .catch(() => this.setState({ isWorking: false }));
  };

  getData = () => {
    if (this.props.shipment && this.props.shipment.list) {
      return this.props.shipment.list.data;
    }
    return [];
  };

  getSystemSettings = () => {
    if (this.props.settings) return this.props.settings.system_settings || {};
    return {};
  };

  displayLocation = (location) => {
    if (!location) return null;

    return (
      <span>
        {!location.id ? (
          <span className="color-red">Location not found </span>
        ) : null}
        <a
          href={
            this.getSystemSettings().turvoBaseUrl +
            "/#/locations/" +
            location.id
          }
          target="_blank"
        >
          <span className="semi-bold">{location.name}</span>&nbsp;{location.id}
        </a>
        <br />
        <span>{location.formatted}</span>
        <br />
        <span>
          {displayDateTime(
            location.appointment.start,
            null,
            true
          )}
          &nbsp;<FontAwesomeIcon icon={icon({ name: "arrow-right" })} />{" "}
          {displayDateTime(location.appointment.end, "hh:mm:ss A", true)}
          &nbsp;
          <span className="demi-text">{location.appointment.timezone}</span>
        </span>
      </span>
    );
  };

  displayOrdersShort = (d) => {
    if (!d.ordersGroups) {
      return <span>Not available</span>;
    }
    let orderGroupsCount = d.ordersGroups.length;
    let ordersCount = 0;
    let orderIdentities = [];
    d.ordersGroups.forEach((og) => {
      if (og.orders) {
        ordersCount += og.orders.length;
        og.orders.forEach((o) => {
          if (o.items) {
            o.items.forEach((i) => {
              orderIdentities.push(i.identityNumber);
            });
          }
        });
      }
    });
    return (
      <span>
        <span>
          {ordersCount} orders in {orderGroupsCount} groups
        </span>
        <br />
        <span>{orderIdentities.join(", ")}</span>
        <br />
        <a
          onClick={(e) => {
            e.preventDefault();
            d.expanded = !d.expanded;
            this.forceUpdate();
          }}
        >
          {d.expanded ? (
            <span>
              Close details <i className="fa fa-caret-down" />
            </span>
          ) : (
            <span>
              Expand details <i className="fa fa-caret-right" />
            </span>
          )}
        </a>
      </span>
    );
  };

  renderExpanded = (item) => {
    return (
      <div>
        {item.ordersGroups.map((og, i) => {
          return (
            <div key={i}>
              <div>
                <b>{"Group " + (i + 1)}</b> |
                {og.customerId ? (
                  <a
                    onClick={(e) => {
                      this.props.history.push(
                        "/customer/edit/" + og.customerId
                      );
                    }}
                  >
                    {" "}
                    {og.customerName}
                  </a>
                ) : null}
              </div>
              <MicroTable
                className={" table-align-top"}
                data={og.orders || []}
                noEdit
                columns={[
                  ...orderHelper.getDefaultOrderColumns(
                    this.props.history,
                    this.getSystemSettings().turvoBaseUrl
                  ),
                ]}
                onChange={(data) => { }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    let columns = [
      {
        name: "Created",
        id: "created",
        type: "custom",
        renderDisplay: (d) => (
          <span>
            {displayDateTime(d.createDate)}
            <br />
            {d.status ? d.status.value : null}
          </span>
        ),
      },
      {
        name: "Orders",
        id: "orders",
        type: "custom",
        renderDisplay: (d) => this.displayOrdersShort(d),
      },
      {
        name: "Pickup",
        id: "pickup",
        type: "custom",
        renderDisplay: (d) => this.displayLocation(d.pickup),
      },
      {
        name: "Delivery",
        id: "delivery",
        type: "custom",
        renderDisplay: (d) => this.displayLocation(d.delivery),
      },
      {
        name: "Total",
        id: "total",
        type: "custom",
        renderDisplay: (d) => (
          <span>
            <span>
              Cost: <b>{d.costAmount}</b>
            </span>
            <br />
            <span>
              Weight: <b>{d.totalWeight}</b>
            </span>
          </span>
        ),
      },
      {
        name: "Actions",
        id: "Actions",
        type: "custom",
        width: 160,
        renderDisplay: (d) => (
          <span>
            {d.state === 1 ? <i className="fa fa-check color-green" /> : null}
            {/*{d.state === 2?<i className="fa fa-times color-red"/>:null}*/}
            {d.state === 0 ? <i className="fa fa-file-text-o" /> : null}
            &nbsp;
            {getLabelByValue(shipmentStates, d.state)}
            <br />
            {d.state === 4 || d.state === 6 ? (
              <a
                href={
                  this.getSystemSettings().turvoBaseUrl +
                  "/#/shipments/" +
                  d.turvoShipmentId
                }
                target="_blank"
              >
                {d.turvoShipmentId}
                <br />
              </a>
            ) : null}
            <a
              className="btn btn-xxs bg-slate"
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push("/shipment/details/" + d.id);
              }}
            >
              Details
            </a>
            &nbsp;
            <a
              className="btn btn-xxs btn-danger"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showDeleteConfirm: true, selectedItem: d });
              }}
            >
              Delete
            </a>
          </span>
        ),
      },
    ];

    let shipment = this.props.shipment || {};

    return (
      <div>
        <Helmet>
          <title>SL3 - Shipments</title>
        </Helmet>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Shipments</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="panel panel-flat">
          <div className="panel-body">
            <div className="row">
              <div className="col-md-2">
                <input
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState(
                      {
                        search: e.target.value,
                      },
                      () => {
                        debounce(500, this.reloadData());
                      }
                    );
                  }}
                  className="form-control"
                  placeholder="Pickup, destination, VIN"
                />
              </div>
              <div className="col-md-2">
                <DateRangeSelector
                  title=""
                  selectedFrom={this.state.from}
                  selectedTo={this.state.to}
                  onDatesChanged={(from, to) =>
                    this.changeDatesValues(from, to)
                  }
                />
              </div>
              <div className="col-md-2">
                <DropdownSelector
                  title="State"
                  items={stateTypes}
                  itemId={(el) => el.id}
                  itemRender={(el) => el.value}
                  onChange={(states) =>
                    this.setState({ states }, this.reloadData)
                  }
                  selectedOptions={this.state.states}
                />
              </div>
              <div className="col-md-1 table-refresh-button">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.reloadData();
                  }}
                >
                  <i className="fa fa-refresh" />
                </a>
              </div>
            </div>
          </div>
          <div className="">
            <MegaTable
              hideHeader
              editMode={false}
              columns={columns}
              onRefresh={() => {
                this.reloadData();
              }}
              className={"table-align-top"}
              isWorking={this.state.isWorking}
              renderExpanded={(item) => {
                return this.renderExpanded(item);
              }}
              summary={
                shipment.list
                  ? {
                    skip: shipment.list.skip,
                    take: shipment.list.take,
                    total: shipment.list.total,
                    totalPages: shipment.list.totalPages,
                  }
                  : null
              }
              page={this.state.page}
              pageChanged={(page) => {
                if (page < 1) page = 1;
                this.setState(
                  {
                    page: page,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              pageSizeChanged={(size) => {
                this.setState(
                  {
                    pageSize: size,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              searchChanged={(search) => {
                this.setState(
                  {
                    search: search,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              data={this.getData()}
            />
          </div>
        </div>
        <DeleteConfirmationModal
          title={"Delete shipment confirmation"}
          description={`Are you sure you want to delete ${this.state.selectedItem.id}?`}
          onClose={() => this.setState({ showDeleteConfirm: false })}
          onConfirm={() => {
            this.props.actions.remove(
              { ids: [this.state.selectedItem.id] },
              () => {
                this.reloadData();
                this.setState({ showDeleteConfirm: false });
              }
            );
          }}
          show={this.state.showDeleteConfirm}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shipment: state.shipment,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notification: bindActionCreators(notificationActions, dispatch),
    actions: bindActionCreators(shipmentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentTable);
