import * as actionTypes from './actionTypes';

const initialState = {
    list: {
        data: [],
        skip: 0,
        take: 0,
        total: 0,
        totalPages: 0
    },
};

const logReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGS_RECEIVE:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.LOGS_CLEAR:
            return initialState;
        default:
            return state;
    }
};

export { logReducer as default };