import * as actionTypes from '../constants/actionTypes';

export default(state = {}, action) => {
    switch (action.type) {
        case actionTypes.FILE_IMPORT_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.FILE_IMPORT_CLEAR_FILE:
            return {
                ...state,
                file: null
            };
        case actionTypes.FILE_IMPORT_LIST_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            }
        default:
            return state;
    }
};