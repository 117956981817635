import * as actionTypes from '../constants/actionTypes';

export default(state = {}, action) => {
    switch (action.type) {
        case actionTypes.COMMON_IMPORTERS_LIST:
            return {
                ...state,
                commonImporters: action.payload
            };
        case actionTypes.COMMON_ITEMS_CLEAR:
                return {
                    ...state,
                    commonImporters: null,
                };
        case actionTypes.COMMON_SCRIPTS_LIST:
            return {
                ...state,
                commonScripts: action.payload
            };
        case actionTypes.COMMON_SCRIPTS_CLEAR:
                return {
                    ...state,
                    commonScripts: null
                };
        default:
            return state;
    }
};