import React from 'react'
import LogHistory from '../../../components/common/LogHistory/LogHistory';

const TriggerExecutionLogsTable = (props) => (
    <LogHistory
        logName="Trigger execution logs"
        args={{triggerExecutionId: props.match.params.triggerExecutionId}}
    />
);

export { TriggerExecutionLogsTable as default }

