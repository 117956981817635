import React, {Component} from 'react'

export default class InputVariableDefinition extends Component {
    render() {
        return <div className="form-group">
                    <label className="col-sm-2 control-label">{this.props.name}</label>
                    <div className="col-sm-8">
                        <input placeholder='Value' value={this.props.value || ''} onChange={(e)=> { this.props.onChange(e.target.value); }} type='text' className='form-control'/>
                    </div>
        </div>
    }
}