import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function listJobs() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/scheduling/jobList", {})
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.SERVICE_LIST_JOBS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function pauseAllJobs() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/scheduling/pauseAll", {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function resumeAllJobs() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/scheduling/resumeAll", {})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function pauseJob(name) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/scheduling/pauseJob", {name: name})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function resumeJob(name) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/scheduling/resumeJob", {name: name})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function listLogs(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/scheduling/listLogs", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.SERVICE_LIST_LOGS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearLogs() {
    return (dispatch) => {
        dispatch({type: actionTypes.SERVICE_CLEAR_LOGS});
    };
}