import React, { Component } from "react";
import parse from "../../../utils/querystring";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as triggerActions from "../actions/triggerActions";
import * as importerActions from "../../importer/actions/importerActions";
import * as transferActions from "../../transfer/actions/transferActions";

import { connect } from "react-redux";
import FormLoader from "../../../components/common/FormLoader";
import NameEditorModal from "../../../components/common/NameEditorModal";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import Tabs from "react-bootstrap/es/Tabs";
import Tab from "react-bootstrap/es/Tab";
import ScriptEditor from "../../../components/common/ScriptEditor";
import TestRunModal from "./TestRunModal";
import TriggerTestsTab from "./tabs/TriggerTestsTab";
import TriggerStats from "./TriggerStats";
import moment from "moment";
import TriggerRunModal from "./TriggerRunModal";
import TagsInput from "react-tagsinput";
import DropdownSelector from "../../../components/common/DropdownSelector";
import { ScriptDiffChecker } from "../../../components/common/ScriptDiffChecker";

class TriggerEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isWorking: false,
      trigger: this.getDefaultTrigger(),
    };
  }

  reloadTrigger = (noClean) => {
    if (!noClean) this.props.triggerActions.clearTrigger();
    if (this.props.match.params.triggerId) {
      this.setState({ isLoading: true });

      this.props.triggerActions
        .getTrigger(this.props.match.params.triggerId)
        .then((response) => {
          let trigger = response.data;

          Promise.all([
            this.props.triggerActions.listTriggersScripts({
              triggerId: trigger.id,
            }),
            this.props.transferActions.listTransfers({
              customerId: trigger.customerId,
              pageSize: null,
              page: null,
            }),
            this.props.triggerActions.listTriggers({
              customerId: trigger.customerId,
              pageSize: null,
              page: null,
            }),
          ])
            .then(() => {
              this.setState({ isLoading: false });
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    this.getAdditionalData();
  };

  getAdditionalData = () => {
    this.props.triggerActions.getAdditionalData();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.triggers && nextProps.triggers.additionalData) {
      this.setState(
        {
          triggerTypes: nextProps.triggers.additionalData.triggerTypes || [],
          contextTypes: nextProps.triggers.additionalData.contextTypes || [],
          filterTypes: nextProps.triggers.additionalData.filterTypes || [],
          turvoStatuses: nextProps.triggers.additionalData.turvoStatuses || [],
        },
        () => {
          this.initImporterTypes();
        }
      );
    }

    if (nextProps.triggers.trigger) {
      this.setState({
        trigger: {
          ...nextProps.triggers.trigger,
          sendDelay: nextProps.triggers.trigger.sendDelay || 0,
        },
      });
    }
  }

  initImporterTypes = () => {
    if (!this.state.trigger.triggerType && this.state.triggerTypes.length > 0) {
      this.setState({
        trigger: {
          ...this.state.trigger,
          triggerType: this.state.triggerTypes[0].value,
        },
      });
    }

    if (!this.state.trigger.contextType && this.state.contextTypes.length > 0) {
      this.setState({
        trigger: {
          ...this.state.trigger,
          contextType: this.state.contextTypes[0].value,
        },
      });
    }

    if (!this.state.trigger.filterType && this.state.filterTypes.length > 0) {
      this.setState({
        trigger: {
          ...this.state.trigger,
          filterType: this.state.filterTypes[0].value,
        },
      });
    }
  };

  getDefaultTrigger = () => {
    return {
      id: this.props.match.params.triggerId,
      name: "",
      condition: "",
    };
  };

  componentDidMount() {
    let params = parse(this.props.location.search);
    if (params["customerId"]) {
      this.setState({
        customerId: params["customerId"],
        trigger: {
          ...this.state.trigger,
          customerId: params["customerId"],
        },
      });
    }
    this.reloadTrigger();
  }

  handleSave = (e, noClean) => {
    return new Promise((resolve, reject) => {
      this.setState({ isWorking: true });
      if (e) {
        e.preventDefault();
      }

      let saveTriggerPromise = this.props.triggerActions
        .saveTrigger(this.state.trigger)
        .then((response) => {
          this.props.notification.add("Trigger saved", "", "success");
          this.setState({ isWorking: false });
          this.props.history.push("/trigger/edit/" + response.data);
          resolve();
        })
        .catch(() => {
          this.props.notification.add("Save failed", "", "fail");
          this.setState({ isWorking: false });
          reject();
        });

      let saveScriptsPromise = this.props.importerActions.saveImporterScripts(
        this.state.trigger.scripts
      );
      Promise.all([saveTriggerPromise, saveScriptsPromise]).then(() => {
        this.reloadTrigger(noClean);
      });
    });
  };

  handleTransferChange = (e) => {
    const { value } = e.target;
    this.setState({
      trigger: {
        ...this.state.trigger,
        transferId: value,
      },
    });
  };

  handleResendOnContextChange = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        resendOnContextChange: e.target.checked,
      },
    });
  };

  handleResendAlwaysChange = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        resendAlways: e.target.checked,
      },
    });
  };

  handleRunOnDeletedContextChange = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        runOnDeletedContext: e.target.checked,
      },
    });
  };

  handleGlobalTriggerChange = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        globalTrigger: e.target.checked,
      },
    });
  };

  handleRepeatChange = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        repeat: e.target.checked,
      },
    });
  };

  handleApiStatusCheckChange = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        apiStatusCheck: e.target.checked,
      },
    });
  };

  handleRunForEachStatusChange = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        runForEachStatus: e.target.checked,
      },
    });
  };

  handleRunAfterChange = (e) => {
    const { value } = e.target;
    this.setState({
      trigger: {
        ...this.state.trigger,
        runAfterTriggerId: value,
      },
    });
  };

  changeTriggerTags = (tags) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        tags: tags.map((el) => ({ name: el })),
      },
    });
  };

  getLocalDate = (value) => {
    if (!value) return null;
    if (value._isAMomentObject) {
      return value.local();
    } else {
      return moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss');
    }
  };

  getStatuses = () => {
    return this.state.turvoStatuses || [];
  };

  handleAutoRepeat = (e) => {
    this.setState({
      trigger: {
        ...this.state.trigger,
        autoRepeat: e.target.checked,
      },
    });
  };

  render() {
    return (
      <div>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Trigger edit</span>
              </h4>
            </div>

            <div className="heading-elements">
              <div className="heading-btn-group">
                {this.state.trigger.enabled ? (
                  <button
                    disabled={this.state.isWorking}
                    onClick={() => {
                      this.setState({ isWorking: true });

                      this.props.triggerActions
                        .disableTrigger(this.state.trigger.id)
                        .then(() => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              enabled: false,
                            },
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                        .then(() => {
                          this.setState({ isWorking: false });
                        });
                    }}
                    className="btn bg-danger-300 has-text"
                  >
                    <span>Disable</span>
                  </button>
                ) : (
                  <button
                    disabled={this.state.isWorking}
                    onClick={() => {
                      this.setState({ isWorking: true });

                      this.props.triggerActions
                        .enableTrigger(this.state.trigger.id)
                        .then(() => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              enabled: true,
                            },
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                        .then(() => {
                          this.setState({ isWorking: false });
                        });
                    }}
                    className="btn bg-green has-text"
                  >
                    <span>Enable</span>
                  </button>
                )}

                <button
                  onClick={() => {
                    this.setState({ showTestRunModal: true });
                  }}
                  className="btn bg-slate has-text"
                >
                  <i className="fa fa-play" />
                  <span>Test run</span>
                </button>
                {this.state.trigger.triggerType == ""}
                <button
                  onClick={() => {
                    this.setState({ showTriggerRunModal: true });
                  }}
                  className="btn bg-success has-text"
                >
                  <i className="fa fa-play" />
                  <span>Run</span>
                </button>
                {this.state.selectedScriptNode &&
                  this.state.selectedScriptNode.props.isCustom ? (
                  <button
                    disabled={this.state.isWorking || this.state.isLoading}
                    onClick={() => {
                      this.setState({ isScriptDeleteConfirmationOpen: true });
                    }}
                    className="btn bg-danger has-text"
                  >
                    <span>Delete</span>
                  </button>
                ) : null}
                {this.state.selectedScriptNode &&
                  this.state.selectedScriptNode.props.eventKey === "custom" ? (
                  <button
                    disabled={this.state.isWorking || this.state.isLoading}
                    onClick={() => {
                      this.setState({ isNewScriptModalOpen: true });
                    }}
                    className="btn bg-blue has-text"
                  >
                    <span>Create</span>
                  </button>
                ) : null}
                <button
                  disabled={this.state.isWorking || this.state.isLoading}
                  onClick={this.handleSave}
                  className="btn bg-blue has-text"
                >
                  {this.state.isWorking ? (
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  ) : (
                    <i className="fa fa-check" />
                  )}
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat relative">
          {this.state.isLoading ? <FormLoader /> : null}
          <div className="panel-body">
            <div className="row">
              <form className="form-horizontal">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Name</label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              name: e.target.value,
                            },
                          })
                        }
                        value={this.state.trigger.name}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Context type
                    </label>
                    <div className="col-sm-10">
                      <select
                        value={this.state.trigger.contextType}
                        onChange={(e) => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              contextType: e.target.value,
                            },
                          });
                        }}
                        className="form-control"
                      >
                        {this.state.contextTypes
                          ? this.state.contextTypes.map((x) => {
                            return (
                              <option key={x.value} value={x.value}>
                                {x.text}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Condition</label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              condition: e.target.value,
                            },
                          })
                        }
                        value={this.state.trigger.condition}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Statuses</label>
                    <div className="col-sm-10">
                      <DropdownSelector
                        title="Statuses"
                        selectedOptions={
                          this.state.trigger
                            ? this.state.trigger.statusesList
                            : []
                        }
                        items={this.getStatuses()}
                        itemRender={(item) => {
                          return item.text;
                        }}
                        itemId={(item) => {
                          return item.value;
                        }}
                        onChange={(val) =>
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              statusesList: val,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Run for each status
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.runForEachStatus}
                        onChange={(e) => this.handleRunForEachStatusChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Run after</label>
                    <div className="col-sm-10">
                      <select
                        value={this.state.trigger.runAfterTriggerId}
                        onChange={this.handleRunAfterChange}
                        className="form-control"
                      >
                        <option value={""}>None</option>
                        {this.props.triggers.list.data.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Resend on change
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.resendOnContextChange}
                        onChange={(e) => this.handleResendOnContextChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Resend Always
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.resendAlways}
                        onChange={(e) => this.handleResendAlwaysChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Repeat</label>
                    <div className="col-sm-10">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.repeat}
                        onChange={(e) => this.handleRepeatChange(e)}
                      />
                    </div>
                  </div>
                  {this.state.trigger.repeat && (
                    <div className="form-group">
                      <label className="col-sm-2 control-label">
                        Stop condition
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              trigger: {
                                ...this.state.trigger,
                                stopCondition: e.target.value,
                              },
                            })
                          }
                          value={this.state.trigger.stopCondition}
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Trigger tags
                    </label>
                    <div className="col-sm-8">
                      <TagsInput
                        inputProps={{
                          placeholder: "Add Tag",
                        }}
                        value={
                          this.state.trigger.tags
                            ? this.state.trigger.tags.map((el) => el.name)
                            : []
                        }
                        onChange={this.changeTriggerTags}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Status api check
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.apiStatusCheck}
                        onChange={(e) => this.handleApiStatusCheckChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Auto retry</label>
                    <div className="col-sm-8">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.autoRepeat}
                        onChange={(e) => this.handleAutoRepeat(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Type</label>
                    <div className="col-sm-10">
                      <select
                        value={this.state.trigger.triggerType}
                        onChange={(e) => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              triggerType: e.target.value,
                            },
                          });
                        }}
                        className="form-control"
                      >
                        {this.state.triggerTypes
                          ? this.state.triggerTypes.map((x) => {
                            return (
                              <option key={x.value} value={x.value}>
                                {x.text}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Filter type
                    </label>
                    <div className="col-sm-10">
                      <select
                        value={this.state.trigger.filterType}
                        onChange={(e) => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              filterType: e.target.value,
                            },
                          });
                        }}
                        className="form-control"
                      >
                        {this.state.filterTypes
                          ? this.state.filterTypes.map((x) => {
                            return (
                              <option key={x.value} value={x.value}>
                                {x.text}
                              </option>
                            );
                          })
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Send delay</label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              sendDelay: e.target.value,
                            },
                          })
                        }
                        value={this.state.trigger.sendDelay}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">Transfer</label>
                    <div className="col-sm-10">
                      <select
                        value={this.state.trigger.transferId}
                        onChange={this.handleTransferChange}
                        className="form-control"
                      >
                        <option value={null}>None</option>
                        {this.props.transfer.list.data.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Resource creation date
                    </label>
                    <div className="col-sm-10">
                      {this.getLocalDate(this.state.trigger.resourceCreationDate)}

                    </div>
                  </div>
                  {this.state.trigger.repeat && (
                    <div className="form-group">
                      <label className="col-sm-2 control-label">
                        Repeat delay (min)
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              trigger: {
                                ...this.state.trigger,
                                repeatDelayMinutes: e.target.value,
                              },
                            })
                          }
                          value={this.state.trigger.repeatDelayMinutes}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.trigger.resendAlways && (
                    <div className="form-group">
                      <label className="col-sm-2 control-label">
                        Limit time interval (minutes)
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              trigger: {
                                ...this.state.trigger,
                                limitMinutes: e.target.value,
                              },
                            })
                          }
                          value={this.state.trigger.limitMinutes}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.trigger.resendAlways && (
                    <div className="form-group">
                      <label className="col-sm-2 control-label">
                        Amount of permitted executions
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              trigger: {
                                ...this.state.trigger,
                                limitExecutions: e.target.value,
                              },
                            })
                          }
                          value={this.state.trigger.limitExecutions}
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Run on deleted items
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.runOnDeletedContext}
                        onChange={(e) =>
                          this.handleRunOnDeletedContextChange(e)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Global trigger
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.globalTrigger}
                        onChange={(e) => this.handleGlobalTriggerChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Error notifications
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="checkbox"
                        checked={this.state.trigger.enableErrorNotifications}
                        onChange={(e) => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              enableErrorNotifications: e.target.checked,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Successful notifications
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="checkbox"
                        checked={
                          this.state.trigger.enableSuccessfulNotifications
                        }
                        onChange={(e) => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              enableSuccessfulNotifications: e.target.checked,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Notification emails
                    </label>
                    <div className="col-sm-10">
                      <TagsInput
                        inputProps={{
                          placeholder: "Add email",
                        }}
                        value={this.state.trigger.notificationEmailList || []}
                        onChange={(values) => {
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              notificationEmailList: values,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-2 control-label">
                      Auto retry attempts
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({
                            trigger: {
                              ...this.state.trigger,
                              autoRepeatAttempts: e.target.value,
                            },
                          })
                        }
                        value={this.state.trigger.autoRepeatAttempts}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              <TriggerStats
                className="margin-bot-10"
                trigger={this.state.trigger}
              />
            </div>
            <Tabs
              id="TriggerTabs"
              activeKey={this.state.selectedTab}
              onSelect={(key) => {
                this.setState({ selectedTab: key });
              }}
            >
              <Tab eventKey={"scripts"} title="Scripts">
                <ScriptEditor
                  scripts={this.state.trigger.scripts || []}
                  onNodeSelected={(node) => {
                    this.setState({ selectedScriptNode: node });
                  }}
                  onScriptChanged={(id, value) => {

                    let scripts = this.state.trigger.scripts.slice(0);
                    let script = scripts.find((x) => x.id === id);
                    script.script = value;
                    this.setState({
                      trigger: { ...this.state.trigger, scripts },
                    });
                  }}
                />
              </Tab>
              <Tab eventKey={"test"} title="Test result">
                <TriggerTestsTab
                  testResults={this.state.trigger.triggerLastTestResult}
                />
              </Tab>
              <Tab
                eventKey={"history"}
                title="History"
                mountOnEnter={true}
                unmountOnExit={true}
              >
                {this.state.trigger.scripts?.length > 0 ? (
                  <ScriptDiffChecker
                    contextId={
                      this.state.selectedScriptNode
                        ? this.state.selectedScriptNode.props.eventKey
                        : this.state.trigger.scripts.at(0).id
                    }
                    currentScript={
                      this.state.selectedScriptNode
                        ? this.state.trigger.scripts.find(
                          (a) =>
                            a.id ==
                            this.state.selectedScriptNode.props.eventKey
                        )
                        : this.state.trigger.scripts.at(0)
                    }
                  />
                ) : null}
              </Tab>
            </Tabs>
          </div>
        </div>
        <NameEditorModal
          title={"Create new script"}
          onClose={() => this.setState({ isNewScriptModalOpen: false })}
          show={this.state.isNewScriptModalOpen}
        />
        <DeleteConfirmationModal
          title={"Delete script confirmation"}
          description={"Are you sure you want to delete selected script?"}
          onClose={() =>
            this.setState({ isScriptDeleteConfirmationOpen: false })
          }
          show={this.state.isScriptDeleteConfirmationOpen}
        />
        <TestRunModal
          triggerId={this.props.match.params.triggerId}
          onClose={() => this.setState({ showTestRunModal: false })}
          saveImporter={(e) => {
            return this.handleSave(e, true);
          }}
          show={this.state.showTestRunModal}
        />
        <TriggerRunModal
          triggerId={this.props.match.params.triggerId}
          onClose={() => this.setState({ showTriggerRunModal: false })}
          show={this.state.showTriggerRunModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    triggers: state.trigger,
    transfer: state.transfer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notification: bindActionCreators(notificationActions, dispatch),
    importerActions: bindActionCreators(importerActions, dispatch),
    triggerActions: bindActionCreators(triggerActions, dispatch),
    transferActions: bindActionCreators(transferActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerEdit);
