import React, {Component} from 'react'
import ReactJson from "react-json-view";
import { Modal, Button } from "react-bootstrap";
import AceEditor from 'react-ace';

export default class CreatedStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            model: {
                ...this.props.model
            }
        };
    }

    renderDetails = (model) => {
        const record = this.getData(model);
        return (<div>
            <b>Shipment snapshot: </b>
            <ReactJson theme="monokai" src={record} />
        </div>);
    };

    getData = (model) => {
        return JSON.parse(JSON.stringify(model));
    };

    render() {
        let {model} = this.props;

        console.log(model);

        return (
            <div>
                <div>
                    <b>Id: </b>
                    <span style={{wordWrap:'break-word'}}>{model.Id}</span>
                </div>
                {this.state.expanded && this.renderDetails(model)}
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({expanded: !this.state.expanded});
                    }}>
                    Details
                    &nbsp;
                    {this.state.expanded ? <i className="fa fa-arrow-up"/> : <i className="fa fa-arrow-down"/>}
                </a>
            </div>
        )
    }
}