import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import displayDateTime from "../../../../../utils/dateTimeHelper";
import VisualStageItem from "../../../../orders/components/tabs/visualTracking/VisualStageItem";
import * as importStates from "../../../../fileImport/constants/importStates";
import * as stageTypes from "../../../../orders/constants/stageTypes";
import MicroTable from "../../../../../components/common/MicroTable";
import getLabelByValue from "../../../../../utils/enumHelper";
import LogMessage from "../../../../../components/common/LogHistory/LogMessage";

export default class ShipmentVisualTrackingLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logLevelIcons:{
                0: ()=><i className="fa fa-wrench" />,
                1: ()=><i className="fa fa-info-circle color-blue" />,
                2: ()=><i className="fa fa-warning" />,
                3: ()=><i className="fa fa-times-circle-o color-red" />
            }
        };
    }

    render() {
        let {item} = this.props;
        let {logItems} = item;

        return (
            <div>
                <div className="row margin-top-10">
                    <div className="col-md-12">
                        <div className="visual-tracker-flex-container">
                            {item.stages.map((stage, i)=>{
                                return <VisualStageItem
                                    settings={this.props.settings}
                                    saveData={this.props.saveData}
                                    reprocess={this.props.reprocess}
                                    isLast={i === item.stages.length - 1}
                                    key={stage.id}
                                    stage={stage}/>
                            })}
                        </div>
                    </div>
                </div>
                <div className="row margin-top-10">
                    <div className="col-md-12">
                        <b>Log messages: </b>
                        <MicroTable
                            data={logItems || []}
                            noEdit
                            itemSelected={(item)=>{

                            }}
                            columns={[
                                {
                                    name: 'Stage',
                                    id: 'stage',
                                    type: 'custom',
                                    renderDisplay: d => <span>
                                           {getLabelByValue(stageTypes, d.stageType)}
                                        </span>
                                },
                                {
                                    name: 'Message',
                                    id: 'message',
                                    type: 'custom',
                                    renderDisplay: d => <LogMessage settings={this.props.settings} log={d}/>
                                }
                            ]}
                            onChange={(data) => {

                            }}/>
                    </div>
                </div>
            </div>
        )
    }
}