import React, {Component} from 'react'

export default class CheckboxVariableDefinition extends Component {
    render() {
        return <div className="form-group">
            <label className="col-sm-2 control-label">{this.props.name}</label>
            <div className="col-sm-8">
                <input
                    checked={this.props.value || false} onChange={(e)=> { this.props.onChange(e.target.checked); }}
                    type="checkbox"
                />
            </div>
        </div>
    }
}