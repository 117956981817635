import displayDateTime from "../../../utils/dateTimeHelper";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const displayLocation = (location, turvoBaseUrl) => {
    if (!location)
        return null;

    return (<span>
        {!location.id ? <span className="color-red">Location not found </span> : null}
        <a href={turvoBaseUrl + '/#/locations/' + location.id}
            target="_blank"><span className="semi-bold">{location.name}</span>&nbsp;{location.id}</a>
        <br />
        <span>{location.formatted}</span>
        <br />
        <span>{displayDateTime(location.appointment.start, null, true)}
            &nbsp;<FontAwesomeIcon icon={icon({ name: "arrow-right" })} /> {displayDateTime(location.appointment.end, "hh:mm:ss A", true)}
            &nbsp;<span className="demi-text">{location.appointment.timezone}</span></span>
    </span>);
};

const displayCosts = (costs) => {
    if (!costs)
        return null;

    return (<span>
        {costs.map((c, i) => {
            return <span key={i}>
                <span className="semi-bold">{c.code ? c.code.value : null}</span> ({c.qty} x ${c.price}) = <span className="semi-bold">${c.amount}</span>
                <br />
            </span>
        })}
    </span>);
}

const getDefaultOrderColumns = (history, turvoBaseUrl) => {
    return [
        {
            name: 'Created',
            id: 'created',
            type: 'custom',
            renderDisplay: d => <span>
                {displayDateTime(d.createDate)}
                <br />
                {d.status ? d.status.value : null}
            </span>
        },
        {
            name: 'Customer',
            id: 'customer',
            type: 'custom',
            renderDisplay: d => <span>
                <a onClick={(e) => {
                    e.preventDefault();
                    history.push('/customer/edit/' + d.customer.slCustomerId);
                }}>{d.customer.slCustomerImg && <img className="customer-img-small" src={d.customer.slCustomerImg} />} {d.customer.slCustomerName} </a>
                <br />
                <a href={turvoBaseUrl + '/#/accounts/' + d.customer.id}
                    target="_blank">
                    {d.customer.name}&nbsp;{d.customer.id}</a>

            </span>
        },
        {
            name: 'Item',
            id: 'item',
            type: 'custom',
            renderDisplay: d => <span>
                {d.items ? d.items.map((i, c) => {
                    let vinDetail = [i.make, i.model, i.year].filter(Boolean).join(" ");

                    return (<span key={c}>
                        <span className="semi-bold">{i.identityNumber}</span>
                        {vinDetail ? <span className="demi-text"><br />{vinDetail}</span> : null}
                        <br />
                        <span className="demi-text">{i.qty} x {i.itemCategory ? i.itemCategory.value : null}</span>
                    </span>);
                }) : null}
                {(d.equipment && d.equipment.type) ? <span className="demi-text"><br />{d.equipment.type.value}</span> : null}
            </span>
        },
        {
            name: 'Pickup',
            id: 'pickup',
            type: 'custom',
            renderDisplay: d => displayLocation(d.pickup, turvoBaseUrl)
        },
        {
            name: 'Delivery',
            id: 'delivery',
            type: 'custom',
            renderDisplay: d => displayLocation(d.delivery, turvoBaseUrl)
        },
        {
            name: 'Costs',
            id: 'costs',
            type: 'custom',
            renderDisplay: d => displayCosts(d.costs, turvoBaseUrl)
        },
    ];
};

export { displayLocation, displayCosts, getDefaultOrderColumns };