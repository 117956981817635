import React, { Component } from 'react'
import { connect } from 'react-redux';
import ApiResponseItem from './ApiResponseItem';

class LocationResolveStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    renderApiResponse = (model) => {
        return (
            <ApiResponseItem settings={this.props.settings} model={model}/>
        );
    };

    renderDetails = (model) =>{
        return (<div>
            <b>Pickup</b><br/>
            {this.displayLocation(model.Pickup)}<br/>
            <b>Delivery</b><br/>
            {this.displayLocation(model.Delivery)}<br/>
            <b>Api requests</b><br/>
            {model.ApiResponses ? model.ApiResponses.map(x=>{
                return this.renderApiResponse(x);
            }):null}
        </div>);
    };

    getSystemSettings = () =>{
        if(this.props.settings)
            return this.props.settings.system_settings || {};
        return {};
    };

    displayLocation = (location) =>{
        if(!location)
            return (<span>Invalid location</span>);
        return (<span>
            <a href={this.getSystemSettings().turvoBaseUrl+'/#/locations/'+location.TurvoLocationId}
               target="_blank"><span className="semi-bold">{location.Name}</span>&nbsp;{location.TurvoLocationId}</a>
            <br/>
            <span>{location.Formatted}</span>
            <br/>
            <span className="demi-text">{location.Timezone}</span>
        </span>);
    }

    render() {
        let { model } = this.props;

        return (
            <div>
                <b>Resolved: </b> {[(model.Pickup && model.Pickup.TurvoLocationId? "Pickup":null),(model.Delivery && model.Delivery.TurvoLocationId? "Delivery":null)].filter(Boolean).join(", ")}
                <br/>
                <b>Api requests:</b> <span>{model.ApiResponses ? model.ApiResponses.length : 0}<br/></span>
                {
                    this.state.expanded ? this.renderDetails(model) : null
                }
                <a
                    onClick={(e)=>{ e.preventDefault(); this.setState({expanded: !this.state.expanded}); }}
                >
                    Details
                    &nbsp;
                    {
                        this.state.expanded 
                            ? <i className="fa fa-arrow-up"/> 
                            : <i className="fa fa-arrow-down"/>
                    }
                </a>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationResolveStage)