import React from 'react'

const JSONDownloader = ({ data, name }) => {
    const handleDownloadClick = (e, data, name) => {
        e.preventDefault();
        let dataAsString = '';

        if(typeof(data) !== 'string'){
            dataAsString = JSON.stringify(data)
        } else {
            dataAsString = data;
        }

        let fileName = `${name}.json`;
        let contentType = "application/json;charset=utf-8;";

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(dataAsString))], { type: contentType });
          navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          var a = document.createElement('a');
          a.download = fileName;
          a.href = 'data:' + contentType + ',' + encodeURIComponent(dataAsString);
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
    }

    return (
        <a onClick={(e) => { handleDownloadClick(e, data, name) }}>
            <i className="fa fa-download"/>
        </a>
    )
}

export default JSONDownloader;