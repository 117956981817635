import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/order/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function listCustomers(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/customer/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_LIST_CUSTOMERS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function getVisualTrackingItems(orderId) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/order/getVisualTrackingItems?orderId="+orderId)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_GET_VISUAL_TRACKING, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function updateOrderSettings(set) {
    return { type: actionTypes.ORDER_UPDATE_SETTING, payload: set };
}

export function clearVisualTrackingItems() {
    return (dispatch) => {
        dispatch({type: actionTypes.ORDER_CLEAR_VISUAL_TRACKING});
    };
}

export function get(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/order/get/?id=" + id)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_GET_DETAILS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearList() {
    return (dispatch) => {
        dispatch({type: actionTypes.ORDER_LIST_CLEAR});
    };
}

export function clear() {
    return (dispatch) => {
        dispatch({type: actionTypes.ORDER_CLEAR_DETAILS});
    };
}

export function listTriggerExecutions(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/triggerExecution/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_LIST_TRIGGER_EXECUTIONS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearTriggerExecutions() {
    return (dispatch) => {
        dispatch({type: actionTypes.ORDER_CLEAR_TRIGGER_EXECUTIONS});
    };
}

export function listFlags(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/flag/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_GET_FLAG_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearFlags() {
    return (dispatch) => {
        dispatch({type: actionTypes.ORDER_CLEAR_FLAG_LIST});
    };
}


export function removeFlag(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/flag/delete", {id: id})
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}

export function listResourceLogs(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/log/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.RESOURCE_LIST_LOGS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function clearResourceLogs() {
    return (dispatch) => {
        dispatch({type: actionTypes.RESOURCE_CLEAR_LOGS});
    };
}

export function reprocessOrder(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/order/reprocess', args)
                .then((response) => {
                    get(args.orderId).then(() => resolve(response));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function editStageData(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/order/editStageData', args)
                .then((response) => {
                    get(args.orderId).then(() => resolve(response));
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
}

export function reprocessFromStep(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/order/reprocessFromStep', args)
                .then((response) => {
                    get(args.orderId).then(() => resolve(response));
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
}

export function clearOrderChanges() {
    return (dispatch) => {
        dispatch({type: actionTypes.ORDER_CHANGE_HISTORY_LIST_CLEAR});
    };
}

export function listOrderChanges(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get(`/api/order/listOrderChangeHistory?orderId=${args.orderId}`)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_CHANGE_HISTORY_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export function getOrderChangeHistory(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get(`/api/order/changeHistory?id=${args}`,  args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_CHANGE_HISTORY_DETAILS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export function saveOrderSettings(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/order/updateSettings', args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function repeatTriggerExecution(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/triggerExecution/repeat', {id: id})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function managementAction(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/order/managementAction', args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
}

export function listSyncPayloads(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/sync/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.ORDER_GET_SYNC_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearSyncPayloads() {
    return (dispatch) => {
        dispatch({type: actionTypes.ORDER_CLEAR_SYNC_LIST});
    };
}