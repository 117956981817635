import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import InboundProcessingTable from "./components/InboundProcessingTable";
import InboundProcessingDetails from "./components/InboundProcessingDetails";

export default class InboundProcessing extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/updates" component={InboundProcessingTable} />
                <Route exact path="/updates/details/:inboundProcessingId?/:tab?" component={InboundProcessingDetails} />
            </Switch>
        )
    }
}