import React, { Component } from "react";
import { debounce } from "throttle-debounce";
import displayDateTime from "../../../utils/dateTimeHelper";
import MegaTable from "../../../components/common/MegaTable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as logActions from "../actions/actionLogActions";

class ActionsLogTable extends Component {
  constructor(props) {
    super(props);
    const defaultFilter = {};
    this.state = {
      page: 1,
      pageSize: 10,
      totalPages: 1,
      total: 0,
      filter: defaultFilter,
      isWorking: false,
      search: "",
    };
    this.refreshDebounce = debounce(1500, false, () => {
      this.reloadData();
    });
  }
  componentDidMount() {
    this.reloadData();
  }
  reloadData = (noLoader) => {
    if (!noLoader) {
      this.setState({ isWorking: true });
    }
    this.props.actions
      .list({
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
      })
      .then(() => this.setState({ isWorking: false }))
      .catch(() => this.setState({ isWorking: false }));
  };
  getData = () => {
    if (this.props.actionLog && this.props.actionLog.list) {
      return this.props.actionLog.list.data;
    }
    return [];
  };
  render() {
    let columns = [
      {
        name: "UserName",
        id: "userName",
        type: "custom",
        renderDisplay: (d) => <span>{d.userName}</span>,
      },
      {
        name: "Dictionary",
        id: "dictionary",
        type: "custom",
        renderDisplay: (d) => (
          <span>
            {d.contextType === 4 ? <span>{d.parentDictionaryName}</span> : null}
            {d.contextType === 5 ? <span>{d.dictionaryName}</span> : null}
          </span>
        ),
      },
      {
        name: "Action",
        id: "action",
        type: "custom",
        renderDisplay: (d) => (
          <span>
            {d.actionType === 1 && d.contextType === 4 ? (
              <span>
                <strong>{d.userName} </strong>Create{" "}
                <i className="fa fa-plus color-green" /> dictionary Item({" "}
                {d.contextId})
              </span>
            ) : null}
            {d.actionType === 2 && d.contextType === 4 ? (
              <div>
                <strong>{d.userName} </strong>Update{" "}
                <i className="fa fa-cloud upload" /> dictionary Item(
                {d.contextId})
              </div>
            ) : null}
            {d.actionType === 3 && d.contextType === 4 ? (
              <span>
                <strong>{d.userName} </strong>Delete{" "}
                <i className="fa fa-minus color-red" /> dictionary Item({" "}
                {d.contextId})
              </span>
            ) : null}
            {d.actionType === 1 && d.contextType === 5 ? (
              <span>
                <strong>{d.userName} </strong>Create{" "}
                <i className="fa fa-plus color-green" /> dictionary({" "}
                {d.contextId})
              </span>
            ) : null}
            {d.actionType === 2 && d.contextType === 5 ? (
              <span>
                <strong>{d.userName}</strong>Update{" "}
                <i className="fa fa-cloud upload" /> dictionary({d.contextId})
              </span>
            ) : null}
            {d.actionType === 3 && d.contextType === 5 ? (
              <span>
                <strong>{d.userName} </strong>Delete{" "}
                <i className="fa fa-minus color-red" /> dictionary({" "}
                {d.contextId})
              </span>
            ) : null}
            {d.actionType === 6 && d.contextType === 5 ? (
              <span>
                <strong>{d.userName} </strong>Clear{" "}
                <i className="fa fa-remove color-red" /> dictionary({" "}
                {d.contextId})
              </span>
            ) : null}
          </span>
        ),
      },
      {
        name: "CreateDate",
        id: "dateTime",
        type: "custom",
        renderDisplay: (d) => (
          <span>{displayDateTime(d.createDate)}</span>
        ),
      },
    ];
    let actionLog = this.props.actionLog || {};

    return (
      <div>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Action Logs</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="panel panel-flat">
          <MegaTable
            editMode={false}
            columns={columns}
            onRefresh={this.reloadData}
            isWorking={this.state.isWorking}
            summary={
              actionLog.list && {
                skip: actionLog.list.skip,
                take: actionLog.list.take,
                total: actionLog.list.total,
                totalPages: actionLog.list.totalPages,
              }
            }
            page={this.state.page}
            pageChanged={(page) => {
              if (page < 1) page = 1;
              this.setState(
                {
                  page: page,
                },
                () => {
                  this.reloadData();
                }
              );
            }}
            pageSize={this.state.pageSize}
            pageSizeChanged={(size) => {
              this.setState(
                {
                  pageSize: size,
                },
                () => {
                  this.reloadData();
                }
              );
            }}
            searchChanged={(search) => {
              this.setState(
                {
                  search: search,
                },
                () => {
                  this.refreshDebounce();
                }
              );
            }}
            data={this.getData()}
          />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { actionLog: state.actionLog, oidc: state.oidc };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(logActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionsLogTable);
