import useSWR from "swr";
import fetcherPost from "../fetchers/fetcherPost";
import { ResponseFetch } from "../models/ResponseFetch";
import CustomerFileImportRequestObject from "../models/Requests/CustomerFileImportRequestObject";
import { FileImportObject } from "../models/FileImportObject";

function useGetCustomerFileImports(args: CustomerFileImportRequestObject) {
  // create a vin regex
  const vinRegex = new RegExp(/^[A-HJ-NPR-Z0-9]{17}$/i);
  let shouldFetch = true;

  if (
    vinRegex.test(args.search ?? "") &&
    (!args.customerId || !args.importerId)
  ) {
    shouldFetch = false;
  }

  const { data, error, isLoading, mutate, isValidating } = useSWR<
    ResponseFetch<FileImportObject[]>
  >(
    shouldFetch ? ["/api/fileimport/listCustomerImports", args] : null,
    ([url, args]) => fetcherPost(url, args),
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  );

  return {
    customerFileImports: data,
    customerFileError: shouldFetch
      ? error
      : "Please choose a customer and importer to search by Vins",
    customerFileImportsIsLoading: isLoading,
    customerFileImportsMutate: mutate,
    isValidating,
  };
}

export default useGetCustomerFileImports;
