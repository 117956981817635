import React, {Component} from 'react';
import * as notificationActions from '../pages/notifications/actions/notificationActions';
import * as settingsActions from '../pages/settings/actions/settingsActions';
import * as authActions from '../pages/login/actions/authActions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Dropdown, MenuItem} from "react-bootstrap";
import CustomMenu from "./CustomMenu";
import CustomToggle from "./CustomToggle";
import empty_avatar from '../img/empty_avatar.jpg';
import * as oidcActions from "redux-oidc/src/actions/index";


class UserWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isWorking: false
        };
    }

    componentWillMount() {
        this.validateUser();
    }

    validateUser = () => {
        if (window.location && window.location.pathname != '/oidc-callback') {
            this.props.userManager.getUser().then(response => {
                if (!response || response.expired) {  // <==
                    this.props.oidcActions.userExpired();
                    this.props.userManager.signinRedirect();
                    return;
                }

                this.props.oidcActions.userFound(response);
                this.getSystemSettings();
                //const currentLocation = localStorage.getItem('last_initial_url') || '/';
                //this.props.history.push(currentLocation);
            });
        }
    }

    logoutClick = (e) => {
        e.preventDefault();
        this.props.userManager.signoutRedirect();
    };

    renderAvatar = (url) => {
        return (<img className={"user-avatar"} src={url ? url : empty_avatar}/>);
    };

    getSystemSettings = () => {
        this.props.settingActions.getSystemSettings();
    }

    render() {
        const {oidc} = this.props;

        return (
            <div>
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        {oidc.user && oidc.user.profile ? <Dropdown
                            id="dropdown-custom-menu"
                            className="dropdown btn-group navbar-dropdown">
                            <CustomToggle bsRole="toggle">
                                {this.renderAvatar(oidc.user.avatarUrl)}
                                {oidc.user.profile.name}
                                <i className="fa fa-caret-down position-right" aria-hidden="true"></i>
                            </CustomToggle>

                            <CustomMenu bsRole="menu">
                                <MenuItem eventKey="1" onClick={(e) => this.logoutClick(e)}>
                                    <i className="fa fa-power-off" aria-hidden="true"></i>Logout</MenuItem>
                            </CustomMenu>
                        </Dropdown> : <a href=""><i className="fa fa-spinner fa-spin fa-fw position-right"/></a>}

                    </li>
                </ul>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {oidc: state.oidc}
}

function mapDispatchToProps(dispatch) {
    return {
        notificationActions: bindActionCreators(notificationActions, dispatch),
        settingActions: bindActionCreators(settingsActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        oidcActions: bindActionCreators(oidcActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserWidget)