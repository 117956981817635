import React, {Component} from 'react';
import {Modal, Button} from "react-bootstrap";

export default class NameEditorModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.show && !this.props.show){
            this.setState({name: nextProps.name});
        }
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-horizontal">
                        <div className="form-group">
                            <label className="col-sm-2 control-label">Name</label>
                            <div className="col-sm-10">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    value={this.state.name}/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={() => {
                            this
                                .props
                                .onConfirm(this.state.name);
                        }}>Ok</Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
