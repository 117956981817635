import React, { Component } from "react";
import FormLoader from "../../../components/common/FormLoader";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as customerActions from "../actions/customerActions";
import * as importerActions from "../../importer/actions/importerActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import CustomerDetailsTab from "./tabs/CustomerDetailsTab";
import CustomerImportTab from "./tabs/CustomerImportTab";
import CustomerSettingsTab from "./tabs/CustomerSettingsTab";
import TriggersTab from "./tabs/TriggersTab";
import CustomerTransfersTab from "./tabs/CustomerTransfersTab";
import NameEditorModal from "../../../components/common/NameEditorModal";
import CustomerVariablesEditModal from "./modals/CustomerVariablesEditModal";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

class CustomerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = this.prepInitialState();
  }

  componentDidMount() {
    this.getCustomerData();
  }

  getCustomerData = () => {
    this.props.actions.clear();
    if (this.state.customerId) {
      this.setState({ isLoading: true });
      this.props.actions
        .get(this.state.customerId)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ customer: this.getDefaultCustomer() });
    }
    this.getAdditionalData();
  };

  getDefaultCustomer = (additionalData) => {
    return {
      customerSettings: this.getDefaultSettings(additionalData),
    };
  };

  getDefaultSettings = (additionalData) => {
    let defaultTurvoEnv;
    if (
      additionalData &&
      additionalData.turvoEnvironments &&
      additionalData.turvoEnvironments.length > 0
    )
      defaultTurvoEnv = additionalData.turvoEnvironments[0].value;

    return {
      validationStrategies: { 0: true, 1: true, 2: true, 3: true },
      turvoEnvironment: defaultTurvoEnv,
      duplicateAction: 0,
      exportAction: 0,
    };
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      customer:
        nextProps.customers.customer ||
        this.getDefaultCustomer(nextProps.customers.additionalData),
    });
  }

  getAdditionalData = () => {
    this.props.actions.getAdditionalData();
  };

  handleSave = (e) => {
    this.setState({ isWorking: true });
    if (e) {
      e.preventDefault();
    }

    const customerData = this.state.customer;
    delete customerData.importers;
    delete customerData.settingsJson;
    delete customerData.updateDate;
    delete customerData.getOMSDateCorrectionValue;
    delete customerData.createDate;

    this.props.actions
      .save(this.state.customer)
      .then((data) => {
        this.props.notification.add("Customer saved", "", "success");
        if (!this.state.customerId) {
          this.props.history.push(`/customer/edit/${data.data}`);
          this.setState({ ...this.prepInitialState() });
        }
        this.getCustomerData();
      })
      .catch((ex) => {
        let errorMessage = "";
        if (ex.response && ex.response.data) {
          errorMessage = Object.values(ex.response.data)
            .map((a) => a[0])
            .join("\n");
        }
        this.props.notification.add(`${errorMessage}`, "Save Failed", "fail");
      })
      .finally(() => {
        this.setState({ isWorking: false });
      });
  };

  changeTab = () => {
    const { selectedTab, customerId } = this.state;
    this.state.customerId
      ? this.props.history.push(`/customer/edit/${customerId}/${selectedTab}`)
      : this.props.history.push(`/customer/new/${selectedTab}`);
  };

  prepInitialState() {
    return {
      customerId: this.props.match.params.customerId,
      isLoading: false,
      isWorking: false,
      customer: this.getDefaultCustomer(),
      selectedTab: this.props.match.params.tab,
      isCloneCustomerModalOpen: false,
      isVariablesEditModalOpen: false,
    };
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>
            {"SL3 - " +
              (this.state.customer && this.state.customer.name
                ? this.state.customer.name
                : "")}
          </title>
        </Helmet>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Customer edit</span>
              </h4>
            </div>

            <div className="heading-elements">
              <div className="heading-btn-group">
                <button
                  onClick={() => {
                    this.setState({ isVariablesEditModalOpen: true });
                  }}
                  className="btn bg-slate has-text"
                >
                  <FontAwesomeIcon
                    icon={icon({ name: "cog" })}
                    className="mr-5"
                  />
                  <span>Variables</span>
                </button>
                <button
                  disabled={this.state.isWorking || this.state.isLoading}
                  onClick={() =>
                    this.setState({ isCloneCustomerModalOpen: true })
                  }
                  className="btn bg-blue has-text"
                >
                  <FontAwesomeIcon
                    icon={icon({ name: "clone" })}
                    className="mr-5"
                  />
                  <span>Clone</span>
                </button>
                <button
                  disabled={this.state.isWorking || this.state.isLoading}
                  onClick={this.handleSave}
                  className="btn bg-blue has-text"
                >
                  {this.state.isWorking ? (
                    <FontAwesomeIcon
                      icon={icon({ name: "spinner" })}
                      className="mr-5"
                      spin
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={icon({ name: "check" })}
                      className="mr-5"
                    />
                  )}
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat relative">
          {this.state.isLoading ? <FormLoader /> : null}
          <div className="panel-body">
            <Tabs
              id="CustomerTabs"
              activeKey={this.state.selectedTab}
              onSelect={(key) =>
                this.setState({ selectedTab: key }, this.changeTab)
              }
            >
              <Tab eventKey={"details"} title="Details">
                <CustomerDetailsTab
                  customer={this.state.customer}
                  onChange={(set) =>
                    this.setState({
                      customer: {
                        ...this.state.customer,
                        ...set,
                      },
                    })
                  }
                  uploadFile={this.props.actions.uploadLogo}
                  notify={(error) => this.props.notification.add(error, "fail")}
                />
              </Tab>
              <Tab eventKey={"import"} title="Import">
                <CustomerImportTab
                  customerId={this.state.customerId}
                  reloadImporters={() => {
                    this.props.importerActions.listImporters({
                      customerId: this.state.customerId,
                    });
                  }}
                  history={this.props.history}
                  customer={this.state.customer}
                  additionalData={this.props.customers.additionalData}
                  onChange={(set) =>
                    this.setState({
                      customer: {
                        ...this.state.customer,
                        ...set,
                      },
                    })
                  }
                />
              </Tab>
              <Tab eventKey={"triggers"} title="Triggers">
                <TriggersTab
                  history={this.props.history}
                  customer={this.state.customer}
                />
              </Tab>
              <Tab eventKey={"transfers"} title="Transfers">
                <CustomerTransfersTab
                  history={this.props.history}
                  customer={this.state.customer}
                />
              </Tab>
              <Tab eventKey={"settings"} title="Settings">
                <CustomerSettingsTab
                  customerId={this.state.customerId}
                  customer={this.state.customer}
                  additionalData={this.props.customers.additionalData}
                  onChange={(set) =>
                    this.setState({
                      customer: {
                        ...this.state.customer,
                        customerSettings: {
                          ...this.state.customer.customerSettings,
                          ...set,
                        },
                      },
                    })
                  }
                />
              </Tab>
            </Tabs>
          </div>
        </div>
        <NameEditorModal
          title={"Clone customer"}
          onClose={() => this.setState({ isCloneCustomerModalOpen: false })}
          onConfirm={(name) => {
            this.setState({ isWorking: true });
            this.props.actions
              .cloneCustomer({
                customerId: this.state.customerId,
                customerName: name,
              })
              .then(() => {
                this.setState({
                  isWorking: false,
                  isCloneCustomerModalOpen: false,
                });
                this.props.notification.add("Customer saved", "", "success");
              })
              .catch(() => {
                this.setState({
                  isWorking: false,
                  isCloneCustomerModalOpen: false,
                });
                this.props.notification.add("Save failed", "", "fail");
              });
          }}
          show={this.state.isCloneCustomerModalOpen}
        />
        <CustomerVariablesEditModal
          onClose={() => this.setState({ isVariablesEditModalOpen: false })}
          variableDefinitions={
            this.state.customer && this.state.customer.customerSettings
              ? this.state.customer.customerSettings.variableDefinitions
              : null
          }
          onConfirm={(variables) => {
            this.setState(
              {
                customer: {
                  ...this.state.customer,
                  customerSettings: {
                    ...this.state.customer.customerSettings,
                    variableDefinitions: variables,
                  },
                },
              },
              () => {
                this.handleSave();
              }
            );
            this.setState({
              isWorking: false,
              isVariablesEditModalOpen: false,
            });
          }}
          show={this.state.isVariablesEditModalOpen}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { customers: state.customer, oidc: state.oidc };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(customerActions, dispatch),
    importerActions: bindActionCreators(importerActions, dispatch),
    notification: bindActionCreators(notificationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
