import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import ReactJson from "react-json-view";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as fileExportActions from "../../exportList/actions/exportListActions";
import connect from "react-redux/es/connect/connect";
import FormLoader from "../../../components/common/FormLoader";
import { TextArea } from '@radix-ui/themes';

class TriggerFilePreviewModal extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isLoading: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show && !this.props.show) {
            this.setState(this.getInitialState());

            if (nextProps.fileExportId)
                this.reloadFile(nextProps.fileExportId);

        }
    }

    reloadFile = (fileExportId) => {
        this.props.fileExportActions.clearFile();
        this.setState({ isLoading: true });
        this.props.fileExportActions.getFile(fileExportId).then(() => {
            this.setState({ isLoading: false });
        }).catch(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>File preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.isLoading
                        ? <FormLoader />
                        : null}
                    <form className="form-horizontal">
                        <TextArea rows={15} defaultValue={this.props.fileExports.file ? this.props.fileExports.file.content : ''}>

                        </TextArea>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bsStyle="success"
                        onClick={() => {
                            window.location.href = "/api/fileExport/downloadArchiveFile?fileImportId=" + this.props.fileExportId;
                        }}>Download</Button>
                    <Button
                        onClick={() => {
                            this
                                .props
                                .onClose()
                        }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


function mapStateToProps(state) {
    return {
        fileExports: state.exportList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        fileExportActions: bindActionCreators(fileExportActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TriggerFilePreviewModal)