import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FormLoader from "../../../components/common/FormLoader";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import DictionaryModal from "./DictionaryModal";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as dictionariesActions from "../actions/dictionariesActions";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import GuardedComponent from "../../../components/GuardedComponent";
import { Helmet } from "react-helmet";
import * as userRoles from "../../../constants/userRoles";
import { useAppDispatch } from "../../../utils/hooks";
import { DataNode, EventDataNode } from "rc-tree/es/interface";
import useGetDictionaryFolders from "../../../hooks/useGetDictionaryFolders";
import useGetDictionaries from "../../../hooks/useGetDictionaries";

const DictionariesBrowser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNode, setSelectedNode] =
    useState<EventDataNode<DataNode> | null>(null);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isDictionaryModalOpen, setIsDictionaryModalOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const dispatch = useAppDispatch();

  let history = useHistory();

  const [treeData, setTreeData] = useState<DataNode[]>([
    {
      key: "root",
      title: "Dictionaries",
      isLeaf: false,
      children: [],
    },
  ]);

  const { folders, mutate: mutateDictionaryFolders } = useGetDictionaryFolders({
    pageSize: null,
    page: null,
    sorting: [
      {
        id: "name",
        desc: false,
      },
    ],
  });

  const { dictionaries, mutate: mutateUseGetDictionaries } = useGetDictionaries(
    {
      pageSize: null,
      page: null,
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
    }
  );

  useEffect(() => {
    let treeFolder: DataNode[] = [];
    if (folders && folders.data && dictionaries && dictionaries.data) {
      treeFolder = folders.data
        .filter((a) => !a.parentFolderId)
        .map((f) => {
          return {
            key: f.id.toString(),
            title: f.name,
            isLeaf: false,
            children:
              dictionaries.data
                ?.filter((d) => d.folderId == f.id)
                .map((d) => {
                  return {
                    key: `${f.id}-${d.id}`,
                    title: d.name,
                    isLeaf: true,
                  };
                }) || [],
          };
        });
      folders?.data
        ?.filter((a) => a.parentFolderId)
        .forEach((f) => {
          let parentFolder = treeFolder.find((a) => a.key == f.parentFolderId);
          if (parentFolder) {
            parentFolder.children = [
              {
                key: f.id.toString(),
                title: f.name,
                isLeaf: false,
                children:
                  dictionaries.data
                    ?.filter((d) => d.folderId == f.id)
                    .map((d) => {
                      return {
                        key: `${f.id}-${d.id}`,
                        title: d.name,
                        isLeaf: true,
                      };
                    }) || [],
              },
              ...(parentFolder.children || []),
            ];
          }
        });
    }

    // @ts-ignore
    treeData.at(0).children = [...treeFolder];
    setTreeData([...treeData]);
    setIsLoading(false);
  }, [folders, dictionaries]);

  return (
    <div>
      <Helmet>
        <title>SL3 - Dictionaries</title>
      </Helmet>
      <div className="page-header page-header-default">
        <div className="page-header-content">
          <div className="page-title">
            <h4>
              <span className="text-semibold">Dictionaries</span>
            </h4>
          </div>

          <div className="heading-elements">
            <div className="heading-btn-group">
              {selectedNode &&
                !selectedNode.isLeaf &&
                selectedNode.key != "root" && (
                  <GuardedComponent allowedRoles={[userRoles.Admin]}>
                    <button
                      onClick={() => {
                        setShowDeleteConfirm(true);
                      }}
                      className="btn bg-danger has-text"
                    >
                      <i className="fa fa-times"></i>
                      <span>Remove</span>
                    </button>
                  </GuardedComponent>
                )}
              <button
                disabled={!(selectedNode && !selectedNode.isLeaf)}
                onClick={() => {
                  setIsFolderModalOpen(true);
                }}
                className="btn bg-blue has-text"
              >
                <i className="fa fa-plus"></i>
                <span>Folder</span>
              </button>
              {selectedNode &&
              !selectedNode.isLeaf &&
              selectedNode.key != "root" ? (
                <button
                  disabled={!(selectedNode && !selectedNode.isLeaf)}
                  onClick={() => {
                    setIsDictionaryModalOpen(true);
                  }}
                  className="btn bg-blue has-text"
                >
                  <i className="fa fa-plus"></i>
                  <span>Dictionary</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-flat relative">
        {isLoading ? <FormLoader /> : null}
        <div className="panel-body">
          <Tree
            onSelect={(e, v) => {
              console.log("onSelect", e, v);
              if (v.node.isLeaf) {
                history.push(
                  "/dictionaries/edit/" +
                    v.node.key.toString().split("-").at(-1)
                );
              } else {
                setSelectedNode(v.node);
              }
            }}
            className="myCls"
            showLine
            autoExpandParent
            defaultExpandAll
            defaultExpandParent
            treeData={treeData}
          ></Tree>
        </div>
      </div>
      <DictionaryModal
        showModal={isFolderModalOpen}
        onClose={() => {
          setIsDictionaryModalOpen(false);
        }}
        title={"Save folder"}
        onSave={(e: any) => {
          let folderModel = { ...e };
          if (selectedNode && selectedNode.key != "root") {
            folderModel.parentFolderId = selectedNode.key;
          }
          dispatch(dictionariesActions.saveFolder(folderModel))
            .then(() => {
              dispatch(notificationActions.add("Folder saved", "", "success"));
              mutateDictionaryFolders();
              mutateUseGetDictionaries();
            })
            .catch(() => {
              dispatch(
                notificationActions.add("Folder save failed", "", "fail")
              );
            })
            .finally(() => {
              setIsFolderModalOpen(false);
            });
        }}
      />
      {isDictionaryModalOpen ? (
        <DictionaryModal
          showModal={isDictionaryModalOpen}
          onClose={() => {
            setIsDictionaryModalOpen(false);
          }}
          title={"Save dictionary"}
          onSave={(e: any) => {
            let dictionaryModel = { ...e };
            if (selectedNode?.key !== "root") {
              dictionaryModel.folderId = selectedNode?.key;
            }
            dispatch(dictionariesActions.saveDictionary(dictionaryModel))
              .then(() => {
                dispatch(
                  notificationActions.add("Dictionary saved", "", "success")
                );
                mutateDictionaryFolders();
                mutateUseGetDictionaries();
              })
              .catch(() => {
                dispatch(
                  notificationActions.add("Dictionary save failed", "", "fail")
                );
              })
              .finally(() => {
                setIsDictionaryModalOpen(false);
              });
          }}
        />
      ) : null}
      {showDeleteConfirm && selectedNode && selectedNode.key !== "root" ? (
        <DeleteConfirmationModal
          title={"Delete folder confirmation"}
          description={
            "Are you sure you want to delete " +
            (selectedNode ? selectedNode.title : null) +
            "?"
          }
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            dispatch(
              dictionariesActions.removeFolder(
                selectedNode?.key.toString().split("-").at(-1) || ""
              )
            )
              .then(() => {
                dispatch(
                  notificationActions.add("Folder deleted", "", "success")
                );
                mutateDictionaryFolders();
                mutateUseGetDictionaries();
              })
              .catch(() => {
                dispatch(
                  notificationActions.add("Folder delete failed", "", "fail")
                );
              })
              .finally(() => {
                setShowDeleteConfirm(false);
              });
          }}
          show={showDeleteConfirm}
        />
      ) : null}
    </div>
  );
};

export default DictionariesBrowser;
