import React, {Component} from 'react'
import MicroTable from "../../../../components/common/MicroTable";
import getLabelByValue from "../../../../utils/enumHelper";
import * as importerTypes from "../../constants/importerTypes";
import DeleteConfirmationModal from "../../../../components/common/DeleteConfirmationModal";
import * as notificationActions from "../../../notifications/actions/notificationActions";
import {bindActionCreators} from "redux";
import * as customerActions from "../../actions/customerActions";
import * as importerActions from "../../../importer/actions/importerActions";
import {connect} from "react-redux";

class CustomerImportTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            showImporterDeleteConfirm: false,
            selectedItem: {},
        };
    }

    getImporters = () => {
        if(this.props.importers) return this.props.importers;
        if(this.props.customer && this.props.customer.importers) return this.props.customer.importers;
        return [];
    }

    render() {
        let addButtonStyle = {
            marginTop:"-10px",
            marginRight: "10px"
        }

        return (
            <div>
                <div className="form-group row">
                    <b className="table-top-heading">Importers</b>
                    <a className="btn btn-xs bg-blue has-text pull-right" style={addButtonStyle}
                       onClick={(e)=> {
                            e.preventDefault();
                            this.props.history.push(`/importer/edit${this.props.customerId ? `?customerId=${this.props.customerId}`: ''}`);
                        }}>
                        <i className="fa fa-plus"></i>
                        <span>Add</span>
                    </a>
                    <div className="col-sm-12">
                        <MicroTable
                            data={this.getImporters()}
                            noEdit
                            columns={[
                                { name: 'Name', id:'name', type:'text' },
                                {
                                    name: 'Importer type',
                                    id: 'importerType',
                                    type: 'custom',
                                    renderDisplay: d => <span>{getLabelByValue(importerTypes, d.importerType)}</span>
                                },
                                {
                                    name: 'Actions',
                                    id: 'actions',
                                    type: 'custom',
                                    width: '100',
                                    renderDisplay: d => <div>
                                        <a className="btn btn-xxs btn-primary" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push('/importer/edit/'+d.id);
                                        }}>Edit</a>
                                        &nbsp;
                                        <a className="btn btn-xxs btn-danger"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               this.setState({showImporterDeleteConfirm: true, selectedItem: d});
                                           }}>Delete</a>
                                    </div>
                                }
                            ]}
                            onChange={(data) => {}} />
                    </div>
                </div>
                <DeleteConfirmationModal
                    title={"Delete importer confirmation"}
                    description={"Are you sure you want to delete " + this.state.selectedItem.name + "?"}
                    onClose={() => this.setState({showDeleteConfirm: false})}
                    onConfirm={() => {
                        this
                            .props
                            .importerActions
                            .removeImporter(this.state.selectedItem.id, () => {
                                this.props.reloadImporters();
                                this.setState({showImporterDeleteConfirm: false});
                            })
                    }}
                    show={this.state.showImporterDeleteConfirm}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(customerActions, dispatch),
        importerActions: bindActionCreators(importerActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerImportTab)