import * as actionTypes from "./actionTypes";
import httpHandler from "../../../utils/httpHandler";

export function requestLogs(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/log/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.LOGS_RECEIVE, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}


export function clearLogs() {
    return (dispatch) => {
        dispatch({type: actionTypes.LOGS_CLEAR});
    };
}