import { ImporterObject } from "../../../../models/ImporterObject";

type ImporterTestsTabProps = {
  importer: ImporterObject;
};

const ImporterTestsTab = (props: ImporterTestsTabProps) => {
  return (
    <pre
      className="bg-black text-detroitblue-300 font-mono"
      style={{ fontSize: "16px" }}
    >
      {props.importer.mappingLastTestResult}
    </pre>
  );
};

export default ImporterTestsTab;
