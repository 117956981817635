import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { MetricsObject } from "../../../../models/Metrics/MetricsObject";
import { MetricsResultObject } from "../../../../models/Metrics/MetricsResultObject";

const defaultStackId = "main";

type Data = {
  name: string;
  [key: string]: unknown;
};

type BarChartWidgetProps = {
  editMode: boolean;
  widget: MetricsObject;
};

function BarChartWidget(props: BarChartWidgetProps) {
  const { series } = props.widget.dataSource;

  const generateData = (result: MetricsResultObject) => {
    const { series } = result;
    if (!series || series.length === 0) return null;

    let data: Data[] = [];

    series.forEach((s) => {
      // @ts-ignore
      Object.keys(s.data).forEach((date) => {
        // @ts-ignore
        let value = s.data[date];
        let dataNode = data.find((node) => node.name == date);
        if (!dataNode) {
          dataNode = { name: date };
          data.push(dataNode);
        }
        dataNode[s.key] = value;
      });
    });

    return data;
  };

  const barChartData = generateData(props.widget.result);

  return (
    <ResponsiveContainer debounce={300}>
      <BarChart data={barChartData ?? []}>
        <XAxis dataKey="name" />
        <Tooltip label="name" />
        {series?.map((s) => {
          return (
            <Bar
              stackId={defaultStackId}
              dataKey={s.name}
              fill={s.color}
              key={s.name}
            ></Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartWidget;
