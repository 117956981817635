import { useEffect, useState } from "react";
import * as importStates from "../constants/importStates";
import * as notificationActions from "../../notifications/actions/notificationActions";
import getLabelByValue from "../../../utils/enumHelper";
import displayDateTime from "../../../utils/dateTimeHelper";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory, useLocation } from "react-router";
import {
  Badge,
  Button,
  Callout,
  Checkbox,
  Select,
  Text,
  TextField,
  Tooltip,
} from "@radix-ui/themes";
import { useDebounceCallback } from "usehooks-ts";
import useGetCustomers from "../../../hooks/useGetCustomers";
import ImporterRequestObject from "../../../models/Requests/ImporterRequestObject";
import CustomerFileImportRequestObject from "../../../models/Requests/CustomerFileImportRequestObject";
import { FileImportObject } from "../../../models/FileImportObject";
import MegaTable from "../../../components/common/MegaTable";
import useGetCustomerFileImports from "../../../hooks/useGetCustomerFileImports";
import useGetCustomerImporters from "../../../hooks/useGetCustomerImporters";
import fetcherFile from "../../../fetchers/fetcherFile";
import { cancel } from "../actions/fileImportActions";
import { useDispatch } from "react-redux";
import { urlCreator, urlDelete } from "../../../utils/urlParams";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

const FileImportTable = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);

  const [fileNameValue, setFileNameValue] = useState(
    params.get("filename") ?? "",
  );

  const [customerIdValue, setCustomerIdValue] = useState(
    params.get("customerId") ?? "",
  );

  let columns = [
    {
      name: "File name",
      id: "originalFileName",
      type: "custom",
      renderDisplay: (d: FileImportObject) => (
        <span>
          <a href={"/api/fileImport/downloadArchiveFile?fileImportId=" + d.id}>
            {d.originalFileName}
          </a>
        </span>
      ),
    },
    {
      name: "Target",
      id: "target",
      type: "custom",
      renderDisplay: (d: FileImportObject) => (
        <span>
          {d.customerId ? (
            <span>
              <a href={`/customer/list/${d.customerId}`}>{d.customerName}</a>
              <FontAwesomeIcon
                className="mx-2"
                icon={icon({ name: "arrow-right" })}
              />
              <a href={`/importer/edit/${d.importerId}`}>{d.importerName}</a>
            </span>
          ) : null}
        </span>
      ),
    },
    {
      name: "Status",
      id: "status",
      type: "custom",
      renderDisplay: (d: FileImportObject) => (
        <div className="text-right">
          {d.importedRecords}/{d.totalRecords}{" "}
          {d.errorCount && d.errorCount > 0 ? (
            <span className="font-bold text-error-700">({d.errorCount})</span>
          ) : null}{" "}
          {getLabelByValue(importStates, d.importState)}
          &nbsp;
          {d.importState == 0 ? (
            <FontAwesomeIcon icon={icon({ name: "clock" })} />
          ) : null}
          {d.importState == 2 ? (
            <FontAwesomeIcon
              icon={icon({ name: "check", style: "solid" })}
              className="text-success-700"
              fixedWidth
            />
          ) : null}
          {d.importState == 4 ? (
            <FontAwesomeIcon
              icon={icon({ name: "remove", style: "solid" })}
              className="text-error-700"
              fixedWidth
            />
          ) : null}
          {d.importState == 6 ? (
            <FontAwesomeIcon
              icon={icon({ name: "ban", style: "solid" })}
              className="text-error-700"
              fixedWidth
            />
          ) : null}
          {d.error ? <span className="text-error-700">{d.error}</span> : null}
        </div>
      ),
    },
    {
      name: "Stats",
      id: "stats",
      type: "custom",
      renderDisplay: (d: FileImportObject) => (
        <div className="grid grid-cols-2 gap-2">
          <Tooltip content="Created">
            <Badge color="green" size="2" className="font-extrabold">
              C: {d.createdCount}
            </Badge>
          </Tooltip>
          <Tooltip content="Updated">
            <Badge color="plum" size="2" className="font-extrabold">
              U: {d.processingCount}
            </Badge>
          </Tooltip>
          <Tooltip content="Ignored">
            <Badge color="cyan" size="2" className="font-extrabold">
              I: {d.ignoredCount}
            </Badge>
          </Tooltip>
          <Tooltip content="Errors">
            <Badge color="red" size="2" className="font-extrabold">
              E: {d.errorCount}
            </Badge>
          </Tooltip>
        </div>
      ),
    },
    {
      name: "User",
      id: "userName",
      type: "custom",
      renderDisplay: (d: FileImportObject) => (
        <span>
          {d.userId ? (
            <>
              <FontAwesomeIcon icon={icon({ name: "user" })} className="mr-2" />{" "}
              {d.userName}
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={icon({ name: "robot" })}
                className="mr-2"
              />{" "}
              system
            </>
          )}
        </span>
      ),
    },
    {
      name: "Date time",
      id: "dateTime",
      type: "custom",
      renderDisplay: (d: FileImportObject) => (
        <div className="tabular-nums">
          {d.startedDate ? (
            <span>
              {displayDateTime(d.startedDate)}
              {d.finishDate ? (
                <span>
                  {" "}
                  <FontAwesomeIcon icon={icon({ name: "arrow-right" })} />{" "}
                  {displayDateTime(d.finishDate, "hh:mm:ss A")}
                </span>
              ) : null}
            </span>
          ) : (
            <span>{displayDateTime(d.createDate)}</span>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      id: "Actions",
      type: "custom",
      renderDisplay: (d: FileImportObject) => {
        return (
          <div>
            {[
              importStates.InProgress,
              importStates.InQueue,
              importStates.Pending,
            ].some((el) => el === d.importState) && (
              <Button
                variant="soft"
                color="amber"
                className="mr-2 w-52"
                onClick={() => cancelOrder(d.id)}
              >
                <FontAwesomeIcon
                  icon={icon({ name: "cancel" })}
                  className="mr-2"
                />
                Cancel
              </Button>
            )}
            {d.importState === 2 &&
              !d.dictionaryId &&
              d.importerObjectType === 0 && (
                <Button
                  variant="soft"
                  color="gray"
                  asChild
                  className="mr-2 w-52"
                >
                  <a target="_blank" href={`/order?fileImportId=${d.id}`}>
                    <FontAwesomeIcon
                      icon={icon({ name: "receipt" })}
                      className="mr-2"
                    />
                    Orders
                  </a>
                </Button>
              )}

            {d.importState === 2 &&
              (d.importerObjectType === 1 || d.processingCount > 0) && (
                <Button
                  variant="soft"
                  color="cyan"
                  asChild
                  className="mr-2 w-52"
                >
                  <a target="_blank" href={`/updates?fileImportId=${d.id}`}>
                    <FontAwesomeIcon
                      icon={icon({ name: "table-list" })}
                      className="mr-2"
                    />
                    Updates
                  </a>
                </Button>
              )}
            {(d.errorCount > 0 || d.error) && (
              <Button
                variant="soft"
                color="crimson"
                className="mr-2 w-52"
                onClick={() => getErrorFile(d.id)}
              >
                <FontAwesomeIcon icon={icon({ name: "download" })} />
                Export Errors
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setFileNameValue(params.get("filename") ?? "");
    setCustomerIdValue(params.get("customerId") ?? "");
    setCustomerFileImportsRequest({
      ...customerFileImportsRequest,
      page: parseInt(params.get("page") ?? "1"),
      pageSize: parseInt(params.get("pageSize") ?? "25"),
      search: params.get("filename") ?? "",
      customerId: parseInt(params.get("customerId") ?? ""),
      importerId: parseInt(params.get("importerId") ?? ""),
      startDate: params.get("startDate") ?? null,
      endDate: params.get("endDate") ?? null,
    });
    setCustomerImports({
      ...customerImports,
      customerId: parseInt(params.get("customerId") ?? ""),
    });
  }, [history.location]);

  const cancelOrder = (id: number) => {
    if (window.confirm("Are you sure you want to cancel this import?")) {
      const canceling = cancel(id);
      canceling()
        .then(() => {
          dispatch(notificationActions.add("Import cancelled", "", "success"));
        })
        .catch((error) => {
          dispatch(
            notificationActions.add("An error has occurred", error, "fail"),
          );
        });
    }
  };

  const getErrorFile = (id: number) => {
    fetcherFile(`/api/fileImport/downloadErrorsFile?fileImportId=${id}`);
  };

  const [customerImports, setCustomerImports] = useState<ImporterRequestObject>(
    {
      page: null,
      pageSize: null,
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
      common: false,
      customerId: null,
    },
  );

  const [customerFileImportsRequest, setCustomerFileImportsRequest] =
    useState<CustomerFileImportRequestObject>({
      page: parseInt(params.get("page") ?? "1"),
      pageSize: parseInt(params.get("pageSize") ?? "25"),
      search: params.get("filename") ?? "",
      customerId: parseInt(params.get("customerId") ?? ""),
      importerId: parseInt(params.get("importerId") ?? ""),
      startDate: params.get("startDate") ?? null,
      endDate: params.get("endDate") ?? null,
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
      isTestRun: false,
      created: params.get("created") === "false" ? false : true,
      updated: params.get("updated") === "false" ? false : true,
      ignored: params.get("ignored") === "false" ? false : true,
      errors: params.get("errors") === "false" ? false : true,
    });

  useEffect(() => {
    setCustomerFileImportsRequest({
      ...customerFileImportsRequest,
      search: fileNameValue,
    });
    const url = urlCreator(history.location, "filename", fileNameValue);
    if (fileNameValue !== "" || params.has("filename")) {
      history.push(url);
    }
  }, [fileNameValue]);

  const { customers } = useGetCustomers({
    page: null,
    pageSize: null,
    sorting: [
      {
        id: "name",
        desc: false,
      },
    ],
  });

  const { importList } = useGetCustomerImporters(customerImports);

  const {
    customerFileImports,
    customerFileImportsIsLoading,
    customerFileImportsMutate,
    customerFileError,
  } = useGetCustomerFileImports(customerFileImportsRequest);

  const customerSelect = (e: string) => {
    setCustomerImports({
      ...customerImports,
      customerId: parseInt(e),
    });
    setCustomerFileImportsRequest({
      ...customerFileImportsRequest,
      customerId: parseInt(e),
    });
    if (e !== "0") {
      const url = urlCreator(history.location, "customerId", e);
      history.push(url);
    } else {
      const url = urlDelete(history.location, ["customerId", "importerId"]);
      history.push(url);
    }
  };

  const importerSelect = (e: string) => {
    setCustomerFileImportsRequest({
      ...customerFileImportsRequest,
      importerId: parseInt(e),
    });
    const url = urlCreator(history.location, "importerId", e);
    history.push(url);
  };

  const currentPageChanged = (pageSize: string) => {
    const url = urlCreator(history.location, "page", pageSize);
    history.push(url);
  };

  const filterChanged = (
    key: keyof CustomerFileImportRequestObject,
    value: string | boolean | number,
  ) => {
    setCustomerFileImportsRequest({
      ...customerFileImportsRequest,
      [key]: value,
    });
    const url = urlCreator(history.location, key, value.toLocaleString());
    history.push(url);
  };

  const resetFilters = () => {
    setCustomerFileImportsRequest({
      ...customerFileImportsRequest,
      search: "",
      customerId: null,
      importerId: null,
      startDate: null,
      endDate: null,
      created: true,
      updated: true,
      ignored: true,
      errors: true,
      isTestRun: false,
    });
    history.push(history.location.pathname);
  };

  const debounced = useDebounceCallback(setFileNameValue, 500);

  return (
    <div>
      <Helmet>
        <title>SL3 - Imports</title>
      </Helmet>
      <div className="page-header page-header-default">
        <div className="page-header-content">
          <div className="page-title">
            <h4>
              <span className="text-semibold">Imports </span>
            </h4>
          </div>
        </div>
      </div>
      <div className="panel panel-flat">
        <div className="panel-body">
          {customerFileError ? (
            <div className="flex mb-3">
              <Callout.Root size="3" color="ruby" className="w-full">
                <Callout.Icon>
                  <ExclamationTriangleIcon />
                </Callout.Icon>
                <Callout.Text>
                  {customerFileError ? customerFileError : ""}
                </Callout.Text>
              </Callout.Root>
            </div>
          ) : null}
          <div className="flex gap-4 items-end">
            <div className="flex-auto">
              <label htmlFor="filename">Filename or VIN</label>
              <TextField.Root className="w-full" key={fileNameValue}>
                <TextField.Input
                  placeholder="Filename or VIN"
                  defaultValue={fileNameValue}
                  onChange={(event) => debounced(event.target.value)}
                  size="3"
                  id="filename"
                />
              </TextField.Root>
            </div>
            <div className="flex-auto">
              <label htmlFor="customer">Pick a Customer</label>
              <Select.Root
                size="3"
                onValueChange={customerSelect}
                value={customerIdValue}
              >
                <Select.Trigger
                  className="w-full"
                  placeholder="Pick a Customer"
                  id="customer"
                />
                <Select.Content position="popper">
                  <Select.Item value="0"></Select.Item>
                  {customers?.data?.map((customer) => (
                    <Select.Item
                      value={customer.id.toString()}
                      key={customer.id}
                    >
                      {customer.name}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </div>
            <div className="flex-auto">
              <label htmlFor="importer">Pick an Importer</label>
              <Select.Root
                size="3"
                onValueChange={importerSelect}
                value={params.get("importerId") ?? ""}
              >
                <Select.Trigger
                  placeholder="Pick an Importer"
                  id="importer"
                  className="w-full"
                />
                <Select.Content position="popper">
                  <Select.Item value="0"></Select.Item>
                  {importList?.data?.map((importer) => (
                    <Select.Item
                      value={importer.id.toString()}
                      key={importer.id}
                    >
                      {importer.name}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </div>
            <div className="shrink grid grid-cols-2 gap-2">
              <Text size="3" className="mb-2" as="label">
                <Checkbox
                  checked={customerFileImportsRequest.created}
                  className="mr-2"
                  onCheckedChange={(e) => {
                    filterChanged("created", !!e);
                  }}
                  color="green"
                />
                Created
              </Text>
              <Text size="3" className="mb-2" as="label">
                <Checkbox
                  checked={customerFileImportsRequest.updated}
                  className="mr-2"
                  onCheckedChange={(e) => {
                    filterChanged("updated", !!e);
                  }}
                  color="purple"
                />
                Updated
              </Text>
              <Text size="3" className="mb-2" as="label">
                <Checkbox
                  checked={customerFileImportsRequest.ignored}
                  className="mr-2"
                  onCheckedChange={(e) => {
                    filterChanged("ignored", !!e);
                  }}
                  color="cyan"
                />
                Ignored
              </Text>
              <Text size="3" className="mb-2" as="label">
                <Checkbox
                  checked={customerFileImportsRequest.errors}
                  className="mr-2"
                  onCheckedChange={(e) => {
                    filterChanged("errors", !!e);
                  }}
                  color="red"
                />
                Errors
              </Text>
            </div>
            <div className="flex flex-col">
              <label htmlFor="startDate">Start Date</label>
              <TextField.Root className="grow w-full">
                <TextField.Input
                  onChange={(e) => filterChanged("startDate", e.target.value)}
                  size="3"
                  id="startDate"
                  type="date"
                  value={params.get("startDate") ?? ""}
                />
              </TextField.Root>
            </div>
            <div className="flex flex-col">
              <label htmlFor="endDate">End Date</label>
              <TextField.Root className="grow w-full">
                <TextField.Input
                  onChange={(e) => filterChanged("endDate", e.target.value)}
                  size="3"
                  id="endDate"
                  type="date"
                  value={params.get("endDate") ?? ""}
                />
              </TextField.Root>
            </div>

            <div>
              <Text size="3" className="mb-2" as="label">
                <Checkbox
                  className="mr-2"
                  onCheckedChange={(e) => {
                    filterChanged("isTestRun", !!e);
                  }}
                  color="amber"
                  checked={customerFileImportsRequest.isTestRun}
                />
                Test Run Only
              </Text>
            </div>
            <Tooltip content="Refresh Data" delayDuration={250}>
              <Button
                size="3"
                variant="surface"
                color="indigo"
                onClick={() => {
                  customerFileImportsMutate();
                }}
              >
                <FontAwesomeIcon fixedWidth icon={icon({ name: "refresh" })} />
              </Button>
            </Tooltip>
            <Tooltip content="Reset Filters" delayDuration={250}>
              <Button
                size="3"
                variant="surface"
                color="amber"
                onClick={() => {
                  resetFilters();
                }}
              >
                <FontAwesomeIcon
                  fixedWidth
                  icon={icon({ name: "filter-circle-xmark" })}
                />
              </Button>
            </Tooltip>

            <div className="">
              <label htmlFor="pageSize">Show</label>
              <Select.Root
                size="3"
                onValueChange={(e) => {
                  filterChanged("pageSize", e);
                }}
                value={params.get("pageSize") ?? "25"}
              >
                <Select.Trigger id="pageSize" className="w-full" />
                <Select.Content>
                  <Select.Item value="10">10</Select.Item>
                  <Select.Item value="25">25</Select.Item>
                  <Select.Item value="50">50</Select.Item>
                  <Select.Item value="100">100</Select.Item>
                </Select.Content>
              </Select.Root>
            </div>
          </div>
        </div>
        <MegaTable
          hideHeader
          editMode={false}
          columns={columns}
          isWorking={customerFileImportsIsLoading}
          summary={
            customerFileImports
              ? {
                  skip: customerFileImports.skip,
                  take: customerFileImports.take,
                  total: customerFileImports.total,
                  totalPages: customerFileImports.totalPages,
                }
              : null
          }
          page={customerFileImportsRequest.page}
          pageChanged={(page: number) => {
            if (page < 1) page = 1;
            currentPageChanged(page.toString());
          }}
          data={customerFileImports?.data ?? []}
        />
      </div>
    </div>
  );
};

export default FileImportTable;
