import React, {Component, Fragment} from 'react';
import {Responsive, WidthProvider} from 'react-grid-layout';
import * as actions from '../actions/dashboardActions';
import * as notification from '../../notifications/actions/notificationActions';
import metricsTypes from '../constants/metricsType';
import AddWidgetModal from './AddWidgetModal';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Helmet} from 'react-helmet';
import DashboardNotificationsModal from "./DashboardNotificationsModal";


const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashboardMain extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            widgets: [],
            addModalShown: false,
            notificationSettingsShown: false,
            isWorking: false,
            isRefreshing: false,
            selectedWidget: null
        };
    }

    componentDidMount() {
        this.reloadWidgets();
    }

    reloadWidgets = () => {
        this.setState({isWorking: true});
        this.props.actions.listMetrics({}).then().catch().then(() => {
            this.setState({isWorking: false});
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.widgets && nextProps.widgets.data)
            this.setState({widgets: nextProps.widgets.data});
    }

    openSettings = () => {
        this.setState({editMode: true});
    };

    closeSettings = () => {
        this.setState({editMode: false});
    };

    generateLayout = () => {
        let layouts = this.state.widgets.map((widget, index) => {
            let metricsType = metricsTypes.find((t) => t.value == widget.type);
            return {...metricsType.defaultSize, i: widget.id.toString()}
        });

        console.log(layouts);
        return layouts;
    }

    addWidget = () => {
        this.setState({addModalShown: true, selectedWidget: null});
    }

    showNotificationsSettings = () => {
        this.setState({notificationSettingsShown: true});
    }

    editWidget = (widget) => {
        this.setState({addModalShown: true, selectedWidget: widget});
    }

    saveWidget = (data, callback) => {
        this.props.actions.saveMetrics(data)
            .then((response) => {
                this
                    .props
                    .notification
                    .add("Widget saved", "", "success");
                this.setState({addModalShown: false});
            }).catch(() => {
            this
                .props
                .notification
                .add("Widget save failed", "", "fail")
        }).then(() => {
            callback();
        });
    };

    removeWidget = (key) => {
        this.props.actions.clearMetrics();
        this.setState({controls: this.state.controls.filter(el => el.key !== key)});
    }

    refreshAll = () => {
        this.setState({isRefreshing: true});
        this.props.actions.refreshAllMetrics({}).then(() => {
            this.props.actions.listMetrics({}).then().catch().then(() => {
                this.setState({isRefreshing: false});
            });
        }).catch(() => {
            this.setState({isRefreshing: false});
        });
    }

    isWidgetWarning = (widget) => {
        if (widget.result && widget.result.series && widget.result.series.some(s => s.state == 1))
            return true;

        return false;
    }

    deleteWidget = (widget) => {
        this.props.actions.deleteMetrics(widget.id)
            .then(()=>{
                this
                    .props
                    .notification
                    .add("Widget deleted", "", "success");
            }).catch(()=>{
                this
                    .props
                    .notification
                    .add("Widget delete failed", "", "fail")
        }).then(()=>{
            this.reloadWidgets();
        });
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>SL3 - Dashboard</title>
                </Helmet>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Dashboard</span>
                            </h4>
                        </div>
                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <a onClick={this.refreshAll}
                                   className={'btn bg-blue has-text ' + (this.state.isRefreshing && 'disabled')}>
                                    {this.state.isRefreshing ? <i className="fa fa-spinner fa-spin fa-fw"></i> :
                                        <i className="fa fa-refresh"/>}
                                    <span>Refresh</span>
                                </a>
                                {this.state.editMode ? (
                                    <Fragment>
                                        <a onClick={this.addWidget} className="btn bg-blue has-text">
                                            <i className="fa fa-plus"/>
                                            <span>Add widget</span>
                                        </a>
                                        <a onClick={this.showNotificationsSettings} className="btn bg-blue has-text">
                                            <i className="fa fa-envelope"/>
                                            <span>Notifications</span>
                                        </a>
                                        <a onClick={this.closeSettings} className="btn bg-orange has-text">
                                            <i className="fa fa-close"/>
                                            <span>Cancel</span>
                                        </a>
                                    </Fragment>
                                ) : (
                                    <a onClick={this.openSettings} className="btn bg-blue has-text">
                                        <i className="fa fa-gear"/>
                                        <span>Configure</span>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.widgets.length > 0 ? <ResponsiveReactGridLayout
                    className="layout"
                    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                    rowHeight={20}
                    width={1200}
                    compactType={null}
                    draggableHandle='.panel-heading'
                    onLayoutChange={(layout, layouts) => {
                        let metricsLayouts = layout.map((l) => {
                            return {
                                id: parseInt(l.i),
                                position: JSON.stringify({
                                    x: l.x,
                                    y: l.y,
                                    h: l.h,
                                    w: l.w
                                })
                            };
                        });

                        this.props.actions.updateLayout(metricsLayouts);
                    }}
                >
                    {this.state.widgets.map((widget, index) => {
                        let isWarning = this.isWidgetWarning(widget);
                        let metricsType = metricsTypes.find((t) => t.value == widget.type);

                        let widgetPosition = widget.position || metricsType.defaultSize;
                        let grid = {...widgetPosition, static: false};

                        return <div className={'panel panel-flat dashboard-widget' + (isWarning && ' warning-widget')}
                                    key={widget.id} data-grid={grid}>
                            <div className="selector-container">
                                {this.state.isRefreshing && <div className='table-loader'/>}
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-md-9">
                                            <h6 className="panel-title">{widget.title}
                                                {this.state.isRefreshing &&
                                                <i className="fa fa-spinner fa-spin fa-fw"></i>}</h6>
                                        </div>
                                        <div className="col-md-3">
                                            {this.state.editMode && (
                                                <span>
                                                    <a onClick={()=>this.deleteWidget(widget)}
                                                       className="btn-xss bg-danger pull-right">
                                                        <i className="fa fa-close"/>
                                                    </a>
                                                    <a onClick={() => {
                                                        this.editWidget(widget)
                                                    }}
                                                       className="btn-xss bg-primary pull-right">
                                                        <i className="fa fa-pencil"/>
                                                    </a>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-body panel-fullsize">
                                    {React.cloneElement(metricsType.component, {
                                        editMode: this.state.editMode,
                                        widget: widget,
                                    })}
                                </div>
                            </div>
                        </div>;
                    })}
                </ResponsiveReactGridLayout> : (this.state.isWorking ?
                    <h1 style={{marginTop: '15%'}} className="text-center text-grey top-divided"><i
                        className="fa fa-spinner fa-spin fa-fw fa-2x"></i></h1> :
                    <h1 style={{marginTop: '15%'}} className="text-center text-grey top-divided">Add widgets via
                        settings</h1>)}

                <AddWidgetModal show={this.state.addModalShown}
                                widget={this.state.selectedWidget}
                                onClose={() => {
                                    this.setState({addModalShown: false});
                                }}
                                onSave={this.saveWidget}
                />
                <DashboardNotificationsModal show={this.state.notificationSettingsShown} onClose={() => {
                    this.setState({notificationSettingsShown: false});
                }}/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        widgets: state.dashboard.widgets
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        notification: bindActionCreators(notification, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMain)