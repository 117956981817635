import { WebStorageStateStore } from "oidc-client";
import { createUserManager } from 'redux-oidc';

export const AUTH_CONFIG = {
    client_id: 'sl3',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/oidc-callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
    response_type: 'token id_token',
    scope: 'openid profile',
    authority: 'https://auth.loadrpm.com',
    silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: localStorage })
};

const userManager = createUserManager(AUTH_CONFIG);
export default userManager;
