import { User } from "oidc-client";
import { AUTH_CONFIG } from "./userManager";

const getUserInformation = JSON.parse(
  localStorage.getItem(
    `oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`
  ) ?? "{}"
) as User;

export default getUserInformation;
