import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';

export default class ValidationStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    renderDetails = (model) =>{
        return (<div>
            <ul className="ul-clear">
                {Object.entries(model.Errors).map(([key, value])=>{
                    return (<li key={value.Key}>{value.ErrorMessage}</li>);
                })}
            </ul>
        </div>);
    }

    render() {

        let { model } = this.props;

        return (
            <div>
                <b>Is valid: </b>{model.IsValid?"true":"false"}
                <br/>
                {this.state.expanded?this.renderDetails(model):null}
                <a
                    onClick={(e)=>{ e.preventDefault(); this.setState({expanded: !this.state.expanded}); }}>
                    Details
                    &nbsp;
                    {this.state.expanded?<i className="fa fa-arrow-up"/>:<i className="fa fa-arrow-down"/>}
                </a>
            </div>
        )
    }
}