import * as actionTypes from '../constants/actionTypes';

const initialState = {

};

export default(state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHIPMENT_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.SHIPMENT_LIST_CLEAR:
            return {
                ...state,
                list: null
            };
        case actionTypes.SHIPMENT_GET_DETAILS:
            return {
                ...state,
                shipment: action.payload
            };
        case actionTypes.SHIPMENT_CLEAR_DETAILS:
            return {
                ...state,
                shipment: null
            };
        case actionTypes.SHIPMENT_GET_VISUAL_TRACKING:
            return {
                ...state,
                visualTrackingItems: action.payload
            };
        case actionTypes.SHIPMENT_CLEAR_VISUAL_TRACKING:
            return {
                ...state,
                visualTrackingItems: null
            };
        default:
            return state;
    }
};