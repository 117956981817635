export const Processing = 0;
export const ValidationFailed = 1;
export const Error = 2;
export const MappingDone = 3;
export const ApiCreated = 4;
export const Duplicated = 5;
export const Updated = 6;
export const Ignored = 7;
export const Active = 8; //UI label rename, actual state: Pending
export const Assigned = 9;
export const Pq = 10;
export const PqCompleted = 11;
export const Cancelled = 12;
export const Transport = 13;
export const Delivered = 14;
export const OnHold = 15;
export const Pending = 16; //UI label rename, actual state: Invisible