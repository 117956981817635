import ReactDOM from "react-dom";
import App from "./App";
import * as Oidc from "oidc-client";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { store, history } from "./store/store";
import userManager from "./utils/userManager";
import { Theme } from "@radix-ui/themes";
import { ConnectedRouter } from "connected-react-router";

Oidc.Log.logger = console;

ReactDOM.render(
  <Provider store={store}>
    {/*@ts-ignore*/}
    <OidcProvider store={store} userManager={userManager}>
      <Theme radius="medium">
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Theme>
    </OidcProvider>
  </Provider>,
  document.getElementById("root"),
);
