import React, { Component } from "react";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import * as signalR from "@microsoft/signalr";
import { store } from "../../../store/store";
import MicroTable from "../../../components/common/MicroTable";

let connection;

class ReprocessProgressModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processed: 0,
      details: [],
      loading: true,
    };
  }

  getToken = () => {
    let state = store.getState();
    if (state.oidc && state.oidc.user) {
      return state.oidc.user.access_token;
    }

    return "";
  };

  componentDidMount() {
    connection = new signalR.HubConnectionBuilder()
      .withUrl("/api/reprocessinghub", {
        accessTokenFactory: () => {
          return this.getToken();
        },
      })
      .build();
    connection.start().then(() => {
      connection
        .invoke(
          "sendMessage",
          this.props.orders.map((el) => el.id)
        )
        .then(() => {
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        });
      connection.on("ReprocessedOrders", (data) => {
        this.setState({
          processed: data.processed,
          errorDetails: data.errorDetails,
          loading: true,
        });
      });
    });
  }

  componentWillUnmount() {
    connection.stop();
    connection = null;
  }

  render() {
    const { show, onClose, orders } = this.props;
    let microtable;
    let progress;
    if (this.state.loading)
      progress = (
        <Modal.Title>
          Reprocess progress <i className="fa fa-refresh fa-spin"></i>
        </Modal.Title>
      );
    else progress = <Modal.Title>Reprocesed </Modal.Title>;

    if (this.state.errorDetails && this.state.errorDetails.length > 0)
      microtable = (
        <MicroTable
          data={this.state.errorDetails}
          noEdit
          columns={[
            {
              name: "OrderId",
              id: "orderId",
              type: "custom",
              renderDisplay: (d) => (
                <span>
                  <a href={`/order/details/${d.id}`} target="_blank">
                    {d.id}
                  </a>
                </span>
              ),
            },
            {
              name: "State",
              id: "state",
              type: "custom",
              renderDisplay: (d) => (
                <span>
                  {d.isSuccess ? "Success" : "Failed"}
                  {d.isSuccess ? (
                    <i className="fa fa-check color-green" />
                  ) : (
                    <i className="fa fa-remove color-red" />
                  )}
                </span>
              ),
            },
            {
              name: "State",
              id: "state",
              type: "custom",
              renderDisplay: (d) => <span>{d.message}</span>,
            },
          ]}
        />
      );
    return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>{progress}</Modal.Header>
        <Modal.Body>
          <ProgressBar
            bsStyle="success"
            min={0}
            max={orders.length}
            now={this.state.processed}
            label={`${this.state.processed}/${orders.length}`}
            active
          />
          {microtable}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export { ReprocessProgressModal as default };
