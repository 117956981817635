import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import FlagsTable from './components/FlagsTable';

const Flags = () => (
    <Switch>
        <Route path="/flags" component={FlagsTable} />
    </Switch>
);

export { Flags as default };
