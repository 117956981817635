import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "../reducers/reducer";
import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";

export const history = createBrowserHistory();

const myRouterMiddleware = routerMiddleware(history);

export const store = configureStore({
  reducer: rootReducer(history),
  middleware: [thunk, myRouterMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
