import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as notificationActions from '../../notifications/actions/notificationActions';
import * as dictionariesActions from '../actions/dictionariesActions';
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import ErrorLabel from "../../../components/ErrorLabel";
import UploadButton from "../../../components/common/UploadButton";

class DictionaryFileImportModal extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            isWorking: false,
            isLoading: false,
            errors: {},
            uploadFile: {},
            fileUploaded: false
        }
    }

    handleClose = () => {
        this.props.onClose();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showModal && !this.props.showModal) {
            this.setState(this.getInitialState());
        }
    }

    componentDidMount() {

    }

    handleSave = (e) => {
        e.preventDefault();
        this.validate(() => {
            this.setState({ isWorking: true });
            this.props.dictionariesActions
                .addFileToQueue({ dictionaryId: this.props.dictionaries.dictionary.id, uploadFile: this.state.uploadFile })
                .then((response) => {
                    this.setState({ isWorking: false, fileUploaded: true });
                    this
                        .props
                        .notification
                        .add("File added to queue", "", "success")
                })
                .catch((error) => {
                    this
                        .props
                        .notification
                        .add("An error has occurred", "", "fail")
                });
        });
    }

    validate = (success) => {
        let errors = {};

        if (!this.state.uploadFile.name)
            errors["fileUpload"] = "File not selected";
        this.setState({
            errors: errors
        }, () => {
            let hasErrors = !(Object.keys(this.state.errors).length === 0);
            if (!hasErrors)
                success();
        });
    }

    getError = (key) => {
        return this.state.errors[key];
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Import file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <b>File</b>
                        <div>
                            <UploadButton onFileChanged={(files) => {
                                this.setState({ uploadFile: files[0] });
                            }} btnClass={"btn btn-xs btn-success"} />
                            {this.state.uploadFile ? <span>{this.state.uploadFile.name}</span> : null}
                        </div>
                        <ErrorLabel error={this.getError("fileUpload")} />
                    </div>
                    {this.state.fileUploaded ? <div class="alert alert-success margin-top-10">
                        <strong>Success!</strong> File added to queue
                    </div> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleClose}>Close</Button>
                    <button
                        disabled={this.state.isWorking || this.state.fileUploaded}
                        onClick={this.handleSave}
                        className="btn bg-blue has-text">
                        {this.state.isWorking
                            ? <i className="fa fa-spinner fa-spin fa-fw"></i>
                            : <i className="fa fa-check"></i>}
                        <span>Import</span>
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return { dictionaries: state.dictionaries }
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        dictionariesActions: bindActionCreators(dictionariesActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryFileImportModal)