import React, { Component } from "react";
import debounce from "debounce";
import PhotoUploader from "../../../../components/common/PhotoUploader";

export default class CustomerDetailsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getInitialState(this.props),
    };
  }

  handleChange = (set) => {
    this.setState(set, () => {
      debounce(() => {
        this.props.onChange(this.state);
      }, 300)();
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState(this.getInitialState(nextProps));
  }

  getInitialState = (props) => {
    let customer = props.customer || {};
    return {
      name: customer.name || "",
      dba: customer.dba || "",
      turvoCustomerId: customer.turvoCustomerId || "",
      imageUrl: customer.imageUrl || "",
    };
  };

  uploadLogo = (formData) => {
    this.props.uploadFile(formData).then((response) => {
      this.handleChange({ imageUrl: response.data });
    });
  };

  render() {
    return (
      <div>
        <form className="form-horizontal">
          <div className="form-group">
            <label className="col-sm-1 control-label">Name</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => this.handleChange({ name: e.target.value })}
                required
                value={this.state.name}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-1 control-label">Dba</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => this.handleChange({ dba: e.target.value })}
                value={this.state.dba}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-1 control-label">Logo</label>
            <div className="col-sm-8">
              <PhotoUploader
                notify={this.props.notify}
                imageUrl={this.state.imageUrl}
                uploadFile={this.uploadLogo}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
