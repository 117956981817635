import React, {Component} from 'react'

export default class AuthPlaceholder extends Component {
    render() {
        return (
            <div>
                <div className="panel panel-body login-form">
                    <div className="text-center">
                        <h5 className="content-group">Authorization in progress
                            <small className="display-block">You will be redirected to login server</small>
                        </h5>
                        <i className="fa fa-refresh fa-spin fa-2x"></i>
                    </div>
                </div>
            </div>
        )
    }
}
