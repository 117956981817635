import React, { Component } from 'react'
import * as logActions from './logActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import displayDateTime from '../../../utils/dateTimeHelper';
import FormLoader from '../FormLoader';
import MegaTable from '../MegaTable';

const logLevelIcons = {
    0: (<i className="fa fa-wrench" />),
    1: (<i className="fa fa-info-circle color-blue" />),
    2: (<i className="fa fa-warning" />),
    3: (<i className="fa fa-times-circle-o color-red" />)
};

const columns = [
    {
        name: 'Date',
        id: 'dateTime',
        type: 'custom',
        width: '190',
        renderDisplay: d => <span>
            {displayDateTime(d.createDate)}
        </span>
    },
    {
        name: 'Message',
        id: 'message',
        type: 'custom',
        renderDisplay: d => <span>
            {logLevelIcons[d.logLevel]}
            &nbsp;
            {d.data}
        </span>
    }];

class LogHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isWorking: false,
            page: 1,
            pageSize: 10,
            search: '',
        };
    }

    getItems = (props) => {
        this.setState({ isLoading: true });
        this.props.actions.clearLogs();
        this
            .props
            .actions
            .requestLogs({ ...this.state, ...props.args })
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(() => {
                this.setState({ isLoading: false })
            });
    };

    componentDidMount() {
        this.getItems(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(nextProps.args) !== JSON.stringify(this.props.args)) {
            this.getItems(nextProps);
        }
    }

    render() {
        const { log } = this.props;
        return (
            <div className="relative">
                {this.state.isLoading
                    ? <FormLoader />
                    : null}
                <b>{this.props.logsName}</b>
                <MegaTable
                    editMode={false}
                    columns={this.props.columns || columns}
                    onRefresh={() => {
                        this.getItems(this.props);
                    }}
                    isWorking={this.state.isWorking}
                    summary={log.list
                        ? {
                            skip: log.list.skip,
                            take: log.list.take,
                            total: log.list.total,
                            totalPages: log.list.totalPages
                        }
                        : null}
                    page={this.state.page}
                    pageChanged={(page) => {
                        if (page < 1)
                            page = 1;
                        this.setState({
                            page: page
                        }, () => {
                            this.getItems(this.props);
                        });
                    }}
                    pageSizeChanged={(size) => {
                        this.setState({
                            pageSize: size
                        }, () => {
                            this.getItems(this.props);
                        });
                    }}
                    searchChanged={(search) => {
                        this.setState({
                            search: search
                        }, () => {
                            this.getItems(this.props);
                        });
                    }}
                    data={this.props.log.list.data} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { log: state.log }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(logActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogHistory);
