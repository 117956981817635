import React, { Component } from "react";
import parse from "../../../utils/querystring";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as transferActions from "../actions/transferActions";
import { connect } from "react-redux";
import * as importerActions from "../../importer/actions/importerActions";
import FormLoader from "../../../components/common/FormLoader";

const transferTypes = [
  { value: 0, text: "Azure storage" },
  { value: 1, text: "Email" },
  { value: 2, text: "Queue" },
];

class TransferEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isWorking: false,
      transferName: "",
      transferType: 0,
      transferId: this.props.match.params.transferId,
      settings: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { transfer } = nextProps;
    if (transfer) {
      this.setState({
        transferName: transfer.name,
        transferType: transfer.transferType,
        transferId: this.props.match.params.transferId,
        settings: transfer.settingsJson && JSON.parse(transfer.settingsJson),
      });
    }
  }

  componentDidMount() {
    let params = parse(this.props.location.search);
    if (params["customerId"]) {
      this.setState({
        customerId: params["customerId"],
      });
    }
    this.reloadTransfer();
  }

  handleSave = (e, noClean) => {
    const data = {
      id: this.state.transferId,
      name: this.state.transferName,
      transferType: this.state.transferType,
      settingsJson: JSON.stringify(this.state.settings),
      customerId: this.state.customerId,
    };
    return new Promise((resolve, reject) => {
      this.setState({ isWorking: true });
      if (e) {
        e.preventDefault();
      }

      this.props.transferActions
        .saveTransfer(data)
        .then((response) => {
          this.props.notification.add("Transfer saved", "", "success");
          this.setState({ isWorking: false });
          this.reloadTransfer(noClean);
          resolve();
        })
        .catch(() => {
          this.props.notification.add("Save failed", "", "fail");
          this.setState({ isWorking: false });
          reject();
        });
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleTransferTypeChange = (e) => {
    const { settingsJson } = this.props;
    const { value } = e.target;
    let settings = { ...this.state.settings };
    if (!settingsJson) settings = this.getSettingsStateByType(value);
    this.setState({
      settings,
      transferType: value,
    });
  };

  handleSettingsChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      settings: {
        ...this.state.settings,
        [name]: value,
      },
    });
  };

  reloadTransfer = (noClean) => {
    if (!noClean) this.props.transferActions.clearTransfer();
    if (this.props.match.params.transferId) {
      this.setState({ isLoading: true });
      this.props.transferActions
        .getTransfer(this.props.match.params.transferId)
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  renderSettingsByType = (transferType) => {
    const types = {
      0: this.renderAzureSettings,
      1: this.renderEmailSettings,
      2: this.renderQueueSettings,
    };
    return types[transferType]();
  };

  getSettingsStateByType = (transferType) => {
    const types = {
      0: { shareName: "", path: "", connectionString: "" },
      1: {
        imapHost: "",
        imapPort: "",
        login: "",
        password: "",
        subjectFilter: "",
      },
      2: { tag: "" },
    };
    return types[transferType];
  };

  renderEmailSettings = () => (
    <div>
      <form className="form-horizontal">
        <div className="form-group">
          <label className="col-sm-2 control-label">Imap Host</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.imapHost}
              name="imapHost"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Imap Port</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.imapPort}
              name="imapPort"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Login</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.login}
              name="login"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Password</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.password}
              name="password"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Subject filter</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.subjectFilter}
              name="subjectFilter"
            />
          </div>
        </div>
      </form>
    </div>
  );

  renderAzureSettings = () => (
    <div>
      <form className="form-horizontal">
        <div className="form-group">
          <label className="col-sm-2 control-label">Share name</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.shareName}
              name="shareName"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Path</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.path}
              name="path"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Archive path</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.archivePath}
              name="archivePath"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Connection string</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.connectionString}
              name="connectionString"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Filename mask</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.nameMask}
              name="nameMask"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-2 control-label">Recursive</label>
          <div className="col-sm-2">
            <input
              type="checkbox"
              className="form-control"
              style={{ width: "auto" }}
              onChange={(e) => {
                this.setState({
                  settings: {
                    ...this.state.settings,
                    recursive: e.target.checked,
                  },
                });
              }}
              checked={this.state.settings.recursive}
              name="recursive"
            />
          </div>
        </div>
      </form>
    </div>
  );

  renderQueueSettings = () => (
    <div>
      <form className="form-horizontal">
        <div className="form-group">
          <label className="col-sm-2 control-label">Tag</label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              onChange={this.handleSettingsChange}
              value={this.state.settings.tag}
              name="tag"
            />
          </div>
        </div>
      </form>
    </div>
  );

  render() {
    return (
      <div>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Transfer edit</span>
              </h4>
            </div>
            <div className="heading-elements">
              <div className="heading-btn-group">
                <button
                  disabled={this.state.isWorking || this.state.isLoading}
                  onClick={this.handleSave}
                  className="btn bg-blue has-text"
                >
                  {this.state.isWorking ? (
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  ) : (
                    <i className="fa fa-check" />
                  )}
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat relative">
          {this.state.isLoading ? <FormLoader /> : null}
          <div className="panel-body">
            <h5>General</h5>
            <form className="form-horizontal">
              <div className="form-group">
                <label className="col-sm-2 control-label">Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.transferName}
                    name="transferName"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-2 control-label">Type</label>
                <div className="col-sm-8">
                  <select
                    value={this.state.transferType}
                    onChange={this.handleTransferTypeChange}
                    className="form-control"
                  >
                    {transferTypes.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </form>
            <h5>Settings</h5>
            {this.renderSettingsByType(this.state.transferType)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transfer: state.transfer.transfer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notification: bindActionCreators(notificationActions, dispatch),
    importerActions: bindActionCreators(importerActions, dispatch),
    transferActions: bindActionCreators(transferActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferEdit);
