import logo from "../img/logo-rpm-header3.svg";
import UserWidget from "./UserWidget";
import * as importActions from "../pages/import/actions/importActions";
import { useDispatch } from "react-redux";
import { MenuItem, NavDropdown } from "react-bootstrap";
import GuardedComponent from "./GuardedComponent";
import * as userRoles from "../constants/userRoles";
import { Link, NavLink } from "react-router-dom";
import { UserManager } from "oidc-client";

type NavbarProps = {
  user: UserManager;
  history: History;
};

const Navbar = (props: NavbarProps) => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className="navbar navbar-inverse">
        <div className="navbar-header">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="RPM Logo" />
          </a>
        </div>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav">
            <li>
              <NavLink
                to="/"
                className={(isActive) =>
                  "nav-link" + (!isActive ? " unselected" : "")
                }
              >
                Dashboard
              </NavLink>
            </li>
            <GuardedComponent allowedRoles={[userRoles.Admin]}>
              <li>
                <Link to="/customer">Customers</Link>
              </li>
            </GuardedComponent>
            <li>
              <Link to="/fileimport">Imports</Link>
            </li>
            <li>
              <Link to="/order">Orders</Link>
            </li>
            <GuardedComponent allowedRoles={[userRoles.Admin]}>
              <li>
                <Link to="/updates">Updates</Link>
              </li>
            </GuardedComponent>
            <li>
              <Link to="/shipment">Shipments</Link>
            </li>
            <li>
              <Link to="/dictionaries">Dictionaries</Link>
            </li>
            <NavDropdown eventKey={3} title="System" id="system-nav">
              <MenuItem eventKey="1" href="/historyApiCalls">
                Api calls
              </MenuItem>
              <MenuItem eventKey="2" href="/accessApiKey">
                Api access keys
              </MenuItem>
              <MenuItem eventKey="3" href="/exportList">
                Export list
              </MenuItem>
              <MenuItem eventKey="4" href="/service">
                Background jobs
              </MenuItem>
              <MenuItem eventKey="5" href="/trigger/executions">
                Trigger executions
              </MenuItem>
              <MenuItem eventKey="6" href="/flags">
                Flags
              </MenuItem>
              <MenuItem eventKey="7" href="/location">
                Locations
              </MenuItem>
              <MenuItem eventKey="8" href="/users">
                Users
              </MenuItem>
              <MenuItem eventKey="9" href="/common-items">
                Common items
              </MenuItem>
              <MenuItem eventKey="10" href="/dictionary-actions-log">
                Dictionary Action Log
              </MenuItem>
              <MenuItem eventKey="11" href="/dictionaries/import">
                Dictionary Imports
              </MenuItem>
            </NavDropdown>
            <li>
              <button
                type="button"
                className="btn bg-primary-600"
                onClick={() => {
                  dispatch(importActions.showImportModal());
                }}
              >
                Import
              </button>
            </li>
          </ul>
          <UserWidget {...props} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
