import * as actionTypes from '../constants/actionTypes';

const initalState = {
    list: {
        data: []
    },
};

export default(state = initalState, action) => {
    switch (action.type) {
        case actionTypes.EXPORT_LIST_RECEIVE:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.EXPORT_LIST_CLEAR:
            return {
                ...state,
                list: { data: []}
            };
        case actionTypes.EXPORT_FILE_CLEAR_FILE:
            return {
                ...state,
                file: null
            };
        case actionTypes.EXPORT_FILE_GET_FILE:
            return {
                ...state,
                file: action.payload
            };
        default:
            return state;
    }
};