import MegaTable from "../../../components/common/MegaTable";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import { debounce } from "throttle-debounce";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useState } from "react";
import useGetCustomers from "../../../hooks/useGetCustomers";
import { TableRequestObject } from "../../../models/Requests/TableRequestObject";
import { CustomerObject } from "../../../models/CustomerObject";
import { fetcherPostWithNoReturnData } from "../../../fetchers/fetcherPost";

const CustomerTable = () => {
  const [customerListRequest, setCustomerListRequest] =
    useState<TableRequestObject>({
      skip: 0,
      take: 20,
      search: "",
      page: 1,
      sorting: [
        {
          id: "name",
          desc: false,
        },
      ],
      pageSize: 20,
    });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CustomerObject>();

  const { customers, isLoading, mutate } = useGetCustomers(customerListRequest);

  const searchDebounce = debounce(500, false, (search: string) => {
    setCustomerListRequest({
      ...customerListRequest,
      search: search,
      page: 1,
    });
  });

  const deleteCustomer = async (id: number) => {
    await fetcherPostWithNoReturnData(`/api/customer/delete`, { id: id });
    mutate();
  };

  let columns = [
    {
      name: "Name",
      id: "name",
      type: "custom",
      renderDisplay: (d: CustomerObject) => (
        <Link to={`/customer/edit/${d.id}`}>
          {d.name}
          {d.imageUrl && (
            <img className="customer-table-img" src={d.imageUrl}></img>
          )}
        </Link>
      ),
    },
    {
      name: "Actions",
      id: "Actions",
      type: "custom",
      width: 160,
      renderDisplay: (d: CustomerObject) => (
        <div>
          <Link
            className="btn btn-xxs btn-primary"
            to={`/customer/edit/${d.id}`}
          >
            Edit
          </Link>
          &nbsp;
          <button
            type="button"
            className="btn btn-xxs btn-danger"
            onClick={() => {
              setShowDeleteConfirm(true);
              setSelectedItem(d);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Helmet>
        <title>SL3 - Customers</title>
      </Helmet>
      <div className="page-header page-header-default">
        <div className="page-header-content">
          <div className="page-title">
            <h4>
              <span className="text-semibold">Customers</span>
            </h4>
          </div>

          <div className="heading-elements">
            <div className="heading-btn-group">
              <Link to="/customer/new" className="btn bg-blue has-text">
                <i className="fa fa-plus" />
                <span>Add</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-flat">
        <MegaTable
          editMode={false}
          columns={columns}
          onRefresh={mutate}
          isWorking={isLoading}
          summary={
            customers && {
              skip: customers.skip,
              take: customers.take,
              total: customers.total,
              totalPages: customers.totalPages,
            }
          }
          page={customerListRequest.page}
          pageChanged={(page: number) => {
            if (page < 1) page = 1;
            setCustomerListRequest({
              ...customerListRequest,
              page: page,
            });
          }}
          pageSize={customerListRequest.pageSize}
          pageSizeChanged={(size: number) => {
            setCustomerListRequest({
              ...customerListRequest,
              pageSize: size,
            });
          }}
          searchChanged={(search: string) => {
            searchDebounce(search);
          }}
          data={customers?.data ?? []}
        />
      </div>
      {showDeleteConfirm && selectedItem ? (
        <DeleteConfirmationModal
          title={"Delete customer confirmation"}
          description={`Are you sure you want to delete ${selectedItem.name}?`}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            deleteCustomer(selectedItem.id);
            setShowDeleteConfirm(false);
          }}
          show={showDeleteConfirm}
        />
      ) : null}
    </div>
  );
};

export default CustomerTable;
