import React, { Component } from 'react'
import { connect } from 'react-redux';
import ContractManagerProviderItem from './ContractManagerProviderItem';

class ContractManagerProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    renderApiResponse = (model) => {
        return (
            <ContractManagerProviderItem settings={this.props.settings} model={model}/>
        );
    };
    renderErrors = (model) => {
        return ( <div>
                    <b>Error: </b>{model}
                </div>
        )
    }

    renderDetails = (model) =>{
        return (<div>
            {model.ApiResponses ? model.ApiResponses.map(x=>{
                return this.renderApiResponse(x);
            }):null}
            {model.Errors? model.Errors.map(x=>{
                return this.renderErrors(x)
            }):null}

        </div>);
    };

    render() {
        let { model } = this.props;

        return (
            <div>
                <b>{this.props.Header}</b><br/>
                Is valid: {model.IsValid?"true":"false"} <br/>
                {this.state.expanded?this.renderDetails(model):null}
                <a
                    onClick={(e)=>{ e.preventDefault(); this.setState({expanded: !this.state.expanded}); }}>
                    Details
                    &nbsp;
                    {this.state.expanded?<i className="fa fa-arrow-up"/>:<i className="fa fa-arrow-down"/>}
                </a>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractManagerProvider)