import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import * as notificationActions from "../../../notifications/actions/notificationActions";
import * as shipmentActions from "../../actions/shipmentActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import FormLoader from "../../../../components/common/FormLoader";
import FileImportLine from "../../../orders/components/tabs/visualTracking/FileImportLine";
import ShipmentVisualTrackingLine from "./visualTracking/ShipmentVisualTrackingLine";

class ShipmentVisualTrackingTab extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            shipmentId: this.props.shipmentId,
            isLoading: false,
            isWorking: false
        };
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.props.actions.clearVisualTrackingItems();
        this
            .props
            .actions
            .getVisualTrackingItems(this.state.shipmentId)
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            })
    }

    render() {
        return (
            <div className="relative">
                {this.state.isLoading
                    ? <FormLoader/>
                    : null}
                {(this.props.shipment && this.props.shipment.visualTrackingItems)?
                    <ShipmentVisualTrackingLine
                        settings={this.props.settings}
                        item={this.props.shipment.visualTrackingItems}
                    />:null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        shipment: state.shipment,
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(shipmentActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentVisualTrackingTab)