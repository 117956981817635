import { Component } from "react";
import version_info from "../utils/version.json";

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="sticky-footer text-muted text-center">
          &copy; RPM {new Date().getFullYear()} build:{" "}
          {version_info.build_timestamp}
        </div>
      </footer>
    );
  }
}
