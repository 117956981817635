import * as actionTypes from '../constants/actionTypes';

export default(state = {}, action) => {
    switch (action.type) {
        case actionTypes.INBOUNDPROCESSING_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.INBOUNDPROCESSING_LIST_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            };
        case actionTypes.INBOUNDPROCESSING_LIST_CLEAR:
            return {
                ...state,
                list: null
            };
        case actionTypes.INBOUNDPROCESSING_GET_VISUAL_TRACKING:
            return {
                ...state,
                visualTrackingItems: action.payload
            };
        case actionTypes.INBOUNDPROCESSING_CLEAR_VISUAL_TRACKING:
            return {
                ...state,
                visualTrackingItems: null
            };
        case actionTypes.INBOUNDPROCESSING_GET_DETAILS:
            return {
                ...state,
                inboundProcessing: action.payload
            };
        case actionTypes.INBOUNDPROCESSING_CLEAR_DETAILS:
            return {
                ...state,
                inboundProcessing: null
            };
        case actionTypes.INBOUNDPROCESSING_GET_FLAG_LIST:
            return {
                ...state,
                flags: action.payload
            };
        case actionTypes.INBOUNDPROCESSING_CLEAR_FLAG_LIST:
            return {
                ...state,
                flags: null
            };
        case actionTypes.RESOURCE_LIST_LOGS:
            return {
                ...state,
                resourceLogs: action.payload
            };
        case actionTypes.RESOURCE_CLEAR_LOGS:
            return {
                ...state,
                resourceLogs: null
            };
        default:
            return state;
    }
};