import React, { Component } from 'react'
import MegaTable from "../../../components/common/MegaTable";
import * as apiKeyActions from "../actions/apiKeyActions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import displayDateTime from "../../../utils/dateTimeHelper";
import NameEditorModal from "../../../components/common/NameEditorModal";
import * as notificationActions from '../../notifications/actions/notificationActions';
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";

class ApiAccessKeyTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 10,
            selectedItem: {},
            isWorking: false,
            search: '',
            isAddKeyModalOpen: false,
            isEditKeyModalOpen: false,
            showDeleteConfirm: false,
        };
    }

    componentDidMount() {
        this.reloadData();
    }


    reloadData = (noLoader) => {
        if (!noLoader) {
            this.setState({ isWorking: true });
        }
        this
            .props
            .actions
            .list({ page: this.state.page, pageSize: this.state.pageSize, search: this.state.search })
            .then(() => this.setState({ isWorking: false }))
            .catch(() => this.setState({ isWorking: false }));
    }

    getData = () => {
        if (this.props.apiAccessKey && this.props.apiAccessKey.list) {
            return this.props.apiAccessKey.list.data;
        }
        return [];
    }

    render() {
        let columns = [
            {
                name: 'Name',
                id: 'name',
                type: 'text'
            },
            {
                name: 'Key',
                id: 'key',
                type: 'text'
            },
            {
                name: 'Created date',
                id: 'dateTime',
                type: 'custom',
                width: '190',
                renderDisplay: d => <span>
                    {displayDateTime(d.createDate)}
                </span>
            },
            {
                name: 'Status',
                id: 'status',
                width: '150',
                type: 'custom',
                renderDisplay: d => <span>
                    {d.isDisabled == 0 ? <span><i className="fa fa-check color-green" />&nbsp;Enabled</span> : <span><i className="fa fa-remove color-red" />&nbsp;Disabled</span>}
                </span>
            },
            {
                name: 'Actions',
                id: 'Actions',
                type: 'custom',
                width: '200',
                renderDisplay: d => <div>
                    <a className="btn btn-xxs bg-slate" onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isEditKeyModalOpen: true, selectedItem: d });
                    }}>Edit</a>
                    &nbsp;
                    <a className="btn btn-xxs bg-slate" onClick={(e) => {
                        e.preventDefault();

                        let accessKey = {
                            ...d
                        };
                        accessKey.isDisabled = !accessKey.isDisabled;

                        this.props.actions.save(accessKey).then(() => {
                            this
                                .props
                                .notification
                                .add("Key disabled", "", "success");
                            this.setState({ isWorking: false, isEditKeyModalOpen: false }, () => {
                                this.reloadData();
                            });
                        }).catch(() => {
                            this
                                .props
                                .notification
                                .add("Key save failed", "", "fail");
                        });
                    }}>{d.isDisabled ? <span>Enable</span> : <span>Disable</span>}</a>
                    &nbsp;
                    <a className="btn btn-xxs bg-danger" onClick={(e) => {
                        e.preventDefault();

                        this.setState({ showDeleteConfirm: true, selectedItem: d });
                    }}>Delete</a>

                </div>
            }
        ];

        let apiCalls = this.props.apiCalls || {};

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4><span className="text-semibold">Api access keys</span></h4>
                        </div>

                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ isAddKeyModalOpen: true });

                                }} className="btn bg-blue has-text">
                                    <i className="fa fa-plus"></i>
                                    <span>Add</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat">
                    <MegaTable
                        editMode={false}
                        columns={columns}
                        onRefresh={() => {
                            this.reloadData();
                        }}
                        isWorking={this.state.isWorking}
                        summary={apiCalls.list
                            ? {
                                skip: apiCalls.list.skip,
                                take: apiCalls.list.take,
                                total: apiCalls.list.total,
                                totalPages: apiCalls.list.totalPages
                            }
                            : null}
                        page={this.state.page}
                        pageChanged={(page) => {
                            if (page < 1)
                                page = 1;
                            this.setState({
                                page: page
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        pageSizeChanged={(size) => {
                            this.setState({
                                pageSize: size
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        searchChanged={(search) => {
                            this.setState({
                                search: search
                            }, () => {
                                this.reloadData();
                            });
                        }}
                        data={this.getData()} />
                </div>
                <NameEditorModal
                    title={"Add api key"}
                    onClose={() => this.setState({ isAddKeyModalOpen: false })}
                    onConfirm={(name) => {
                        this.setState({ isWorking: true });

                        this.props.actions.save({
                            name: name
                        }).then(() => {
                            this
                                .props
                                .notification
                                .add("Key saved successfully", "", "success");
                            this.setState({ isWorking: false, isAddKeyModalOpen: false }, () => {
                                this.reloadData();
                            });
                        }).catch(() => {
                            this
                                .props
                                .notification
                                .add("Key save failed", "", "fail");
                            this.setState({ isWorking: false });
                        });
                    }}
                    show={this.state.isAddKeyModalOpen} />
                <NameEditorModal
                    title={"Edit api key"}
                    onClose={() => this.setState({ isEditKeyModalOpen: false })}
                    name={this.state.selectedItem ? this.state.selectedItem.name : ''}
                    onConfirm={(name) => {
                        this.setState({ isWorking: true });

                        this.props.actions.save({
                            name: name,
                            id: this.state.selectedItem.id
                        }).then(() => {
                            this
                                .props
                                .notification
                                .add("Key saved successfully", "", "success");
                            this.setState({ isWorking: false, isEditKeyModalOpen: false }, () => {
                                this.reloadData();
                            });
                        }).catch(() => {
                            this
                                .props
                                .notification
                                .add("Key save failed", "", "fail");
                            this.setState({ isWorking: false });
                        });
                    }}
                    show={this.state.isEditKeyModalOpen} />
                <DeleteConfirmationModal
                    title={"Delete key confirmation"}
                    description={"Are you sure you want to delete " + this.state.selectedItem.name + "?"}
                    onClose={() => this.setState({ showDeleteConfirm: false })}
                    onConfirm={() => {
                        this
                            .props
                            .actions
                            .remove(this.state.selectedItem.id, () => {
                                this.reloadData();
                                this.setState({ showDeleteConfirm: false });
                            })
                    }}
                    show={this.state.showDeleteConfirm} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { apiAccessKey: state.apiAccessKey }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(apiKeyActions, dispatch),
        notification: bindActionCreators(notificationActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiAccessKeyTable)