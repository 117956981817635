import * as actionTypes from '../constants/actionTypes';

const initialState = {
    list: {
        data: [],
    },
};

export default(state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FLAGS_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.FLAGS_LIST_CLEAR:
            return {
                ...state,
                list: { data: [] }
            };
        case actionTypes.FLAGS_REMOVE:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: state.list.data.filter(el => el.id !== action.payload)
                }
            };
        default:
            return state;
    }
};