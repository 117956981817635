import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/fileExport/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.EXPORT_LIST_RECEIVE, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function downloadFile(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/fileExport/downloadArchiveFile", { id: id })
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}

export function clear() {
    return (dispatch) => {
        dispatch({ type: actionTypes.EXPORT_LIST_CLEAR });
    };
}


export function getFile(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/fileExport/get/?id=" + id)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.EXPORT_FILE_GET_FILE, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearFile() {
    return (dispatch) => {
        dispatch({type: actionTypes.EXPORT_FILE_CLEAR_FILE});
    };
}