import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AUTH_CONFIG } from '../utils/userManager';

const appName = AUTH_CONFIG.client_id;

class GuardedComponent extends Component {
    render() {
        if (!this.props.oidc.user)
            return null;

        const userRoles = this.props.oidc.user.profile.roles.split(',')
            .map(el => {
                const appArray = el.split(':');
                if (appArray[0] === appName)
                    return appArray[1];
            });

        const isAllowed = this.props.allowedRoles.some(el => userRoles.includes(el));

        return isAllowed && this.props.children;
    }
}

function mapStateToProps(state) {
    return { oidc: state.oidc }
}

export default connect(mapStateToProps)(GuardedComponent)
