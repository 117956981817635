import React, { Component } from 'react'
import { connect } from 'react-redux';
import ContractManagerProvider from './ExternalProviders/ContractManagerProvider';

class ExternalProvidersStage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerTitles: {"contractManagerProvider": "Contract manager"},
            providerRenderers: {
                "contractManagerProvider": (model)=> <ContractManagerProvider Header = {this.state.headerTitles["contractManagerProvider"]} model={model}/>,
            }
        };
    }

    renderProviders = (model) =>{
        return (<div>
            {model.Providers ? model.Providers.map(x=>{
                return this.state.providerRenderers[x.Name](x);
            }):null}
        </div>);
    };

    render() {
        let { model } = this.props;

        return (
            <div>
                <b>Is valid: </b>{model.IsValid?"true":"false"}
                <br/>
                {/*<b>Providers:</b> <span>{model.Providers ? model.Providers.length : 0}<br/></span>*/}
                {this.renderProviders(model)}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalProvidersStage)