export const ORDER_LIST = 'ORDER_LIST';
export const ORDER_LIST_CLEAR = 'ORDER_LIST_CLEAR';
export const ORDER_LIST_CUSTOMERS = 'ORDER_LIST_CUSTOMERS';
export const ORDER_GET_DETAILS = 'ORDER_GET_DETAILS';
export const ORDER_CLEAR_DETAILS = 'ORDER_CLEAR_DETAILS';
export const ORDER_UPDATE_SETTING = 'ORDER_UPDATE_SETTING';

export const ORDER_GET_VISUAL_TRACKING = 'ORDER_GET_VISUAL_TRACKING';
export const ORDER_CLEAR_VISUAL_TRACKING = 'ORDER_CLEAR_VISUAL_TRACKING';

export const ORDER_LIST_TRIGGER_EXECUTIONS = 'ORDER_LIST_TRIGGER_EXECUTIONS';
export const ORDER_CLEAR_TRIGGER_EXECUTIONS = 'ORDER_CLEAR_TRIGGER_EXECUTIONS';

export const ORDER_CLEAR_FLAG_LIST = 'ORDER_CLEAR_FLAG_LIST';
export const ORDER_GET_FLAG_LIST = 'ORDER_GET_FLAG_LIST';

export const RESOURCE_LIST_LOGS = 'RESOURCE_LIST_LOGS';
export const RESOURCE_CLEAR_LOGS = 'RESOURCE_CLEAR_LOGS';

export const ORDER_CHANGE_HISTORY_LIST = 'ORDER_CHANGE_HISTORY_LIST';
export const ORDER_CHANGE_HISTORY_LIST_CLEAR = 'ORDER_CHANGE_HISTORY_LIST_CLEAR';

export const ORDER_CHANGE_HISTORY_DETAILS = 'ORDER_CHANGE_HISTORY_DETAILS';
export const ORDER_CHANGE_HISTORY_CLEAR_DETAILS = 'ORDER_CHANGE_HISTORY_CLEAR_DETAILS';

export const ORDER_CLEAR_SYNC_LIST = 'ORDER_CLEAR_SYNC_LIST';
export const ORDER_GET_SYNC_LIST = 'ORDER_GET_SYNC_LIST';