import React, {Component} from 'react';
import FormLoader from '../../../components/common/FormLoader';
import * as notificationActions from '../../notifications/actions/notificationActions';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as locationActions from '../actions/locationActions';

class LocationEdit extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.reloadCustomers();
        this.getLocation();
    }

    getLocation = () => {
        this.props.locationActions.clearLocation();
        if (this.state.locationId) {
            this.setState({isLoading: true});
            this
                .props
                .locationActions
                .getLocation(this.state.locationId)
                .then(() => {
                    this.setState({isLoading: false})
                })
                .catch(() => {
                    this.setState({isLoading: false})
                })
        } else {
            this.setState({location: {}});
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            location: nextProps.location.location || {}
        });
    }

    reloadCustomers = () => {
        this.setState({isLoading: true});
        this
            .props
            .locationActions
            .listCustomers({pageSize: null, page: null})
            .then(() => this.setState({isLoading: false}))
            .catch(() => this.setState({isLoading: false}));
    };

    handleSave = (e) => {
        this.setState({isWorking: true});
        e.preventDefault();
        this
            .props
            .locationActions
            .save(this.state.location)
            .then((data) => {
                this
                    .props
                    .notification
                    .add("Location saved", "", "success");
                this.setState({isWorking: false});
                if (!this.state.locationId) {
                    this.props.history.push(`/location/edit/${data.data}`);
                    this.setState({ ...this.getInitialState()});
                }
            })
            .catch(() => {
                this
                    .props
                    .notification
                    .add("Save failed", "", "fail");
                this.setState({isWorking: false});
            })
    };

    getInitialState() {
        return {
            locationId: this.props.match.params.locationId,
            location: {}
        };
    }

    render() {
        const { location } = this.props;
        const customers = location.customers && location.customers.data ? location.customers.data: [];

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Location edit</span>
                            </h4>
                        </div>

                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <button
                                    disabled={this.state.isWorking || this.state.isLoading}
                                    onClick={this.handleSave}
                                    className="btn bg-blue has-text">
                                    {this.state.isWorking
                                        ? <i className="fa fa-spinner fa-spin fa-fw" />
                                        : <i className="fa fa-check" />}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat relative">
                    {this.state.isLoading
                        ? <FormLoader/>
                        : null}
                    <div className="panel-body">
                        <form className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-1 control-label">Customer</label>
                                <div className="col-sm-8">
                                    <select value={this.state.location.customerId} className="form-control" onChange={(e)=>{
                                        this.setState({
                                            location: {
                                                ...this.state.location,
                                                customerId: e.target.value
                                            }
                                        });
                                    }}>
                                        <option value={''}>All</option>
                                        {customers.map((c)=>{
                                            return (<option key={c.id} value={c.id}>{c.name}</option>);
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-1 control-label">Name</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                location: {
                                                    ...this.state.location,
                                                    name: e.target.value
                                                }
                                            });
                                        }}
                                        value={this.state.location.name || ''}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-1 control-label">Line1</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                location: {
                                                    ...this.state.location,
                                                    line1: e.target.value
                                                }
                                            });
                                        }}
                                        value={this.state.location.line1 || ''}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-1 control-label">City</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                location: {
                                                    ...this.state.location,
                                                    city: e.target.value
                                                }
                                            });
                                        }}
                                        value={this.state.location.city || ''}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-1 control-label">State</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                location: {
                                                    ...this.state.location,
                                                    state: e.target.value
                                                }
                                            });
                                        }}
                                        value={this.state.location.state || ''}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-1 control-label">Zip</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                location: {
                                                    ...this.state.location,
                                                    zip: e.target.value
                                                }
                                            });
                                        }}
                                        value={this.state.location.zip || ''}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-1 control-label">Country</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                location: {
                                                    ...this.state.location,
                                                    country: e.target.value
                                                }
                                            });
                                        }}
                                        value={this.state.location.country || ''}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-1 control-label">Turvo location id</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                location: {
                                                    ...this.state.location,
                                                    turvoLocationId: e.target.value
                                                }
                                            });
                                        }}
                                        value={this.state.location.turvoLocationId || ''}/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {location: state.location}
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        locationActions: bindActionCreators(locationActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationEdit)
