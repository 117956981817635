import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import FileImportLine from "./FileImportLine";
import FileParsingStage from "./stages/FileParsingStage";
import ScriptMappingStage from "./stages/ScriptMappingStage";
import LocationResolveStage from "./stages/LocationResolveStage";
import ValidationStage from "./stages/ValidationStage";
import ApiOperationsStage from "./stages/ApiOperationsStage";
import ExternalProvidersStage from "./stages/ExternalProvidersStage";
import CreatedStage from "../../../../shipments/components/tabs/visualTracking/stages/CreatedStage";

import moment from "moment";

export default class VisualStageItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headerTitles: { 0: "File parsing", 1: "Script mapping", 2: "Location resolve", 3: "Validation", 4: "Api operations", 5: 'Cost calculation', 6: 'Created', 7: 'External providers' },
            detailsRendereds: {
                0: (model) => <FileParsingStage
                    saveData={this.props.saveData}
                    editing={this.state.editing}
                    closeEdit={() => {
                        this.setState({ editing: false });
                    }}
                    model={model}
                    closeEditing={() => this.setState({ editing: false })} />,
                1: (model) => <ScriptMappingStage
                    saveData={this.props.saveData}
                    editing={this.state.editing}
                    model={model}
                    closeEditing={() => this.setState({ editing: false })} />,
                2: (model) => <LocationResolveStage settings={this.props.settings} model={model} />,
                3: (model) => <ValidationStage model={model} />,
                4: (model) => <ApiOperationsStage settings={this.props.settings} model={model} />,
                6: (model) => <CreatedStage settings={this.props.settings} model={model} />,
                7: (model) => <ExternalProvidersStage settings={this.props.settings} model={model} />
            },
            editEnabled: [0, 1],
            editing: false,
        };
    }

    displayElapsed = (elapsedMs) => {
        let timeSpan = moment.duration(elapsedMs);
        return <span className="elapsed-time">{timeSpan.seconds()}s {timeSpan.milliseconds()}ms</span>
    }

    displayHeader = (stage) => {
        return (<h6 className="form-wizard-title text-semibold">
            {stage.isValid ? <i className="fa fa-check-circle-o color-green fa-lg" /> :
                <i className="fa fa-times-circle-o color-red fa-lg" />}

            <span className="margin-left-5">{this.state.headerTitles[stage.stageType]}</span>
            {stage.elapsedTime ? this.displayElapsed(stage.elapsedTime) : null}

            {this.state.editEnabled.includes(stage.stageType) && <span>
                <a className="pull-right"
                    onClick={() => this.props.reprocess(stage.stageType)}><i className="fa fa-refresh" /></a>
                <a onClick={(e) => {
                    e.preventDefault();
                    this.setState({ editing: true });
                }} className="pull-right"><i className="fa fa-pencil" /></a>
            </span>}
        </h6>);
    };

    render() {
        let { stage } = this.props;
        let renderer = this.state.detailsRendereds[stage.stageType];
        let model = JSON.parse(stage.data);
        model.id = stage.id;

        return (
            <div className={"relative ticksheet-flex-block visual-item"}>
                <div className="panel panel-flat visual-item-body">
                    <div className="panel-body">
                        {this.displayHeader(stage)}
                        {(renderer ? renderer(model) : null)}
                    </div>
                </div>
                {!this.props.isLast && <i className="fa fa-arrow-circle-right fa-lg visual-item-arrow" />}
            </div>
        )
    }
}