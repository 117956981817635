import useSWR from "swr";
import fetcherPost from "../../../fetchers/fetcherPost";
import { ChangeEvent, useEffect, useState } from "react";
import { TableRequestObject } from "../../../models/Requests/TableRequestObject";
import * as importStates from "../../fileImport/constants/importStates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import displayDateTime from "../../../utils/dateTimeHelper";
import { FileImportObject } from "../../../models/FileImportObject";
import getLabelByValue from "../../../utils/enumHelper";
import MegaTable from "../../../components/common/MegaTable";
import { ResponseFetch } from "../../../models/ResponseFetch";
import { Button, Select, TextField, Tooltip } from "@radix-ui/themes";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import urlCreator from "../../../utils/urlParams";
import { Helmet } from "react-helmet";

let columns = [
  {
    name: "File name",
    id: "originalFileName",
    type: "custom",
    renderDisplay: (d: FileImportObject) => (
      <span>
        <a href={"/api/fileImport/downloadArchiveFile?fileImportId=" + d.id}>
          {d.originalFileName}
        </a>
      </span>
    ),
  },
  {
    name: "Dictionary",
    id: "dictionary",
    type: "custom",
    renderDisplay: (d: FileImportObject) => (
      <div>
        <a href={`/dictionaries/edit/${d.dictionaryId}`}>{d.dictionaryName}</a>
      </div>
    ),
  },
  {
    name: "Status",
    id: "status",
    type: "custom",
    renderDisplay: (d: FileImportObject) => (
      <div className="text-right">
        {d.importedRecords}/{d.totalRecords}{" "}
        {d.errorCount && d.errorCount > 0 ? (
          <span className="font-bold text-error-700">({d.errorCount})</span>
        ) : null}{" "}
        {getLabelByValue(importStates, d.importState)}
        &nbsp;
        {d.importState == 0 ? (
          <FontAwesomeIcon icon={icon({ name: "clock" })} />
        ) : null}
        {d.importState == 2 ? (
          <FontAwesomeIcon
            icon={icon({ name: "check", style: "solid" })}
            className="text-success-700"
            fixedWidth
          />
        ) : null}
        {d.importState == 4 ? (
          <FontAwesomeIcon
            icon={icon({ name: "remove", style: "solid" })}
            className="text-error-700"
            fixedWidth
          />
        ) : null}
        {d.importState == 6 ? (
          <FontAwesomeIcon
            icon={icon({ name: "ban", style: "solid" })}
            className="text-error-700"
            fixedWidth
          />
        ) : null}
        {d.error ? <span className="text-error-700">{d.error}</span> : null}
      </div>
    ),
  },

  {
    name: "User",
    id: "userName",
    type: "custom",
    renderDisplay: (d: FileImportObject) => (
      <div>
        <FontAwesomeIcon icon={icon({ name: "user" })} className="mr-2" />{" "}
        {d.userName}
      </div>
    ),
  },
  {
    name: "Date time",
    id: "dateTime",
    type: "custom",
    renderDisplay: (d: FileImportObject) => (
      <div className="tabular-nums">
        {d.startedDate ? (
          <span>
            {displayDateTime(d.startedDate)}
            {d.finishDate ? (
              <span>
                {" "}
                <FontAwesomeIcon icon={icon({ name: "arrow-right" })} />{" "}
                {displayDateTime(d.finishDate, "hh:mm:ss A")}
              </span>
            ) : null}
          </span>
        ) : (
          <span>{displayDateTime(d.createDate)}</span>
        )}
      </div>
    ),
  },
];

const DictionaryImport = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const [fileNameValue, setFileNameValue] = useState(
    params.get("filename") ?? "",
  );

  const [dictionaryImportParams, setDictionaryImportParams] =
    useState<TableRequestObject>({
      page: 1,
      pageSize: 10,
      search: params.get("filename") ?? "",
    });
  const { data, isLoading, mutate } = useSWR<ResponseFetch<FileImportObject>>(
    ["/api/fileImport/listDictionaryImports", dictionaryImportParams],
    ([url, dictionaryImportParams]) => fetcherPost(url, dictionaryImportParams),
    {},
  );

  const debouncedFileNameValue = useDebounce(fileNameValue, 500);

  const fileNameOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileNameValue(e.target.value);
  };

  useEffect(() => {
    setDictionaryImportParams({
      ...dictionaryImportParams,
      search: debouncedFileNameValue,
    });
    const url = urlCreator(
      history.location,
      "filename",
      debouncedFileNameValue,
    );
    if (debouncedFileNameValue !== "" || params.has("filename")) {
      history.push(url);
    }
  }, [debouncedFileNameValue]);

  const resetFilters = () => {
    setDictionaryImportParams({
      ...dictionaryImportParams,
      search: "",
    });
    history.push(history.location.pathname);
  };

  const filterChanged = (
    key: keyof TableRequestObject,
    value: string | boolean | number,
  ) => {
    setDictionaryImportParams({
      ...dictionaryImportParams,
      [key]: value,
    });
    const url = urlCreator(history.location, key, value.toLocaleString());
    history.push(url);
  };

  useEffect(() => {
    setFileNameValue(params.get("filename") ?? "");
    setDictionaryImportParams({
      ...dictionaryImportParams,
      page: parseInt(params.get("page") ?? "1"),
      pageSize: parseInt(params.get("pageSize") ?? "25"),
      search: params.get("filename") ?? "",
    });
  }, [history.location]);

  return (
    <>
      <Helmet>
        <title>SL3 - Dictionary Imports</title>
      </Helmet>
      <div className="page-header page-header-default">
        <div className="page-header-content">
          <div className="page-title">
            <h4>
              <span className="text-semibold">Imports</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="panel panel-flat">
        <div className="panel-body">
          <div className="flex gap-4 items-end">
            <div className="flex-auto">
              <label htmlFor="filename">Filename</label>
              <TextField.Root className="w-full">
                <TextField.Input
                  placeholder="Filename..."
                  value={fileNameValue}
                  onChange={fileNameOnChange}
                  size="3"
                  id="filename"
                />
              </TextField.Root>
            </div>

            <Tooltip content="Refresh Data" delayDuration={250}>
              <Button
                size="3"
                variant="surface"
                color="indigo"
                onClick={() => {
                  mutate();
                }}
              >
                <FontAwesomeIcon fixedWidth icon={icon({ name: "refresh" })} />
              </Button>
            </Tooltip>
            <Tooltip content="Reset Filters" delayDuration={250}>
              <Button
                size="3"
                variant="surface"
                color="amber"
                onClick={() => {
                  resetFilters();
                }}
              >
                <FontAwesomeIcon
                  fixedWidth
                  icon={icon({ name: "filter-circle-xmark" })}
                />
              </Button>
            </Tooltip>

            <div className="">
              <label htmlFor="pageSize">Show</label>
              <Select.Root
                size="3"
                onValueChange={(e) => {
                  filterChanged("pageSize", e);
                }}
                value={params.get("pageSize") ?? "25"}
              >
                <Select.Trigger id="pageSize" className="w-full" />
                <Select.Content>
                  <Select.Item value="10">10</Select.Item>
                  <Select.Item value="25">25</Select.Item>
                  <Select.Item value="50">50</Select.Item>
                  <Select.Item value="100">100</Select.Item>
                </Select.Content>
              </Select.Root>
            </div>
          </div>
        </div>
      </div>
      <MegaTable
        hideHeader
        editMode={false}
        columns={columns}
        isWorking={isLoading}
        summary={
          data
            ? {
                skip: data.skip,
                take: data.take,
                total: data.total,
                totalPages: data.totalPages,
              }
            : null
        }
        page={dictionaryImportParams.page}
        pageChanged={(page: number) => {
          if (page < 1) page = 1;
          setDictionaryImportParams({
            ...dictionaryImportParams,
            page: page,
          });
        }}
        data={data?.data ?? []}
      />
    </>
  );
};

export default DictionaryImport;
