import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import DictionariesBrowser from "./components/DictionariesBrowser";
import DictionaryEdit from "./components/DictionaryEdit";
import DictionaryImport from './components/DictionaryImport';

export default class Dictionaries extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/dictionaries" component={DictionariesBrowser} />
                <Route exact path="/dictionaries/edit/:dictionaryId" component={DictionaryEdit} />
                <Route exact path="/dictionaries/import" component={DictionaryImport} />
            </Switch>
        )
    }
}