import React, { Component, Fragment } from "react";
import MegaTable from "../../../components/common/MegaTable";
import * as orderActions from "../actions/orderActions";
import * as fileImportActions from "../../fileImport/actions/fileImportActions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { debounce } from "throttle-debounce";
import getLabelByValue from "../../../utils/enumHelper";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as orderStates from "../constants/orderStates";
import parse from "../../../utils/querystring";
import DropdownSelector from "../../../components/common/DropdownSelector";
import DateRangeSelector from "../../../components/common/DateRangeSelector";
import ReprocessProgressModal from "./ReprocessProgressModal";
import * as orderHelper from "../helpers/orderHelper";
import Toggle from "react-bootstrap-toggle";
import { Helmet } from "react-helmet";

const stateTypes = [
  { id: 0, value: "Processing" },
  { id: 1, value: "ValidationFailed" },
  { id: 2, value: "Error" },
  { id: 3, value: "MappingDone" },
  { id: 4, value: "ApiCreated" },
  { id: 5, value: "Duplicated" },
  { id: 6, value: "Updated" },
  { id: 7, value: "Ignored" },
  { id: 8, value: "Active" },
  { id: 9, value: "Assigned" },
  { id: 10, value: "Pq" },
  { id: 11, value: "PqCompleted" },
  { id: 12, value: "Cancelled" },
  { id: 13, value: "Transport" },
  { id: 14, value: "Delivered" },
  { id: 15, value: "OnHold" },
  { id: 16, value: "Pending" },
];

class OrderTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 10,
      selectedItem: {},
      isWorking: false,
      search: "",
      fileImportIds: null,
      from: moment.utc("0001-01-01"),
      to: moment.utc("0001-01-01"),
      states: [4, 6, 7, 8, 9, 10, 11, 16],
      selectionMode: false,
      selectedOrders: [],
      data: [],
      reprocessProgressOpened: false,
      advancedSearch: false,
    };

    this.refreshDebounce = debounce(300, false, () => {
      this.reloadData();
    });
  }

  initSearchData = (callback) => {
    let searchParams = parse(this.props.location.search);
    if (searchParams["fileImportId"]) {
      this.props.fileImportActions
        .getById(searchParams["fileImportId"])
        .then((response) => {
          this.setState(
            {
              fileImportIds: [searchParams["fileImportId"]],
              fileImport: response.data,
              states: null,
            },
            () => {
              callback();
            }
          );
        });
    } else {
      callback();
    }
  };

  componentDidMount() {
    this.props.actions.clearList();
    this.props.actions.listCustomers({ pageSize: null, page: null });

    this.initSearchData(() => {
      this.reloadData();
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: this.getData(nextProps),
    });
  }

  changeDatesValues = (from, to) => {
    if (from <= to) {
      this.setState(
        {
          to,
          from,
        },
        () => this.reloadData()
      );
    }
  };

  checkOrUncheckItem = (item) => {
    const foundItem = this.state.selectedOrders.find((el) => el.id === item.id);
    const checkedData = this.state.data.map((el) =>
      el.id === item.id ? { ...el, checked: !el.checked } : el
    );
    if (foundItem)
      this.setState({
        selectedOrders: this.state.selectedOrders.filter(
          (el) => el.id !== item.id
        ),
        data: checkedData,
      });
    else
      this.setState({
        selectedOrders: [...this.state.selectedOrders, item],
        data: checkedData,
      });
  };

  checkAll = (checked) => {
    const checkedData = this.props.order.list.data.map((el) => ({
      ...el,
      checked,
    }));
    this.setState({ data: checkedData, selectedOrders: checkedData });
  };

  reprocessOrders = () => {
    this.setState({ reprocessProgressOpened: true });
  };

  reprocessAllOrders = () => {
    this.setState({ isWorking: true });
    this.props.actions
      .list({
        pageSize: 5000,
        page: 1,
        customerId: this.state.customerIds,
        fileImportId: this.state.fileImportIds,
        states: this.state.states,
        to: this.state.to,
        from: this.state.from,
        search: this.state.search,
        advancedSearch: this.state.advancedSearch,
      })
      .then(() => {
        this.checkAll(true);
        this.setState({ reprocessProgressOpened: true });
      })
      .catch(() =>
        this.props.notification.add("Failed to reload more details", "", "fail")
      )
      .finally(() => this.setState({ isWorking: false }));
  };

  reloadData = (noLoader) => {
    console.log("reload");
    if (!noLoader) {
      this.setState({ isWorking: true });
    }
    this.props.actions
      .list({
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
        customerId: this.state.customerIds,
        fileImportId: this.state.fileImportIds,
        from: this.state.from,
        to: this.state.to,
        states: this.state.states,
        advancedSearch: this.state.advancedSearch,
      })
      .then(() => this.setState({ isWorking: false }))
      .catch(() => this.setState({ isWorking: false }));
  };

  getData = (props) => {
    if (props.order && props.order.list) {
      return props.order.list.data;
    }
    return [];
  };

  getSystemSettings = () => {
    if (this.props.settings) return this.props.settings.system_settings || {};
    return {};
  };

  getCustomers = () => {
    if (this.props.order && this.props.order.customers) {
      return this.props.order.customers.data || [];
    }
    return [];
  };

  getColumns = () => {
    const { states } = this.state;

    let systemSettings = this.getSystemSettings();
    let columns = [
      ...orderHelper.getDefaultOrderColumns(
        this.props.history,
        systemSettings.turvoBaseUrl
      ),
    ];

    if (states && states.some((el) => el === 2))
      columns.push({
        name: "Error",
        id: "exception",
        type: "text",
      });
    columns.push({
      name: "Actions",
      id: "Actions",
      type: "custom",
      width: 130,
      renderDisplay: (d) => (
        <span>
          {d.state === 3 ||
            d.state === 4 ||
            d.state === 6 ||
            d.state === 8 ||
            d.state === 9 ||
            d.state === 16 ? (
            <i className="fa fa-check color-green" />
          ) : null}
          {d.state === 2 ? <i className="fa fa-times color-red" /> : null}
          &nbsp;
          {getLabelByValue(orderStates, d.state)}
          <br />
          {d.turvoShipmentId ? (
            <a
              href={
                systemSettings.turvoBaseUrl +
                "/#/shipments/" +
                d.turvoShipmentId
              }
              target="_blank"
            >
              {d.turvoShipmentId}
              <br />
            </a>
          ) : null}
          <a
            className="btn btn-xxs bg-slate"
            href={"/order/details/" + d.id}
            target="_blank"
          >
            Details
          </a>
        </span>
      ),
    });

    return columns;
  };

  render() {
    let columns = this.getColumns();

    let order = this.props.order || {};

    return (
      <div>
        <Helmet>
          {this.state.fileImport ? (
            <title>{"SL3 - " + this.state.fileImport.originalFileName}</title>
          ) : (
            <title>SL3 - Orders</title>
          )}
        </Helmet>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Orders</span>
              </h4>
            </div>
            <div className="heading-elements">
              <div className="heading-btn-group">
                <button
                  onClick={this.reprocessAllOrders}
                  className="btn bg-blue btn-sm"
                >
                  Reprocess All
                </button>
                {this.state.selectionMode ? (
                  <Fragment>
                    <button
                      onClick={() =>
                        this.setState({
                          selectionMode: false,
                          selectedOrders: [],
                        })
                      }
                      className="btn bg-orange btn-sm"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={this.reprocessOrders}
                      className="btn bg-green btn-sm margin-left-10"
                    >
                      Reprocess
                    </button>
                  </Fragment>
                ) : (
                  <button
                    onClick={() => this.setState({ selectionMode: true })}
                    className="btn bg-blue btn-sm"
                  >
                    Select orders
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat">
          <div className="panel-body">
            <div className="row">
              <div className="col-md-2">
                <input
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState(
                      {
                        search: e.target.value,
                      },
                      this.refreshDebounce()
                    );
                  }}
                  className="form-control"
                  placeholder="Pickup, destination, VIN"
                />
              </div>
              <div className="col-md-1">
                <Toggle
                  style={{ height: "35px" }}
                  onClick={() =>
                    this.setState(
                      { advancedSearch: !this.state.advancedSearch },
                      this.reloadData
                    )
                  }
                  on={<span>Quick</span>}
                  off={<span>Advanced</span>}
                  size="xs"
                  offstyle="success"
                  active={!this.state.advancedSearch}
                />
              </div>
              <div className="col-md-2">
                <DateRangeSelector
                  title=""
                  selectedFrom={this.state.from}
                  selectedTo={this.state.to}
                  onDatesChanged={(from, to) =>
                    this.changeDatesValues(from, to)
                  }
                />
              </div>
              <div className="col-md-2">
                <DropdownSelector
                  title="Action type"
                  items={stateTypes}
                  itemId={(el) => el.id}
                  itemRender={(el) => el.value}
                  onChange={(states) =>
                    this.setState({ states }, this.reloadData)
                  }
                  selectedOptions={this.state.states}
                />
              </div>
              <div className="col-md-2">
                <DropdownSelector
                  title="Customer"
                  selectedOptions={this.state.customerIds}
                  items={this.getCustomers()}
                  itemRender={(item) => {
                    return item.name;
                  }}
                  itemId={(item) => {
                    return item.id;
                  }}
                  onChange={(val) =>
                    this.setState({ customerIds: val }, this.reloadData)
                  }
                />
              </div>
              {this.state.fileImport ? (
                <div className="col-md-2">
                  <DropdownSelector
                    title="File import"
                    selectedOptions={this.state.fileImportIds}
                    items={[this.state.fileImport]}
                    itemRender={(item) => {
                      return item.originalFileName;
                    }}
                    itemId={(item) => {
                      return item.id;
                    }}
                    onChange={(val) => {
                      if (val.length === 0) {
                        this.setState(
                          {
                            fileImportIds: null,
                            fileImport: null,
                          },
                          () => this.reloadData()
                        );
                      }
                    }}
                  />
                </div>
              ) : null}
              <div className="col-md-1">
                <a
                  className="refresh-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    this.reloadData();
                  }}
                >
                  <i className="fa fa-refresh" />
                </a>
              </div>
            </div>
          </div>
          <div className="">
            <MegaTable
              hideHeader
              editMode={false}
              selectionMode={this.state.selectionMode}
              onItemCheck={this.checkOrUncheckItem}
              checkAll={this.checkAll}
              columns={columns}
              onRefresh={() => {
                this.reloadData();
              }}
              className={"table-align-top"}
              isWorking={this.state.isWorking}
              summary={
                order.list
                  ? {
                    skip: order.list.skip,
                    take: order.list.take,
                    total: order.list.total,
                    totalPages: order.list.totalPages,
                  }
                  : null
              }
              page={this.state.page}
              pageChanged={(page) => {
                if (page < 1) page = 1;
                this.setState(
                  {
                    page: page,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              pageSizeChanged={(size) => {
                this.setState(
                  {
                    pageSize: size,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              searchChanged={(search) => {
                this.setState(
                  {
                    search: search,
                  },
                  () => {
                    this.reloadData();
                  }
                );
              }}
              data={this.state.data}
            />
          </div>
        </div>
        {this.state.reprocessProgressOpened && (
          <ReprocessProgressModal
            onClose={() => {
              this.setState(
                {
                  reprocessProgressOpened: false,
                  selectedOrders: [],
                  selectionMode: false,
                },
                this.reloadData
              );
            }}
            orders={this.state.selectedOrders}
            show={this.state.reprocessProgressOpened}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.order,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    notification: bindActionCreators(notificationActions, dispatch),
    actions: bindActionCreators(orderActions, dispatch),
    fileImportActions: bindActionCreators(fileImportActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTable);
