import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import TransferEdit from "./components/TransferEdit";

export default class Transfer extends Component {
    render() {
        return (
            <Switch>
                <Route path="/transfer/edit/:transferId?" component={TransferEdit}/>
            </Switch>
        )
    }
}