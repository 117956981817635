import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function saveFolder(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionaryFolder/save", args)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function listFolders(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
                .post("/api/dictionaryFolder/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.DICTIONARIES_LIST_FOLDER, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
        });
    });
  };
}

export function removeFolder(id, callback) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionaryFolder/delete", { id: id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function saveDictionary(args = null) {
  return (dispatch) => {
    dispatch({ type: actionTypes.START_DICTIONARY_SAVE });
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionary/save", args)
        .then((response) => {
          dispatch({ type: actionTypes.SUCCESS_DICTIONARY_SAVE, newDictionary: args });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: actionTypes.ERROR_DICTIONARY_SAVE });
          reject(error);
        });
    });
  };
}

export function listDictionaries(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionary/list", args)
        .then((response) => {
          resolve(response);
          dispatch({
            type: actionTypes.DICTIONARIES_LIST_DICTIONARY,
            payload: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function getDictionary(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .get("/api/dictionary/get/?id=" + id)
        .then((response) => {
          resolve(response);
          dispatch({
            type: actionTypes.DICTIONARIES_GET_DICTIONARY,
            payload: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function clearDictionary() {
  return (dispatch) => {
    dispatch({ type: actionTypes.DICTIONARIES_CLEAR_DICTIONARY });
  };
}

export function addFileToQueue(args) {
  var formData = new FormData();
  formData.append("dictionaryId", args.dictionaryId);
  formData.append("uploadFile", args.uploadFile);
  formData.append("importType", "1"); // 1 - Dictionary import

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/fileImport/enqueue", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function clearItems() {
  return (dispatch) => {
    dispatch({ type: actionTypes.DICTIONARIES_CLEAR_ITEMS });
  };
}

export function listItems(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionaryItem/list", args)
        .then((response) => {
          resolve(response);
          dispatch({
            type: actionTypes.DICTIONARIES_LIST_ITEMS,
            payload: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function removeItem(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionaryItem/delete", { id: id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function saveItem(args) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionaryItem/save", args)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function clearDictionaryItems(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionary/clear", { id: id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function deleteDictionary(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionary/delete", { id: id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function dictionaryExport(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .post("/api/dictionary/export", { id: id }, { responseType: "blob" })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export function fetchTurvoCustomerList(searchQuery) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .get(`/api/customer/turvoCustomerList?search=${searchQuery}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
