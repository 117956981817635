import React, {Component} from 'react'
import * as inboundProcessingActions from "../actions/inboundProcessingActions";
import * as notificationActions from "../../notifications/actions/notificationActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {DropdownButton, MenuItem, Tab, Tabs} from "react-bootstrap";
import VisualTrackingTab from "./tabs/InboundProcessingVisualTrackingTab";
import FormLoader from "../../../components/common/FormLoader";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import * as inboundProcessingStates from '../constants/inboundProcessingStates';
import getLabelByValue from "../../../utils/enumHelper";

class InboundProcessingDetails extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.reloadOrder();
    }

    reloadOrder = () =>{
        this.props.actions.clear();
        if (this.state.inboundProcessingId) {
            this.setState({isLoading: true});
            this
                .props
                .actions
                .get(this.state.inboundProcessingId)
                .then(() => {
                    this.setState({isLoading: false})
                })
                .catch(() => {
                    this.setState({isLoading: false})
                })
        }else {
            this.setState({order: {}});
        }
    }

    getInitialState() {
        return {
            inboundProcessingId: this.props.match.params.inboundProcessingId,
            isLoading: false,
            isWorking: false,
            inboundProcessings: {},
            inboundProcessing: {},
            selectedTab: this.props.match.params.tab,
            showReprocessOrderConfirm: false,
            showActionsManager: false,
            activeOrderAction: ''
        };
    }

    reprocessOrder = () => {
        this.setState({isLoading: true});
        this.props.actions
            .reprocessOrder({id: this.state.inboundProcessingId})
            .then(() => {})
            .catch((error) => { console.log(error); }).then(()=>{
            Promise.all([
                this.props.actions.get(this.state.inboundProcessingId),
                this.props.actions.getVisualTrackingItems(this.state.inboundProcessingId)
            ]).then(()=>{}).catch(()=>{}).then(()=>{
                this.setState({
                    isLoading: false,
                    showReprocessOrderConfirm: false,
                });
            });
        });
    };

    editStageData = (data) => {
        data.data = JSON.stringify(data.data);
        this.setState({isLoading: true});
        this.props.actions
            .editStageData({...data, inboundProcessingId: this.state.inboundProcessingId})
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            });
    };

    reprocessFromStep = (data) => {
        this.setState({isLoading: true});
        this.props.actions
            .reprocessFromStep({...data, inboundProcessingId: this.state.inboundProcessingId})
            .then(() => {
                this.setState({isLoading: false})
            })
            .catch(() => {
                this.setState({isLoading: false})
            });
    };

    render() {
        let { inboundProcessings } = this.props;

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4>
                                <span className="text-semibold">Processing details{(inboundProcessings && inboundProcessings.inboundProcessing)?(": "+inboundProcessings.inboundProcessing.id+" "+getLabelByValue(inboundProcessingStates, inboundProcessings.inboundProcessing.state)):null}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat relative">
                    {this.state.isLoading
                        ? <FormLoader/>
                        : null}
                    <div className="panel-body">
                        <Tabs
                            id="InboundProcessingTabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(key) => this.setState({selectedTab: key},() => {
                                this.props.history.push('/inboundProcessing/details/'+this.state.inboundProcessingId+'/'+key);
                            })}>
                            <Tab eventKey={"visualTracking"} title="Visual tracking">
                                <VisualTrackingTab
                                    settings={this.props.settings}
                                    inboundProcessingId={this.state.inboundProcessingId}
                                    history={this.props.history}
                                    reprocess={(step) => this.setState({ showReprocessOrderConfirm: true, reprocessStage: step })}
                                    saveData={(data) => this.editStageData(data)}/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        inboundProcessings: state.inboundProcessing,
        settings: state.settings
    }
}

function mapDispatchToProps(dispatch) {
    return {
        notification: bindActionCreators(notificationActions, dispatch),
        actions: bindActionCreators(inboundProcessingActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InboundProcessingDetails)