import getUserInformation from "../utils/getUserInformation";

const fetcherFile = (url: string) =>
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getUserInformation.id_token}`,
    },
  })
    .then((res) => res.blob())
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", new Date() + ".csv");
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => console.log(err));

export default fetcherFile;
