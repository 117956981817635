import axios from "axios";
import { store } from "../store/store";

const getToken = () =>{
    let state = store.getState();
    if(state.oidc && state.oidc.user){
        return state.oidc.user.access_token;
    }

    return '';
}

const get = (url) => {
    return new Promise((resolve, reject) => {
        let token = getToken();

        axios
            .get(url, {
                maxRedirects: 0,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

const post = (url, params, opt) => {
    return new Promise((resolve, reject) => {
        let token = getToken();
        if(!opt)
            opt = {};
        opt = {
            ...opt,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        axios
            .post(url, params, opt)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export default {get, post};