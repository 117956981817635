import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import DashboardMain from "./components/DashboardMain";

export default class Dashboard extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={DashboardMain}/>
            </Switch>
        )
    }
}