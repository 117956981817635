import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as serviceActions from '../actions/serviceActions';
import displayDateTime from "../../../utils/dateTimeHelper";
import MicroTable from "../../../components/common/MicroTable";
import FormLoader from "../../../components/common/FormLoader";
import jobStates from '../constants/jobStates';
import statuses from "../../exportList/constants/fileStatus";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import JobLogsModal from "./JobLogsModal";

class JobsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: {},
            isWorking: false,
            isLogsModalOpen: false,
            selectedJob: {}
        };
    }

    componentDidMount() {
        this.reloadData();
    }


    reloadData = (noLoader) => {
        if (!noLoader) {
            this.setState({ isWorking: true });
        }
        this
            .props
            .serviceActions
            .listJobs()
            .then()
            .catch((err) => { console.log(err); }).then(() => {
                this.setState({ isWorking: false });
            });
    };

    getData = () => {
        if (this.props.service && this.props.service.jobs) {
            return this.props.service.jobs;
        }
        return [];
    };

    render() {
        let columns = [
            {
                name: 'Job name',
                id: 'name',
                type: 'text',
            },
            {
                name: 'State',
                id: 'state',
                type: 'custom',
                renderDisplay: d => <div>
                    {/*{d.exportState == 0?<span><i className="fa fa-clock-o"/> </span>:null}&nbsp;*/}
                    {d.state == 0 ? <span><i className="fa fa-play color-green" /> </span> : null}&nbsp;
                    {d.state == 1 ? <span><i className="fa fa-pause" /> </span> : null}&nbsp;
                    {/*{d.exportState == 2?<span><i className="fa fa-remove color-red"/> </span>:null}&nbsp;*/}
                    {jobStates[d.state]}
                </div>
            },
            {
                name: 'Status message',
                id: 'stateMessage',
                type: 'text',
            },
            {
                name: 'Next run',
                id: 'nextRun',
                type: 'custom',
                renderDisplay: d => <div>
                    {displayDateTime(d.nextFireTime)}
                </div>
            },
            {
                name: 'Actions',
                id: 'Actions',
                type: 'custom',
                width: 120,
                renderDisplay: d => <div>
                    <a className="btn btn-xxs bg-slate" onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isLogsModalOpen: true, selectedJob: d });
                    }}>Logs</a>
                    &nbsp;
                    {d.state == 0 && <a className="btn btn-xxs btn-danger" onClick={(e) => {
                        e.preventDefault();
                        this.props.serviceActions.pauseJob(d.name).then().catch().then(() => {
                            this.reloadData();
                        });
                    }}>Pause</a>}

                    &nbsp;
                    {d.state == 1 && <a className="btn btn-xxs btn-primary" onClick={(e) => {
                        e.preventDefault();
                        this.props.serviceActions.resumeJob(d.name).then().catch().then(() => {
                            this.reloadData();
                        });
                    }}>Resume</a>}
                </div>
            }
        ];

        return (
            <div>
                <div className="page-header page-header-default">
                    <div className="page-header-content">
                        <div className="page-title">
                            <h4><span className="text-semibold">Background jobs</span></h4>
                        </div>

                        <div className="heading-elements">
                            <div className="heading-btn-group">
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.reloadData();
                                }} className="btn bg-primary has-text">
                                    <i className="fa fa-refresh" />
                                    <span>Refresh</span>
                                </a>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.props.serviceActions.resumeAllJobs().then().catch().then(() => {
                                        this.reloadData();
                                    });
                                }} className="btn bg-success has-text">
                                    <i className="fa fa-play" />
                                    <span>Resume all</span>
                                </a>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    this.props.serviceActions.pauseAllJobs().then().catch().then(() => {
                                        this.reloadData();
                                    });
                                }} className="btn bg-danger has-text">
                                    <i className="fa fa-pause" />
                                    <span>Pause all</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel panel-flat">
                    <div className="panel-body">
                        {this.state.isWorking
                            ? <FormLoader />
                            : null}
                        <MicroTable
                            data={this.getData()}
                            noEdit
                            itemSelected={(item) => {

                            }}
                            columns={columns}
                            onChange={(data) => {

                            }} />
                    </div>
                </div>
                <JobLogsModal
                    jobName={this.state.selectedJob.name}
                    onClose={() => this.setState({ isLogsModalOpen: false })}
                    show={this.state.isLogsModalOpen} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { service: state.service }
}

function mapDispatchToProps(dispatch) {
    return {
        serviceActions: bindActionCreators(serviceActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsTable)