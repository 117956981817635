import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function showImportModal() {
    return (dispatch) => {
        dispatch({type: actionTypes.SHOW_IMPORT_MODAL});
    };
}

export function hideImportModal() {
    return (dispatch) => {
        dispatch({type: actionTypes.HIDE_IMPORT_MODAL});
    };
}

export function listCustomers(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/customer/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.IMPORT_LIST_CUSTOMERS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function listImporters(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/importer/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.IMPORT_LIST_IMPORTERS, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function addFileToQueue(args) {
    var formData = new FormData();
    formData.append('importerId', args.importerId);
    formData.append('uploadFile', args.uploadFile);
    formData.append('importType', '0'); // 0 - shipment import

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/fileImport/enqueue", formData)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

}