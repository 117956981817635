export const INBOUNDPROCESSING_LIST = 'INBOUNDPROCESSING_LIST';
export const INBOUNDPROCESSING_LIST_CLEAR = 'INBOUNDPROCESSING_LIST_CLEAR';
export const INBOUNDPROCESSING_LIST_CUSTOMERS = 'INBOUNDPROCESSING_LIST_CUSTOMERS';
export const INBOUNDPROCESSING_GET_DETAILS = 'INBOUNDPROCESSING_GET_DETAILS';
export const INBOUNDPROCESSING_CLEAR_DETAILS = 'INBOUNDPROCESSING_CLEAR_DETAILS';

export const INBOUNDPROCESSING_GET_VISUAL_TRACKING = 'INBOUNDPROCESSING_GET_VISUAL_TRACKING';
export const INBOUNDPROCESSING_CLEAR_VISUAL_TRACKING = 'INBOUNDPROCESSING_CLEAR_VISUAL_TRACKING';

export const INBOUNDPROCESSING_CLEAR_FLAG_LIST = 'INBOUNDPROCESSING_CLEAR_FLAG_LIST';
export const INBOUNDPROCESSING_GET_FLAG_LIST = 'INBOUNDPROCESSING_GET_FLAG_LIST';

export const RESOURCE_LIST_LOGS = 'RESOURCE_LIST_LOGS';
export const RESOURCE_CLEAR_LOGS = 'RESOURCE_CLEAR_LOGS';
