import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function saveTransfer(obj, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/transfer/save", obj)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearTransfer() {
    return (dispatch) => {
        dispatch({type: actionTypes.TRANSFER_CLEAR});
    };
}


export function getTransfer(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/transfer/get/?id=" + id)
                .then((response) => {
                    dispatch({type: actionTypes.TRANSFER_GET, payload: response.data});
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function removeTransfer(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/transfer/delete", {id: id})
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}

export function listTransfers(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/transfer/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.TRANSFER_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function clearTransferList() {
    return (dispatch) => {
        dispatch({type: actionTypes.TRANSFER_LIST_CLEAR});
    };
}
