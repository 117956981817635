import React, {Component} from 'react'
import {connect} from "react-redux";


class AuthorizeContainer extends Component {
    render() {
        return this.props.oidc && this.props.oidc.user && !this.props.oidc.user.expired ? this.props.children : null;
    }
}


function mapStateToProps(state) {
    return { oidc: state.oidc }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeContainer)