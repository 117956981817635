import httpHandler from "../../../utils/httpHandler";
import * as actionTypes from "../constants/actionTypes";

export function save(obj, callback) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/customer/save", obj)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function list(args) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/customer/list", args)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.CUSTOMER_LIST, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function get(id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/customer/get/?id=" + id)
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.CUSTOMER_GET, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function remove(id, callback) {
    return (dispatch) => {
        httpHandler
            .post("/api/customer/delete", {id: id})
            .then((response) => {
                callback();
            })
            .catch((error) => {
                callback();
            });
    };
}

export function clear() {
    return (dispatch) => {
        dispatch({type: actionTypes.CUSTOMER_CLEAR});
    };
}

export function getAdditionalData() {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            httpHandler
                .get("/api/customer/getAdditionalData")
                .then((response) => {
                    resolve(response);
                    dispatch({type: actionTypes.CUSTOMER_GET_ADDITIONAL_DATA, payload: response.data});
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function cloneCustomer(args) {
    return () => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post("/api/customer/cloneCustomer", args)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
}

export function uploadLogo(formData) {
    return () => {
        return new Promise((resolve, reject) => {
            httpHandler
                .post('/api/customer/logo', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    }
}

