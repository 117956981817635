import * as actionTypes from '../constants/actionTypes';

export default(state = {}, action) => {
    switch (action.type) {
        case actionTypes.LOCATION_LIST_CLEAR:
            return {
                ...state,
                list: null,
            };
        case actionTypes.LOCATION_LIST:
            return {
                ...state,
                list: action.payload
            };
        case actionTypes.LOCATION_LIST_CUSTOMER:
            return {
                ...state,
                customers: action.payload
            };
        case actionTypes.LOCATION_GET:
            return {
                ...state,
                location: action.payload
            };
        case actionTypes.LOCATION_GET_CLEAR:
            return {
                ...state,
                location: null
            };
        default:
            return state;
    }
};