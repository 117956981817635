import React, { Component } from "react";
import parse from "../../../utils/querystring";
import { bindActionCreators } from "redux";
import * as importerActions from "../actions/importerActions";
import * as customerActions from "../../customer/actions/customerActions";
import * as notificationActions from "../../notifications/actions/notificationActions";
import * as transferActions from "../../transfer/actions/transferActions";
import { connect } from "react-redux";
import FormLoader from "../../../components/common/FormLoader";
import { Tab, Tabs } from "react-bootstrap";
import NameEditorModal from "../../../components/common/NameEditorModal";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal";
import TestRunModal from "./TestRunModal";
import ImporterStats from "./ImporterStats";
import ImporterTestsTab from "./tabs/ImporterTestsTab";
import ScriptEditor from "../../../components/common/ScriptEditor";
import TagsInput from "react-tagsinput";
import { ScriptDiffChecker } from "../../../components/common/ScriptDiffChecker";

class ImporterEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerId: null,
      isLoading: false,
      isWorking: false,
      importer: this.getDefaultImporter(),
      showTestRunModal: false,
      columnsExpanded: false,
      selectedScriptNode: null,
      isNewScriptModalOpen: false,
      isScriptDeleteConfirmationOpen: false,
    };
  }

  reloadImporter = (noClean) => {
    if (!noClean) this.props.actions.clearImporter();
    if (this.props.match.params.importerId) {
      this.setState({ isLoading: true });

      this.props.actions
        .getImporter(this.props.match.params.importerId)
        .then((response) => {
          Promise.all([
            this.props.actions.listImporterScripts({
              importerId: response.data.id,
            }),
            this.props.transferActions.listTransfers({
              customerId: response.data.customerId,
              pageSize: null,
              page: null,
            }),
          ])
            .then(() => {
              this.setState({ isLoading: false });
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        });
    } else {
      this.props.transferActions.listTransfers({
        customerId: this.state.importer.customerId,
      });
      this.setState({ importer: this.getDefaultImporter() });
    }
    this.getAdditionalData();
  };

  getAdditionalData = () => {
    this.props.customerActions.getAdditionalData();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.customers && nextProps.customers.additionalData) {
      this.setState(
        {
          importerTypes: nextProps.customers.additionalData.importerTypes || [],
          importerObjectType:
            nextProps.customers.additionalData.importerObjectType || [],
        },
        () => {
          this.initImporterType();
        }
      );
    }

    const importer = nextProps.importers.importer
      ? { ...nextProps.importers.importer }
      : this.getDefaultImporter();

    this.setState({
      importer: importer,
      scripts: nextProps.importers.scripts
        ? nextProps.importers.scripts.data
        : [],
    });
  }

  initImporterType = () => {
    if (
      !this.state.importer.importerType &&
      this.state.importerTypes.length > 0
    ) {
      this.setState({
        importer: {
          ...this.state.importer,
          importerType: this.state.importerTypes[0].value,
        },
      });
    }
  };

  getDefaultImporter = () => {
    return {
      id: this.props.match.params.importerId,
      name: "",
      workbook: "",
      customerId: this.state ? this.state.customerId : null,
    };
  };

  componentDidMount() {
    let params = parse(this.props.location.search);
    if (params["customerId"]) {
      this.setState(
        {
          customerId: params["customerId"],
          importer: {
            ...this.state.importer,
            customerId: params["customerId"],
          },
        },
        this.reloadImporter
      );
    } else {
      this.reloadImporter();
    }
  }

  handleSave = (e, noClean) => {
    return new Promise((resolve, reject) => {
      this.setState({ isWorking: true });
      if (e) {
        e.preventDefault();
      }

      let saveImporterPromise = this.props.actions
        .saveImporter(this.state.importer)
        .then((response) => {
          this.props.notification.add("Importer saved", "", "success");
          this.setState({ isWorking: false });
          this.props.history.push("/importer/edit/" + response.data);
          resolve();
        })
        .catch(() => {
          this.props.notification.add("Save failed", "", "fail");
          this.setState({ isWorking: false });
          reject();
        });

      let saveScriptsPromise = this.props.actions.saveImporterScripts(
        this.state.scripts
      );
      Promise.all([saveImporterPromise, saveScriptsPromise]).then(() => {
        this.reloadImporter(noClean);
      });
    });
  };

  handleTransferChange = (e) => {
    const { value } = e.target;
    this.setState({
      importer: {
        ...this.state.importer,
        transferId: value,
      },
    });
  };

  render() {
    return (
      <div>
        <div className="page-header page-header-default">
          <div className="page-header-content">
            <div className="page-title">
              <h4>
                <span className="text-semibold">Importer edit</span>
              </h4>
            </div>

            <div className="heading-elements">
              <div className="heading-btn-group">
                <button
                  onClick={() => {
                    this.setState({ showTestRunModal: true });
                  }}
                  className="btn bg-slate has-text"
                >
                  <i className="fa fa-play" />
                  <span>Test run</span>
                </button>
                {this.state.selectedScriptNode &&
                  this.state.selectedScriptNode.props.isCustom ? (
                  <button
                    disabled={this.state.isWorking || this.state.isLoading}
                    onClick={() => {
                      this.setState({ isScriptDeleteConfirmationOpen: true });
                    }}
                    className="btn bg-danger has-text"
                  >
                    <span>Delete</span>
                  </button>
                ) : null}
                {this.state.selectedScriptNode &&
                  this.state.selectedScriptNode.props.eventKey === "custom" ? (
                  <button
                    disabled={this.state.isWorking || this.state.isLoading}
                    onClick={() => {
                      this.setState({ isNewScriptModalOpen: true });
                    }}
                    className="btn bg-blue has-text"
                  >
                    <span>Create</span>
                  </button>
                ) : null}
                <button
                  disabled={this.state.isWorking || this.state.isLoading}
                  onClick={this.handleSave}
                  className="btn bg-blue has-text"
                >
                  {this.state.isWorking ? (
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  ) : (
                    <i className="fa fa-check" />
                  )}
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="panel panel-flat relative">
          {this.state.isLoading ? <FormLoader /> : null}
          <div className="panel-body">
            <div className="row">
              <form className="form-horizontal">
                <div className="form-group">
                  <label className="col-sm-1 control-label">Name</label>
                  <div className="col-sm-11">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            name: e.target.value,
                          },
                        })
                      }
                      value={this.state.importer.name}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-1 control-label">
                    Import object type
                  </label>
                  <div className="col-sm-5">
                    <select
                      value={this.state.importer.importerObjectType}
                      onChange={(e) => {
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            importerObjectType: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                    >
                      {this.state.importerObjectType
                        ? this.state.importerObjectType.map((x) => {
                          return (
                            <option key={x.value} value={x.value}>
                              {x.text}
                            </option>
                          );
                        })
                        : null}
                    </select>
                  </div>
                  <label className="col-sm-1 control-label">Type</label>
                  <div className="col-sm-5">
                    <select
                      value={this.state.importer.importerType}
                      onChange={(e) => {
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            importerType: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                    >
                      {this.state.importerTypes
                        ? this.state.importerTypes.map((x) => {
                          return (
                            <option key={x.value} value={x.value}>
                              {x.text}
                            </option>
                          );
                        })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-1 control-label">Workbook</label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            workbook: e.target.value,
                          },
                        })
                      }
                      value={this.state.importer.workbook}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-1 control-label">Transfer</label>
                  <div className="col-sm-5">
                    <select
                      value={this.state.importer.transferId}
                      onChange={this.handleTransferChange}
                      className="form-control"
                    >
                      <option value={""}>None</option>
                      {this.props.transfer.list.data.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <label className="col-sm-1 control-label">
                    Record argument
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            recordArgument: e.target.value,
                          },
                        })
                      }
                      value={this.state.importer.recordArgument}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-1 control-label">
                    Action Identifier
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            actionIdentifier: e.target.value,
                          },
                        })
                      }
                      value={this.state.importer.actionIdentifier}
                    />
                  </div>
                </div>
                {this.state.importer &&
                  (this.state.importer.importerType == 1 ||
                    this.state.importer.importerType == 2) && (
                    <div className="form-group">
                      <label className="col-sm-1 control-label">
                        Transaction set node
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              importer: {
                                ...this.state.importer,
                                transactionSetNode: e.target.value,
                              },
                            })
                          }
                          value={this.state.importer.transactionSetNode}
                        />
                      </div>
                    </div>
                  )}
                <div className="form-group">
                  <label className="col-sm-1 control-label">Settings</label>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            settings: e.target.value,
                          },
                        })
                      }
                      value={this.state.importer.settings}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-1 control-label">
                    Summary notifications
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="checkbox"
                      checked={this.state.importer.enableSummaryNotification}
                      onChange={(e) => {
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            enableSummaryNotification: e.target.checked,
                          },
                        });
                      }}
                    />
                  </div>
                  <label className="col-sm-1 control-label">
                    Notification emails
                  </label>
                  <div className="col-sm-5">
                    <TagsInput
                      inputProps={{
                        placeholder: "Add email",
                      }}
                      value={this.state.importer.notificationEmailList || []}
                      onChange={(values) => {
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            notificationEmailList: values,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-1 control-label">
                    Error notifications
                  </label>
                  <div className="col-sm-5">
                    <input
                      type="checkbox"
                      checked={this.state.importer.enableErrorNotification}
                      onChange={(e) => {
                        this.setState({
                          importer: {
                            ...this.state.importer,
                            enableErrorNotification: e.target.checked,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              <ImporterStats
                className="margin-bot-10"
                importer={this.state.importer}
              />
            </div>
            <Tabs
              id="ImporterTabs"
              activeKey={this.state.selectedTab}
              onSelect={(key) => {
                this.setState({ selectedTab: key });
              }}
            >
              <Tab eventKey={"scripts"} title="Scripts">
                <ScriptEditor
                  scripts={this.state.scripts || []}
                  onNodeSelected={(node) => {
                    this.setState({ selectedScriptNode: node });
                  }}
                  onScriptChanged={(id, value) => {
                    let scripts = this.state.scripts.slice(0);
                    let script = scripts.find((x) => x.id == id);
                    script.script = value;
                    this.setState({ scripts: scripts });
                  }}
                />
              </Tab>
              <Tab eventKey={"test"} title="Test result">
                <ImporterTestsTab importer={this.state.importer} />
              </Tab>
              <Tab
                eventKey={"history"}
                title="History"
                mountOnEnter={true}
                unmountOnExit={true}
              >
                {this.state.scripts?.length > 0 ? (
                  <ScriptDiffChecker
                    contextId={
                      this.state.selectedScriptNode
                        ? this.state.selectedScriptNode.props.eventKey
                        : this.state.scripts.at(0).id
                    }
                    currentScript={
                      this.state.selectedScriptNode
                        ? this.state.scripts.find(
                          (a) =>
                            a.id ==
                            this.state.selectedScriptNode.props.eventKey
                        )
                        : this.state.scripts.at(0)
                    }
                  />
                ) : null}
              </Tab>
            </Tabs>
          </div>
        </div>
        <NameEditorModal
          title={"Create new script"}
          onClose={() => this.setState({ isNewScriptModalOpen: false })}
          onConfirm={(name) => {
            this.setState({ isNewScriptModalOpen: false });
            let scripts = this.state.scripts.slice(0);
            scripts.push({
              isDefault: false,
              name: name,
              importerId: this.state.importer.id,
              scriptType: 2,
            });
            this.setState({ scripts: scripts }, () => {
              this.handleSave();
            });
          }}
          show={this.state.isNewScriptModalOpen}
        />
        <DeleteConfirmationModal
          title={"Delete script confirmation"}
          description={"Are you sure you want to delete selected script?"}
          onClose={() =>
            this.setState({ isScriptDeleteConfirmationOpen: false })
          }
          onConfirm={() => {
            let scripts = this.state.scripts.slice(0);
            let script = scripts.find(
              (x) => x.id === this.state.selectedScriptNode.props.eventKey
            );
            if (script) {
              this.props.actions.removeImporterScript(script.id).then(() => {
                this.props.notification.add("Script deleted", "", "success");
                this.reloadImporter();
              });
            }
            this.setState({ isScriptDeleteConfirmationOpen: false });
          }}
          show={this.state.isScriptDeleteConfirmationOpen}
        />
        <TestRunModal
          onClose={() => this.setState({ showTestRunModal: false })}
          saveImporter={(e) => {
            return this.handleSave(e, true);
          }}
          show={this.state.showTestRunModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.customer,
    importers: state.importer,
    transfer: state.transfer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(importerActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    notification: bindActionCreators(notificationActions, dispatch),
    transferActions: bindActionCreators(transferActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImporterEdit);
